import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/pages/CercoVerde.scss";
import "../styles/pages/Home.scss";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/swiper-bundle.css';
import Title from "../components/Title";
import { Product, ResponseGeneral } from "../common/types";
import Card, { CardsManager } from "../components/Card";
import routes from "../common/routes";
import Input from "../components/Input";

import Select from 'react-select';
import Loader from "../components/Loader";
import { madePost } from "../common/post";
import toast from "../common/toast";

export const Categories: { title: string, code: string }[] = [ //p: productos, f: frutas
    {
        title: "Frutas amazónicas",
        code: "f-amz"
    },
    {
        title: "Ecoturismo",
        code: "p-eco-t"
    },
    {
        title: "Cacao",
        code: "f-cacao"
    },
    {
        title: "Cuidado de fauna",
        code: "p-pre-fn"
    },
    {
        title: "Café",
        code: "f-cafe"
    },
    {
        title: "Cosméticos",
        code: "p-cosmt"
    },
    {
        title: "Cuidado de flora y fuentes de agua",
        code: "p-pre-fl-ag"
    },
    {
        title: "Plantas medicinales",
        code: "p-med"
    },
    {
        title: "Materiales de contrucción",
        code: "p-const"
    },
    {
        title: "Manejo de abejas nativas",
        code: "p-abj"
    },
    {
        title: "Derivados de frutos amázonicos",
        code: "p-drv-amz"
    },
    {
        title: "Mateial audiovisual",
        code: "p-maudv"
    },
];

const Productss: Product[] = [
    {
        product_code: "1",
        name: "Producto 1",
        description: "<i>Descripcion</i>",
        winnings: [
            {
                icon: "check-lg",
                text: "Winning 1"
            }
        ],
        provider: {
            enterprise: "GNE",
            comunity: "N/N",
            others: "Proveedor registrado en ...",
            location: "Santa Cruz, Bolivia",
            locationInfo: "Area caliente a 5km de Ascencion de Guarayos",
            sanitaryCode: "10202120232523RD"
        },
        category: "f-amz",
        images: ["https://pagos.nuevaeconomia.com.bo/media/courses/powerbi-para-finanzas.png", "https://pagos.nuevaeconomia.com.bo/media/courses/powerbi-para-finanzas.png"],
        price: 100
    },
    {
        product_code: "1",
        name: "Producto 1",
        description: "<i>Descripcion</i>",
        winnings: [
            {
                icon: "check-lg",
                text: "Winning 1"
            }
        ],
        provider: {
            enterprise: "GNE",
            comunity: "N/N",
            others: "Proveedor registrado en ...",
            location: "Santa Cruz, Bolivia",
            locationInfo: "Area caliente a 5km de Ascencion de Guarayos",
            sanitaryCode: "10202120232523RD"
        },
        category: "p-eco-t",
        images: ["https://pagos.nuevaeconomia.com.bo/media/courses/powerbi-para-finanzas.png", "https://pagos.nuevaeconomia.com.bo/media/courses/powerbi-para-finanzas.png"],
        price: 200
    },
    {
        product_code: "1",
        name: "Producto 1",
        description: "<i>Descripcion</i>",
        winnings: [
            {
                icon: "check-lg",
                text: "Winning 1"
            }
        ],
        provider: {
            enterprise: "GNE",
            comunity: "N/N",
            others: "Proveedor registrado en ...",
            location: "Santa Cruz, Bolivia",
            locationInfo: "Area caliente a 5km de Ascencion de Guarayos",
            sanitaryCode: "10202120232523RD"
        },
        category: "f-cacao",
        images: ["https://pagos.nuevaeconomia.com.bo/media/courses/powerbi-para-finanzas.png", "https://pagos.nuevaeconomia.com.bo/media/courses/powerbi-para-finanzas.png"],
        price: 300
    },
]

const CercoVerde = () => {

    const [category, setCategory] = useState({ category: "*", isOpen: false });
    const [Products, setProducts] = useState({ value: [] as Product[], isLoad: false });
    const [renderProducts, setRenderProducts] = useState([] as Product[]);
    const [filter, setFilter] = useState({ word: "", priceMin: 0, priceMax: 1000000000, categories: ["all"] });

    const navigate = useNavigate();

    const allSectionsRef = useRef({} as HTMLDivElement);

    useEffect(() => {
        madePost(
            {
                key: "get-visible-products"
            }, "Product.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                setProducts({ value: res.VALUE, isLoad: true });
                setRenderProducts(res.VALUE);
            } else {
                toast.error("Ha ocurrido un error, porfavor intenta de nuevo más tarde")
            }
        }).catch(err => {
            console.error("> Error obtaining courses: ", err)
        })
    }, []);

    useEffect(() => {
        let newvals = Products.value.filter((p: Product) => {
            let returnIt = false;

            if (filter.categories.includes("all") || filter.categories.includes(p.category)) {
                //si se tiene todass como catergorias seleccionada, revisar precios y name

                if (filter.priceMax >= p.price && filter.priceMin <= p.price) {
                    //precios

                    if (p.name.toLowerCase().includes(filter.word.toLowerCase())) {
                        //name

                        returnIt = true;
                    }
                }
            }

            return returnIt;
        });

        setRenderProducts(newvals);
    }, [filter])

    const SupSection = () => {

        return <div className="home-supsection">
            <img src="/assets/img/cercoverdeBg.jpg" alt="" />

            <div className="home-title-content sides-section-x" style={{ background: "linear-gradient(223deg, rgba(0, 0, 0, 0.1) 25%, rgba(255, 255, 255, 0.05) 100%)" }}>
                <h1>
                    Apoya a nuestros productores bolivianos
                </h1>

                <div className="see-more-home-supsection" onClick={() => {
                    setCategory({ category: "*", isOpen: true });

                    setTimeout(() => window.scrollTo({ top: allSectionsRef.current.getBoundingClientRect().top, behavior: "smooth" }), 100);

                }}>
                    <i className="bi bi-arrow-right"></i>
                    <span>Ver productos</span>
                </div>
            </div>

        </div>
    }


    const goToCourse = (pr: Product) => {
        navigate(routes.producto + "/" + pr.name + "-" + pr.product_code, {
            state: {
                product: pr
            }
        })
    }

    const onWordChange = (ev: any) => {
        setFilter({ word: ev.target.value, priceMin: filter.priceMin, priceMax: filter.priceMax, categories: filter.categories });
    }
    const onMinChange = (ev: any) => {
        console.debug(ev.target.value.length > 0)
        setFilter({ word: filter.word, priceMin: (ev.target.value.length > 0 ? parseInt(ev.target.value) : 0), priceMax: filter.priceMax, categories: filter.categories });
    }
    const onMaxChange = (ev: any) => {
        setFilter({ word: filter.word, priceMin: filter.priceMin, priceMax: (ev.target.value.length > 0 ? parseInt(ev.target.value) : 10000000000), categories: filter.categories });
    }
    const onCatChange = (ev: any) => {
        let vl: string[] = ev.map((e: { label: string, value: string }) => {
            return e.value;
        });
        setFilter({ word: filter.word, priceMin: filter.priceMin, priceMax: filter.priceMax, categories: vl });
    }

    return <div className="cercoverde-page">
        <Header />
        <SupSection />
        {
            Products.isLoad ?
                <div className="cercoverde-content" ref={allSectionsRef}>
                    <section className="sides-section-x sides-max-y">
                        <Title>Categorías populares</Title>
                        <Swiper
                            style={{ maxWidth: "86vw", width: "100%" }}
                            slidesPerView={2}
                            spaceBetween={20}
                            breakpoints={{
                                450: {
                                    slidesPerView: 3
                                },
                                600: {
                                    slidesPerView: 5
                                },
                                800: {
                                    slidesPerView: 7
                                },
                                1100: {
                                    slidesPerView: 9
                                }
                            }}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination]}

                            className="categories-swiper  "
                        >
                            {
                                Categories.map((c: { title: string, code: string }, index: number) => {
                                    let len = renderProducts.filter((p: Product) => {
                                        return p.category == c.code;
                                    }).length;
                                    return <SwiperSlide
                                        className={"slide-categorie " + ((category.category == c.code && category.isOpen) ? "active" : "")}
                                        onClick={() => {
                                            setCategory({ category: c.code, isOpen: true });
                                        }}
                                        style={{ order: Categories.length - len }}
                                    >
                                        <img src={"/assets/icons/categories/" + c.code + ".png"} alt="" onError={(ev: any) => {
                                            ev.target.src = "/assets/img/404-img.png";
                                        }} />
                                        <span>{c.title}</span>

                                        <small className={"len:" + len.toString() + " badge"} >{len} Encontrado {len > 1 ? "s" : ""}</small>
                                    </SwiperSlide>
                                })
                            }
                        </Swiper>

                        <div className="search-section ">
                            <div className="form-content expanded">
                                <Input title={"Buscar"} inputProperties={{ placeholder: "Buscar producto", type: "search", onInput: onWordChange }} />

                                <Input id={1} title={"P. min"} inputProperties={{ type: "number", min: 0, onChange: onMinChange, defaultValue: 0 }} />
                                <Input id={2} title={"P. máx"} inputProperties={{ type: "number", min: 1, onChange: onMaxChange, defaultValue: 9000 }} />

                                <div className="form-input multiselect">
                                    <label htmlFor="">Categorias</label>
                                    <div className="form-input-element">
                                        <Select
                                            defaultValue={[{ label: "Todas", value: "all" }] as any}
                                            isMulti
                                            name="cat"
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            onChange={onCatChange}
                                            options={
                                                [
                                                    { label: "Todas", value: "all" },
                                                    ...Categories.map((c: { title: string, code: string }) => {
                                                        return { label: c.title, value: c.code };
                                                    })
                                                ]
                                            }
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {
                        category.isOpen ?
                            <section className={"sides-section-x sides-max-y"}>
                                <Title>{Categories.find((e: { title: string, code: string }) => {
                                    return e.code == category.category;
                                })?.title}</Title>

                                {

                                    <CardsManager cards={
                                        renderProducts.filter((p: Product) => {
                                            return p.category == category.category;
                                        }).map((product: Product, index: number) => {
                                            return <Card
                                                title={product.name}
                                                description={product.description}
                                                image={JSON.parse(product.images as unknown as string)[0]}
                                                price={product.price.toString()}
                                                key={"card-" + index}
                                                onClick={() => goToCourse(product)}
                                            />
                                        })
                                    } />
                                }

                            </section>
                            :
                            <></>
                    }

                    {
                        Categories.map((cat: { title: string, code: string }) => {
                            let prods: Product[] = renderProducts.filter((pr: Product) => {
                                return pr.category == cat.code;
                            })

                            if (prods.length > 0 && category.category != cat.code) {
                                return <section className={"sides-section-x sides-max-y"}>
                                    <Title>{cat.title}</Title>

                                    <CardsManager cards={
                                        prods.map((product: Product, index: number) => {
                                            return <Card
                                                title={product.name}
                                                description={product.description}
                                                image={JSON.parse(product.images as unknown as string)[0]}
                                                price={product.price.toString()}
                                                key={"card-" + index}
                                                onClick={() => goToCourse(product)}
                                            />
                                        })
                                    } />

                                </section>
                            } else {
                                return <></>
                            }
                        })
                    }

                </div>
                :
                <Loader text="Cargando productos..." />
        }
        <Footer />
    </div>
}

export default CercoVerde;