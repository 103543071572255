import "../styles/components/Loader.scss";

const Loader = (props: {
    text: string,
    style?: React.CSSProperties
}) => {
    return <div id="loader" style={props.style ? props.style : {}}>
        <div className="lds-facebook"><div></div><div></div><div></div></div>
        <span id="loader-messagge">{props.text}</span>
    </div>
}

export default Loader;