import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { madePost } from "../common/post";
import routes from "../common/routes";
import toast from "../common/toast";
import { ResponseGeneral, User } from "../common/types";
import { getUser } from "../common/user";
import { setItem } from "../common/useStorage";
import { onCatch, ReCaptchaClientKey, userStorageName } from "../common/variables";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Input, { InputPhone } from "../components/Input";
import InputLocation from "../components/InputLocation";

const Login = () => {
    const location = useLocation();
    const user: User | null = getUser();

    const [action, setAction] = useState("login" as "login" | "logup");
    const [isValidForm, setIsValidForm] = useState(false);
    const [bc, setBc] = useState<{ from: string, action: "login" | "logup", windowFrom: any }>();
    const [cell, setCell] = useState<any>();
    const [captcha, setCaptcha] = useState<any>();
    const [locationV, setLocation] = useState("");

    const captchaRef = useRef({} as any);

    const navigate = useNavigate();

    const nameRef = useRef({} as HTMLInputElement);
    const ci_nitRef = useRef({} as HTMLInputElement);
    const emailRef = useRef({} as HTMLInputElement);

    const passwordRef = useRef({} as HTMLInputElement);
    const repeatPasswordRef = useRef({} as HTMLInputElement);

    useEffect(() => {
        setIsValidForm(false);
    }, [action])

    useEffect(() => {
        validateForm({})
    }, [cell])

    useEffect(() => {
        window.addEventListener("message", (ev: { data: any, source: any }) => {
            try {
                if (Object.keys(ev.data).includes("from")) {

                    setBc({ ...ev.data, windowFrom: ev.source })
                }
            } catch (er) { }
        }, false)
    }, [])

    useEffect(() => {
        if (bc) {
            bc.windowFrom.postMessage("received", "*");//responsing

            setAction(bc.action);

            if (user) {
                bc.windowFrom.postMessage({ user: JSON.stringify(user), storageName: userStorageName }, "*");
                window.close();
            }
        }
    }, [bc])

    const onCreateAccount = () => {
        let tokenCaptcha = captchaRef.current.getValue();
        captchaRef.current.reset();
        madePost(
            {
                key: "create-user",
                name: nameRef.current.value,
                nit_ci: ci_nitRef.current.value,
                email: emailRef.current.value,
                cellphone: cell,
                location: locationV,
                password: passwordRef.current.value,
                tokenReCaptcha: tokenCaptcha
            },
            "User.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                //Login
                toast.success("Tu cuenta ha sido creada satisfactoriamente");

                //Save user info

                let usr = {
                    user_code: res.VALUE.USER_CODE,
                    full_name: nameRef.current.value,
                    ci_nit: ci_nitRef.current.value,
                    email: emailRef.current.value,
                    cellphone: cell,
                    location: locationV,
                    password: res.VALUE.PASSWORD
                }

                setItem(userStorageName, JSON.stringify(usr));

                if (bc) {
                    bc.windowFrom.postMessage({ user: JSON.stringify(usr), storageName: userStorageName }, "*")

                    //navigate(bc.from);
                    window.close();
                } else {
                    navigate(routes.profile);
                }
            } else {
                toast.error(res.EXCEPTION_MESSAGE);
            }

        }).catch(err => {
            toast.error(onCatch)
        })
    }

    const onLoginAccount = () => {
        madePost(
            {
                key: "login-user",
                email: emailRef.current.value,
                password: passwordRef.current.value
            },
            "User.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast.success("Accediste correctamente");

                setItem(userStorageName, JSON.stringify(res.VALUE));

                if (bc) {
                    bc.windowFrom.postMessage({ user: JSON.stringify(res.VALUE), storageName: userStorageName }, "*")

                    //navigate(bc.from);
                    window.close();
                } else {
                    navigate(routes.profile);
                }
            } else {
                toast.error(res.EXCEPTION_MESSAGE);
            }
        }).catch(err => {
            toast.error(onCatch)
        })
    }

    const validateLoginForm = (ev: any, email?: { isEmail: boolean }) => {
        if (email) {
            if (email.isEmail && emailRef.current.value.length > 0 && passwordRef.current.value.length > 4) {
                setIsValidForm(true);
            } else {
                setIsValidForm(false);
            }
        } else {
            if (emailRef.current.value.length > 0 && passwordRef.current.value.length > 4) {
                setIsValidForm(true);
            } else {
                setIsValidForm(false);
            }
        }
    }

    const validateForm = (ev: any, email?: { isEmail: boolean }) => {

        try {
            if (email) {
                if (email.isEmail && nameRef.current.value.length > 0 &&
                    ci_nitRef.current.value.length > 0 &&
                    emailRef.current.value.length > 0 &&
                    cell!.length > 0 &&
                    locationV.length > 0 &&
                    passwordRef.current.value.length > 4 &&
                    repeatPasswordRef.current.value.length > 4 &&
                    passwordRef.current.value === repeatPasswordRef.current.value
                ) {
                    setIsValidForm(true);
                } else {
                    setIsValidForm(false);
                }
            } else {
                if (nameRef.current.value.length > 0 &&
                    ci_nitRef.current.value.length > 0 &&
                    emailRef.current.value.length > 0 &&
                    cell!.length > 0 &&
                    locationV.length > 0 &&
                    passwordRef.current.value.length > 4 &&
                    repeatPasswordRef.current.value.length > 4 &&
                    passwordRef.current.value === repeatPasswordRef.current.value
                ) {
                    setIsValidForm(true);
                } else {
                    setIsValidForm(false);
                }
            }
        } catch (er) {
            setIsValidForm(false);
        }


    }

    return <div className="login-page">
        <Header />

        <div className="form">
            <div className="form-content">
                {
                    action === "login" ?
                        <>
                            <div className="form-header">
                                <h4>Inicia Sesión</h4>
                                <span>Ingresa a tu cuenta</span>
                            </div>

                            <Input required title={<>Correo electrónico o celular <small>(+ Cód. xxxxx)</small></>} inputProperties={{
                                type: "text",
                                ref: emailRef,
                                onInput: validateLoginForm
                            }} />

                            <Input required title="Contraseña" inputProperties={{
                                type: "password",
                                ref: passwordRef,
                                onInput: validateLoginForm,
                                onKeyDown: (ev: any) => {
                                    if (ev.key === "Enter" && isValidForm) {
                                        onLoginAccount()
                                    }
                                }
                            }} />

                            <Link to={routes.recovery}>Olvidaste tu contraseña</Link>

                            <div className="form-footer">
                                <Button onClick={() => setAction("logup")}>Crear cuenta</Button>
                                <Button solid disabled={!isValidForm} onClick={onLoginAccount}>Ingresar</Button>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-header">
                                <h4>Crear cuenta</h4>
                                <span>Comencemos creando tu cuenta</span>
                            </div>

                            <Input required title="Nombre completo" inputProperties={{
                                type: "text",
                                ref: nameRef,
                                onInput: validateForm
                            }} />

                            <Input required title="Número de NIT o CI (Para facturación)" inputProperties={{
                                type: "text",
                                ref: ci_nitRef,
                                onInput: validateForm
                            }}
                            />

                            <Input required title="Correo electrónico" inputProperties={{
                                type: "email",
                                ref: emailRef,
                                onInput: validateForm
                            }}
                            />

                            <InputPhone
                                required
                                onChange={(ev) => {
                                    setCell(ev)
                                }}
                            />

                            <InputLocation required onInput={validateForm} onChange={(loc: string)=>{
                                setLocation(loc);
                            }}/>

                            <Input required title="Contraseña" inputProperties={{
                                type: "password",
                                ref: passwordRef,
                                onInput: validateForm
                            }} />

                            <Input required title="Repetir contraseña" inputProperties={{
                                type: "password",
                                ref: repeatPasswordRef,
                                onInput: (ev: any) => {
                                    if (ev.target.value !== passwordRef.current.value || ev.target.value.length < 5) {
                                        ev.target.style.color = "red";
                                        ev.target.style.border = "1px solid red";
                                    } else {
                                        ev.target.style.color = "black";
                                        ev.target.style.border = "1px solid rgba(0, 0, 0, 0.3)";

                                    }
                                    validateForm(ev);
                                },
                                onBlur: (ev: any) => {
                                    if (ev.target.value !== passwordRef.current.value || ev.target.value.length < 5) {
                                        ev.target.style.color = "red";
                                        ev.target.style.border = "1px solid red";
                                    } else {
                                        ev.target.style.color = "black";
                                        ev.target.style.border = "1px solid rgba(0, 0, 0, 0.3)";
                                    }
                                    validateForm(ev);
                                }
                            }} />

                            <ReCAPTCHA
                                sitekey={ReCaptchaClientKey}
                                onChange={(ev: any) => {
                                    setCaptcha(ev)
                                }}
                                ref={captchaRef}
                            />

                            <div className="form-footer">
                                <Button onClick={() => setAction("login")}>Iniciar sesión</Button>
                                <Button solid onClick={onCreateAccount} disabled={!isValidForm}>Crear cuenta</Button>
                            </div>


                        </>
                }
            </div>
        </div>
        <Footer />
    </div>


}

export default Login;