import saveAs from "file-saver";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cloneObject } from "../common/functions";
import { madePost } from "../common/post";
import { Course, MagazzinePlan, Product, ResponseGeneral, Winning } from "../common/types";
import { addElementToShoppingCart } from "../common/user";
import { timeShoppingCartOpen } from "../common/variables";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";

import "../styles/pages/Magazzine.scss";
import routes from "../common/routes";

export const MagazzinePlanCard = (props: {
    plan: MagazzinePlan,
    index: number,
    noborder?: boolean,
    style?: React.CSSProperties,
    onSubscribe: (plan: MagazzinePlan, index: number) => any,
}) => {
    //const habs = useRef<string[]>(JSON.parse(props.plan.habs));
    const navigate = useNavigate();

    let durationPlan = () => {
        switch (props.plan.duration) {
            case 3: return "Trimestral";
            case 6: return "Semestral";
            case 12: return "Anual";
            case 24: return "Bianual";
            default: return props.plan.duration + " Meses";
        }
    }

    const selectedPlan = () => {
        //Add to Shopping cart
    }

    return <div className={`card-magazzine-plan ${props.noborder? 'noborder' : ''}`} onClick={selectedPlan} key={"magazzine-card-" + props.index} style={props.style? props.style : {}}>
        {
            /*
            <div className="habs-icons">
            {
                habs.current.includes("physical") && <i className="bi bi-truck"></i>
            }

            {
                habs.current.includes("virtual") && <i className="bi bi-globe"></i>
            }
        </div>
            */
        }

        <img className="icon-sup-magazzine-plan" src={props.index % 2 != 0 ? "/assets/icons/check-dark-blue.png" : "/assets/icons/check-light-blue.png"} />

        <h4 className="title-plan" onClick={() => {
            navigate(routes.plan + "?id=" + props.plan.plan_code)
        }} >{props.plan.name}</h4>

        <p>{durationPlan()}</p>

        <Button onClick={() => {
            props.onSubscribe(props.plan, props.index)
        }}><b>{props.plan.price} Bs.</b></Button>

        <hr />

        <div className="winning-magazzine-plans-container">
            {
                props.plan.winnings.map((winning: Winning, index: number) => {
                    return <div className="winning-magazzine-plan">
                        {
                            /*
                            <i className={"bi bi-" + winning.icon}></i>
                            */
                        }
                        <i className="bi bi-dot"></i>
                        <small>{winning.text}</small>
                    </div>
                }
                )
            }
        </div>



    </div>
}

const Magazzine = () => {
    const location = useLocation();

    const [plans, setPlans] = useState({ values: [] as MagazzinePlan[], isLoaded: false });
    const [openCart, setOpenCart] = useState(false);

    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);

    useEffect(() => {
        if (!plans.isLoaded) {
            madePost(
                {
                    key: "get-plans"
                },
                "Magazzine.php"
            ).then(r => {
                let res = r as ResponseGeneral;
                console.debug(res)

                if (res.RESULT) {
                    setPlans({ values: restorePlans(res.VALUE), isLoaded: true });
                } else {
                    //ERrormessage
                }
            }).catch(err => {
                //ERRor message
            })
        }
    }, [])

    const restorePlans = (plans: MagazzinePlan[]): MagazzinePlan[] => {
        let plansCleaned: MagazzinePlan[] = cloneObject(plans)

        plansCleaned.map((plan: MagazzinePlan, index: number) => {
            try {
                plan.winnings = JSON.parse(plan.winnings as unknown as string);

                plansCleaned[index] = plan;
            } catch (e) { }
        })

        return plansCleaned;
    }

    return <div className="magazzine-page">
        <Header openShoppingCart={openCart} />

        {plans.isLoaded ?
            <>
                <div className="magazzine-blue-bg sides-section-x sides-max-y">
                    <h1 className="text-3xl"><b className="font-bold">Revista</b> Nueva Economía</h1>
                    <h5>Elige el plan que más te convenga</h5>
                </div>

                <div className="magazzine-plans">
                    {
                        plans.values.map((plan: MagazzinePlan, index: number) => {
                            return <MagazzinePlanCard plan={plan} index={index} onSubscribe={(plan: MagazzinePlan, index: number) => {
                                setOpenCart(true);

                                setTimeout(() => {
                                    if (openCart) {
                                        setOpenCart(false);
                                    }
                                }, timeShoppingCartOpen);

                                addElementToShoppingCart("magazzine", plan as MagazzinePlan & Course & Product);
                            }} />
                        })
                    }

                </div>

            </>
            :
            <Loader text="Cargando planes de revista" />
        }

        <Footer />
    </div>
}
export default Magazzine;