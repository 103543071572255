import { useEffect, useState } from "react"

import * as EmailValidator from 'email-validator';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export const InputSelect = (props: {
    title: any,
    inputProperties: any,
    required?: boolean,
    options: { text: string, value: string }[]
}) => {

    return <div className="form-input">
        <label>{props.title} {props.required ? <small color="red">*</small> : <></>}</label>

        <div className="form-input-element">
            <select {...props.inputProperties}>
                {
                    props.options.map((option: { text: string, value: string }, index: number) => {
                        return <option value={option.value} key={"option-select-" + props.title + index}>{option.text}</option>
                    })
                }
            </select>

        </div>
    </div>
}

export const InputPhone = (props: {
    required?: boolean,
    defaultValue?: any,
    disabled?: boolean,
    onChange: (phone: any) => any
}) => {
    return <div className="form-input">
        <label>{"Número de celular"} {props.required ? <small color="red">*</small> : <></>}</label>
        <PhoneInput
            disabled={props.disabled ? props.disabled : false}
            className="form-input-element"
            defaultCountry="BO"
            value={props.defaultValue}
            onChange={(ev) => {
                props.onChange(ev)
            }} />
    </div>
}

const Input = (props: {
    id?: number,
    title: any,
    inputProperties: any,
    required?: boolean,
    style?: React.CSSProperties,
    isWithContent?: boolean,
    isWithContentElement?: any,
}) => {

    const [visible, setVisible] = useState(props.inputProperties.type ? (props.inputProperties.type === "password" ? false : true) : true);
    const [withError, setWithError] = useState(false);

    useEffect(() => {
        console.log(withError)
    }, [withError])

    return <div className={"form-input "+(props.id? "form-input"+props.id : "")} style={props.style ? props.style : {}}>
        <label>{props.title} {props.required ? <small color="red">*</small> : <></>}</label>

        <div className="form-input-element">
            <input {...props.inputProperties}
                type={(props.inputProperties.type ? (props.inputProperties.type === "password" ? (visible ? "text" : "password") : props.inputProperties.type) : "text")}
                innerType={props.inputProperties.type}
                style={!withError ? { color: "black", border: "1px solid rgba(0, 0, 0, 0.3)" } : { color: "red", border: "1px solid red" }}
                onInput={(ev: any) => {
                    let isEmail = { is: EmailValidator.validate(ev.target.value), innerType: ev.target.type };
                    if (props.required) {
                        if (ev.target.value.length > 0 && (isEmail.innerType !== "email")) {
                            setWithError(false)
                        } else if (isEmail.innerType === "email") {
                            if (isEmail.is) {
                                setWithError(false)
                            } else {
                                setWithError(true)
                            }
                        } else {
                            setWithError(true)
                        }
                    } if (props.inputProperties.type) {
                        if (props.inputProperties.type === "email") {

                            props.inputProperties.onInput(ev, { isEmail: isEmail.is });

                        } else {
                            props.inputProperties.onInput(ev);
                        }
                    } else {
                        props.inputProperties.onInput(ev);
                    }
                }} />
            {
                props.inputProperties.type ?
                    props.inputProperties.type === "password" ?
                        <div className="form-input-password-icon" onClick={() => setVisible(!visible)}>
                            <i className={"bi bi-eye" + (visible ? "" : "-slash")}></i>
                        </div>
                        :
                        <></>
                    :
                    <></>
            }

            {
                props.isWithContent ?
                    props.isWithContentElement
                    :
                    <></>
            }

        </div>
    </div>
}

export default Input;