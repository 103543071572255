import "../styles/components/Title.scss";

const Title = (props: {
    id?: string,
    children: any
}) => {
    return <div className="title-component" id={props.id}>
        <span>{props.children}</span>
        <div></div>
    </div>
}

export default Title;