import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { cloneObject } from "../common/functions";
import { madePost } from "../common/post";
import { Course as CourseType, Location, MagazzinePlan, Product, ResponseGeneral, Winning } from "../common/types";
import { addElementToShoppingCart } from "../common/user";
import { onCatch, onNotFound, timeShoppingCartOpen } from "../common/variables";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";

import "../styles/pages/Courses.scss";

const Course = () => {

    let location: {
        pathname: string,
        state: {
            course: CourseType
        }
    } = useLocation() as Location;

    const params = useParams<{ course: string }>();

    const [course, setCourse] = useState({ value: {} as CourseType, isLoaded: false });
    const [isOpenCart, setIsOpenCart] = useState(false);

    useEffect(() => {
        if (location.state === null) {
            //Obtain by id

            if (!course.isLoaded) {

                let id = params.course!.split("-")[params.course!.split("-").length - 1];

                madePost(
                    {
                        key: "get-course-by-id",
                        id: id
                    },
                    "Course.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        setCourse({ value: restoreCourse(res.VALUE), isLoaded: true });
                    } else {
                        console.error(res.EXCEPTION_MESSAGE);
                    }
                }).catch(err => {
                    console.error(onCatch);
                })
            }
        } else {
            setCourse({ value: restoreCourse(location.state.course), isLoaded: true });
        }

    }, [location])

    const restoreCourse = (course: CourseType): CourseType => {
        //clean "[""]"

        let courseClean: CourseType = cloneObject(course)

        try {
            courseClean.details = JSON.parse(courseClean.details as unknown as string);
            courseClean.teacher = JSON.parse(courseClean.teacher as unknown as string);
        } catch (e) {
            console.debug(e)
        }
        return courseClean;
    }
    console.debug(course.value)

    return <div className="">
        <Header openShoppingCart={isOpenCart} />
        {
            course.isLoaded ?
                <>
                    <div className="page-course-container">
                        <div className="course-page">
                            <div className="controllers">
                                {course.value.start_date != null ?
                                    course.value.start_date != "NULL" && course.value.start_date != "0000-00-00" ?
                                        <small className="badge" color="light">Comienza el {(new Date(course.value.start_date).toLocaleString("es-BO"))}</small>
                                        :
                                        <></>
                                    :
                                    <></>
                                }
                                {course.value.end_date != null ?
                                    course.value.end_date != "NULL" && course.value.end_date != "0000-00-00" ?
                                        <small className="badge" color="danger">Concluye el {(new Date(course.value.end_date).toLocaleString("es-BO"))}</small>
                                        :
                                        <></>
                                    :
                                    <></>
                                }

                            </div>
                            <img src={course.value.cover_image} alt={"Curso " + course.value.title} className="bg" />

                            <div className="course-content ">
                                <div className="course-info">
                                    <span>
                                        {course.value.type === "face-to-face" ? "Curso presencial" : (course.value.type === "train" ? "Taller" : "Curso virtual")}
                                    </span>
                                    <h2>
                                        {course.value.title}
                                    </h2>
                                    <p>
                                        {course.value.description}
                                    </p>
                                </div>
                                <div className="course-winning">
                                    <span>Detalles</span>
                                    <ul className="winning-course-list">
                                        {
                                            course.value.details.map((win: Winning, index: number) => {
                                                return <li className="winning-course" key={"winning-" + index}>
                                                    <i className={"bi bi-" + win.icon}></i>
                                                    <p>{win.text}</p>
                                                </li>
                                            })
                                        }
                                    </ul>

                                    <Button solid onClick={() => {
                                        setIsOpenCart(true);

                                        setTimeout(() => {
                                            if (isOpenCart) {
                                                setIsOpenCart(false);
                                            }
                                        }, timeShoppingCartOpen);

                                        addElementToShoppingCart("course", course.value as MagazzinePlan & CourseType & Product);
                                    }}>
                                        Comenzar aprendiendo
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <Loader text="Cargando curso" />
        }
        <Footer />
    </div>
}

export default Course;
