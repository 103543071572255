import Footer from "../components/Footer";
import Header from "../components/Header";

import "../styles/pages/Policy.scss";

const Policy = () => {
    return <div className="policy-page" style={{ width: "100%" }}>
        <Header />
        <div className="content">
            <h2>NUESTRA POLÍTICA DE USO, PRIVACIDAD Y COOKIES</h2>

            <span><strong>1. ¿QUIÉNES SOMOS?</strong></span>

            <p>
                Somos Grupo Nueva Economía y tratamos tus datos personales como corresponsables del tratamiento. Esto quiere decir que nos hacemos cargo conjuntamente de cómo usar y proteger tus datos.
            </p>

            <span><strong>2. ¿PARA QUÉ USAMOS TUS DATOS?</strong></span>

            <p>
                Usaremos tus datos (obtenidos online mediante el registro o tu actividad), entre otras finalidades, para gestionar tu registro como usuario, gestionar la compra de productos o servicios, atender tus consoltas así como para, en caso de que lo desees, enviarte nuestras comunicaciones personalizadas.
            </p>

            <span><strong>3. ¿POR QUÉ LOS USAMOS?</strong></span>

            <p>
                Estamos legitimados para tratar tus datos por diferentes motivos. El principal, es que necesitamos tratarlos para ejecutar el contrato que aceptas con nosotros al registrarte y al hacer una compra, suscripción o disfrutar de alguno de nuestros servicios o funcionalidades, aunque hay otras razones que nos legitiman a ello, como el interés en atender tus consoltas o el consentimiento que nos prestas para enviarte nuestras newsletter, entre otras.
            </p>

            <span><strong>4. ¿CON QUIÉN COMPARTIMOS TUS DATOS?</strong></span>

            <p>
                Compartiremos tus datos con prestadores de servicios que nos ayudan o dan soporte, ya sean empresas propias o asociadas de la empresa, o colaboradores externos con quien hemos llegado a un acuerdo.
            </p>

            <span><strong>5. TUS DERECHOS</strong></span>

            <p>
                Tienes derecho a acceder, rectificar o suprimir tus datos personales. En algunos casos, también tienes otros derechos, por ejemplo, a oponerte a que usemos tus datos o a portarlos. Te invitamos a leer nuestra Política de Uso, Privacidad y Cookies completa a continuación para entender en detalle el uso que haremos de tus datos personales y los derechos de los que dispones en relación con los mismos.
            </p>

            <span>
                ANTES DE EMPEZAR…
            </span>

            <p>
                En esta Política de Privacidad y Cookies encontrarás toda la información relevante que aplica al uso que hacemos de los datos personales de nuestros clientes y usuarios.
                <br />
                <br />

                Somos transparentes sobre lo que hacemos con tus datos personales, para que entiendas las implicaciones de los usos que llevamos a cabo o los derechos de los que dispones en relación con tus datos:
                <br />
                <br />
                Ponemos a tu disposición de forma permanente toda la información en esta Política de Uso, Privacidad y Cookies que puedes consoltar cuando lo estimes oportuno y además, también irás encontrando información acerca de cada tratamiento de tus datos personales conforme vayas interactuando con nosotros.
                <br />
                <br />
                Algunos nombres que usaremos en esta Política de Uso,  Privacidad y Cookies:
                <br />
                <br />
                Cuando hablamos de nuestra Plataforma, nos referiremos en general a cualquiera de los canales, medios digitales, páginas web, correo o cualquier otro medio que hayas utilizado para interactuar con nosotros
            </p>

            <span><strong>1. ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE TUS DATOS?</strong></span>

            <p>Los responsables del tratamiento de tus datos somos:</p>
            <ol>
                <li>
                    Grupo Nueva Economía que actualmente opera en Bolivia.
                </li>
            </ol>

            <span><strong>2. ¿CON QUÉ FINALIDAD TRATAMOS TUS DATOS PERSONALES?</strong></span>

            <p>
                Dependiendo de los productos, servicios o funcionalidades de los que quieras disfrutar en cada momento necesitaremos tratar unos datos u otros, que en general serán, según el caso, los siguientes:

            </p>

            <ol>
                <li>
                    Tus datos identificativos (por ejemplo, tu nombre, apellido, idioma y ubicación desde el que interactúas con nosotros, datos de contacto, etc)
                </li>
                <li>
                    Información de tus actividades en nuestras plataformas (por ejemplo, tu carrito de compras, planes de revista, cursos, tu actividad sobre estos,  etc.)
                </li>
                <li>
                    Datos de conexión, geolocalización y navegación (en caso de que interactúen con nosotros desde el móvil, por ejemplo)
                </li>
                <li>
                    Información comercial (por ejemplo, si estás suscrito a nuestra newsletter)
                </li>
                <li>
                    Datos sobre tus gustos y preferencias.
                </li>
            </ol>

            <p>
                Recuerda que, cuando te pidamos que rellenes tus datos personales para darte acceso a alguna funcionalidad o servicio de la Plataforma, marcaremos algunos campos como obligatorios, puesto que son datos que necesitamos para poder prestarte el servicio o darte acceso a la funcionalidad en cuestión. Por favor, ten en cuenta que si decides no facilitarnos estos datos, es posible que no puedas completar tu registro como usuario o que no puedas disfrutar de esos servicios o funcionalidades.
                <br />
                En determinados casos, un tercero puede habernos facilitado información sobre ti al usar una funcionalidad o servicio de la Plataforma, por ejemplo, si se te ha inscrito en algún curso o capacitación o si alguna institución solicita tu registro como parte de alguna inscripción masiva. En tales casos, únicamente trataremos tus datos en relación con esa funcionalidad o servicio, de conformidad con lo establecido en la presente Política de Privacidad y Cookies.
                Dependiendo de cómo interactúas con nuestra Plataforma, trataremos tus datos personales para las siguientes finalidades:

            </p>

            <ol>
                <li>
                    <strong>Para gestionar tu registro como usuario de la Plataforma.</strong>
                    <br />
                    En caso de que decidas registrarte como usuario en nuestra Plataforma, necesitamos tratar tus datos para identificarte como usuario de la misma y darte acceso a sus diferentes funcionalidades, productos y servicios que están a tu disposición como usuario registrado. Puedes cancelar tu cuenta de usuario registrado contactando con nosotros a través de Atención al Cliente o los medios de comunicación descritos en la Plataforma..
                </li>
                <li>
                    <strong>
                        Para el desarrollo, cumplimiento y ejecución del contrato de compraventa o de servicios que hayas contratado con Nosotros en la Plataforma.
                    </strong>
                    <br />
                    Esta finalidad incluye el tratamiento de tus datos para, principalmente:
                    <br />
                    Contactar contigo en relación a actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluyendo el envío de encuestas de calidad sobre los productos o servicios prestados.
                    <br />
                    Gestionar el pago de los productos que compres, independientemente del medio de pago utilizado.
                    <br />
                    Activar los mecanismos necesarios con objeto de prevenir y detectar el uso indebido dentro de las múltiples plataformas que conforman Grupo Nueva Economía, por ejemplo, durante el proceso de compra, así como potenciales fraudes contra ti y/o contra Nosotros. Si consideramos que la operación puede ser fraudolenta o detectamos comportamientos anormales con indicios de tratarse de usos fraudolentos de nuestras funcionalidades, productos y servicios, este tratamiento puede tener consecuencias como el bloqueo de la transacción o la cancelación de tu cuenta de usuario.

                    Con fines de facturación y para poner a tu disposición los tickets y facturas de las compras que hayas realizado en la Plataforma.</li>
                <li>
                    <strong>Para atender las solicitudes o peticiones que realices a través de los canales de Atención al Cliente.</strong>
                    <br />
                    Sólo tratamos los datos personales que sean estrictamente necesarios para gestionar o resolver tu solicitud o petición.
                    <br />
                    Si utilizas correo electrónico como medio de comunicación, los mensajes serán almacenados para atender tu petición y garantizar la calidad de la misma.
                    <br />
                    Si decides utilizar WhatsApp como canal para comunicarte con Atención al Cliente, facilitaremos tu número de teléfono a WhatsApp Inc. para comprobar que eres usuario de este servicio. Te recomendamos que revises tu configuración de privacidad y leas la política de privacidad de WhatsApp para obtener información más detallada sobre el uso que hace WhatsApp de los datos personales de los usuarios que utilizan sus servicios.


                </li>
                <li>
                    <strong>Para fines de marketing.</strong>
                    <br />
                    Esta finalidad incluye el tratamiento de tus datos para, principalmente:
                    <br />
                    Personalizar los servicios que te ofrecemos y poder hacerte recomendaciones en función de tu interacción con los cursos o planes de revista de Grupo Nueva Economía y sus asociados u otros usuarios en la Plataforma y del análisis de tu perfil de usuario (por ejemplo, en base a tu historial de compras, inscripciones  y navegación).
                    <br />
                    En la medida en que te suscribas a nuestra Newsletter, trataremos tus datos personales para gestionar tu suscripción, incluyendo el envío de información personalizada acerca de nuestros productos o servicios a través de diversos medios (como el correo electrónico o mensaje de WhatsApp). Así mismo, podremos hacerte llegar esta información a través de notificaciones push en el caso de que las hayas activado en tu dispositivo móvil o computador.
                    Por tanto, ten en cuenta que este tratamiento de datos conlleva el análisis de tu perfil de usuario o cliente para determinar cuáles son tus preferencias y por tanto cuáles pueden ser los productos y servicios que más encajan con tu estilo a la hora de enviarte información. Por ejemplo, en base a tu historial de compras y navegación (a saber, en función de los cursos o planes de revista sobre las que hayas hecho click), te haremos sugerencias acerca de productos o servicios que creemos que pueden interesarte.
                    <br />
                    Si no deseas recibir notificaciones push, podrás desactivar esta opción en tu dispositivo móvil o computador.
                    <br />
                    Mostrarte publicidad en Internet que podrás ver cuando navegues en sitios web y apps, por ejemplo, en redes sociales. La publicidad que veas puede mostrarse de modo aleatorio, pero en otras ocasiones se trata de publicidad que puede estar relacionada con tu historial de compras, productos, revistas, cursos, preferencias y navegación.
                    <br />
                    En caso de que seas usuario de redes sociales, podremos facilitarles a aquellas con las que colaboramos determinada información para que puedan mostrarte anuncios acerca de nosotros y nuestros productos y servicios, en general, ofrecerte a ti o a otros usuarios similares, publicidad teniendo en cuenta tu perfil en dichas redes sociales. Si quieres información sobre el uso de tus datos y el funcionamiento de anuncios en estas redes sociales, te recomendamos que revises la información acerca de tu privacidad en las redes sociales en las que participas.
                    <br />
                    Adicionalmente utilizamos tus datos para llevar a cabo análisis de medición y segmentación de los anuncios que mostramos a los usuarios en algunas plataformas de colaboradores. Para ello colaboramos con estos terceros que nos ofrecen la tecnología necesaria (por ejemplo, a través de cookies, píxeles, SDK) para utilizar estos servicios. Ten en cuenta que, si bien no facilitamos datos personales identificativos a estos colaboradores, les comunicamos algún identificador único (por ejemplo, el identificador de publicidad asociado al dispositivo, identificador de alguna cookie, etc).
                    <br />
                    Enriquecimiento de datos: Cuando obtengamos tus datos personales de diversas fuentes, podremos combinarlos en determinadas circunstancias con el fin de mejorar nuestra comprensión de tus necesidades y preferencias en relación con nuestros productos y servicios (incluidas las finalidades de análisis, generación de perfiles de uso, estudios de marketing, encuestas de calidad y mejora de la interacción con nuestros clientes). Nos referimos, por ejemplo, a la combinación de información que podemos hacer si tienes una cuenta registrada y, con la misma dirección de correo electrónico que la vincolada a tu cuenta, realizas compras como invitado; o a información recopilada automáticamente (como direcciones IP, MAC o metadatos) que podemos vincolar con aquella información que nos hayas proporcionado directamente a través de tu actividad en la Plataforma o en cualquiera de nuestras tiendas.
                    <br />
                    Llevar a cabo acciones promocionales (por ejemplo, para el desarrollo de concursos, promociones o el envío de tu lista de artícolos o productos guardados al correo electrónico que nos indiques). Al participar en alguna acción promocional, nos autorizas a que tratemos los datos que nos facilites en función de cada acción promocional y los podamos comunicar a través de diversos medios como redes sociales o en la propia Plataforma. En cada acción promocional en la que participes tendrás disponibles las bases legales donde te facilitaremos información más detallada sobre el tratamiento de tus datos.
                    <br />
                    Difundir en la Plataforma o a través de nuestros canales en las redes sociales fotografías, imágenes o comentarios que hayas compartido de forma pública, siempre que nos des tu consentimiento para ello.
                </li>
                <li>
                    <strong>Análisis de usabilidad y de calidad para la mejora de nuestros servicios.</strong>
                    <br />
                    Si accedes a nuestra Plataforma, te informamos de que trataremos tus datos de navegación para fines analíticos y estadísticos, es decir, para entender la forma en la que los usuarios interactúan con nuestra Plataforma y con las acciones que podemos llevar a cabo en otros sitios web y apps y así ser capaces de introducir mejoras. Así mismo, en ocasiones realizamos acciones y encuestas de calidad destinadas a conocer el grado de satisfacción de nuestros clientes y usuarios y detectar aquellas áreas en las que podemos mejorar.
                </li>
            </ol>

            <span><strong>3. ¿CUÁL ES LA LEGITIMACIÓN PARA EL TRATAMIENTO DE TUS DATOS?</strong></span>

            <p>
                La base legal que nos permite tratar tus datos personales también depende de la finalidad para la que los tratemos, según lo explicado a continuación:
            </p>

            <ol>
                <li>
                    <strong>Gestionar tu registro como usuario de la Plataforma.</strong>
                    <br />
                    El tratamiento de tus datos es necesario para la ejecución de los términos que regolan el uso de la Plataforma. En otras palabras, para que puedas registrarte como usuario en la Plataforma, necesitamos tratar tus datos personales, ya que de lo contrario no podríamos gestionar tu registro.
                </li>
                <li>
                    <strong>Desarrollo, cumplimiento y ejecución del contrato de compraventa o de servicios.</strong>
                    <br />
                    El tratamiento de tus datos es necesario para la ejecución del contrato de compraventa o de prestación de servicios que nos vincole contigo. Es posible que algunos tratamientos de datos asociados al proceso de compra se activen únicamente porque tú lo solicites o nos autorices, como es el caso del almacenamiento de los datos de pago (tarjeta, qr, efectivo, etc) para futuras compras o del tratamiento de datos necesario para escanear los tickets de compra o para informarte de la disponibilidad de nuestros productos (en caso de que esté disponible esta funcionalidad). En estos casos, la base sobre la que tratamos tus datos es tu propio consentimiento. Consideramos que tenemos un interés legítimo para realizar las comprobaciones necesarias para detectar y prevenir posibles fraudes o usos fraudolentos cuando usas la Plataforma, por ejemplo, al realizar una compra. Entendemos que el tratamiento de estos datos resolta positivo para todas las partes: para ti, ya que nos permite poner medidas para protegerse contra intentos de fraude realizados por terceros; para Nosotros, porque nos permite evitar que se haga un uso indebido de la Plataforma; para todos nuestros clientes y la sociedad, porque también protege su interés en asegurar que las actividades fraudolentas sean desalentadas y detectadas cuando ocurran.
                </li>
                <li>
                    <strong>Atención al Cliente.</strong>
                    <br />
                    Consideramos que tenemos un interés legítimo para atender las solicitudes o consoltas que nos plantees a través de los diversos medios de contacto existentes. Entendemos que el tratamiento de estos datos resolta también beneficioso para ti en tanto que nos permite poder atenderte adecuadamente y resolver las consoltas planteadas.
                    <br />
                    Cuando tu consolta esté relacionada con el ejercicio de los derechos sobre los que te informamos más abajo, o con reclamaciones relacionadas con nuestros productos o servicios, lo que nos legitima para tratar tus datos es el cumplimiento de obligaciones legales por nuestra parte.
                </li>
                <li>
                    <strong>Marketing.</strong>
                    <br />
                    La base legítima para tratar tus datos con finalidades de marketing es el consentimiento que nos prestas, por ejemplo: cuando aceptas recibir información personalizada a través de diversos medios, cuando autorizas el envío de notificaciones push en tu dispositivo móvil o lo configuras de tal manera que permiten mostrarte publicidad personalizada, o cuando aceptas las bases legales para participar en una acción promocional o para publicar tus fotografías en la Plataforma o en nuestros canales de redes sociales.
                    <br />
                    Para ofrecerte servicios personalizados o mostrarte información personalizada, ya sea en nuestra Plataforma o en la de terceros, así como para llevar a cabo el enriquecimiento de datos, consideramos que tenemos un interés legítimo para realizar un perfilado con la información que tenemos sobre ti (como la navegación que realizas, preferencias o histórico de compras, cursos comprados, planes de revista o intereses) y los datos personales que nos has facilitado como rango de edad o idioma, ya que entendemos que el tratamiento de estos datos también resolta beneficioso para ti porque te permite mejorar tu experiencia como usuario y acceder a información de acuerdo con tus preferencias.
                </li>
                <li>
                    <strong>Análisis de usabilidad y de calidad.</strong>
                    <br />
                    Consideramos que tenemos un interés legítimo para analizar la usabilidad de la Plataforma y el grado de satisfacción del usuario ya que entendemos que el tratamiento de estos datos también resolta beneficioso para ti porque la finalidad es mejorar la experiencia del usuario y ofrecer un servicio de mayor calidad.
                </li>
            </ol>

            <span><strong>4. ¿DURANTE CUÁNTO TIEMPO CONSERVAREMOS TUS DATOS?</strong></span>

            <p>
                El plazo de conservación de tus datos dependerá de las finalidades para las que los tratemos, según lo explicado a continuación:
            </p>

            <ol>
                <li>
                    <strong>Gestionar tu registro como usuario de la Plataforma.</strong>
                    <br />
                    Trataremos tus datos durante el tiempo en que mantengas la condición de usuario registrado (es decir, hasta que decidas darte de baja).
                </li>

                <li>
                    <strong>Inscripción o registro en algún curso, revista, producto o servicio.</strong>
                    <br />
                    Trataremos tus datos durante el tiempo en que mantengas tu cuenta activa (es decir, hasta que decidas darte de baja).

                </li>
                <li>
                    <strong>Atención al Cliente.</strong>
                    <br />
                    Trataremos tus datos durante el tiempo que sea necesario para atender tu solicitud o petición.

                </li>
                <li>
                    <strong>Marketing.</strong>
                    <br />
                    Trataremos tus datos hasta que te des de baja o canceles tu suscripción a la newsletter.


                </li>
            </ol>
            <p>
                Así mismo, te mostraremos publicidad personalizada hasta que configures tu dispositivo móvil o navegador de manera que revoques tu consentimiento.

                Si participas en acciones promocionales conservaremos tus datos por un plazo de seis (6) meses desde que finalice la acción.

                Trataremos tus datos puntualmente durante el tiempo en el que procedamos a realizar una acción o encuesta de calidad concreta o hasta que anonimicemos tus datos de navegación.
                Independientemente de que tratemos tus datos durante el tiempo estrictamente necesario para cumplir con la finalidad correspondiente, los conservaremos posteriormente debidamente guardados y protegidos durante el tiempo en que pudieran surgir responsabilidades derivadas del tratamiento, en cumplimiento con la normativa vigente en cada momento. Una vez prescriban las posibles acciones en cada caso, procederemos a la supresión de los datos personales.
            </p>

            <span><strong>5. ¿COMPARTIREMOS TUS DATOS CON TERCEROS?</strong></span>

            <p>
                Para cumplir las finalidades indicadas en la presente Política de Privacidad y Cookies, es necesario que demos acceso a tus datos personales a entidades de Grupo Nueva Economía  y a terceras partes que nos presten apoyo en los cursos, revistas o servicios que te ofrecemos, a saber:
            </p>

            <ol>
                <li>
                    Entidades financieras.
                </li>
                <li>
                    Entidades de detección y prevención de fraude.
                </li>
                <li>
                    Proveedores de servicios tecnológicos y analítica.
                </li>
                <li>
                    Proveedores y colaboradores de cursos, revistas, productos o servicios.
                </li>
                <li>
                    Proveedores de servicios relacionados con atención al cliente.
                </li>
                <li>
                    Sub Empresas de Grupo Nueva Economía.
                </li>
                <li>
                    Proveedores y colaboradores de servicios relacionados con marketing y publicidad, como redes sociales, agencias de publicidad o colaboradores publicitarios
                </li>
            </ol>

            <p>
                Por eficiencia del servicio, algunos de los prestadores mencionados están ubicados fuera del territorio Nacional Boliviano. En tales casos, te informamos de que transferimos tus datos con garantías adecuadas y siempre guardando la seguridad de tus datos.
            </p>

            <span><strong>6. ¿CUÁLES SON TUS DERECHOS CUANDO NOS FACILITAS TUS DATOS?</strong></span>

            <p>
                Nos comprometemos a respetar la confidencialidad de tus datos personales y a garantizar el ejercicio de tus derechos. Los Corresponsables hemos acordado que puedes ejercitarlos sin coste alguno escribiéndonos un correo electrónico a una dirección de correo única info@nuevaeconomia.com.bo, simplemente indicándonos el motivo de tu solicitud y el derecho que quieres ejercitar. En caso de que lo consideremos necesario para poder identificarte, podremos solicitarle ingresar a un enlace con el objetivo de obtener tu información de Inicio de Sesión, siendo este enlace de un único uso y bajo el dominio pagos.nuevaeconomia.com.bo
                <br />
                En particular, independientemente de la finalidad o la base legal en virtud de la que tratemos tus datos, tienes derecho a:
            </p>

            <ol>
                <li>
                    Pedirnos acceso a los datos de los que disponemos de ti. Te recordamos que en caso de ser usuario registrado en la Plataforma también puedes consoltar esta información en la sección correspondiente a tus datos personales.
                </li>
                <li>
                    Pedirnos que rectifiquemos los datos de los que ya disponemos. Recuerda que si eres usuario registrado en la Plataforma también puedes acceder a la sección correspondiente a tus datos personales de tu cuenta de para modificar o actualizar algunos de tus datos personales. De todas formas, ten en cuenta que, al facilitarnos activamente tus datos personales por cualquier vía, garantizas que son ciertos y exactos y te comprometes a notificarnos cualquier cambio o modificación de los mismos. Cualquier pérdida o daño causado a la Plataforma o al responsable de la Plataforma o a cualquier tercero por motivo de una comunicación de información errónea, inexacta o incompleta en los formolarios de registro, será responsabilidad exclusiva del usuario. Por favor, recuerda que por regla general solo debes facilitarnos tus propios datos personales, no los de terceros, salvo en lo permitido en esta Política de Privacidad y Cookies.
                </li>
                <li>
                    Pedirnos que suprimamos tus datos en la medida en que ya no sean necesarios para la finalidad para los que necesitemos tratarlos según te hemos informado más arriba, o en que ya no contemos con legitimación para hacerlo.
                </li>
                <li>
                    Pedirnos que limitemos el tratamiento de tus datos, lo que supone que en determinados casos puedas solicitarnos que suspendemos temporalmente el tratamiento de los datos o que los conservemos más allá del tiempo necesario cuando puedas necesitarlo.
                </li>
            </ol>

            <p>
                Si nos has proporcionado tu consentimiento para el tratamiento de tus datos para cualquier finalidad, también tienes derecho a retirarlo en cualquier momento..
                <br />
                Por otra parte, cuando el tratamiento de tus datos esté basado en nuestro interés legítimo, también tendrás derecho a oponerte al tratamiento de tus datos.
            </p>

            <span><strong>7. ¿QUÉ PASA SI NOS FACILITAS DATOS DE TERCEROS O SI UN TERCERO NOS HA FACILITADO DATOS TUYOS?</strong></span>

            <p>
                Si un tercero nos ha facilitado tus datos o los has aportado tú mismo como consecuencia de una funcionalidad o servicio solicitado por uno de nuestros usuarios, los usaremos para gestionar la funcionalidad o servicio que se esté desarrollando en cada caso, dentro de las finalidades recogidas en esta Política de Privacidad y Cookies, cuyo enlace incluimos habitualmente en nuestras comunicaciones.
            </p>

            <span><strong>8. CAMBIOS A LA POLÍTICA DE PRIVACIDAD Y COOKIES</strong></span>

            <p>
                Es posible que modifiquemos la información contenida en esta Política de Uso, Privacidad y Cookies cuando lo estimemos conveniente. En caso de que lo hagamos, te lo notificaremos por distintas vías a través de la Plataforma (por ejemplo, a través de un banner, un pop-up o una notificación push), o incluso te lo comunicaremos a tu dirección de correo electrónico cuando el cambio en cuestión sea significativo para con tu privacidad, de manera que puedas revisar los cambios, valorarlos y, en su caso, oponerte o darte de baja en algún servicio o funcionalidad. En cualquier caso, te sugerimos que revises esta Política de Uso, Privacidad y Cookies de vez en cuando por si hubiera cambios menores o introducimos alguna mejora interactiva, aprovechando que siempre la encontrarás como punto permanente de información en nuestra Web.
            </p>

            <span><strong>9. INFORMACIÓN SOBRE COOKIES</strong></span>

            <p>
                Utilizamos cookies y mecanismos similares para facilitar tu navegación en la Plataforma, conocer cómo interactúas con Nosotros y, en determinados casos, poder mostrarte publicidad en función de tus hábitos de navegación. Por favor, lee nuestra Información sobre Cookies para conocer con mayor detalle las cookies y dispositivos similares que usamos, su finalidad y otra información de interés.
            </p>
        </div>
        <Footer />
    </div>
}

export default Policy;