import toast from "./toast";

//NUEVA ECONOMIA
export const basePathHome = "https://nuevaeconomia.com.bo/"; //PROD
//export const basePathHome = "https://development.nuevaeconomia.com.bo/"; //PREPROD
//export const basePathHome = "http://192.168.100.176:4500/"; //DEV

//ADMINISTRADOR
//export const basePathAdmin = "https://administrador.nuevaeconomia.com.bo/"; //PROD
export const basePathAdmin = "https://administrador.nuevaeconomia.com.bo/"; //PREPROD
//export const basePathAdmin = "http://192.168.100.176:9000/"; //DEV

//PAGOS
export const basePathPagos = "https://pagos.nuevaeconomia.com.bo/"; //PROD
//export const basePathPagos = "https://dev.pagos.nuevaeconomia.com.bo/"; //PREPROD
//export const basePathPagos = "http://192.168.100.176:8080/"; //DEV

export const ReCaptchaClientKey = "6LdP_zEiAAAAAANsKkuG22DUtwT58KdVKsnWMviU";

export const home = basePathPagos;
export const homeAPI = home + "api/";

export const userStorageName = "USER";
export const shoppingStorageName = "SHOPPING_CART";

export const timeShoppingCartOpen = 10000;

/*
VARIABLES STRING
*/

export const onCatch = "Ha ocurrido un error, porfavor intenta nuevamente más tarde";
export const onNotFound = "El elemento que solicitaste no se ha encontrado o no se encuentra activo";

export const Catch = (err: any) => {
    toast.error(onCatch)
}