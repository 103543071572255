import { ShoppingElement } from "../components/ShoppingCart";
import { Course, MagazzinePlan, Product, User } from "./types";
import { getItem, removeItem, setItem } from "./useStorage";
import { shoppingStorageName, userStorageName } from "./variables";

/**
 * 
 * @return User or null, if user is logged returns User then Null
 */
export const getUser = (): User | null => {
    let user = getItem(userStorageName);

    if (user !== null) {
        return JSON.parse(user) as User;
    } else {
        return null;
    }
}

/**
 * @return Product shoppin carts or null, if user is logged returns User then Null
 */
export const getShoppingCart = (): ShoppingElement[] | null => {
    let sh = getItem(shoppingStorageName);

    if (sh !== null) {
        try {
            return JSON.parse(sh) as ShoppingElement[];
        } catch (err) {
            return sh as unknown as ShoppingElement[];
        }
    } else {
        return null;
    }
}

export const addElementToShoppingCart = (type: "course" | "magazzine" | "product", product: MagazzinePlan & Course & Product) => {
    let products: null | ShoppingElement[] = getShoppingCart();

    if (products) {
        let exists = products
            .find((sho: ShoppingElement) => { return (sho.type === "course" ? (sho.product.course_code == product.course_code ) : (sho.type == "magazzine"? (sho.product.plan_code === product.plan_code) : (sho.product.product_code == product.product_code) ) ) }) //ANALIZE

        if (!exists) {
            products.push({
                quantity: 1,
                type: type,
                product: product
            });

            setItem(shoppingStorageName, JSON.stringify(products));
        }
    } else {
        let newProducts: ShoppingElement[] = [];

        newProducts.push({
            quantity: 1,
            type: type,
            product: product
        });

        setItem(shoppingStorageName, JSON.stringify(newProducts));
    }
}

export const modifyQuantity = (toModify: ShoppingElement) => {
    let products: null | ShoppingElement[] = getShoppingCart();

    if (products) {
        let index = products
            .findIndex((sho: ShoppingElement) => { return sho.type === toModify.type && (sho.type === "course" ? (sho.product.course_code === toModify.product.course_code) : (sho.product.plan_code === toModify.product.plan_code)) })

        if (index !== -1) {
            products[index] = toModify;

            setItem(shoppingStorageName, JSON.stringify(products));
        }
    }
}

export const deleteProduct = (toDelete: ShoppingElement) => {
    let products: null | ShoppingElement[] = getShoppingCart();

    if (products) {
        let without = products.filter((sho: ShoppingElement) => {
            return !((sho.type === toDelete.type) && (sho.type === "course" ? (sho.product.course_code === toDelete.product.course_code) : (sho.product.plan_code === toDelete.product.plan_code)))
        })

        if (without.length > 0) {
            setItem(shoppingStorageName, JSON.stringify(without));
        } else {
            removeItem(shoppingStorageName);
        }
    }
}

export const getTotalPrice = () => {
    let products: null | ShoppingElement[] = getShoppingCart();
    let total = 0;

    if (products) {
        products.map((prod: ShoppingElement) => {
            total += prod.product.price * prod.quantity;
        })
    }

    return total;
}