import { useCallback, useEffect, useRef, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

interface MapProps extends google.maps.MapOptions {
    style?: { [key: string]: string };
    onClick?: (e: any) => any;
    onIdle?: (map: any) => any;
    onAddMarker?: (latLng: google.maps.LatLngLiteral) => any;
    posicionateOnAproxLocation: string | undefined;
};

const Map = (props: MapProps) => {
    const defaultActualPosition = { lat: -16.159691, lng: -65.301177 };

    const divMapRef = useRef<HTMLDivElement>(null);

    const [actualPosition, setActualPosition] = useState<google.maps.LatLngLiteral>();

    const [map, setMap] = useState<google.maps.Map>();
    const [markerLocation, setMarkerLocation] = useState<google.maps.LatLngLiteral>();
    const [marker, setMarker] = useState<google.maps.Marker>();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onClick = (ev: google.maps.MapMouseEvent) => {
        if (ev.latLng?.lat() && ev.latLng?.lng()) {
            setMarkerLocation({ lat: ev.latLng?.lat()!, lng: ev.latLng?.lng()! });
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onIdle = (map: any) => {
        //console.log(map)
    }

    useEffect(() => {
        if (markerLocation) {

            if (marker) {
                marker.setMap(null);
            }

            let markerEl = new google.maps.Marker({
                position: markerLocation,
                map: map
            });
            setMarker(markerEl);
            if (props.onAddMarker) {
                props.onAddMarker(markerLocation);
            }
        }
    }, [markerLocation])

    useEffect(() => {
        if (divMapRef.current && !map) {
            let map = new google.maps.Map(divMapRef.current, {
                zoom: 10,
                center: defaultActualPosition,
                zoomControl: true,
                scaleControl: true,
                streetViewControl: true,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.TOP_LEFT
                }
            });

            setMap(map)
        }
    });

    useEffect(()=>{
        //Analize location of user
        if(props.posicionateOnAproxLocation){
            geocodeByAddress(props.posicionateOnAproxLocation)
            .then(res=>{
                getLatLng(res[0]).then(({lat, lng})=>{
                    setActualPosition({lat: lat, lng: lng});
                })
            })
        }
    }, [props.posicionateOnAproxLocation])

    useEffect(()=>{
        if(actualPosition && map){
            map.setCenter(actualPosition)
            map.setZoom(10)
            console.log(actualPosition)
        }
    }, [actualPosition])

    useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    return <div style={{ width: "100%", height: "100%", ...props.style }} ref={divMapRef} id="map"></div>

}

const Maps = (props: { onAddMarker: (mk: google.maps.LatLngLiteral) => any, posicionateOnAproxLocation: string | undefined }) => {

    return <div style={{width: "100%", height: "100%"}}>
        <Map onAddMarker={props.onAddMarker} posicionateOnAproxLocation={props.posicionateOnAproxLocation}/>
    </div>
}

export default Maps;

