import { CSSProperties, MutableRefObject, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import routes from "../common/routes";
import { User } from "../common/types";
import { getShoppingCart, getUser } from "../common/user";
import "../styles/components/Header.scss";
import ShoppingCartContainer, { ShoppingElement } from "./ShoppingCart";

const ShoppingCart = (props: {
    fatherRef: MutableRefObject<HTMLDivElement>,
    bottomOf: MutableRefObject<HTMLDivElement>,
    refresher?: boolean,
    onClick: () => any
}) => {
    let elementProperties, bottomOfProperties;

    const [position, setPosition] = useState({} as CSSProperties);

    useEffect(() => {
        try {
            elementProperties = props.fatherRef.current.getBoundingClientRect();
            bottomOfProperties = props.bottomOf.current.getBoundingClientRect();
            setPosition({
                top: bottomOfProperties.height,
                right: (window.screen.availWidth - (elementProperties.width + elementProperties.left))
            })

            window.addEventListener("resize", () => {
                elementProperties = props.fatherRef.current.getBoundingClientRect();
                bottomOfProperties = props.bottomOf.current.getBoundingClientRect();
                setPosition({
                    top: bottomOfProperties.height,
                    right: (window.screen.availWidth - (elementProperties.width + elementProperties.left))
                })
            })
        } catch (err) {
            console.log(err)
        }
    }, [props])



    return <div className="shopping-cart-component" style={position} onClick={props.onClick}>
        <ShoppingCartContainer refresher={props.refresher} />
    </div>
}

const Header = (props: {
    openShoppingCart?: boolean
}) => {

    let location: {
        state: Object | null,
        pathname: string
    } = useLocation() as any;

    let shoppingCart: null | ShoppingElement[] = getShoppingCart();

    const user: null | User = getUser();

    const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(props.openShoppingCart ? props.openShoppingCart : false);
    const [shoppingCartSize, setShoppingCartSize] = useState(shoppingCart ? shoppingCart.length : 0);

    const ShoppingRef = useRef(new Object as HTMLDivElement);
    const HeaderRef = useRef(new Object as HTMLDivElement);

    useEffect(() => {
        shoppingCart = getShoppingCart();

        if (shoppingCart) {
            setShoppingCartSize(shoppingCart.length)
        }

        if (props.openShoppingCart) {
            setIsShoppingCartOpen(props.openShoppingCart)
        }

    }, [props, isShoppingCartOpen])

    return <div className="header-component" ref={HeaderRef}>
        <Link to={routes.home} className="img-logo-header">
            <img src="https://nuevaeconomia.com.bo/img/logos/logoGNELight.png" alt="Logo GNE" />
        </Link>

        <div className="routes-header">
            <Link to={routes.home}
                className={location.pathname.includes(routes.home) ? "active" : ""}>
                Inicio
            </Link>
            {
                /*
                <Link to={routes.pays}
                className={location.pathname.includes(routes.pays) ? "active" : ""}>
                PAGOS
            </Link>
                */
            }

            {
                /*
                <Link to={routes.cercoverde}
                className={location.pathname.includes(routes.cercoverde) ? "active" : ""}>
                CERCOVERDE
            </Link>
                */
            }
            <Link to={routes.courses}
                className={location.pathname.includes(routes.courses) ? "active" : ""}>
                Cursos
            </Link>
            <Link to={routes.magazzine}
                className={location.pathname.includes(routes.magazzine) ? "active" : ""}>
                Revista
            </Link>


        </div>
        <div className="mini-menu-routes-header" ref={ShoppingRef}>
            <div >
                <Link to={user !== null ? routes.profile : routes.login}><i className="bi bi-person-circle"></i></Link>

                {
                    user !== null ?
                        <a href="#"
                            className={isShoppingCartOpen ? "shopping-icon active-shopping" : "shopping-icon"}
                            onClick={() => { setIsShoppingCartOpen(!isShoppingCartOpen) }}
                        >
                            <p>{shoppingCartSize}</p>
                            <i className="bi bi-cart"></i>
                        </a>
                        :
                        <></>
                }
            </div>
        </div>

        {isShoppingCartOpen ?
            <ShoppingCart fatherRef={ShoppingRef} refresher={props.openShoppingCart} bottomOf={HeaderRef}
                onClick={() => setIsShoppingCartOpen(true)}
            />
            :
            <></>
        }
    </div>
}
export default Header;