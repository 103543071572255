import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack';

//const { enqueueSnackbar, closeSnackbar } = useSnackbar()

let useSnackbarRef: WithSnackbarProps
export const SnackbarUtilsConfigurator: React.FC = () => {
    useSnackbarRef = useSnackbar()
    return null
}


const onSuccesToast = (message: string) => {
    useSnackbarRef.enqueueSnackbar(message, { variant: "success" });
}

const onErrorToast = (message: string) => {
    console.log(message)
    useSnackbarRef.enqueueSnackbar(message, { variant: "error" });
}

const onInfoToast = (message: string) => {
    useSnackbarRef.enqueueSnackbar(message, { variant: "info" });
}

const toast = {
    success: onSuccesToast,
    error: onErrorToast,
    info: onInfoToast
}

export default toast;