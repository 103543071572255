import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../common/routes";
import { User } from "../common/types";
import { getUser } from "../common/user";
import Footer from "../components/Footer";
import Header from "../components/Header";

const SuccessPaymentCard = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const caseParam = params.get("success");

    const [counter, setCounter] = useState(5);

    const navigate = useNavigate();

    useEffect(() => {
        if (counter > 0) {
            setTimeout(() => {
                setCounter(counter - 1)
            }, 1000)
        } else {
            if (caseParam == "false") {
                navigate(routes.shoppingCart);
            } else {
                let us: User | null = getUser();
                if (us) {
                    navigate(routes.shoppingCart);
                } else {
                    navigate(routes.login);
                }


            }
        }
    }, [counter])

    return <div>
        <Header />

        <div className="confirmation-page">
            {
                caseParam == "true" ?
                    <>
                        {/* CORRECT */}
                        <div className="form">
                            <div className="form-content">
                                <div className="form-header">
                                    <h4>Realizaste correctamente el pago</h4>
                                    <span>Gracias por comprar mediante nuestra plataforma, un administrador de ventas se contactará contigo y te explicará el procedimiento</span></div>
                                <div className="form-body">
                                    <span>Volviendo a tus suscriptiones en {counter}</span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {/* ERROR URL */}
                        <div className="form">
                            <div className="form-content">
                                <div className="form-header">
                                    <h4>Ha ocurrido un error</h4>
                                    <span>Ha ocurrido un error y tu transacción no ha podido realizarse, antes de volver a ingresar tus datos de tarjeta presiona el icono de actualización <i className="bi bi-arrow-clockwise"></i></span></div>
                                <div className="form-body">
                                    <span>Volviendo a tu carrito en {counter}</span>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>

        <Footer />
    </div>
}

export default SuccessPaymentCard;