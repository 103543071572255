import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { cloneObject } from "../common/functions";
import routes from "../common/routes";
import { Course, MagazzinePlan, Product } from "../common/types"
import { deleteProduct, getShoppingCart, modifyQuantity } from "../common/user";
import { Categories } from "../pages/CercoVerde";

import "../styles/components/ShoppingCart.scss";
import Button from "./Button";

export type ShoppingElement = {
    quantity: number,
    type: "course" | "magazzine" | "product",
    product: Course & MagazzinePlan & Product
}

export const ShoppingCartCard = (props:
    {
        element: ShoppingElement,
        onModify: (newValue: ShoppingElement) => any,
        onRemove: (newValue: ShoppingElement) => any,
        disabled?: boolean
    }
) => {

    const [quantity, setQuantity] = useState(props.element.quantity);

    const navigate = useNavigate();

    useEffect(() => {
        if (quantity !== props.element.quantity && quantity > 0 && !props.disabled) {
            let nw = cloneObject(props.element) as ShoppingElement;
            nw.quantity = quantity;

            props.onModify(nw);
        }
    }, [quantity])

    switch (props.element.type) {
        case "course": return <div className={"shopping-cart-card course " + (props.disabled ? "disabled" : "")}>
            <div className="row row-1">
                <div>
                    <span>{props.element.product.title}</span>
                    <p>{props.element.product.start_date}</p>
                </div>
                <div>
                    <Button className="button-remove" disabled={props.disabled ? props.disabled : false} onClick={() => {
                        props.onRemove(props.element);
                    }}>Eliminar</Button>
                </div>
            </div>


            <div className="row row-2">
                <span>Cantidad:
                    <input type="number" defaultValue={quantity} disabled={props.disabled ? props.disabled : false}
                        onInput={(ev: any) => {
                            try {
                                if (ev.target.value.length > 0 && ev.target.value.length <= 3) {
                                    setQuantity(parseInt(ev.target.value));
                                } else {
                                    ev.target.value = ev.target.value.slice(0, 3);
                                }
                            } catch (err) {
                                //setQuantity(ev.target.value);
                            }
                        }} />
                </span>
                <span>Total: <b>{props.element.product.price * quantity} Bs</b></span>
            </div>
        </div>

        ///////

        case "magazzine": return <div className={"shopping-cart-card magazzine " + (props.disabled ? "disabled" : "")}>
            <div className="row row-1">
                <div>
                    <span>Plan {props.element.product.name} Revista</span>
                    <p>{(new Date).getFullYear()}</p>
                </div>
                <div>
                    <Button className="button-remove" disabled={props.disabled ? props.disabled : false} onClick={() => {
                        props.onRemove(props.element);
                    }}>Eliminar</Button>
                    <Button className="button-change" disabled={props.disabled ? props.disabled : false} onClick={() => {
                        props.onRemove(props.element);

                        navigate(routes.magazzine);
                    }}>Cambiar</Button>
                </div>
            </div>


            <div className="row row-2">
                <span>Cantidad:
                    <input type="number" defaultValue={quantity} disabled={props.disabled ? props.disabled : false}
                        onInput={(ev: any) => {
                            try {
                                if (ev.target.value.length > 0 && ev.target.value.length <= 3) {
                                    setQuantity(parseInt(ev.target.value));
                                } else {
                                    ev.target.value = ev.target.value.slice(0, 3);
                                }
                            } catch (err) {
                                //setQuantity(ev.target.value);
                            }
                        }} />
                </span>
                <span>Total: <b>{props.element.product.price * quantity} Bs</b></span>
            </div>
        </div>;

        case "product": return <div className={"shopping-cart-card magazzine " + (props.disabled ? "disabled" : "")}>
            <div className="row row-1">
                <div>
                    <span>{props.element.product.name}</span>
                    <p>{Categories.find((c: { title: string, code: string }) => {
                        return c.code == props.element.product.category;
                    })?.title}</p>
                </div>
                <div>
                    <Button className="button-remove" disabled={props.disabled ? props.disabled : false} onClick={() => {
                        props.onRemove(props.element);
                    }}>Eliminar</Button>
                    <Button className="button-change" disabled={props.disabled ? props.disabled : false} onClick={() => {
                        props.onRemove(props.element);

                        navigate(routes.magazzine);
                    }}>Cambiar</Button>
                </div>
            </div>


            <div className="row row-2">
                <span>Cantidad:
                    <input type="number" defaultValue={quantity} disabled={props.disabled ? props.disabled : false}
                        onInput={(ev: any) => {
                            try {
                                if (ev.target.value.length > 0 && ev.target.value.length <= 3) {
                                    setQuantity(parseInt(ev.target.value));
                                } else {
                                    ev.target.value = ev.target.value.slice(0, 3);
                                }
                            } catch (err) {
                                //setQuantity(ev.target.value);
                            }
                        }} />
                </span>
                <span>Total: <b>{props.element.product.price * quantity} Bs</b></span>
            </div>
        </div>
    }
}

const ShoppingCartContainer = (props: {
    refresher?: boolean
}) => {

    let shoppingCart: null | ShoppingElement[] = getShoppingCart();

    const [shopping, setShopping] = useState(shoppingCart ? shoppingCart : []);

    const navigate = useNavigate();

    useEffect(() => {
        shoppingCart = getShoppingCart();
        setShopping(shoppingCart ? shoppingCart as ShoppingElement[] : [])
    }, [props])

    return <>
        {
            shopping.length > 0 ?
                <>
                    {
                        shopping.map((element: ShoppingElement, index: number) => {
                            return <ShoppingCartCard element={element}
                                key={"shopping-card-card-" + index}
                                onModify={(newValue: ShoppingElement) => {
                                    modifyQuantity(newValue);
                                }}

                                onRemove={(valueToRemove: ShoppingElement) => {
                                    deleteProduct(valueToRemove);

                                    shoppingCart = getShoppingCart();
                                    setShopping(shoppingCart ? shoppingCart as ShoppingElement[] : [])
                                }} />

                        })
                    }
                    <Button solid onClick={() => navigate(routes.shoppingCart)}>Ver todo</Button>
                </>
                :
                <div className="shopping-cart-void">
                    <label style={{ textAlign: "center" }}>Todavía no tienes productos en tu carrito</label>
                    <Button style={{ marginBottom: ".5rem" }} solid onClick={() => {
                        navigate(routes.shoppingCart)
                    }}>Ver tus suscripciones</Button>
                    {
                        /*
                        <Button onClick={()=>{
                        navigate(routes.shoppingCart)
                    }}>Canjear código</Button>
                        */
                    }
                </div>
        }
    </>
}
export default ShoppingCartContainer;