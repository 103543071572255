import "../styles/components/Button.scss";

const Button = (props: {
    children: any,
    className?: string,
    disabled?: boolean,
    solid?: boolean,
    onClick?: (ev: any) => any,
    ref?: any,
    style?: React.CSSProperties
}) => {
    return <button
        style={props.style}
        className={"button-component " + (props.className ? props.className : "")}
        color={props.solid ? "solid" : ""}
        onClick={props.onClick}
        disabled={props.disabled}
        ref={props.ref}
    >
        {props.children}
    </button>
}

export default Button;