import { madePost } from "../post";
import { ResponseQRGenerate, ResponseQRStatus } from "../types";
import { Catch, onCatch } from "../variables";

export const QR = (onGenerate: (success: boolean, image?: string, subscription_id?: number, expiration_date?: string, exception_message?: string) => any) => {

    madePost(
        {
            key: "example-qr"
        },
        "Subscription.php"
    ).then(r => {
        let res = r as ResponseQRGenerate;
        if (res.RESULT) {
            onGenerate(true, res.QR_IMAGE, res.SUBSCRIPTION_CODE, res.EXPIRATION_DATE);
        } else {
            onGenerate(false, undefined, undefined, undefined, res.EXCEPTION_MESSAGE);
        }
    }).catch(err => {
        onGenerate(false, undefined, undefined, undefined, "Ha ocurrido un error, revisa tu conexión e intenta nuevamente más tarde");
    })
}

export const QRStatus = (subscription_id: number, onObtain: (res: ResponseQRStatus) => any) => {
    madePost(
        {
            key: "subscription-status",
            id: subscription_id
        },
        "Subscription.php"
    ).then(r => {
        let res = r as ResponseQRStatus;

        onObtain(res);
    }).catch(Catch)
}