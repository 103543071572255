import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteProduct, getShoppingCart, getTotalPrice, getUser, modifyQuantity } from "../common/user";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Input, { InputSelect, InputPhone } from "../components/Input";
import { formatPhoneNumber } from 'react-phone-number-input'

import { ShoppingCartCard, ShoppingElement } from "../components/ShoppingCart";

import "../styles/components/ShoppingCart.scss";

import { Collapse } from 'react-collapse';
import Button from "../components/Button";
import { removeItem } from "../common/useStorage";
import { Catch, onCatch, shoppingStorageName } from "../common/variables";
import { Course, MagazzinePlan, Product, ResponseGeneral, ResponseQRGenerate, ResponseQRStatus, ShoppingCart, Subscription, User } from "../common/types";
import routes from "../common/routes";
import InputLocation from "../components/InputLocation";
import Maps from "../components/Maps";
import { madePost } from "../common/post";
import { QRStatus } from "../common/payment/qr";
import { cloneObject } from "../common/functions";
import toast from "../common/toast";
import saveAs from "file-saver";
import Loader from "../components/Loader";
import Invoice from "../components/Invoice";
import jsPDF from "jspdf";
//import Maps from "../components/Maps";

const QRPaymentComponent = (props: {
    info: ResponseQRGenerate,
    onReturn: () => any
}) => {
    const restore = (val: ResponseQRGenerate): ResponseQRGenerate => {
        let data: ResponseQRGenerate = cloneObject(val) as ResponseQRGenerate;

        try {
            data.SUBSCRIPTION = JSON.parse(data.SUBSCRIPTION as unknown as string);
            data.TOTAL_PRICE = parseFloat(data.TOTAL_PRICE as unknown as string);
        } catch (e) {
            data.SUBSCRIPTION = val.SUBSCRIPTION;
            data.TOTAL_PRICE = val.TOTAL_PRICE;
        }

        return data;
    }

    const [qr, setQR] = useState(restore(props.info) as ResponseQRGenerate);

    const canvasRef = useRef({} as HTMLCanvasElement);
    const [products, setProducts] = useState({ values: [] as any, parsed: "" });

    useEffect(() => {
        let parsed = "";

        qr.SUBSCRIPTION.products.map((v: any) => {//name, type, price, product, quantity
            parsed += v.name + ";;" + v.quantity + ";;" + v.price + ";;";
        });

        parsed = parsed.substring(0, parsed.length - 2);

        setProducts({ values: qr.SUBSCRIPTION.products, parsed: parsed })


    }, [qr])

    const onQRStatusObtained = (qr_res: ResponseQRStatus) => {
        let re: ResponseQRGenerate = cloneObject(qr);
        re.SUBSCRIPTION.state_payment = qr_res.QR_STATUS as any;

        setQR(re);

        if (re.SUBSCRIPTION.state_payment === "paid") {
            removeItem(shoppingStorageName)

            toast.success("El pago para esta suscripción se ha realizado exitosamente");
            /*setTimeout(() => {
                navigate(routes.home)
            }, 1000)*/
        }
    }
    const updateQRState = () => {
        let copy: ResponseQRGenerate = cloneObject(qr);
        copy.SUBSCRIPTION.state_payment = "loading";

        setQR(copy);

        QRStatus(qr.SUBSCRIPTION.subscription_code, onQRStatusObtained);
    }

    const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    if (qr.RESULT) {
        return <div className="qr-component-payment">
            <div className="form-header">
                {
                    qr.SUBSCRIPTION.state_payment === "debt" || qr.SUBSCRIPTION.state_payment !== "paid" ?
                        <>
                            <h4>Paga con QR
                            </h4>
                            <span>Puedes pagar desde cualquier banco mediante su aplicación móvil
                            </span>
                        </>
                        :
                        <>
                            <h4>Suscripción
                            </h4>
                            <span>Puedes descargar el comprobante a continuación
                            </span>
                        </>
                }

            </div>
            <div className="qr-contents" style={qr.SUBSCRIPTION.state_payment === "paid" ? { flexFlow: "nowrap column-reverse" } : {}}>
                <div>
                    {
                        qr.SUBSCRIPTION.state_payment !== "paid" ?
                            <span>Fecha límite de pago <b>{qr.EXPIRATION_DATE.split("-")[2] + "/" + qr.EXPIRATION_DATE.split("-")[1] + "/" + qr.EXPIRATION_DATE.split("-")[0]}</b></span>
                            :
                            <></>
                    }
                    <span>Productos:</span>
                    {
                        qr.SUBSCRIPTION.products.map((product: { name: string, price: number, product: number, quantity: number, type: "magazzine" | "course" }) => {
                            return <div className="shopping-cart-card">
                                <div className="row row-1">
                                    <div>
                                        <span>{product.type === "magazzine" ? "Revista: " : "Curso: "}<b>{product.name}</b></span>
                                    </div>
                                </div>
                                <div className="row row-2">
                                    <span>Cantidad: {product.quantity}</span>
                                    <span>Precio: <b>{product.price}Bs</b></span>
                                </div>
                            </div>
                        })
                    }
                    <span className="end">Total: <b>{qr.TOTAL_PRICE}Bs</b></span>
                </div>
                <div>
                    {
                        qr.SUBSCRIPTION.state_payment === "paid" ?
                            <>
                                <div className="iframe-pagos-net" style={{ width: "100%", maxHeight: "30rem", border: "none", borderRadius: "0", WebkitBorderRadius: "0" }}>
                                    <Invoice canvasRef={canvasRef}
                                        to={"nuevaeco"}
                                        products={products.parsed}
                                        subscription={qr.SUBSCRIPTION}
                                        totalPrice={qr.TOTAL_PRICE}
                                        expirationDate={qr.EXPIRATION_DATE.split("-")[2] + "/" + qr.EXPIRATION_DATE.split("-")[1] + "/" + qr.EXPIRATION_DATE.split("-")[0]} />
                                </div>
                            </>
                            :
                            qr.SUBSCRIPTION.state_payment === "defeated" ?
                                <>
                                    <label>La suscripción no ha sido pagada en el tiempo establecido, realiza el proceso nuevamente</label>
                                </>
                                :
                                <img src={"data:image/png;base64," + qr.QR_IMAGE} width="300" height="300" alt="" />
                    }
                    <div>
                        {
                            qr.SUBSCRIPTION.state_payment === "debt" ?
                                <>
                                    <span>
                                        <i className="bi bi-circle-fill yellow"></i>
                                        Estado del pago: <b>En deuda</b></span>

                                </>
                                :
                                (qr.SUBSCRIPTION.state_payment === "paid" ?
                                    <>
                                        <span style={{ textAlign: "center" }}>
                                            <i className="bi bi-circle-fill green"></i>
                                            Estado del pago: <b>Pago realizado</b></span>
                                    </>
                                    :
                                    (
                                        qr.SUBSCRIPTION.state_payment === "loading" ?
                                            <>
                                                <span style={{ textAlign: "center" }}>
                                                    <i className="bi bi-circle-fill gray"></i>
                                                    Estado del pago: <b>---</b></span>
                                            </>
                                            :
                                            <>
                                                <span>
                                                    <i className="bi bi-circle-fill red"></i>
                                                    Estado del pago: <b>Expirado</b></span>
                                            </>
                                    )
                                )
                        }
                        {
                            qr.SUBSCRIPTION.state_payment === "debt" ?
                                <i className="bi bi-arrow-clockwise" onClick={updateQRState}></i>
                                :
                                <></>
                        }

                    </div>
                    <div className="download-qr"
                        onClick={() => {
                            if (qr.SUBSCRIPTION.state_payment !== "paid") {
                                //No pagado comprobante
                                saveAs(b64toBlob(qr.QR_IMAGE, "image/png", 2048), "QR-de-pago.png");
                            } else {
                                //Esta pagado, descargar comprobante

                                const imgData = canvasRef.current.toDataURL('image/jpeg', 1);
                                const pdf = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' });
                                pdf.addImage(imgData, 'JPEG', 0, 0, 210, 279, undefined, undefined);
                                // pdf.output('dataurlnewwindow');
                                pdf.save("Comprobante de pago - Nro " + qr.SUBSCRIPTION.subscription_code + ".pdf");

                            }
                        }}>
                        <span style={qr.SUBSCRIPTION.state_payment === "paid" ? { textAlign: "center" } : {}}><i className="bi bi-cloud-arrow-down" ></i>{qr.SUBSCRIPTION.state_payment === "paid" ? "Descargar comprobante" : "Descargar QR"}</span>
                    </div>
                </div>
            </div>

            <div className="form-footer">
                <Button onClick={props.onReturn}>
                    Volver
                </Button>
            </div>
        </div>
    }

    return <label>{qr.EXCEPTION_MESSAGE}</label>
}

const CardPaymentComponent = (props: {
    info: Subscription,
    onReturn: () => any
}) => {

    const [url, setUrl] = useState(props.info.url!);
    const [subscription, setSubscription] = useState(props.info);
    const [products, setProducts] = useState({ values: (JSON.parse(JSON.stringify(subscription.products)) as any) as { name: string, type: any, price: number, product: number, quantity: number }[], amount: 0, parsed: "" });
    const navigate = useNavigate();

    const canvasRef = useRef({} as HTMLCanvasElement);

    const [expirationDate, setExpirationDate] = useState({ value: subscription.expiration_date, isSet: false });

    useEffect(() => {

    }, [url])

    useEffect(() => {
        let am = 0;

        let parsed = "";

        products.values.map((v: any) => {//name, type, price, product, quantity
            am += v.price;
            parsed += v.name + ";;" + v.quantity + ";;" + v.price + ";;";
        });

        parsed = parsed.substring(0, parsed.length - 2);

        setProducts({ values: products.values, amount: am, parsed: parsed })

        //Set expirationdate

        try {
            if (!expirationDate.isSet) {
                let r = expirationDate.value!.split("");
                let rr = "";

                rr += r[6] + r[7];
                rr += "/";
                rr += r[4] + r[5];
                rr += "/";
                rr += r[0] + r[1] + r[2] + r[3];

                setExpirationDate({ value: rr, isSet: true });
            }
        } catch (err) {
            console.debug(expirationDate.value);
            console.debug(err)
        }
    }, [subscription])

    const updateCardState = () => {
        let cl: Subscription = cloneObject(subscription);
        cl.state_payment = "loading";

        setSubscription(cl);
        madePost(
            {
                key: "get-card-subscription-status",
                subscription_code: subscription.subscription_code
            },
            "Subscription.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            let cl: Subscription = cloneObject(subscription);
            cl.state_payment = "loading";

            if (res.RESULT) {
                cl.state_payment = res.VALUE;
            } else {
                cl.state_payment = "debt";

                toast.error(res.EXCEPTION_MESSAGE);
            }
            setSubscription(cl);
        }).catch((err) => {
            console.debug(err)
            Catch(err);

            let cl: Subscription = cloneObject(subscription);
            cl.state_payment = "loading";

            cl.state_payment = "debt";

            setSubscription(cl);
        })
    }

    return <div className="qr-component-payment">
        <div className="form-header">
            {
                subscription.state_payment === "debt" || subscription.state_payment !== "paid" ?
                    <>
                        <h4>Paga con Tarjeta
                        </h4>
                        <span>Puedes pagar desde cualquier banco y con tarjeta de crédito o débito
                        </span>
                    </>
                    :
                    <>
                        <h4>Suscripción
                        </h4>
                        <span>Puedes descargar el comprobante a continuación
                        </span>
                    </>
            }

        </div>
        <div className="qr-contents" style={{ flexFlow: "nowrap column-reverse" }}>
            <div >
                {
                    subscription.state_payment !== "paid" ?
                        <span>Fecha límite de pago <b>{expirationDate.isSet ? expirationDate.value : "N/N"}</b></span>
                        :
                        <></>
                }
                <span>Productos:</span>
                {
                    products.values.map((product: { name: string, price: number, product: number, quantity: number, type: "magazzine" | "course" }) => {
                        return <div className="shopping-cart-card">
                            <div className="row row-1">
                                <div>
                                    <span>{product.type === "magazzine" ? "Revista: " : "Curso: "}<b>{product.name}</b></span>
                                </div>
                            </div>
                            <div className="row row-2">
                                <span>Cantidad: {product.quantity}</span>
                                <span>Precio: <b>{product.price}Bs</b></span>
                            </div>
                        </div>
                    })
                }
                <span className="end">Total: <b>{products.amount}Bs</b></span>
            </div>
            <div>
                {
                    subscription.state_payment === "paid" ?
                        <>
                            <div className="iframe-pagos-net" style={{ width: "100%", maxHeight: "30rem", border: "none", borderRadius: "0", WebkitBorderRadius: "0" }}>
                                <Invoice canvasRef={canvasRef} to={"nuevaeco"} products={products.parsed}
                                    subscription={subscription} totalPrice={products.amount} expirationDate={expirationDate.isSet ? expirationDate.value! : "0000-00-00"} />
                            </div>
                        </>
                        :
                        subscription.state_payment === "defeated" ?
                            <>
                                <label>La suscripción no ha sido pagada en el tiempo establecido, realiza el proceso nuevamente</label>
                            </>
                            :

                            <div className="iframe-pagos-net" style={{ width: "100%", height: "30rem", border: "none" }}>
                                <iframe src={subscription.url!} frameBorder="none" scrolling="auto" style={{ height: "100%", overflow: "auto", width: "100%" }}></iframe>
                            </div>
                }
                <div>
                    {
                        subscription.state_payment === "debt" ?
                            <>
                                <span>
                                    <i className="bi bi-circle-fill yellow"></i>
                                    Estado del pago: <b>En deuda</b></span>

                            </>
                            :
                            (subscription.state_payment === "paid" ?
                                <>
                                    <span style={{ textAlign: "center" }}>
                                        <i className="bi bi-circle-fill green"></i>
                                        Estado del pago: <b>Pago realizado</b></span>
                                </>
                                :
                                (
                                    subscription.state_payment === "loading" ?
                                        <>
                                            <span style={{ textAlign: "center" }}>
                                                <i className="bi bi-circle-fill gray"></i>
                                                Estado del pago: <b>---</b></span>
                                        </>
                                        :
                                        <>
                                            <span>
                                                <i className="bi bi-circle-fill red"></i>
                                                Estado del pago: <b>Expirado</b></span>
                                        </>
                                )
                            )
                    }
                    {
                        subscription.state_payment == "debt" ?
                            <i className="bi bi-arrow-clockwise" onClick={updateCardState}></i>
                            :
                            <></>
                    }

                </div>
                {
                    subscription.state_payment === "paid" ?
                        <div className="download-qr"
                            onClick={() => {
                                const imgData = canvasRef.current.toDataURL('image/png');
                                const pdf = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' });
                                pdf.addImage(imgData, 'JPEG', 0, 0, 210, 279, undefined);
                                // pdf.output('dataurlnewwindow');
                                pdf.save("Comprobante de pago Nro " + subscription.subscription_code + ".pdf");
                            }}>
                            <span style={subscription.state_payment === "paid" ? { textAlign: "center" } : {}}><i className="bi bi-cloud-arrow-down" ></i>Descargar comprobante</span>
                        </div>
                        :
                        <></>
                }

            </div>
        </div>

        <div className="form-footer">
            <Button onClick={props.onReturn}>
                Volver
            </Button>
        </div>
    </div>

}

const MoneyPaymentComponent = (props: {
    info: Subscription,
    onReturn: () => any
}) => {

    const [subscription, setSubscription] = useState(props.info);
    const [products, setProducts] = useState({ values: (JSON.parse(JSON.stringify(subscription.products)) as any) as { name: string, type: any, price: number, product: number, quantity: number }[], amount: 0, parsed: "" });

    const [expirationDate, setExpirationDate] = useState({ value: subscription.expiration_date, isSet: false });

    const canvasRef = useRef({} as HTMLCanvasElement);

    useEffect(() => {
        let am = 0;
        let parsed = "";

        products.values.map((v: any) => {//name, type, price, product, quantity
            am += v.price;
            parsed += v.name + ";;" + v.quantity + ";;" + v.price + ";;";
        });

        parsed = parsed.substring(0, parsed.length - 2);

        setProducts({ values: products.values, amount: am, parsed: parsed })

        //Set expirationdate

        try {
            if (!expirationDate.isSet) {
                let r = expirationDate.value!.split("");
                let rr = "";

                rr += r[6] + r[7];
                rr += "/";
                rr += r[4] + r[5];
                rr += "/";
                rr += r[0] + r[1] + r[2] + r[3];

                setExpirationDate({ value: rr, isSet: true });
            }
        } catch (err) {
            console.debug(expirationDate.value);
            console.debug(err)
        }
    }, [subscription])

    const updateMoneyState = () => {
        let cl: Subscription = cloneObject(subscription);
        cl.state_payment = "loading";

        setSubscription(cl);
        madePost(
            {
                key: "get-money-subscription-status",
                subscription_code: subscription.subscription_code
            },
            "Subscription.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            let cl: Subscription = cloneObject(subscription);
            cl.state_payment = "loading";

            if (res.RESULT) {
                cl.state_payment = res.VALUE;
            } else {
                cl.state_payment = "debt";

                toast.error(res.EXCEPTION_MESSAGE);
            }
            setSubscription(cl);
        }).catch((err) => {
            console.debug(err)
            Catch(err);

            let cl: Subscription = cloneObject(subscription);
            cl.state_payment = "loading";

            cl.state_payment = "debt";

            setSubscription(cl);
        })
    }

    return <div className="qr-component-payment">
        <div className="form-header">
            {
                subscription.state_payment === "debt" || subscription.state_payment !== "paid" ?
                    <>
                        <h4>Paga con Efectivo
                        </h4>
                        <span>Puedes pagar con el siguiente documento desde cualquier entidad bancaria de nuestra lista:
                        </span>
                    </>
                    :
                    <>
                        <h4>Suscripción
                        </h4>
                        <span>Puedes descargar el comprobante a continuación
                        </span>
                    </>
            }

        </div>
        <div className="qr-contents" style={{ flexFlow: "nowrap column-reverse" }}>
            <div >
                {
                    subscription.state_payment !== "paid" ?
                        <span>Fecha límite de pago <b>{expirationDate.isSet ? expirationDate.value : "N/N"}</b></span>
                        :
                        <></>
                }
                <span>Productos:</span>
                {
                    products.values.map((product: { name: string, price: number, product: number, quantity: number, type: "magazzine" | "course" }) => {
                        return <div className="shopping-cart-card">
                            <div className="row row-1">
                                <div>
                                    <span>{product.type === "magazzine" ? "Revista: " : "Curso: "}<b>{product.name}</b></span>
                                </div>
                            </div>
                            <div className="row row-2">
                                <span>Cantidad: {product.quantity}</span>
                                <span>Precio: <b>{product.price}Bs</b></span>
                            </div>
                        </div>
                    })
                }
                <span className="end">Total: <b>{products.amount}Bs</b></span>
            </div>
            <div>
                {
                    subscription.state_payment === "paid" ?
                        <>
                            <div className="iframe-pagos-net" style={{ width: "100%", maxHeight: "30rem", border: "none", borderRadius: "0", WebkitBorderRadius: "0" }}>
                                <Invoice canvasRef={canvasRef} to={"nuevaeco"} products={products.parsed}
                                    subscription={subscription} totalPrice={products.amount} expirationDate={expirationDate.isSet ? expirationDate.value! : "0000-00-00"} />
                            </div>
                        </>
                        :
                        subscription.state_payment === "defeated" ?
                            <>
                                <label>La suscripción no ha sido pagada en el tiempo establecido, realiza el proceso nuevamente</label>
                            </>
                            :

                            <div className="iframe-pagos-net" style={{ width: "100%", maxHeight: "30rem", border: "none", borderRadius: "0", WebkitBorderRadius: "0" }}>
                                <Invoice canvasRef={canvasRef} to={"nuevaeco"} products={products.parsed}
                                    subscription={subscription} totalPrice={products.amount} expirationDate={expirationDate.isSet ? expirationDate.value! : "0000-00-00"} />
                            </div>
                }
                <div>
                    {
                        subscription.state_payment === "debt" ?
                            <>
                                <span>
                                    <i className="bi bi-circle-fill yellow"></i>
                                    Estado del pago: <b>En deuda</b></span>

                            </>
                            :
                            (subscription.state_payment === "paid" ?
                                <>
                                    <span style={{ textAlign: "center" }}>
                                        <i className="bi bi-circle-fill green"></i>
                                        Estado del pago: <b>Pago realizado</b></span>
                                </>
                                :
                                (
                                    subscription.state_payment === "loading" ?
                                        <>
                                            <span style={{ textAlign: "center" }}>
                                                <i className="bi bi-circle-fill gray"></i>
                                                Estado del pago: <b>---</b></span>
                                        </>
                                        :
                                        <>
                                            <span>
                                                <i className="bi bi-circle-fill red"></i>
                                                Estado del pago: <b>Expirado</b></span>
                                        </>
                                )
                            )
                    }
                    {
                        subscription.state_payment == "debt" ?
                            <i className="bi bi-arrow-clockwise" onClick={updateMoneyState}></i>
                            :
                            <></>
                    }

                </div>
                {
                    subscription.state_payment === "paid" ?
                        <div className="download-qr"
                            onClick={() => {
                                const imgData = canvasRef.current.toDataURL('image/png');
                                const pdf = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' });
                                pdf.addImage(imgData, 'JPEG', 0, 0, 210, 279, undefined);
                                // pdf.output('dataurlnewwindow');
                                pdf.save("Comprobante de pago Nro " + subscription.subscription_code + ".pdf");
                            }}>
                            <span style={subscription.state_payment === "paid" ? { textAlign: "center" } : {}}><i className="bi bi-cloud-arrow-down" ></i>Descargar comprobante</span>
                        </div>
                        :
                        <div className="download-qr"
                            onClick={() => {
                                const imgData = canvasRef.current.toDataURL('image/png');
                                const pdf = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' });
                                pdf.addImage(imgData, 'JPEG', 0, 0, 210, 279, undefined);
                                // pdf.output('dataurlnewwindow');
                                pdf.save("Ticket de pago Nro " + subscription.subscription_code + ".pdf");
                            }}>
                            <span style={subscription.state_payment === "debt" ? { textAlign: "center" } : {}}><i className="bi bi-cloud-arrow-down" ></i>Descargar ticket</span>
                        </div>
                }
            </div>
        </div>

        <div className="form-footer">
            <Button onClick={props.onReturn}>
                Volver
            </Button>
        </div>
    </div>

}


const Shopping = () => {
    let shoppingCart: null | ShoppingElement[] = getShoppingCart();
    let user: null | User = getUser();

    const [shopping, setShopping] = useState(shoppingCart ? shoppingCart : []);
    const [subscriptions, setSubscriptions] = useState({ values: [] as Subscription[], isLoaded: false });

    const [isOpenToPay, setIsOpenToPay] = useState(false);
    const [isShowPaymentMethod, setIsShowPaymentMethod] = useState({ isOpen: false, typePayment: "qr" as string, values: {} as any, isLoaded: false });

    const [isFactureToOther, setIsFactureToOther] = useState(false);
    const [isValidForm, setIsValidForm] = useState({ valid: false, isSending: false });
    const [specifiedLocation, setSpecifiedLocation] = useState<google.maps.LatLngLiteral>();
    const [isSelectingSpecifiedLocation, setIsSelectingSpecifiedLocation] = useState(false);

    const [location, setLocation] = useState((user?.location)? user.location : "");

    const [aproxLocationUser, setAproxLocationUser] = useState((user?.location)? user.location : "");

    const [cell, setCell] = useState(user?.cellphone);
    const [isShowList, setIsShowList] = useState(true);

    const nameRef = useRef({} as HTMLInputElement);
    const directionRef = useRef({} as HTMLInputElement);

    const facturaTypeRef = useRef({} as HTMLSelectElement);
    const paymentTypeRef = useRef({} as HTMLSelectElement);

    const otherNitRef = useRef({} as HTMLInputElement);
    const otherNameRef = useRef({} as HTMLInputElement);

    const acceptRef = useRef({} as HTMLInputElement);

    const navigate = useNavigate();

    useEffect(() => {
        shoppingCart = getShoppingCart();
        setShopping(shoppingCart ? shoppingCart as ShoppingElement[] : [])

        if (user === null) {
            navigate(routes.login);
        }

        getSubscriptions();
    }, [])

    useEffect(() => {
        validateForm();
    }, [specifiedLocation])

    useEffect(() => {
        validateForm()
    }, [cell])

    useEffect(() => {
        validateForm()
    }, [])

    const getSubscriptions = () => {
        setSubscriptions({ values: [] as Subscription[], isLoaded: false });
        madePost(
            {
                key: "get-subscriptions",
                email: user?.email,
                password: user?.password
            },
            "Subscription.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                setSubscriptions({ values: restoreSub(res.VALUE), isLoaded: true });
            } else {
                toast.error(res.EXCEPTION_MESSAGE)
            }
        }).catch(Catch)
    }

    const restoreSub = (val: any): Subscription[] => {
        let data: Subscription[] = [];
        val.map((sub: Subscription) => {
            try {
                sub.buyer = JSON.parse(sub.buyer as unknown as string);
                sub.products = JSON.parse(sub.products as unknown as string);

                data.push(sub);
            } catch (e) {
                data.push(sub)
            }
        })
        return data;
    }

    const updateCart = () => {
        shoppingCart = getShoppingCart();
        setShopping(shoppingCart ? shoppingCart as ShoppingElement[] : [])
    }

    const validateForm = () => {
        try {
            if (
                nameRef.current.value.length > 0 &&
                cell!.length > 0 &&
                location.length > 0 &&
                directionRef.current.value.length > 0 &&
                acceptRef.current.checked &&
                specifiedLocation && getTotalPrice() > 0 
            ) {
                setIsValidForm({ valid: true, isSending: false });
            } else {
                setIsValidForm({ valid: false, isSending: false });
            }
        } catch (e) {
            console.debug(e)
            setIsValidForm({ valid: false, isSending: false });
        }
    }

    const sendForm = () => {
        let data = {} as any;

        data.name = nameRef.current.value;
        data.cellphone = formatPhoneNumber(cell? cell : '');
        data.location = location;
        data.direction = directionRef.current.value;
        data.specified_location = JSON.stringify(specifiedLocation);

        data.invoice_number = (isFactureToOther ? otherNitRef.current.value : user?.ci_nit);
        data.invoice_name = (isFactureToOther ? otherNameRef.current.value : user?.full_name);
        data.invoice_to = facturaTypeRef.current.value;

        data.type_payment = paymentTypeRef.current.value;

        data.products = JSON.stringify(cleanShoppingToSend(shopping));

        data.user_code = user?.user_code;
        data.email = user?.email;
        data.password = user?.password;

        data.key = "subscribe";

        setIsValidForm({ valid: true, isSending: true });

        madePost(
            data,
            "Subscription.php"
        ).then(r => {
            setIsShowList(false);
            let res = r as ResponseQRGenerate;

            if (res.RESULT) {
                setIsValidForm({ valid: false, isSending: false });
                setIsOpenToPay(false);
                setIsShowPaymentMethod({ isOpen: true, typePayment: data.type_payment, values: res, isLoaded: true });

                getSubscriptions();
            } else {
                setIsValidForm({ valid: false, isSending: false });
                setIsOpenToPay(false);
                toast.error(res.EXCEPTION_MESSAGE);
            }

        }).catch(Catch)
    }

    const getSubscriptionInfo = (sub_code: number, type: string) => {
        setIsShowPaymentMethod({ values: {}, isOpen: true, typePayment: type, isLoaded: false });

        switch (type) {
            case "qr":
                madePost(
                    {
                        key: "get-qr-subscription",
                        subscription_code: sub_code
                    },
                    "Subscription.php"
                ).then(r => {
                    let res = r as ResponseQRGenerate;

                    if (res.RESULT) {
                        setIsShowPaymentMethod({ values: res, isOpen: true, typePayment: type, isLoaded: true });
                    } else {
                        toast.error(res.EXCEPTION_MESSAGE)
                    }
                }).catch(Catch)
                break;

            case "card":
                madePost(
                    {
                        key: "get-card-subscription",
                        subscription_code: sub_code
                    },
                    "Subscription.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    let vals: Subscription = res.VALUE;
                    vals.products = JSON.parse(vals.products as unknown as string);

                    res.VALUE = vals;

                    if (res.RESULT) {
                        setIsShowPaymentMethod({ values: res, isOpen: true, typePayment: type, isLoaded: true });
                    } else {
                        toast.error(res.EXCEPTION_MESSAGE)
                    }
                }).catch(Catch)
                break;
            case "money":
                madePost(
                    {
                        key: "get-money-subscription",
                        subscription_code: sub_code
                    },
                    "Subscription.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    let vals: Subscription = res.VALUE;
                    vals.products = JSON.parse(vals.products as unknown as string);

                    res.VALUE = vals;

                    if (res.RESULT) {
                        setIsShowPaymentMethod({ values: res, isOpen: true, typePayment: type, isLoaded: true });
                    } else {
                        toast.error(res.EXCEPTION_MESSAGE)
                    }
                }).catch(Catch)
                break;

            default: break;
        }
    }

    const cleanShoppingToSend = (shopping: { product: (Course & MagazzinePlan & Product) | number, quantity: number, type: "magazzine" | "course" | "product" }[]): any => {
        let clone: { product: (Course & MagazzinePlan & Product) | number | string, quantity: number, type: "magazzine" | "course" | "product" }[] = []

        shopping.map((elem: { product: (Course & MagazzinePlan & Product) | number, quantity: number, type: "magazzine" | "course" | "product"}, index: number) => {
            clone.push(
                {
                    product: elem.type === "course" ? (elem.product as Course).course_code : (elem.type == "magazzine"? (elem.product as MagazzinePlan).plan_code : (elem.product as Product).product_code),
                    quantity: elem.quantity,
                    type: elem.type
                }
            )
        })

        return clone;
    }

    return <div className="shopping-page">
        <Header />
        <div className="form">
            <div className="form-content expanded">
                {!isShowPaymentMethod.isOpen ?
                    shopping.length > 0 ?
                        <>
                            <div className="form-header">
                                <h4>
                                    Tu carrito de compras
                                </h4>
                                <span>
                                    Gestiona tus productos y confirma tu compra
                                </span>
                            </div>

                            <div className="shopping-cart-container-row">
                                {
                                    shopping.map((prod: ShoppingElement, index: number) => {
                                        return <ShoppingCartCard element={prod}
                                            onModify={(toModify: ShoppingElement) => {
                                                if (!isOpenToPay) {
                                                    modifyQuantity(toModify)
                                                    updateCart();
                                                }
                                            }}
                                            onRemove={(toDelete: ShoppingElement) => {
                                                if (!isOpenToPay) {
                                                    deleteProduct(toDelete)
                                                    updateCart();
                                                }
                                            }}
                                            disabled={isOpenToPay} />
                                    })
                                }
                            </div>

                            <div className="form-footer" style={{ marginBottom: "2rem" }}>
                                {
                                    !isOpenToPay ?
                                        <>
                                            <Button onClick={() => {
                                                removeItem(shoppingStorageName);
                                                setShopping([]);
                                            }}>
                                                Limpiar carrito
                                            </Button>
                                            <Button solid
                                                onClick={() => {
                                                    setIsOpenToPay(true);
                                                }} >Completar compra</Button>
                                        </>
                                        :
                                        <></>
                                }

                            </div>

                        </>
                        :
                        <label style={{ marginBottom: "1rem", paddingBottom: "1rem", borderBottom: "1px solid rgba(0,0,0,.1)", width: "100%", display: "block", textAlign: "center" }}>Todavía no tienes elementos en tu carrito</label>
                    :
                    <></>
                }

                {subscriptions.isLoaded ?
                    !isOpenToPay ?
                        <>
                            {
                                isShowList ?
                                    <>
                                        <div className="form-header">
                                            <h4>Tus suscripciones</h4>
                                            <span>Revisa tus transacciones pendientes y completadas</span>
                                        </div>

                                        <div className="shopping-cart-container-row">
                                            {subscriptions.values.length > 0 ?
                                                subscriptions.values.map((subs: Subscription, index: number) => {
                                                    return <div className="shopping-cart-card subs" key={"subscription-" + index}
                                                        onClick={() => {
                                                            getSubscriptionInfo(subs.subscription_code, subs.type_payment);
                                                            setIsShowList(false);
                                                        }}>
                                                        <div className="row row-1">
                                                            <div>
                                                                <span>{subs.date_subscription as unknown as string}</span>
                                                            </div>

                                                            <div>
                                                                <p className="button-component button-remove state-subscription"
                                                                    color={subs.state_payment === "debt" ? "yellow" :
                                                                        (subs.state_payment === "paid" ? "green" : "red")}
                                                                >
                                                                    {subs.state_payment === "debt" ? "En deuda" :
                                                                        (subs.state_payment === "paid" ? "Pagado" : "Expirado")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row row-2">
                                                            <span>Productos: {subs.products.length}</span>
                                                            <span>Tipo de pago: <b>
                                                                {
                                                                    subs.type_payment === "qr" ?
                                                                        "QR"
                                                                        :
                                                                        (subs.type_payment === "card" ?
                                                                            "Tarjeta"
                                                                            :
                                                                            (subs.type_payment === "money" ?
                                                                                "Efectivo"
                                                                                :
                                                                                "Otro"))
                                                                }
                                                            </b></span>
                                                        </div>
                                                    </div>

                                                })
                                                :
                                                <label>Aún no tienes suscripciones</label>
                                            }
                                        </div>
                                    </>
                                    :
                                    <></>
                            }

                        </>
                        :
                        <></>

                    :
                    <Loader text="Cargando tus suscripciones" />
                }

                <Collapse isOpened={isOpenToPay} >

                    <div className="form-header">
                        <span>
                            <small>Carrito de compras
                                <button onClick={() => {
                                    removeItem(shoppingStorageName);
                                    updateCart();

                                    setIsOpenToPay(false);
                                }}>Limpiar</button>
                            </small>
                        </span>
                        <h4>
                            Información de la compra
                        </h4>
                    </div>

                    <div className="form-body q-2">
                        <Input
                            title="Nombre completo"
                            required
                            inputProperties={{
                                defaultValue: user?.full_name,
                                ref: nameRef,
                                onInput: validateForm,
                                disabled: isValidForm.isSending
                            }}
                        />
                        <InputPhone
                            defaultValue={cell}
                            required
                            disabled={isValidForm.isSending}
                            onChange={(ev) => {
                                setCell(ev);
                            }}
                        />

                        <InputLocation
                            defaultValue={location}
                            required
                            onInput={validateForm}
                            onBlur={(ev: any) => {
                                setAproxLocationUser(ev.target.value);
                            }}
                            onChange={(location: string)=>{
                                setLocation(location);
                            }}
                            disabled={isValidForm.isSending}
                        />

                        <Input
                            title="Dirección especifica (Para envíos y facturación)"
                            required
                            inputProperties={{
                                onFocus: () => {
                                    setIsSelectingSpecifiedLocation(true);
                                },
                                defaultValue: (specifiedLocation ?
                                    ("Lat: " + specifiedLocation.lat.toString().slice(0, 11) + "; Lng: " + specifiedLocation.lng.toString().slice(0, 11))
                                    : ""
                                ),
                                placeholder: "Clik aquí para seleccionar tu ubicación",
                                disabled: isSelectingSpecifiedLocation
                            }}
                        />
                    </div>

                    <Collapse isOpened={isSelectingSpecifiedLocation} theme={{
                        collapse: "collapse-map-specified-location",
                        content: "collapse-map-specified-location-content"
                    }}>
                        <Maps
                            onAddMarker={(marker: google.maps.LatLngLiteral) => {
                                setSpecifiedLocation(marker);

                                validateForm()
                            }}
                            posicionateOnAproxLocation={aproxLocationUser}
                        />
                        <Input
                            title="Dirección literal"
                            required
                            style={{
                                marginTop: "1rem",
                                height: "auto"
                            }}
                            inputProperties={{
                                defaultValue: '',
                                placeholder: "Zona ... Avenida ... Calle ...",
                                ref: directionRef,
                                onInput: validateForm
                            }}
                        />
                    </Collapse>

                    <div className="form-body q-2 border-top">
                        <InputSelect
                            title={<><>Factura </><small style={{ fontSize: ".8rem" }}>{!isFactureToOther ? "(NIT: " + user?.ci_nit + ", De: " + user?.full_name + ")" : ""}</small></>}
                            inputProperties={{
                                type: "select",
                                defaultValue: "personal-to-me",
                                ref: facturaTypeRef,
                                onChange: (ev: any) => {
                                    if (ev.target.value !== "personal-to-me") {
                                        setIsFactureToOther(true);
                                    } else {
                                        setIsFactureToOther(false);
                                    }

                                    validateForm()
                                },
                                disabled: isValidForm.isSending
                            }}
                            options={[
                                {
                                    text: "Personal - Para mí",
                                    value: "personal-to-me"
                                },
                                {
                                    text: "Personal - Para un tercero",
                                    value: "personal-to-other"
                                },
                                {
                                    text: "Institucional - Para una empresa",
                                    value: "institutional"
                                },
                            ]}
                        />
                        <InputSelect
                            title="Método de pago"
                            inputProperties={{
                                type: "select",
                                defaultValue: "qr",
                                ref: paymentTypeRef,
                                onChange: (ev: any) => {
                                    validateForm()
                                },
                                disabled: isValidForm.isSending
                            }}
                            options={[
                                {
                                    text: "QR",
                                    value: "qr"
                                },
                                {
                                    text: "Tarjeta Crédito/Débito",
                                    value: "card"
                                },
                                {
                                    text: "Efectivo",
                                    value: "money"
                                }
                            ]}
                        />
                    </div>

                    <Collapse isOpened={isFactureToOther}>
                        <div className="form-body q-2 border-top">
                            <Input
                                title="Número de NIT o CI"
                                inputProperties={{
                                    ref: otherNitRef,
                                    type: "text"
                                }}
                            />
                            <Input
                                title="Razón social o nombre"
                                inputProperties={{
                                    ref: otherNameRef
                                }}
                            />
                        </div>


                    </Collapse>

                    <div className="form-footer space-between price-and-policy">
                        <div className="">
                            <input type="checkbox" id="accept" ref={acceptRef} onChange={validateForm} disabled={isValidForm.isSending} />
                            <label htmlFor="accept">Acepto la <Link to={routes.policy}>política de Uso, Privacidad y Cookies</Link></label>
                        </div>

                        <span>
                            Total: <b>{getTotalPrice()} Bs</b>
                        </span>
                    </div>

                    <div className="form-footer">
                        <Button disabled={isValidForm.isSending} onClick={() => {
                            setIsOpenToPay(false);
                        }}>Cancelar</Button>
                        <Button solid disabled={!(isValidForm.valid && !isValidForm.isSending)} onClick={sendForm}>{isValidForm.isSending ? "Procesando compra" : "Continuar"}</Button>
                    </div>

                </Collapse>

                <Collapse isOpened={isShowPaymentMethod.isOpen}>
                    {isShowPaymentMethod.isLoaded ?
                        isShowPaymentMethod.isOpen ?
                            <>
                                {
                                    isShowPaymentMethod.typePayment === "qr" ?
                                        <QRPaymentComponent
                                            onReturn={() => {
                                                setIsShowList(true);
                                                setIsShowPaymentMethod({ isOpen: false, typePayment: isShowPaymentMethod.typePayment, values: isShowPaymentMethod.values, isLoaded: false })
                                            }} info={isShowPaymentMethod.values} />
                                        :
                                        <></>
                                }
                                {
                                    isShowPaymentMethod.typePayment === "card" ?
                                        <CardPaymentComponent info={isShowPaymentMethod.values.VALUE} onReturn={() => {
                                            setIsShowList(true);
                                            setIsShowPaymentMethod({ isOpen: false, typePayment: isShowPaymentMethod.typePayment, values: isShowPaymentMethod.values, isLoaded: false })
                                        }} />
                                        :
                                        <></>
                                }

                                {
                                    isShowPaymentMethod.typePayment === "money" ?
                                        <MoneyPaymentComponent info={isShowPaymentMethod.values.VALUE} onReturn={() => {
                                            setIsShowList(true);
                                            setIsShowPaymentMethod({ isOpen: false, typePayment: isShowPaymentMethod.typePayment, values: isShowPaymentMethod.values, isLoaded: false })
                                        }} />
                                        :
                                        <></>
                                }
                            </>

                            :
                            <>

                            </>
                        :
                        <Loader text="Cargando suscripción" />
                    }
                </Collapse>
            </div>
        </div>

        <Footer />
    </div>
}

export default Shopping;