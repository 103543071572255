export type ListType = {pais: string, code_pais: string, ciudad: string, code_ciudad: string | number};

export const availCountry: ListType[] = [
    {
        "pais": "Andorra",
        "code_pais": "AD",
        "ciudad": "Canillo",
        "code_ciudad": "02"
    },
    {
        "pais": "Andorra",
        "code_pais": "AD",
        "ciudad": "Encamp",
        "code_ciudad": "03"
    },
    {
        "pais": "Andorra",
        "code_pais": "AD",
        "ciudad": "La Massana",
        "code_ciudad": "04"
    },
    {
        "pais": "Andorra",
        "code_pais": "AD",
        "ciudad": "Ordino",
        "code_ciudad": "05"
    },
    {
        "pais": "Andorra",
        "code_pais": "AD",
        "ciudad": "Sant Julia de Loria",
        "code_ciudad": "06"
    },
    {
        "pais": "Andorra",
        "code_pais": "AD",
        "ciudad": "Andorra la Vella",
        "code_ciudad": "07"
    },
    {
        "pais": "Andorra",
        "code_pais": "AD",
        "ciudad": "Escaldes-Engordany",
        "code_ciudad": "08"
    },
    {
        "pais": "Emiratos Árabes Unidos",
        "code_pais": "AE",
        "ciudad": "'Ajman",
        "code_ciudad": "AJ"
    },
    {
        "pais": "Emiratos Árabes Unidos",
        "code_pais": "AE",
        "ciudad": "Abu Zaby",
        "code_ciudad": "AZ"
    },
    {
        "pais": "Emiratos Árabes Unidos",
        "code_pais": "AE",
        "ciudad": "Dubayy",
        "code_ciudad": "DU"
    },
    {
        "pais": "Emiratos Árabes Unidos",
        "code_pais": "AE",
        "ciudad": "Al Fujayrah",
        "code_ciudad": "FU"
    },
    {
        "pais": "Emiratos Árabes Unidos",
        "code_pais": "AE",
        "ciudad": "Ra's al Khaymah",
        "code_ciudad": "RK"
    },
    {
        "pais": "Emiratos Árabes Unidos",
        "code_pais": "AE",
        "ciudad": "Ash Shariqah",
        "code_ciudad": "SH"
    },
    {
        "pais": "Emiratos Árabes Unidos",
        "code_pais": "AE",
        "ciudad": "Umm al Qaywayn",
        "code_ciudad": "UQ"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Balkh",
        "code_ciudad": "BAL"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Bamyan",
        "code_ciudad": "BAM"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Badakhshan",
        "code_ciudad": "BDS"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Baghlan",
        "code_ciudad": "BGL"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Daykundi",
        "code_ciudad": "DAY"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Farah",
        "code_ciudad": "FRA"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Faryab",
        "code_ciudad": "FYB"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Ghazni",
        "code_ciudad": "GHA"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Ghor",
        "code_ciudad": "GHO"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Helmand",
        "code_ciudad": "HEL"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Herat",
        "code_ciudad": "HER"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Jowzjan",
        "code_ciudad": "JOW"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Kabul",
        "code_ciudad": "KAB"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Kandahar",
        "code_ciudad": "KAN"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Kunduz",
        "code_ciudad": "KDZ"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Khost",
        "code_ciudad": "KHO"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Laghman",
        "code_ciudad": "LAG"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Logar",
        "code_ciudad": "LOG"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Nangarhar",
        "code_ciudad": "NAN"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Nimroz",
        "code_ciudad": "NIM"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Parwan",
        "code_ciudad": "PAR"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Paktiya",
        "code_ciudad": "PIA"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Takhar",
        "code_ciudad": "TAK"
    },
    {
        "pais": "Afganistán",
        "code_pais": "AF",
        "ciudad": "Uruzgan",
        "code_ciudad": "URU"
    },
    {
        "pais": "Antigua y Barbuda",
        "code_pais": "AG",
        "ciudad": "Saint George",
        "code_ciudad": "03"
    },
    {
        "pais": "Antigua y Barbuda",
        "code_pais": "AG",
        "ciudad": "Saint John",
        "code_ciudad": "04"
    },
    {
        "pais": "Antigua y Barbuda",
        "code_pais": "AG",
        "ciudad": "Saint Mary",
        "code_ciudad": "05"
    },
    {
        "pais": "Antigua y Barbuda",
        "code_pais": "AG",
        "ciudad": "Saint Paul",
        "code_ciudad": "06"
    },
    {
        "pais": "Antigua y Barbuda",
        "code_pais": "AG",
        "ciudad": "Saint Peter",
        "code_ciudad": "07"
    },
    {
        "pais": "Antigua y Barbuda",
        "code_pais": "AG",
        "ciudad": "Saint Philip",
        "code_ciudad": "08"
    },
    {
        "pais": "Antigua y Barbuda",
        "code_pais": "AG",
        "ciudad": "Redonda",
        "code_ciudad": 11
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Berat",
        "code_ciudad": "01"
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Durres",
        "code_ciudad": "02"
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Elbasan",
        "code_ciudad": "03"
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Fier",
        "code_ciudad": "04"
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Gjirokaster",
        "code_ciudad": "05"
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Korce",
        "code_ciudad": "06"
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Kukes",
        "code_ciudad": "07"
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Lezhe",
        "code_ciudad": "08"
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Diber",
        "code_ciudad": "09"
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Shkoder",
        "code_ciudad": 10
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Tirane",
        "code_ciudad": 11
    },
    {
        "pais": "Albania",
        "code_pais": "AL",
        "ciudad": "Vlore",
        "code_ciudad": 12
    },
    {
        "pais": "Armenia",
        "code_pais": "AM",
        "ciudad": "Aragacotn",
        "code_ciudad": "AG"
    },
    {
        "pais": "Armenia",
        "code_pais": "AM",
        "ciudad": "Ararat",
        "code_ciudad": "AR"
    },
    {
        "pais": "Armenia",
        "code_pais": "AM",
        "ciudad": "Armavir",
        "code_ciudad": "AV"
    },
    {
        "pais": "Armenia",
        "code_pais": "AM",
        "ciudad": "Erevan",
        "code_ciudad": "ER"
    },
    {
        "pais": "Armenia",
        "code_pais": "AM",
        "ciudad": "Gegark'unik'",
        "code_ciudad": "GR"
    },
    {
        "pais": "Armenia",
        "code_pais": "AM",
        "ciudad": "Kotayk'",
        "code_ciudad": "KT"
    },
    {
        "pais": "Armenia",
        "code_pais": "AM",
        "ciudad": "Lori",
        "code_ciudad": "LO"
    },
    {
        "pais": "Armenia",
        "code_pais": "AM",
        "ciudad": "Sirak",
        "code_ciudad": "SH"
    },
    {
        "pais": "Armenia",
        "code_pais": "AM",
        "ciudad": "Syunik'",
        "code_ciudad": "SU"
    },
    {
        "pais": "Armenia",
        "code_pais": "AM",
        "ciudad": "Tavus",
        "code_ciudad": "TV"
    },
    {
        "pais": "Armenia",
        "code_pais": "AM",
        "ciudad": "Vayoc Jor",
        "code_ciudad": "VD"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Bengo",
        "code_ciudad": "BGO"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Benguela",
        "code_ciudad": "BGU"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Bie",
        "code_ciudad": "BIE"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Cabinda",
        "code_ciudad": "CAB"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Kuando Kubango",
        "code_ciudad": "CCU"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Cunene",
        "code_ciudad": "CNN"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Kwanza Norte",
        "code_ciudad": "CNO"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Kwanza Sul",
        "code_ciudad": "CUS"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Huambo",
        "code_ciudad": "HUA"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Huila",
        "code_ciudad": "HUI"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Lunda Norte",
        "code_ciudad": "LNO"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Lunda Sul",
        "code_ciudad": "LSU"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Luanda",
        "code_ciudad": "LUA"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Malange",
        "code_ciudad": "MAL"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Moxico",
        "code_ciudad": "MOX"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Namibe",
        "code_ciudad": "NAM"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Uige",
        "code_ciudad": "UIG"
    },
    {
        "pais": "Angola",
        "code_pais": "AO",
        "ciudad": "Zaire",
        "code_ciudad": "ZAI"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Salta",
        "code_ciudad": "A"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Buenos Aires",
        "code_ciudad": "B"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Ciudad Autonoma de Buenos Aires",
        "code_ciudad": "C"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "San Luis",
        "code_ciudad": "D"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Entre Rios",
        "code_ciudad": "E"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "La Rioja",
        "code_ciudad": "F"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Santiago del Estero",
        "code_ciudad": "G"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Chaco",
        "code_ciudad": "H"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "San Juan",
        "code_ciudad": "J"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Catamarca",
        "code_ciudad": "K"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "La Pampa",
        "code_ciudad": "L"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Mendoza",
        "code_ciudad": "M"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Misiones",
        "code_ciudad": "N"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Formosa",
        "code_ciudad": "P"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Neuquen",
        "code_ciudad": "Q"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Rio Negro",
        "code_ciudad": "R"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Santa Fe",
        "code_ciudad": "S"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Tucuman",
        "code_ciudad": "T"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Chubut",
        "code_ciudad": "U"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Tierra del Fuego",
        "code_ciudad": "V"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Corrientes",
        "code_ciudad": "W"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Cordoba",
        "code_ciudad": "X"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Jujuy",
        "code_ciudad": "Y"
    },
    {
        "pais": "Argentina",
        "code_pais": "AR",
        "ciudad": "Santa Cruz",
        "code_ciudad": "Z"
    },
    {
        "pais": "Austria",
        "code_pais": "AT",
        "ciudad": "Burgenland",
        "code_ciudad": 1
    },
    {
        "pais": "Austria",
        "code_pais": "AT",
        "ciudad": "Karnten",
        "code_ciudad": 2
    },
    {
        "pais": "Austria",
        "code_pais": "AT",
        "ciudad": "Niederosterreich",
        "code_ciudad": 3
    },
    {
        "pais": "Austria",
        "code_pais": "AT",
        "ciudad": "Oberosterreich",
        "code_ciudad": 4
    },
    {
        "pais": "Austria",
        "code_pais": "AT",
        "ciudad": "Salzburg",
        "code_ciudad": 5
    },
    {
        "pais": "Austria",
        "code_pais": "AT",
        "ciudad": "Steiermark",
        "code_ciudad": 6
    },
    {
        "pais": "Austria",
        "code_pais": "AT",
        "ciudad": "Tirol",
        "code_ciudad": 7
    },
    {
        "pais": "Austria",
        "code_pais": "AT",
        "ciudad": "Vorarlberg",
        "code_ciudad": 8
    },
    {
        "pais": "Austria",
        "code_pais": "AT",
        "ciudad": "Wien",
        "code_ciudad": 9
    },
    {
        "pais": "Australia",
        "code_pais": "AU",
        "ciudad": "Australian Capital Territory",
        "code_ciudad": "ACT"
    },
    {
        "pais": "Australia",
        "code_pais": "AU",
        "ciudad": "New South Wales",
        "code_ciudad": "NSW"
    },
    {
        "pais": "Australia",
        "code_pais": "AU",
        "ciudad": "Northern Territory",
        "code_ciudad": "NT"
    },
    {
        "pais": "Australia",
        "code_pais": "AU",
        "ciudad": "Queensland",
        "code_ciudad": "QLD"
    },
    {
        "pais": "Australia",
        "code_pais": "AU",
        "ciudad": "South Australia",
        "code_ciudad": "SA"
    },
    {
        "pais": "Australia",
        "code_pais": "AU",
        "ciudad": "Tasmania",
        "code_ciudad": "TAS"
    },
    {
        "pais": "Australia",
        "code_pais": "AU",
        "ciudad": "Victoria",
        "code_ciudad": "VIC"
    },
    {
        "pais": "Australia",
        "code_pais": "AU",
        "ciudad": "Western Australia",
        "code_ciudad": "WA"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Abseron",
        "code_ciudad": "ABS"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Agstafa",
        "code_ciudad": "AGA"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Agcabadi",
        "code_ciudad": "AGC"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Agsu",
        "code_ciudad": "AGU"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Astara",
        "code_ciudad": "AST"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Baki",
        "code_ciudad": "BA"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Balakan",
        "code_ciudad": "BAL"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Barda",
        "code_ciudad": "BAR"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Beylaqan",
        "code_ciudad": "BEY"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Bilasuvar",
        "code_ciudad": "BIL"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Calilabad",
        "code_ciudad": "CAL"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Fuzuli",
        "code_ciudad": "FUZ"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Ganca",
        "code_ciudad": "GA"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Gadabay",
        "code_ciudad": "GAD"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Goranboy",
        "code_ciudad": "GOR"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Goycay",
        "code_ciudad": "GOY"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Goygol",
        "code_ciudad": "GYG"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Haciqabul",
        "code_ciudad": "HAC"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Imisli",
        "code_ciudad": "IMI"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Ismayilli",
        "code_ciudad": "ISM"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Kurdamir",
        "code_ciudad": "KUR"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Lankaran",
        "code_ciudad": "LA"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Masalli",
        "code_ciudad": "MAS"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Mingacevir",
        "code_ciudad": "MI"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Naftalan",
        "code_ciudad": "NA"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Neftcala",
        "code_ciudad": "NEF"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Naxcivan",
        "code_ciudad": "NX"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Oguz",
        "code_ciudad": "OGU"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Qabala",
        "code_ciudad": "QAB"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Qax",
        "code_ciudad": "QAX"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Qazax",
        "code_ciudad": "QAZ"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Quba",
        "code_ciudad": "QBA"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Qusar",
        "code_ciudad": "QUS"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Sabirabad",
        "code_ciudad": "SAB"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Saki",
        "code_ciudad": "SAK"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Salyan",
        "code_ciudad": "SAL"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Saatli",
        "code_ciudad": "SAT"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Siyazan",
        "code_ciudad": "SIY"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Samkir",
        "code_ciudad": "SKR"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Sumqayit",
        "code_ciudad": "SM"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Samaxi",
        "code_ciudad": "SMI"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Samux",
        "code_ciudad": "SMX"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Sirvan",
        "code_ciudad": "SR"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Tartar",
        "code_ciudad": "TAR"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Tovuz",
        "code_ciudad": "TOV"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Xacmaz",
        "code_ciudad": "XAC"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Xizi",
        "code_ciudad": "XIZ"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Yardimli",
        "code_ciudad": "YAR"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Yevlax",
        "code_ciudad": "YEV"
    },
    {
        "pais": "Azerbaiyán",
        "code_pais": "AZ",
        "ciudad": "Zaqatala",
        "code_ciudad": "ZAQ"
    },
    {
        "pais": "Bosnia y Herzegovina",
        "code_pais": "BA",
        "ciudad": "Federacija Bosne i Hercegovine",
        "code_ciudad": "BIH"
    },
    {
        "pais": "Bosnia y Herzegovina",
        "code_pais": "BA",
        "ciudad": "Brcko distrikt",
        "code_ciudad": "BRC"
    },
    {
        "pais": "Bosnia y Herzegovina",
        "code_pais": "BA",
        "ciudad": "Republika Srpska",
        "code_ciudad": "SRP"
    },
    {
        "pais": "Barbados",
        "code_pais": "BB",
        "ciudad": "Christ Church",
        "code_ciudad": "01"
    },
    {
        "pais": "Barbados",
        "code_pais": "BB",
        "ciudad": "Saint Andrew",
        "code_ciudad": "02"
    },
    {
        "pais": "Barbados",
        "code_pais": "BB",
        "ciudad": "Saint George",
        "code_ciudad": "03"
    },
    {
        "pais": "Barbados",
        "code_pais": "BB",
        "ciudad": "Saint James",
        "code_ciudad": "04"
    },
    {
        "pais": "Barbados",
        "code_pais": "BB",
        "ciudad": "Saint John",
        "code_ciudad": "05"
    },
    {
        "pais": "Barbados",
        "code_pais": "BB",
        "ciudad": "Saint Joseph",
        "code_ciudad": "06"
    },
    {
        "pais": "Barbados",
        "code_pais": "BB",
        "ciudad": "Saint Lucy",
        "code_ciudad": "07"
    },
    {
        "pais": "Barbados",
        "code_pais": "BB",
        "ciudad": "Saint Michael",
        "code_ciudad": "08"
    },
    {
        "pais": "Barbados",
        "code_pais": "BB",
        "ciudad": "Saint Peter",
        "code_ciudad": "09"
    },
    {
        "pais": "Barbados",
        "code_pais": "BB",
        "ciudad": "Saint Philip",
        "code_ciudad": 10
    },
    {
        "pais": "Barbados",
        "code_pais": "BB",
        "ciudad": "Saint Thomas",
        "code_ciudad": 11
    },
    {
        "pais": "Bangladés",
        "code_pais": "BD",
        "ciudad": "Barisal",
        "code_ciudad": "A"
    },
    {
        "pais": "Bangladés",
        "code_pais": "BD",
        "ciudad": "Chittagong",
        "code_ciudad": "B"
    },
    {
        "pais": "Bangladés",
        "code_pais": "BD",
        "ciudad": "Dhaka",
        "code_ciudad": "C"
    },
    {
        "pais": "Bangladés",
        "code_pais": "BD",
        "ciudad": "Khulna",
        "code_ciudad": "D"
    },
    {
        "pais": "Bangladés",
        "code_pais": "BD",
        "ciudad": "Rajshahi",
        "code_ciudad": "E"
    },
    {
        "pais": "Bangladés",
        "code_pais": "BD",
        "ciudad": "Rangpur",
        "code_ciudad": "F"
    },
    {
        "pais": "Bangladés",
        "code_pais": "BD",
        "ciudad": "Sylhet",
        "code_ciudad": "G"
    },
    {
        "pais": "Bélgica",
        "code_pais": "BE",
        "ciudad": "Brussels Hoofdstedelijk Gewest",
        "code_ciudad": "BRU"
    },
    {
        "pais": "Bélgica",
        "code_pais": "BE",
        "ciudad": "Antwerpen",
        "code_ciudad": "VAN"
    },
    {
        "pais": "Bélgica",
        "code_pais": "BE",
        "ciudad": "Vlaams-Brabant",
        "code_ciudad": "VBR"
    },
    {
        "pais": "Bélgica",
        "code_pais": "BE",
        "ciudad": "Limburg",
        "code_ciudad": "VLI"
    },
    {
        "pais": "Bélgica",
        "code_pais": "BE",
        "ciudad": "Oost-Vlaanderen",
        "code_ciudad": "VOV"
    },
    {
        "pais": "Bélgica",
        "code_pais": "BE",
        "ciudad": "West-Vlaanderen",
        "code_ciudad": "VWV"
    },
    {
        "pais": "Bélgica",
        "code_pais": "BE",
        "ciudad": "Brabant wallon",
        "code_ciudad": "WBR"
    },
    {
        "pais": "Bélgica",
        "code_pais": "BE",
        "ciudad": "Hainaut",
        "code_ciudad": "WHT"
    },
    {
        "pais": "Bélgica",
        "code_pais": "BE",
        "ciudad": "Liege",
        "code_ciudad": "WLG"
    },
    {
        "pais": "Bélgica",
        "code_pais": "BE",
        "ciudad": "Luxembourg",
        "code_ciudad": "WLX"
    },
    {
        "pais": "Bélgica",
        "code_pais": "BE",
        "ciudad": "Namur",
        "code_ciudad": "WNA"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Bale",
        "code_ciudad": "BAL"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Bam",
        "code_ciudad": "BAM"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Bazega",
        "code_ciudad": "BAZ"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Boulgou",
        "code_ciudad": "BLG"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Boulkiemde",
        "code_ciudad": "BLK"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Comoe",
        "code_ciudad": "COM"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Gnagna",
        "code_ciudad": "GNA"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Houet",
        "code_ciudad": "HOU"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Kadiogo",
        "code_ciudad": "KAD"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Kourweogo",
        "code_ciudad": "KOW"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Leraba",
        "code_ciudad": "LER"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Mouhoun",
        "code_ciudad": "MOU"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Poni",
        "code_ciudad": "PON"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Sanmatenga",
        "code_ciudad": "SMT"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Tapoa",
        "code_ciudad": "TAP"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Tuy",
        "code_ciudad": "TUI"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Yatenga",
        "code_ciudad": "YAT"
    },
    {
        "pais": "Burkina Faso",
        "code_pais": "BF",
        "ciudad": "Zoundweogo",
        "code_ciudad": "ZOU"
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Blagoevgrad",
        "code_ciudad": "01"
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Burgas",
        "code_ciudad": "02"
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Varna",
        "code_ciudad": "03"
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Veliko Tarnovo",
        "code_ciudad": "04"
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Vidin",
        "code_ciudad": "05"
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Vratsa",
        "code_ciudad": "06"
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Gabrovo",
        "code_ciudad": "07"
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Dobrich",
        "code_ciudad": "08"
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Kardzhali",
        "code_ciudad": "09"
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Kyustendil",
        "code_ciudad": 10
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Lovech",
        "code_ciudad": 11
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Montana",
        "code_ciudad": 12
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Pazardzhik",
        "code_ciudad": 13
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Pernik",
        "code_ciudad": 14
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Pleven",
        "code_ciudad": 15
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Plovdiv",
        "code_ciudad": 16
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Razgrad",
        "code_ciudad": 17
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Ruse",
        "code_ciudad": 18
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Silistra",
        "code_ciudad": 19
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Sliven",
        "code_ciudad": 20
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Smolyan",
        "code_ciudad": 21
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Sofia (stolitsa)",
        "code_ciudad": 22
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Sofia",
        "code_ciudad": 23
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Stara Zagora",
        "code_ciudad": 24
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Targovishte",
        "code_ciudad": 25
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Haskovo",
        "code_ciudad": 26
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Shumen",
        "code_ciudad": 27
    },
    {
        "pais": "Bulgaria",
        "code_pais": "BG",
        "ciudad": "Yambol",
        "code_ciudad": 28
    },
    {
        "pais": "Baréin",
        "code_pais": "BH",
        "ciudad": "Al 'Asimah",
        "code_ciudad": 13
    },
    {
        "pais": "Baréin",
        "code_pais": "BH",
        "ciudad": "Al Janubiyah",
        "code_ciudad": 14
    },
    {
        "pais": "Baréin",
        "code_pais": "BH",
        "ciudad": "Al Muharraq",
        "code_ciudad": 15
    },
    {
        "pais": "Baréin",
        "code_pais": "BH",
        "ciudad": "Ash Shamaliyah",
        "code_ciudad": 17
    },
    {
        "pais": "Burundi",
        "code_pais": "BI",
        "ciudad": "Bubanza",
        "code_ciudad": "BB"
    },
    {
        "pais": "Burundi",
        "code_pais": "BI",
        "ciudad": "Bujumbura Mairie",
        "code_ciudad": "BM"
    },
    {
        "pais": "Burundi",
        "code_pais": "BI",
        "ciudad": "Bururi",
        "code_ciudad": "BR"
    },
    {
        "pais": "Burundi",
        "code_pais": "BI",
        "ciudad": "Cibitoke",
        "code_ciudad": "CI"
    },
    {
        "pais": "Burundi",
        "code_pais": "BI",
        "ciudad": "Gitega",
        "code_ciudad": "GI"
    },
    {
        "pais": "Burundi",
        "code_pais": "BI",
        "ciudad": "Kirundo",
        "code_ciudad": "KI"
    },
    {
        "pais": "Burundi",
        "code_pais": "BI",
        "ciudad": "Muramvya",
        "code_ciudad": "MU"
    },
    {
        "pais": "Burundi",
        "code_pais": "BI",
        "ciudad": "Mwaro",
        "code_ciudad": "MW"
    },
    {
        "pais": "Burundi",
        "code_pais": "BI",
        "ciudad": "Ngozi",
        "code_ciudad": "NG"
    },
    {
        "pais": "Burundi",
        "code_pais": "BI",
        "ciudad": "Rutana",
        "code_ciudad": "RT"
    },
    {
        "pais": "Burundi",
        "code_pais": "BI",
        "ciudad": "Ruyigi",
        "code_ciudad": "RY"
    },
    {
        "pais": "Benín",
        "code_pais": "BJ",
        "ciudad": "Atacora",
        "code_ciudad": "AK"
    },
    {
        "pais": "Benín",
        "code_pais": "BJ",
        "ciudad": "Alibori",
        "code_ciudad": "AL"
    },
    {
        "pais": "Benín",
        "code_pais": "BJ",
        "ciudad": "Atlantique",
        "code_ciudad": "AQ"
    },
    {
        "pais": "Benín",
        "code_pais": "BJ",
        "ciudad": "Borgou",
        "code_ciudad": "BO"
    },
    {
        "pais": "Benín",
        "code_pais": "BJ",
        "ciudad": "Donga",
        "code_ciudad": "DO"
    },
    {
        "pais": "Benín",
        "code_pais": "BJ",
        "ciudad": "Littoral",
        "code_ciudad": "LI"
    },
    {
        "pais": "Benín",
        "code_pais": "BJ",
        "ciudad": "Mono",
        "code_ciudad": "MO"
    },
    {
        "pais": "Benín",
        "code_pais": "BJ",
        "ciudad": "Oueme",
        "code_ciudad": "OU"
    },
    {
        "pais": "Benín",
        "code_pais": "BJ",
        "ciudad": "Plateau",
        "code_ciudad": "PL"
    },
    {
        "pais": "Benín",
        "code_pais": "BJ",
        "ciudad": "Zou",
        "code_ciudad": "ZO"
    },
    {
        "pais": "Brunéi",
        "code_pais": "BN",
        "ciudad": "Belait",
        "code_ciudad": "BE"
    },
    {
        "pais": "Brunéi",
        "code_pais": "BN",
        "ciudad": "Brunei-Muara",
        "code_ciudad": "BM"
    },
    {
        "pais": "Brunéi",
        "code_pais": "BN",
        "ciudad": "Temburong",
        "code_ciudad": "TE"
    },
    {
        "pais": "Brunéi",
        "code_pais": "BN",
        "ciudad": "Tutong",
        "code_ciudad": "TU"
    },
    {
        "pais": "Bolivia",
        "code_pais": "BO",
        "ciudad": "El Beni",
        "code_ciudad": "B"
    },
    {
        "pais": "Bolivia",
        "code_pais": "BO",
        "ciudad": "Cochabamba",
        "code_ciudad": "C"
    },
    {
        "pais": "Bolivia",
        "code_pais": "BO",
        "ciudad": "Chuquisaca",
        "code_ciudad": "H"
    },
    {
        "pais": "Bolivia",
        "code_pais": "BO",
        "ciudad": "La Paz",
        "code_ciudad": "L"
    },
    {
        "pais": "Bolivia",
        "code_pais": "BO",
        "ciudad": "Pando",
        "code_ciudad": "N"
    },
    {
        "pais": "Bolivia",
        "code_pais": "BO",
        "ciudad": "Oruro",
        "code_ciudad": "O"
    },
    {
        "pais": "Bolivia",
        "code_pais": "BO",
        "ciudad": "Potosi",
        "code_ciudad": "P"
    },
    {
        "pais": "Bolivia",
        "code_pais": "BO",
        "ciudad": "Santa Cruz",
        "code_ciudad": "S"
    },
    {
        "pais": "Bolivia",
        "code_pais": "BO",
        "ciudad": "Tarija",
        "code_ciudad": "T"
    },
    {
        "pais": "Bonaire, San Eustaquio y Saba",
        "code_pais": "BQ",
        "ciudad": "Bonaire",
        "code_ciudad": "BO"
    },
    {
        "pais": "Bonaire, San Eustaquio y Saba",
        "code_pais": "BQ",
        "ciudad": "Saba",
        "code_ciudad": "SA"
    },
    {
        "pais": "Bonaire, San Eustaquio y Saba",
        "code_pais": "BQ",
        "ciudad": "Sint Eustatius",
        "code_ciudad": "SE"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Acre",
        "code_ciudad": "AC"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Alagoas",
        "code_ciudad": "AL"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Amazonas",
        "code_ciudad": "AM"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Amapa",
        "code_ciudad": "AP"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Bahia",
        "code_ciudad": "BA"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Ceara",
        "code_ciudad": "CE"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Distrito Federal",
        "code_ciudad": "DF"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Espirito Santo",
        "code_ciudad": "ES"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Goias",
        "code_ciudad": "GO"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Maranhao",
        "code_ciudad": "MA"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Minas Gerais",
        "code_ciudad": "MG"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Mato Grosso do Sul",
        "code_ciudad": "MS"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Mato Grosso",
        "code_ciudad": "MT"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Para",
        "code_ciudad": "PA"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Paraiba",
        "code_ciudad": "PB"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Pernambuco",
        "code_ciudad": "PE"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Piaui",
        "code_ciudad": "PI"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Parana",
        "code_ciudad": "PR"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Rio de Janeiro",
        "code_ciudad": "RJ"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Rio Grande do Norte",
        "code_ciudad": "RN"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Rondonia",
        "code_ciudad": "RO"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Roraima",
        "code_ciudad": "RR"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Rio Grande do Sul",
        "code_ciudad": "RS"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Santa Catarina",
        "code_ciudad": "SC"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Sergipe",
        "code_ciudad": "SE"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Sao Paulo",
        "code_ciudad": "SP"
    },
    {
        "pais": "Brasil",
        "code_pais": "BR",
        "ciudad": "Tocantins",
        "code_ciudad": "TO"
    },
    {
        "pais": "Bahamas",
        "code_pais": "BS",
        "ciudad": "Central Andros",
        "code_ciudad": "CS"
    },
    {
        "pais": "Bahamas",
        "code_pais": "BS",
        "ciudad": "City of Freeport",
        "code_ciudad": "FP"
    },
    {
        "pais": "Bahamas",
        "code_pais": "BS",
        "ciudad": "Harbour Island",
        "code_ciudad": "HI"
    },
    {
        "pais": "Bahamas",
        "code_pais": "BS",
        "ciudad": "Hope Town",
        "code_ciudad": "HT"
    },
    {
        "pais": "Bahamas",
        "code_pais": "BS",
        "ciudad": "Long Island",
        "code_ciudad": "LI"
    },
    {
        "pais": "Bahamas",
        "code_pais": "BS",
        "ciudad": "New Providence",
        "code_ciudad": "NP"
    },
    {
        "pais": "Bahamas",
        "code_pais": "BS",
        "ciudad": "South Eleuthera",
        "code_ciudad": "SE"
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Paro",
        "code_ciudad": 11
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Chhukha",
        "code_ciudad": 12
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Haa",
        "code_ciudad": 13
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Samtse",
        "code_ciudad": 14
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Thimphu",
        "code_ciudad": 15
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Tsirang",
        "code_ciudad": 21
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Punakha",
        "code_ciudad": 23
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Wangdue Phodrang",
        "code_ciudad": 24
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Trongsa",
        "code_ciudad": 32
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Bumthang",
        "code_ciudad": 33
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Trashigang",
        "code_ciudad": 41
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Monggar",
        "code_ciudad": 42
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Pemagatshel",
        "code_ciudad": 43
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Lhuentse",
        "code_ciudad": 44
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Samdrup Jongkhar",
        "code_ciudad": 45
    },
    {
        "pais": "Bután",
        "code_pais": "BT",
        "ciudad": "Gasa",
        "code_ciudad": "GA"
    },
    {
        "pais": "Botsuana",
        "code_pais": "BW",
        "ciudad": "Central",
        "code_ciudad": "CE"
    },
    {
        "pais": "Botsuana",
        "code_pais": "BW",
        "ciudad": "Kgalagadi",
        "code_ciudad": "KG"
    },
    {
        "pais": "Botsuana",
        "code_pais": "BW",
        "ciudad": "Kgatleng",
        "code_ciudad": "KL"
    },
    {
        "pais": "Botsuana",
        "code_pais": "BW",
        "ciudad": "Kweneng",
        "code_ciudad": "KW"
    },
    {
        "pais": "Botsuana",
        "code_pais": "BW",
        "ciudad": "North East",
        "code_ciudad": "NE"
    },
    {
        "pais": "Botsuana",
        "code_pais": "BW",
        "ciudad": "North West",
        "code_ciudad": "NW"
    },
    {
        "pais": "Botsuana",
        "code_pais": "BW",
        "ciudad": "South East",
        "code_ciudad": "SE"
    },
    {
        "pais": "Botsuana",
        "code_pais": "BW",
        "ciudad": "Southern",
        "code_ciudad": "SO"
    },
    {
        "pais": "Bielorrusia",
        "code_pais": "BY",
        "ciudad": "Brestskaya voblasts'",
        "code_ciudad": "BR"
    },
    {
        "pais": "Bielorrusia",
        "code_pais": "BY",
        "ciudad": "Horad Minsk",
        "code_ciudad": "HM"
    },
    {
        "pais": "Bielorrusia",
        "code_pais": "BY",
        "ciudad": "Homyel'skaya voblasts'",
        "code_ciudad": "HO"
    },
    {
        "pais": "Bielorrusia",
        "code_pais": "BY",
        "ciudad": "Hrodzenskaya voblasts'",
        "code_ciudad": "HR"
    },
    {
        "pais": "Bielorrusia",
        "code_pais": "BY",
        "ciudad": "Mahilyowskaya voblasts'",
        "code_ciudad": "MA"
    },
    {
        "pais": "Bielorrusia",
        "code_pais": "BY",
        "ciudad": "Minskaya voblasts'",
        "code_ciudad": "MI"
    },
    {
        "pais": "Bielorrusia",
        "code_pais": "BY",
        "ciudad": "Vitsyebskaya voblasts'",
        "code_ciudad": "VI"
    },
    {
        "pais": "Belice",
        "code_pais": "BZ",
        "ciudad": "Belize",
        "code_ciudad": "BZ"
    },
    {
        "pais": "Belice",
        "code_pais": "BZ",
        "ciudad": "Cayo",
        "code_ciudad": "CY"
    },
    {
        "pais": "Belice",
        "code_pais": "BZ",
        "ciudad": "Corozal",
        "code_ciudad": "CZL"
    },
    {
        "pais": "Belice",
        "code_pais": "BZ",
        "ciudad": "Orange Walk",
        "code_ciudad": "OW"
    },
    {
        "pais": "Belice",
        "code_pais": "BZ",
        "ciudad": "Stann Creek",
        "code_ciudad": "SC"
    },
    {
        "pais": "Belice",
        "code_pais": "BZ",
        "ciudad": "Toledo",
        "code_ciudad": "TOL"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "Alberta",
        "code_ciudad": "AB"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "British Columbia",
        "code_ciudad": "BC"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "Manitoba",
        "code_ciudad": "MB"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "New Brunswick",
        "code_ciudad": "NB"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "Newfoundland and Labrador",
        "code_ciudad": "NL"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "Nova Scotia",
        "code_ciudad": "NS"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "Northwest Territories",
        "code_ciudad": "NT"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "Nunavut",
        "code_ciudad": "NU"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "Ontario",
        "code_ciudad": "ON"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "Prince Edward Island",
        "code_ciudad": "PE"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "Quebec",
        "code_ciudad": "QC"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "Saskatchewan",
        "code_ciudad": "SK"
    },
    {
        "pais": "Canadá",
        "code_pais": "CA",
        "ciudad": "Yukon",
        "code_ciudad": "YT"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Kongo Central",
        "code_ciudad": "BC"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Equateur",
        "code_ciudad": "EQ"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Haut-Katanga",
        "code_ciudad": "HK"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Haut-Lomami",
        "code_ciudad": "HL"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Haut-Uele",
        "code_ciudad": "HU"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Ituri",
        "code_ciudad": "IT"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Kasai Central",
        "code_ciudad": "KC"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Kasai Oriental",
        "code_ciudad": "KE"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Kwilu",
        "code_ciudad": "KL"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Kinshasa",
        "code_ciudad": "KN"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Lualaba",
        "code_ciudad": "LU"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Maniema",
        "code_ciudad": "MA"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Nord-Kivu",
        "code_ciudad": "NK"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Nord-Ubangi",
        "code_ciudad": "NU"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Sankuru",
        "code_ciudad": "SA"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Sud-Kivu",
        "code_ciudad": "SK"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Sud-Ubangi",
        "code_ciudad": "SU"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Tanganyika",
        "code_ciudad": "TA"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Tshopo",
        "code_ciudad": "TO"
    },
    {
        "pais": "República Democrática del Congo",
        "code_pais": "CD",
        "ciudad": "Tshuapa",
        "code_ciudad": "TU"
    },
    {
        "pais": "República Centroafricana",
        "code_pais": "CF",
        "ciudad": "Ouham",
        "code_ciudad": "AC"
    },
    {
        "pais": "República Centroafricana",
        "code_pais": "CF",
        "ciudad": "Bangui",
        "code_ciudad": "BGF"
    },
    {
        "pais": "República Centroafricana",
        "code_pais": "CF",
        "ciudad": "Nana-Mambere",
        "code_ciudad": "NM"
    },
    {
        "pais": "República Centroafricana",
        "code_pais": "CF",
        "ciudad": "Ouham-Pende",
        "code_ciudad": "OP"
    },
    {
        "pais": "República del Congo",
        "code_pais": "CG",
        "ciudad": "Sangha",
        "code_ciudad": 13
    },
    {
        "pais": "República del Congo",
        "code_pais": "CG",
        "ciudad": "Cuvette-Ouest",
        "code_ciudad": 15
    },
    {
        "pais": "República del Congo",
        "code_pais": "CG",
        "ciudad": "Pointe-Noire",
        "code_ciudad": 16
    },
    {
        "pais": "República del Congo",
        "code_pais": "CG",
        "ciudad": "Brazzaville",
        "code_ciudad": "BZV"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Aargau",
        "code_ciudad": "AG"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Appenzell Innerrhoden",
        "code_ciudad": "AI"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Appenzell Ausserrhoden",
        "code_ciudad": "AR"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Bern",
        "code_ciudad": "BE"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Basel-Landschaft",
        "code_ciudad": "BL"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Basel-Stadt",
        "code_ciudad": "BS"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Fribourg",
        "code_ciudad": "FR"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Geneve",
        "code_ciudad": "GE"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Glarus",
        "code_ciudad": "GL"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Graubunden",
        "code_ciudad": "GR"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Jura",
        "code_ciudad": "JU"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Luzern",
        "code_ciudad": "LU"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Neuchatel",
        "code_ciudad": "NE"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Nidwalden",
        "code_ciudad": "NW"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Obwalden",
        "code_ciudad": "OW"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Sankt Gallen",
        "code_ciudad": "SG"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Schaffhausen",
        "code_ciudad": "SH"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Solothurn",
        "code_ciudad": "SO"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Schwyz",
        "code_ciudad": "SZ"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Thurgau",
        "code_ciudad": "TG"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Ticino",
        "code_ciudad": "TI"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Uri",
        "code_ciudad": "UR"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Vaud",
        "code_ciudad": "VD"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Valais",
        "code_ciudad": "VS"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Zug",
        "code_ciudad": "ZG"
    },
    {
        "pais": "Suiza",
        "code_pais": "CH",
        "ciudad": "Zurich",
        "code_ciudad": "ZH"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Abidjan",
        "code_ciudad": "AB"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Bas-Sassandra",
        "code_ciudad": "BS"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Comoe",
        "code_ciudad": "CM"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Denguele",
        "code_ciudad": "DN"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Goh-Djiboua",
        "code_ciudad": "GD"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Lacs",
        "code_ciudad": "LC"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Lagunes",
        "code_ciudad": "LG"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Montagnes",
        "code_ciudad": "MG"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Sassandra-Marahoue",
        "code_ciudad": "SM"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Savanes",
        "code_ciudad": "SV"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Vallee du Bandama",
        "code_ciudad": "VB"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Woroba",
        "code_ciudad": "WR"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Yamoussoukro",
        "code_ciudad": "YM"
    },
    {
        "pais": "Costa de Marfil",
        "code_pais": "CI",
        "ciudad": "Zanzan",
        "code_ciudad": "ZZ"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Aisen del General Carlos Ibanez del Campo",
        "code_ciudad": "AI"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Antofagasta",
        "code_ciudad": "AN"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Arica y Parinacota",
        "code_ciudad": "AP"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "La Araucania",
        "code_ciudad": "AR"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Atacama",
        "code_ciudad": "AT"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Biobio",
        "code_ciudad": "BI"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Coquimbo",
        "code_ciudad": "CO"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Libertador General Bernardo O'Higgins",
        "code_ciudad": "LI"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Los Lagos",
        "code_ciudad": "LL"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Los Rios",
        "code_ciudad": "LR"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Magallanes",
        "code_ciudad": "MA"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Maule",
        "code_ciudad": "ML"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Region Metropolitana de Santiago",
        "code_ciudad": "RM"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Tarapaca",
        "code_ciudad": "TA"
    },
    {
        "pais": "Chile",
        "code_pais": "CL",
        "ciudad": "Valparaiso",
        "code_ciudad": "VS"
    },
    {
        "pais": "Camerún",
        "code_pais": "CM",
        "ciudad": "Adamaoua",
        "code_ciudad": "AD"
    },
    {
        "pais": "Camerún",
        "code_pais": "CM",
        "ciudad": "Centre",
        "code_ciudad": "CE"
    },
    {
        "pais": "Camerún",
        "code_pais": "CM",
        "ciudad": "Extreme-Nord",
        "code_ciudad": "EN"
    },
    {
        "pais": "Camerún",
        "code_pais": "CM",
        "ciudad": "Est",
        "code_ciudad": "ES"
    },
    {
        "pais": "Camerún",
        "code_pais": "CM",
        "ciudad": "Littoral",
        "code_ciudad": "LT"
    },
    {
        "pais": "Camerún",
        "code_pais": "CM",
        "ciudad": "Nord",
        "code_ciudad": "NO"
    },
    {
        "pais": "Camerún",
        "code_pais": "CM",
        "ciudad": "Nord-Ouest",
        "code_ciudad": "NW"
    },
    {
        "pais": "Camerún",
        "code_pais": "CM",
        "ciudad": "Ouest",
        "code_ciudad": "OU"
    },
    {
        "pais": "Camerún",
        "code_pais": "CM",
        "ciudad": "Sud",
        "code_ciudad": "SU"
    },
    {
        "pais": "Camerún",
        "code_pais": "CM",
        "ciudad": "Sud-Ouest",
        "code_ciudad": "SW"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Anhui",
        "code_ciudad": "AH"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Beijing",
        "code_ciudad": "BJ"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Chongqing",
        "code_ciudad": "CQ"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Fujian",
        "code_ciudad": "FJ"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Guangdong",
        "code_ciudad": "GD"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Gansu",
        "code_ciudad": "GS"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Guangxi",
        "code_ciudad": "GX"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Guizhou",
        "code_ciudad": "GZ"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Henan",
        "code_ciudad": "HA"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Hubei",
        "code_ciudad": "HB"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Hebei",
        "code_ciudad": "HE"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Hainan",
        "code_ciudad": "HI"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Heilongjiang",
        "code_ciudad": "HL"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Hunan",
        "code_ciudad": "HN"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Jilin",
        "code_ciudad": "JL"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Jiangsu",
        "code_ciudad": "JS"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Jiangxi",
        "code_ciudad": "JX"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Liaoning",
        "code_ciudad": "LN"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Nei Mongol",
        "code_ciudad": "NM"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Ningxia",
        "code_ciudad": "NX"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Qinghai",
        "code_ciudad": "QH"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Sichuan",
        "code_ciudad": "SC"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Shandong",
        "code_ciudad": "SD"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Shanghai",
        "code_ciudad": "SH"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Shaanxi",
        "code_ciudad": "SN"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Shanxi",
        "code_ciudad": "SX"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Tianjin",
        "code_ciudad": "TJ"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Xinjiang",
        "code_ciudad": "XJ"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Xizang",
        "code_ciudad": "XZ"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Yunnan",
        "code_ciudad": "YN"
    },
    {
        "pais": "República Popular China",
        "code_pais": "CN",
        "ciudad": "Zhejiang",
        "code_ciudad": "ZJ"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Amazonas",
        "code_ciudad": "AMA"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Antioquia",
        "code_ciudad": "ANT"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Arauca",
        "code_ciudad": "ARA"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Atlantico",
        "code_ciudad": "ATL"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Bolivar",
        "code_ciudad": "BOL"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Boyaca",
        "code_ciudad": "BOY"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Caldas",
        "code_ciudad": "CAL"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Caqueta",
        "code_ciudad": "CAQ"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Casanare",
        "code_ciudad": "CAS"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Cauca",
        "code_ciudad": "CAU"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Cesar",
        "code_ciudad": "CES"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Choco",
        "code_ciudad": "CHO"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Cordoba",
        "code_ciudad": "COR"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Cundinamarca",
        "code_ciudad": "CUN"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Distrito Capital de Bogota",
        "code_ciudad": "DC"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Guainia",
        "code_ciudad": "GUA"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Guaviare",
        "code_ciudad": "GUV"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Huila",
        "code_ciudad": "HUI"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "La Guajira",
        "code_ciudad": "LAG"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Magdalena",
        "code_ciudad": "MAG"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Meta",
        "code_ciudad": "MET"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Narino",
        "code_ciudad": "NAR"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Norte de Santander",
        "code_ciudad": "NSA"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Putumayo",
        "code_ciudad": "PUT"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Quindio",
        "code_ciudad": "QUI"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Risaralda",
        "code_ciudad": "RIS"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Santander",
        "code_ciudad": "SAN"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "San Andres, Providencia y Santa Catalina",
        "code_ciudad": "SAP"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Sucre",
        "code_ciudad": "SUC"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Tolima",
        "code_ciudad": "TOL"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Valle del Cauca",
        "code_ciudad": "VAC"
    },
    {
        "pais": "Colombia",
        "code_pais": "CO",
        "ciudad": "Vichada",
        "code_ciudad": "VID"
    },
    {
        "pais": "Costa Rica",
        "code_pais": "CR",
        "ciudad": "Alajuela",
        "code_ciudad": "A"
    },
    {
        "pais": "Costa Rica",
        "code_pais": "CR",
        "ciudad": "Cartago",
        "code_ciudad": "C"
    },
    {
        "pais": "Costa Rica",
        "code_pais": "CR",
        "ciudad": "Guanacaste",
        "code_ciudad": "G"
    },
    {
        "pais": "Costa Rica",
        "code_pais": "CR",
        "ciudad": "Heredia",
        "code_ciudad": "H"
    },
    {
        "pais": "Costa Rica",
        "code_pais": "CR",
        "ciudad": "Limon",
        "code_ciudad": "L"
    },
    {
        "pais": "Costa Rica",
        "code_pais": "CR",
        "ciudad": "Puntarenas",
        "code_ciudad": "P"
    },
    {
        "pais": "Costa Rica",
        "code_pais": "CR",
        "ciudad": "San Jose",
        "code_ciudad": "SJ"
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Pinar del Rio",
        "code_ciudad": "01"
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "La Habana",
        "code_ciudad": "03"
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Matanzas",
        "code_ciudad": "04"
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Villa Clara",
        "code_ciudad": "05"
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Cienfuegos",
        "code_ciudad": "06"
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Sancti Spiritus",
        "code_ciudad": "07"
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Ciego de Avila",
        "code_ciudad": "08"
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Camaguey",
        "code_ciudad": "09"
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Las Tunas",
        "code_ciudad": 10
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Holguin",
        "code_ciudad": 11
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Granma",
        "code_ciudad": 12
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Santiago de Cuba",
        "code_ciudad": 13
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Guantanamo",
        "code_ciudad": 14
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Artemisa",
        "code_ciudad": 15
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Mayabeque",
        "code_ciudad": 16
    },
    {
        "pais": "Cuba",
        "code_pais": "CU",
        "ciudad": "Isla de la Juventud",
        "code_ciudad": 99
    },
    {
        "pais": "Cabo Verde",
        "code_pais": "CV",
        "ciudad": "Boa Vista",
        "code_ciudad": "BV"
    },
    {
        "pais": "Cabo Verde",
        "code_pais": "CV",
        "ciudad": "Porto Novo",
        "code_ciudad": "PN"
    },
    {
        "pais": "Cabo Verde",
        "code_pais": "CV",
        "ciudad": "Praia",
        "code_ciudad": "PR"
    },
    {
        "pais": "Cabo Verde",
        "code_pais": "CV",
        "ciudad": "Ribeira Grande",
        "code_ciudad": "RG"
    },
    {
        "pais": "Cabo Verde",
        "code_pais": "CV",
        "ciudad": "Sao Filipe",
        "code_ciudad": "SF"
    },
    {
        "pais": "Cabo Verde",
        "code_pais": "CV",
        "ciudad": "Sal",
        "code_ciudad": "SL"
    },
    {
        "pais": "Cabo Verde",
        "code_pais": "CV",
        "ciudad": "Sao Vicente",
        "code_ciudad": "SV"
    },
    {
        "pais": "Cabo Verde",
        "code_pais": "CV",
        "ciudad": "Tarrafal",
        "code_ciudad": "TA"
    },
    {
        "pais": "Chipre",
        "code_pais": "CY",
        "ciudad": "Lefkosia",
        "code_ciudad": "01"
    },
    {
        "pais": "Chipre",
        "code_pais": "CY",
        "ciudad": "Lemesos",
        "code_ciudad": "02"
    },
    {
        "pais": "Chipre",
        "code_pais": "CY",
        "ciudad": "Larnaka",
        "code_ciudad": "03"
    },
    {
        "pais": "Chipre",
        "code_pais": "CY",
        "ciudad": "Ammochostos",
        "code_ciudad": "04"
    },
    {
        "pais": "Chipre",
        "code_pais": "CY",
        "ciudad": "Pafos",
        "code_ciudad": "05"
    },
    {
        "pais": "Chipre",
        "code_pais": "CY",
        "ciudad": "Keryneia",
        "code_ciudad": "06"
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Praha, Hlavni mesto",
        "code_ciudad": 10
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Stredocesky kraj",
        "code_ciudad": 20
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Jihocesky kraj",
        "code_ciudad": 31
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Plzensky kraj",
        "code_ciudad": 32
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Karlovarsky kraj",
        "code_ciudad": 41
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Ustecky kraj",
        "code_ciudad": 42
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Liberecky kraj",
        "code_ciudad": 51
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Kralovehradecky kraj",
        "code_ciudad": 52
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Pardubicky kraj",
        "code_ciudad": 53
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Kraj Vysocina",
        "code_ciudad": 63
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Jihomoravsky kraj",
        "code_ciudad": 64
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Olomoucky kraj",
        "code_ciudad": 71
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Zlinsky kraj",
        "code_ciudad": 72
    },
    {
        "pais": "República Checa",
        "code_pais": "CZ",
        "ciudad": "Moravskoslezsky kraj",
        "code_ciudad": 80
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Brandenburg",
        "code_ciudad": "BB"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Berlin",
        "code_ciudad": "BE"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Baden-Wurttemberg",
        "code_ciudad": "BW"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Bayern",
        "code_ciudad": "BY"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Bremen",
        "code_ciudad": "HB"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Hessen",
        "code_ciudad": "HE"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Hamburg",
        "code_ciudad": "HH"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Mecklenburg-Vorpommern",
        "code_ciudad": "MV"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Niedersachsen",
        "code_ciudad": "NI"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Nordrhein-Westfalen",
        "code_ciudad": "NW"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Rheinland-Pfalz",
        "code_ciudad": "RP"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Schleswig-Holstein",
        "code_ciudad": "SH"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Saarland",
        "code_ciudad": "SL"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Sachsen",
        "code_ciudad": "SN"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Sachsen-Anhalt",
        "code_ciudad": "ST"
    },
    {
        "pais": "Alemania",
        "code_pais": "DE",
        "ciudad": "Thuringen",
        "code_ciudad": "TH"
    },
    {
        "pais": "Yibuti",
        "code_pais": "DJ",
        "ciudad": "Djibouti",
        "code_ciudad": "DJ"
    },
    {
        "pais": "Dinamarca",
        "code_pais": "DK",
        "ciudad": "Nordjylland",
        "code_ciudad": 81
    },
    {
        "pais": "Dinamarca",
        "code_pais": "DK",
        "ciudad": "Midtjylland",
        "code_ciudad": 82
    },
    {
        "pais": "Dinamarca",
        "code_pais": "DK",
        "ciudad": "Syddanmark",
        "code_ciudad": 83
    },
    {
        "pais": "Dinamarca",
        "code_pais": "DK",
        "ciudad": "Hovedstaden",
        "code_ciudad": 84
    },
    {
        "pais": "Dinamarca",
        "code_pais": "DK",
        "ciudad": "Sjaelland",
        "code_ciudad": 85
    },
    {
        "pais": "Dominica",
        "code_pais": "DM",
        "ciudad": "Saint Andrew",
        "code_ciudad": "02"
    },
    {
        "pais": "Dominica",
        "code_pais": "DM",
        "ciudad": "Saint David",
        "code_ciudad": "03"
    },
    {
        "pais": "Dominica",
        "code_pais": "DM",
        "ciudad": "Saint George",
        "code_ciudad": "04"
    },
    {
        "pais": "Dominica",
        "code_pais": "DM",
        "ciudad": "Saint John",
        "code_ciudad": "05"
    },
    {
        "pais": "Dominica",
        "code_pais": "DM",
        "ciudad": "Saint Luke",
        "code_ciudad": "07"
    },
    {
        "pais": "Dominica",
        "code_pais": "DM",
        "ciudad": "Saint Mark",
        "code_ciudad": "08"
    },
    {
        "pais": "Dominica",
        "code_pais": "DM",
        "ciudad": "Saint Patrick",
        "code_ciudad": "09"
    },
    {
        "pais": "Dominica",
        "code_pais": "DM",
        "ciudad": "Saint Paul",
        "code_ciudad": 10
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Distrito Nacional (Santo Domingo)",
        "code_ciudad": "01"
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Azua",
        "code_ciudad": "02"
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Baoruco",
        "code_ciudad": "03"
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Barahona",
        "code_ciudad": "04"
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Dajabon",
        "code_ciudad": "05"
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Duarte",
        "code_ciudad": "06"
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Elias Pina",
        "code_ciudad": "07"
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "El Seibo",
        "code_ciudad": "08"
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Espaillat",
        "code_ciudad": "09"
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Independencia",
        "code_ciudad": 10
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "La Altagracia",
        "code_ciudad": 11
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "La Romana",
        "code_ciudad": 12
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "La Vega",
        "code_ciudad": 13
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Maria Trinidad Sanchez",
        "code_ciudad": 14
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Monte Cristi",
        "code_ciudad": 15
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Peravia",
        "code_ciudad": 17
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Puerto Plata",
        "code_ciudad": 18
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Hermanas Mirabal",
        "code_ciudad": 19
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Samana",
        "code_ciudad": 20
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "San Cristobal",
        "code_ciudad": 21
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "San Juan",
        "code_ciudad": 22
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "San Pedro de Macoris",
        "code_ciudad": 23
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Sanchez Ramirez",
        "code_ciudad": 24
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Santiago",
        "code_ciudad": 25
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Santiago Rodriguez",
        "code_ciudad": 26
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Valverde",
        "code_ciudad": 27
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Monsenor Nouel",
        "code_ciudad": 28
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Monte Plata",
        "code_ciudad": 29
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "Hato Mayor",
        "code_ciudad": 30
    },
    {
        "pais": "República Dominicana",
        "code_pais": "DO",
        "ciudad": "San Jose de Ocoa",
        "code_ciudad": 31
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Adrar",
        "code_ciudad": "01"
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Chlef",
        "code_ciudad": "02"
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Laghouat",
        "code_ciudad": "03"
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Oum el Bouaghi",
        "code_ciudad": "04"
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Batna",
        "code_ciudad": "05"
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Bejaia",
        "code_ciudad": "06"
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Biskra",
        "code_ciudad": "07"
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Bechar",
        "code_ciudad": "08"
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Blida",
        "code_ciudad": "09"
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Bouira",
        "code_ciudad": 10
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Tamanrasset",
        "code_ciudad": 11
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Tebessa",
        "code_ciudad": 12
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Tlemcen",
        "code_ciudad": 13
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Tiaret",
        "code_ciudad": 14
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Tizi Ouzou",
        "code_ciudad": 15
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Alger",
        "code_ciudad": 16
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Djelfa",
        "code_ciudad": 17
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Jijel",
        "code_ciudad": 18
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Setif",
        "code_ciudad": 19
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Saida",
        "code_ciudad": 20
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Skikda",
        "code_ciudad": 21
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Sidi Bel Abbes",
        "code_ciudad": 22
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Annaba",
        "code_ciudad": 23
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Guelma",
        "code_ciudad": 24
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Constantine",
        "code_ciudad": 25
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Medea",
        "code_ciudad": 26
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Mostaganem",
        "code_ciudad": 27
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "M'sila",
        "code_ciudad": 28
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Mascara",
        "code_ciudad": 29
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Ouargla",
        "code_ciudad": 30
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Oran",
        "code_ciudad": 31
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "El Bayadh",
        "code_ciudad": 32
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Illizi",
        "code_ciudad": 33
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Bordj Bou Arreridj",
        "code_ciudad": 34
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Boumerdes",
        "code_ciudad": 35
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "El Tarf",
        "code_ciudad": 36
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Tindouf",
        "code_ciudad": 37
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Tissemsilt",
        "code_ciudad": 38
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "El Oued",
        "code_ciudad": 39
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Khenchela",
        "code_ciudad": 40
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Souk Ahras",
        "code_ciudad": 41
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Tipaza",
        "code_ciudad": 42
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Mila",
        "code_ciudad": 43
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Ain Defla",
        "code_ciudad": 44
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Naama",
        "code_ciudad": 45
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Ain Temouchent",
        "code_ciudad": 46
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Ghardaia",
        "code_ciudad": 47
    },
    {
        "pais": "Argelia",
        "code_pais": "DZ",
        "ciudad": "Relizane",
        "code_ciudad": 48
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Azuay",
        "code_ciudad": "A"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Bolivar",
        "code_ciudad": "B"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Carchi",
        "code_ciudad": "C"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Orellana",
        "code_ciudad": "D"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Esmeraldas",
        "code_ciudad": "E"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Canar",
        "code_ciudad": "F"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Guayas",
        "code_ciudad": "G"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Chimborazo",
        "code_ciudad": "H"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Imbabura",
        "code_ciudad": "I"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Loja",
        "code_ciudad": "L"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Manabi",
        "code_ciudad": "M"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Napo",
        "code_ciudad": "N"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "El Oro",
        "code_ciudad": "O"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Pichincha",
        "code_ciudad": "P"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Los Rios",
        "code_ciudad": "R"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Morona Santiago",
        "code_ciudad": "S"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Santo Domingo de los Tsachilas",
        "code_ciudad": "SD"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Santa Elena",
        "code_ciudad": "SE"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Tungurahua",
        "code_ciudad": "T"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Sucumbios",
        "code_ciudad": "U"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Galapagos",
        "code_ciudad": "W"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Cotopaxi",
        "code_ciudad": "X"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Pastaza",
        "code_ciudad": "Y"
    },
    {
        "pais": "Ecuador",
        "code_pais": "EC",
        "ciudad": "Zamora Chinchipe",
        "code_ciudad": "Z"
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Harjumaa",
        "code_ciudad": 37
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Hiiumaa",
        "code_ciudad": 39
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Ida-Virumaa",
        "code_ciudad": 44
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Jogevamaa",
        "code_ciudad": 49
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Jarvamaa",
        "code_ciudad": 51
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Laanemaa",
        "code_ciudad": 57
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Laane-Virumaa",
        "code_ciudad": 59
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Polvamaa",
        "code_ciudad": 65
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Parnumaa",
        "code_ciudad": 67
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Raplamaa",
        "code_ciudad": 70
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Saaremaa",
        "code_ciudad": 74
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Tartumaa",
        "code_ciudad": 78
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Valgamaa",
        "code_ciudad": 82
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Viljandimaa",
        "code_ciudad": 84
    },
    {
        "pais": "Estonia",
        "code_pais": "EE",
        "ciudad": "Vorumaa",
        "code_ciudad": 86
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Iskandariyah",
        "code_ciudad": "ALX"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Aswan",
        "code_ciudad": "ASN"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Asyut",
        "code_ciudad": "AST"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Bahr al Ahmar",
        "code_ciudad": "BA"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Buhayrah",
        "code_ciudad": "BH"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Bani Suwayf",
        "code_ciudad": "BNS"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Qahirah",
        "code_ciudad": "C"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Ad Daqahliyah",
        "code_ciudad": "DK"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Dumyat",
        "code_ciudad": "DT"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Fayyum",
        "code_ciudad": "FYM"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Gharbiyah",
        "code_ciudad": "GH"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Jizah",
        "code_ciudad": "GZ"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Isma'iliyah",
        "code_ciudad": "IS"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Janub Sina'",
        "code_ciudad": "JS"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Qalyubiyah",
        "code_ciudad": "KB"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Kafr ash Shaykh",
        "code_ciudad": "KFS"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Qina",
        "code_ciudad": "KN"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Uqsur",
        "code_ciudad": "LX"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Minya",
        "code_ciudad": "MN"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Minufiyah",
        "code_ciudad": "MNF"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Matruh",
        "code_ciudad": "MT"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Bur Sa'id",
        "code_ciudad": "PTS"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Suhaj",
        "code_ciudad": "SHG"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Ash Sharqiyah",
        "code_ciudad": "SHR"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Shamal Sina'",
        "code_ciudad": "SIN"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "As Suways",
        "code_ciudad": "SUZ"
    },
    {
        "pais": "Egipto",
        "code_pais": "EG",
        "ciudad": "Al Wadi al Jadid",
        "code_ciudad": "WAD"
    },
    {
        "pais": "Eritrea",
        "code_pais": "ER",
        "ciudad": "Al Awsat",
        "code_ciudad": "MA"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Andalucia",
        "code_ciudad": "AN"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Aragon",
        "code_ciudad": "AR"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Asturias, Principado de",
        "code_ciudad": "AS"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Cantabria",
        "code_ciudad": "CB"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Ceuta",
        "code_ciudad": "CE"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Castilla y Leon",
        "code_ciudad": "CL"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Castilla-La Mancha",
        "code_ciudad": "CM"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Canarias",
        "code_ciudad": "CN"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Catalunya",
        "code_ciudad": "CT"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Extremadura",
        "code_ciudad": "EX"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Galicia",
        "code_ciudad": "GA"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Illes Balears",
        "code_ciudad": "IB"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Murcia, Region de",
        "code_ciudad": "MC"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Madrid, Comunidad de",
        "code_ciudad": "MD"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Melilla",
        "code_ciudad": "ML"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Navarra, Comunidad Foral de",
        "code_ciudad": "NC"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Pais Vasco",
        "code_ciudad": "PV"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "La Rioja",
        "code_ciudad": "RI"
    },
    {
        "pais": "España",
        "code_pais": "ES",
        "ciudad": "Valenciana, Comunidad",
        "code_ciudad": "VC"
    },
    {
        "pais": "Etiopía",
        "code_pais": "ET",
        "ciudad": "Adis Abeba",
        "code_ciudad": "AA"
    },
    {
        "pais": "Etiopía",
        "code_pais": "ET",
        "ciudad": "Afar",
        "code_ciudad": "AF"
    },
    {
        "pais": "Etiopía",
        "code_pais": "ET",
        "ciudad": "Amara",
        "code_ciudad": "AM"
    },
    {
        "pais": "Etiopía",
        "code_pais": "ET",
        "ciudad": "Binshangul Gumuz",
        "code_ciudad": "BE"
    },
    {
        "pais": "Etiopía",
        "code_pais": "ET",
        "ciudad": "Hareri Hizb",
        "code_ciudad": "HA"
    },
    {
        "pais": "Etiopía",
        "code_pais": "ET",
        "ciudad": "Oromiya",
        "code_ciudad": "OR"
    },
    {
        "pais": "Etiopía",
        "code_pais": "ET",
        "ciudad": "YeDebub Biheroch Bihereseboch na Hizboch",
        "code_ciudad": "SN"
    },
    {
        "pais": "Etiopía",
        "code_pais": "ET",
        "ciudad": "Sumale",
        "code_ciudad": "SO"
    },
    {
        "pais": "Etiopía",
        "code_pais": "ET",
        "ciudad": "Tigray",
        "code_ciudad": "TI"
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Etela-Karjala",
        "code_ciudad": "02"
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Etela-Pohjanmaa",
        "code_ciudad": "03"
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Etela-Savo",
        "code_ciudad": "04"
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Kainuu",
        "code_ciudad": "05"
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Kanta-Hame",
        "code_ciudad": "06"
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Keski-Pohjanmaa",
        "code_ciudad": "07"
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Keski-Suomi",
        "code_ciudad": "08"
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Kymenlaakso",
        "code_ciudad": "09"
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Lappi",
        "code_ciudad": 10
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Pirkanmaa",
        "code_ciudad": 11
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Pohjanmaa",
        "code_ciudad": 12
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Pohjois-Karjala",
        "code_ciudad": 13
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Pohjois-Pohjanmaa",
        "code_ciudad": 14
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Pohjois-Savo",
        "code_ciudad": 15
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Paijat-Hame",
        "code_ciudad": 16
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Satakunta",
        "code_ciudad": 17
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Uusimaa",
        "code_ciudad": 18
    },
    {
        "pais": "Finlandia",
        "code_pais": "FI",
        "ciudad": "Varsinais-Suomi",
        "code_ciudad": 19
    },
    {
        "pais": "Fiyi",
        "code_pais": "FJ",
        "ciudad": "Central",
        "code_ciudad": "C"
    },
    {
        "pais": "Fiyi",
        "code_pais": "FJ",
        "ciudad": "Eastern",
        "code_ciudad": "E"
    },
    {
        "pais": "Fiyi",
        "code_pais": "FJ",
        "ciudad": "Northern",
        "code_ciudad": "N"
    },
    {
        "pais": "Fiyi",
        "code_pais": "FJ",
        "ciudad": "Rotuma",
        "code_ciudad": "R"
    },
    {
        "pais": "Fiyi",
        "code_pais": "FJ",
        "ciudad": "Western",
        "code_ciudad": "W"
    },
    {
        "pais": "Micronesia (Estados Federados de)",
        "code_pais": "FM",
        "ciudad": "Kosrae",
        "code_ciudad": "KSA"
    },
    {
        "pais": "Micronesia (Estados Federados de)",
        "code_pais": "FM",
        "ciudad": "Pohnpei",
        "code_ciudad": "PNI"
    },
    {
        "pais": "Micronesia (Estados Federados de)",
        "code_pais": "FM",
        "ciudad": "Chuuk",
        "code_ciudad": "TRK"
    },
    {
        "pais": "Micronesia (Estados Federados de)",
        "code_pais": "FM",
        "ciudad": "Yap",
        "code_ciudad": "YAP"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Auvergne-Rhone-Alpes",
        "code_ciudad": "ARA"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Bourgogne-Franche-Comte",
        "code_ciudad": "BFC"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Bretagne",
        "code_ciudad": "BRE"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Corse",
        "code_ciudad": "COR"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Centre-Val de Loire",
        "code_ciudad": "CVL"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Grand-Est",
        "code_ciudad": "GES"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Hauts-de-France",
        "code_ciudad": "HDF"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Ile-de-France",
        "code_ciudad": "IDF"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Nouvelle-Aquitaine",
        "code_ciudad": "NAQ"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Normandie",
        "code_ciudad": "NOR"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Occitanie",
        "code_ciudad": "OCC"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Provence-Alpes-Cote-d'Azur",
        "code_ciudad": "PAC"
    },
    {
        "pais": "Francia",
        "code_pais": "FR",
        "ciudad": "Pays-de-la-Loire",
        "code_ciudad": "PDL"
    },
    {
        "pais": "Gabón",
        "code_pais": "GA",
        "ciudad": "Estuaire",
        "code_ciudad": 1
    },
    {
        "pais": "Gabón",
        "code_pais": "GA",
        "ciudad": "Haut-Ogooue",
        "code_ciudad": 2
    },
    {
        "pais": "Gabón",
        "code_pais": "GA",
        "ciudad": "Moyen-Ogooue",
        "code_ciudad": 3
    },
    {
        "pais": "Gabón",
        "code_pais": "GA",
        "ciudad": "Ngounie",
        "code_ciudad": 4
    },
    {
        "pais": "Gabón",
        "code_pais": "GA",
        "ciudad": "Ogooue-Ivindo",
        "code_ciudad": 6
    },
    {
        "pais": "Gabón",
        "code_pais": "GA",
        "ciudad": "Ogooue-Maritime",
        "code_ciudad": 8
    },
    {
        "pais": "Gabón",
        "code_pais": "GA",
        "ciudad": "Woleu-Ntem",
        "code_ciudad": 9
    },
    {
        "pais": "Reino Unido",
        "code_pais": "GB",
        "ciudad": "England",
        "code_ciudad": "ENG"
    },
    {
        "pais": "Reino Unido",
        "code_pais": "GB",
        "ciudad": "Northern Ireland",
        "code_ciudad": "NIR"
    },
    {
        "pais": "Reino Unido",
        "code_pais": "GB",
        "ciudad": "Scotland",
        "code_ciudad": "SCT"
    },
    {
        "pais": "Reino Unido",
        "code_pais": "GB",
        "ciudad": "Wales",
        "code_ciudad": "WLS"
    },
    {
        "pais": "Granada",
        "code_pais": "GD",
        "ciudad": "Saint Andrew",
        "code_ciudad": "01"
    },
    {
        "pais": "Granada",
        "code_pais": "GD",
        "ciudad": "Saint David",
        "code_ciudad": "02"
    },
    {
        "pais": "Granada",
        "code_pais": "GD",
        "ciudad": "Saint George",
        "code_ciudad": "03"
    },
    {
        "pais": "Granada",
        "code_pais": "GD",
        "ciudad": "Saint John",
        "code_ciudad": "04"
    },
    {
        "pais": "Granada",
        "code_pais": "GD",
        "ciudad": "Saint Mark",
        "code_ciudad": "05"
    },
    {
        "pais": "Granada",
        "code_pais": "GD",
        "ciudad": "Saint Patrick",
        "code_ciudad": "06"
    },
    {
        "pais": "Granada",
        "code_pais": "GD",
        "ciudad": "Southern Grenadine Islands",
        "code_ciudad": 10
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "Abkhazia",
        "code_ciudad": "AB"
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "Ajaria",
        "code_ciudad": "AJ"
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "Guria",
        "code_ciudad": "GU"
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "Imereti",
        "code_ciudad": "IM"
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "K'akheti",
        "code_ciudad": "KA"
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "Kvemo Kartli",
        "code_ciudad": "KK"
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "Mtskheta-Mtianeti",
        "code_ciudad": "MM"
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "Rach'a-Lechkhumi-Kvemo Svaneti",
        "code_ciudad": "RL"
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "Samtskhe-Javakheti",
        "code_ciudad": "SJ"
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "Shida Kartli",
        "code_ciudad": "SK"
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "Samegrelo-Zemo Svaneti",
        "code_ciudad": "SZ"
    },
    {
        "pais": "Georgia",
        "code_pais": "GE",
        "ciudad": "Tbilisi",
        "code_ciudad": "TB"
    },
    {
        "pais": "Ghana",
        "code_pais": "GH",
        "ciudad": "Greater Accra",
        "code_ciudad": "AA"
    },
    {
        "pais": "Ghana",
        "code_pais": "GH",
        "ciudad": "Ashanti",
        "code_ciudad": "AH"
    },
    {
        "pais": "Ghana",
        "code_pais": "GH",
        "ciudad": "Brong-Ahafo",
        "code_ciudad": "BA"
    },
    {
        "pais": "Ghana",
        "code_pais": "GH",
        "ciudad": "Central",
        "code_ciudad": "CP"
    },
    {
        "pais": "Ghana",
        "code_pais": "GH",
        "ciudad": "Eastern",
        "code_ciudad": "EP"
    },
    {
        "pais": "Ghana",
        "code_pais": "GH",
        "ciudad": "Northern",
        "code_ciudad": "NP"
    },
    {
        "pais": "Ghana",
        "code_pais": "GH",
        "ciudad": "Volta",
        "code_ciudad": "TV"
    },
    {
        "pais": "Ghana",
        "code_pais": "GH",
        "ciudad": "Upper East",
        "code_ciudad": "UE"
    },
    {
        "pais": "Ghana",
        "code_pais": "GH",
        "ciudad": "Western",
        "code_ciudad": "WP"
    },
    {
        "pais": "Groenlandia",
        "code_pais": "GL",
        "ciudad": "Avannaata Kommunia",
        "code_ciudad": "AV"
    },
    {
        "pais": "Groenlandia",
        "code_pais": "GL",
        "ciudad": "Kommune Kujalleq",
        "code_ciudad": "KU"
    },
    {
        "pais": "Groenlandia",
        "code_pais": "GL",
        "ciudad": "Qeqqata Kommunia",
        "code_ciudad": "QE"
    },
    {
        "pais": "Groenlandia",
        "code_pais": "GL",
        "ciudad": "Kommune Qeqertalik",
        "code_ciudad": "QT"
    },
    {
        "pais": "Groenlandia",
        "code_pais": "GL",
        "ciudad": "Kommuneqarfik Sermersooq",
        "code_ciudad": "SM"
    },
    {
        "pais": "Gambia",
        "code_pais": "GM",
        "ciudad": "Banjul",
        "code_ciudad": "B"
    },
    {
        "pais": "Gambia",
        "code_pais": "GM",
        "ciudad": "Lower River",
        "code_ciudad": "L"
    },
    {
        "pais": "Gambia",
        "code_pais": "GM",
        "ciudad": "Central River",
        "code_ciudad": "M"
    },
    {
        "pais": "Gambia",
        "code_pais": "GM",
        "ciudad": "North Bank",
        "code_ciudad": "N"
    },
    {
        "pais": "Gambia",
        "code_pais": "GM",
        "ciudad": "Upper River",
        "code_ciudad": "U"
    },
    {
        "pais": "Gambia",
        "code_pais": "GM",
        "ciudad": "Western",
        "code_ciudad": "W"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Boke",
        "code_ciudad": "B"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Boffa",
        "code_ciudad": "BF"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Conakry",
        "code_ciudad": "C"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Coyah",
        "code_ciudad": "CO"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Dabola",
        "code_ciudad": "DB"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Dalaba",
        "code_ciudad": "DL"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Dubreka",
        "code_ciudad": "DU"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Kankan",
        "code_ciudad": "K"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Kissidougou",
        "code_ciudad": "KS"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Labe",
        "code_ciudad": "L"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Macenta",
        "code_ciudad": "MC"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Nzerekore",
        "code_ciudad": "N"
    },
    {
        "pais": "Guinea",
        "code_pais": "GN",
        "ciudad": "Siguiri",
        "code_ciudad": "SI"
    },
    {
        "pais": "Guinea Ecuatorial",
        "code_pais": "GQ",
        "ciudad": "Bioko Norte",
        "code_ciudad": "BN"
    },
    {
        "pais": "Guinea Ecuatorial",
        "code_pais": "GQ",
        "ciudad": "Bioko Sur",
        "code_ciudad": "BS"
    },
    {
        "pais": "Guinea Ecuatorial",
        "code_pais": "GQ",
        "ciudad": "Litoral",
        "code_ciudad": "LI"
    },
    {
        "pais": "Guinea Ecuatorial",
        "code_pais": "GQ",
        "ciudad": "Wele-Nzas",
        "code_ciudad": "WN"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Anatoliki Makedonia kai Thraki",
        "code_ciudad": "A"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Kentriki Makedonia",
        "code_ciudad": "B"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Dytiki Makedonia",
        "code_ciudad": "C"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Ipeiros",
        "code_ciudad": "D"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Thessalia",
        "code_ciudad": "E"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Ionia Nisia",
        "code_ciudad": "F"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Dytiki Ellada",
        "code_ciudad": "G"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Sterea Ellada",
        "code_ciudad": "H"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Attiki",
        "code_ciudad": "I"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Peloponnisos",
        "code_ciudad": "J"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Voreio Aigaio",
        "code_ciudad": "K"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Notio Aigaio",
        "code_ciudad": "L"
    },
    {
        "pais": "Grecia",
        "code_pais": "GR",
        "ciudad": "Kriti",
        "code_ciudad": "M"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Alta Verapaz",
        "code_ciudad": "AV"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Baja Verapaz",
        "code_ciudad": "BV"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Chimaltenango",
        "code_ciudad": "CM"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Chiquimula",
        "code_ciudad": "CQ"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Escuintla",
        "code_ciudad": "ES"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Guatemala",
        "code_ciudad": "GU"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Huehuetenango",
        "code_ciudad": "HU"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Izabal",
        "code_ciudad": "IZ"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Jalapa",
        "code_ciudad": "JA"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Jutiapa",
        "code_ciudad": "JU"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Peten",
        "code_ciudad": "PE"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "El Progreso",
        "code_ciudad": "PR"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Quiche",
        "code_ciudad": "QC"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Quetzaltenango",
        "code_ciudad": "QZ"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Retalhuleu",
        "code_ciudad": "RE"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Sacatepequez",
        "code_ciudad": "SA"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "San Marcos",
        "code_ciudad": "SM"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Solola",
        "code_ciudad": "SO"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Santa Rosa",
        "code_ciudad": "SR"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Suchitepequez",
        "code_ciudad": "SU"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Totonicapan",
        "code_ciudad": "TO"
    },
    {
        "pais": "Guatemala",
        "code_pais": "GT",
        "ciudad": "Zacapa",
        "code_ciudad": "ZA"
    },
    {
        "pais": "Guinea-Bisáu",
        "code_pais": "GW",
        "ciudad": "Bissau",
        "code_ciudad": "BS"
    },
    {
        "pais": "Guinea-Bisáu",
        "code_pais": "GW",
        "ciudad": "Gabu",
        "code_ciudad": "GA"
    },
    {
        "pais": "Guyana",
        "code_pais": "GY",
        "ciudad": "Barima-Waini",
        "code_ciudad": "BA"
    },
    {
        "pais": "Guyana",
        "code_pais": "GY",
        "ciudad": "Demerara-Mahaica",
        "code_ciudad": "DE"
    },
    {
        "pais": "Guyana",
        "code_pais": "GY",
        "ciudad": "East Berbice-Corentyne",
        "code_ciudad": "EB"
    },
    {
        "pais": "Guyana",
        "code_pais": "GY",
        "ciudad": "Essequibo Islands-West Demerara",
        "code_ciudad": "ES"
    },
    {
        "pais": "Guyana",
        "code_pais": "GY",
        "ciudad": "Mahaica-Berbice",
        "code_ciudad": "MA"
    },
    {
        "pais": "Guyana",
        "code_pais": "GY",
        "ciudad": "Pomeroon-Supenaam",
        "code_ciudad": "PM"
    },
    {
        "pais": "Guyana",
        "code_pais": "GY",
        "ciudad": "Potaro-Siparuni",
        "code_ciudad": "PT"
    },
    {
        "pais": "Guyana",
        "code_pais": "GY",
        "ciudad": "Upper Demerara-Berbice",
        "code_ciudad": "UD"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Atlantida",
        "code_ciudad": "AT"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Choluteca",
        "code_ciudad": "CH"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Colon",
        "code_ciudad": "CL"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Comayagua",
        "code_ciudad": "CM"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Copan",
        "code_ciudad": "CP"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Cortes",
        "code_ciudad": "CR"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "El Paraiso",
        "code_ciudad": "EP"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Francisco Morazan",
        "code_ciudad": "FM"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Islas de la Bahia",
        "code_ciudad": "IB"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Intibuca",
        "code_ciudad": "IN"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Lempira",
        "code_ciudad": "LE"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "La Paz",
        "code_ciudad": "LP"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Ocotepeque",
        "code_ciudad": "OC"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Olancho",
        "code_ciudad": "OL"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Santa Barbara",
        "code_ciudad": "SB"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Valle",
        "code_ciudad": "VA"
    },
    {
        "pais": "Honduras",
        "code_pais": "HN",
        "ciudad": "Yoro",
        "code_ciudad": "YO"
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Zagrebacka zupanija",
        "code_ciudad": "01"
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Krapinsko-zagorska zupanija",
        "code_ciudad": "02"
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Sisacko-moslavacka zupanija",
        "code_ciudad": "03"
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Karlovacka zupanija",
        "code_ciudad": "04"
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Varazdinska zupanija",
        "code_ciudad": "05"
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Koprivnicko-krizevacka zupanija",
        "code_ciudad": "06"
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Bjelovarsko-bilogorska zupanija",
        "code_ciudad": "07"
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Primorsko-goranska zupanija",
        "code_ciudad": "08"
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Licko-senjska zupanija",
        "code_ciudad": "09"
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Viroviticko-podravska zupanija",
        "code_ciudad": 10
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Pozesko-slavonska zupanija",
        "code_ciudad": 11
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Brodsko-posavska zupanija",
        "code_ciudad": 12
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Zadarska zupanija",
        "code_ciudad": 13
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Osjecko-baranjska zupanija",
        "code_ciudad": 14
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Sibensko-kninska zupanija",
        "code_ciudad": 15
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Vukovarsko-srijemska zupanija",
        "code_ciudad": 16
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Splitsko-dalmatinska zupanija",
        "code_ciudad": 17
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Istarska zupanija",
        "code_ciudad": 18
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Dubrovacko-neretvanska zupanija",
        "code_ciudad": 19
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Medimurska zupanija",
        "code_ciudad": 20
    },
    {
        "pais": "Croacia",
        "code_pais": "HR",
        "ciudad": "Grad Zagreb",
        "code_ciudad": 21
    },
    {
        "pais": "Haití",
        "code_pais": "HT",
        "ciudad": "Artibonite",
        "code_ciudad": "AR"
    },
    {
        "pais": "Haití",
        "code_pais": "HT",
        "ciudad": "Centre",
        "code_ciudad": "CE"
    },
    {
        "pais": "Haití",
        "code_pais": "HT",
        "ciudad": "Grande'Anse",
        "code_ciudad": "GA"
    },
    {
        "pais": "Haití",
        "code_pais": "HT",
        "ciudad": "Nord",
        "code_ciudad": "ND"
    },
    {
        "pais": "Haití",
        "code_pais": "HT",
        "ciudad": "Ouest",
        "code_ciudad": "OU"
    },
    {
        "pais": "Haití",
        "code_pais": "HT",
        "ciudad": "Sud",
        "code_ciudad": "SD"
    },
    {
        "pais": "Haití",
        "code_pais": "HT",
        "ciudad": "Sud-Est",
        "code_ciudad": "SE"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Baranya",
        "code_ciudad": "BA"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Bekes",
        "code_ciudad": "BE"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Bacs-Kiskun",
        "code_ciudad": "BK"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Budapest",
        "code_ciudad": "BU"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Borsod-Abauj-Zemplen",
        "code_ciudad": "BZ"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Csongrad",
        "code_ciudad": "CS"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Fejer",
        "code_ciudad": "FE"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Gyor-Moson-Sopron",
        "code_ciudad": "GS"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Hajdu-Bihar",
        "code_ciudad": "HB"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Heves",
        "code_ciudad": "HE"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Jasz-Nagykun-Szolnok",
        "code_ciudad": "JN"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Komarom-Esztergom",
        "code_ciudad": "KE"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Nograd",
        "code_ciudad": "NO"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Pest",
        "code_ciudad": "PE"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Somogy",
        "code_ciudad": "SO"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Szabolcs-Szatmar-Bereg",
        "code_ciudad": "SZ"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Tolna",
        "code_ciudad": "TO"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Vas",
        "code_ciudad": "VA"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Veszprem",
        "code_ciudad": "VE"
    },
    {
        "pais": "Hungría",
        "code_pais": "HU",
        "ciudad": "Zala",
        "code_ciudad": "ZA"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Aceh",
        "code_ciudad": "AC"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Bali",
        "code_ciudad": "BA"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Kepulauan Bangka Belitung",
        "code_ciudad": "BB"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Bengkulu",
        "code_ciudad": "BE"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Banten",
        "code_ciudad": "BT"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Gorontalo",
        "code_ciudad": "GO"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Jambi",
        "code_ciudad": "JA"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Jawa Barat",
        "code_ciudad": "JB"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Jawa Timur",
        "code_ciudad": "JI"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Jakarta Raya",
        "code_ciudad": "JK"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Jawa Tengah",
        "code_ciudad": "JT"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Kalimantan Barat",
        "code_ciudad": "KB"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Kalimantan Timur",
        "code_ciudad": "KI"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Kepulauan Riau",
        "code_ciudad": "KR"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Kalimantan Selatan",
        "code_ciudad": "KS"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Kalimantan Tengah",
        "code_ciudad": "KT"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Kalimantan Utara",
        "code_ciudad": "KU"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Lampung",
        "code_ciudad": "LA"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Maluku",
        "code_ciudad": "ML"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Maluku Utara",
        "code_ciudad": "MU"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Nusa Tenggara Barat",
        "code_ciudad": "NB"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Nusa Tenggara Timur",
        "code_ciudad": "NT"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Papua Barat",
        "code_ciudad": "PB"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Papua",
        "code_ciudad": "PP"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Riau",
        "code_ciudad": "RI"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Sulawesi Utara",
        "code_ciudad": "SA"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Sumatera Barat",
        "code_ciudad": "SB"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Sulawesi Tenggara",
        "code_ciudad": "SG"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Sulawesi Selatan",
        "code_ciudad": "SN"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Sulawesi Barat",
        "code_ciudad": "SR"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Sumatera Selatan",
        "code_ciudad": "SS"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Sulawesi Tengah",
        "code_ciudad": "ST"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Sumatera Utara",
        "code_ciudad": "SU"
    },
    {
        "pais": "Indonesia",
        "code_pais": "ID",
        "ciudad": "Yogyakarta",
        "code_ciudad": "YO"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Clare",
        "code_ciudad": "CE"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Cavan",
        "code_ciudad": "CN"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Cork",
        "code_ciudad": "CO"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Carlow",
        "code_ciudad": "CW"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Dublin",
        "code_ciudad": "D"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Donegal",
        "code_ciudad": "DL"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Galway",
        "code_ciudad": "G"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Kildare",
        "code_ciudad": "KE"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Kilkenny",
        "code_ciudad": "KK"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Kerry",
        "code_ciudad": "KY"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Longford",
        "code_ciudad": "LD"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Louth",
        "code_ciudad": "LH"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Limerick",
        "code_ciudad": "LK"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Leitrim",
        "code_ciudad": "LM"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Laois",
        "code_ciudad": "LS"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Meath",
        "code_ciudad": "MH"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Monaghan",
        "code_ciudad": "MN"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Mayo",
        "code_ciudad": "MO"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Offaly",
        "code_ciudad": "OY"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Roscommon",
        "code_ciudad": "RN"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Sligo",
        "code_ciudad": "SO"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Tipperary",
        "code_ciudad": "TA"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Waterford",
        "code_ciudad": "WD"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Westmeath",
        "code_ciudad": "WH"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Wicklow",
        "code_ciudad": "WW"
    },
    {
        "pais": "Irlanda",
        "code_pais": "IE",
        "ciudad": "Wexford",
        "code_ciudad": "WX"
    },
    {
        "pais": "Israel",
        "code_pais": "IL",
        "ciudad": "HaDarom",
        "code_ciudad": "D"
    },
    {
        "pais": "Israel",
        "code_pais": "IL",
        "ciudad": "Hefa",
        "code_ciudad": "HA"
    },
    {
        "pais": "Israel",
        "code_pais": "IL",
        "ciudad": "Yerushalayim",
        "code_ciudad": "JM"
    },
    {
        "pais": "Israel",
        "code_pais": "IL",
        "ciudad": "HaMerkaz",
        "code_ciudad": "M"
    },
    {
        "pais": "Israel",
        "code_pais": "IL",
        "ciudad": "Tel Aviv",
        "code_ciudad": "TA"
    },
    {
        "pais": "Israel",
        "code_pais": "IL",
        "ciudad": "HaTsafon",
        "code_ciudad": "Z"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Andaman and Nicobar Islands",
        "code_ciudad": "AN"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Andhra Pradesh",
        "code_ciudad": "AP"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Arunachal Pradesh",
        "code_ciudad": "AR"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Assam",
        "code_ciudad": "AS"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Bihar",
        "code_ciudad": "BR"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Chandigarh",
        "code_ciudad": "CH"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Chhattisgarh",
        "code_ciudad": "CT"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Daman and Diu",
        "code_ciudad": "DD"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Delhi",
        "code_ciudad": "DL"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Dadra and Nagar Haveli",
        "code_ciudad": "DN"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Goa",
        "code_ciudad": "GA"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Gujarat",
        "code_ciudad": "GJ"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Himachal Pradesh",
        "code_ciudad": "HP"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Haryana",
        "code_ciudad": "HR"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Jharkhand",
        "code_ciudad": "JH"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Jammu and Kashmir",
        "code_ciudad": "JK"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Karnataka",
        "code_ciudad": "KA"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Kerala",
        "code_ciudad": "KL"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Lakshadweep",
        "code_ciudad": "LD"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Maharashtra",
        "code_ciudad": "MH"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Meghalaya",
        "code_ciudad": "ML"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Manipur",
        "code_ciudad": "MN"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Madhya Pradesh",
        "code_ciudad": "MP"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Mizoram",
        "code_ciudad": "MZ"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Nagaland",
        "code_ciudad": "NL"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Odisha",
        "code_ciudad": "OR"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Punjab",
        "code_ciudad": "PB"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Puducherry",
        "code_ciudad": "PY"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Rajasthan",
        "code_ciudad": "RJ"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Sikkim",
        "code_ciudad": "SK"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Telangana",
        "code_ciudad": "TG"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Tamil Nadu",
        "code_ciudad": "TN"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Tripura",
        "code_ciudad": "TR"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Uttar Pradesh",
        "code_ciudad": "UP"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "Uttarakhand",
        "code_ciudad": "UT"
    },
    {
        "pais": "India",
        "code_pais": "IN",
        "ciudad": "West Bengal",
        "code_ciudad": "WB"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Al Anbar",
        "code_ciudad": "AN"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Arbil",
        "code_ciudad": "AR"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Al Basrah",
        "code_ciudad": "BA"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Babil",
        "code_ciudad": "BB"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Baghdad",
        "code_ciudad": "BG"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Dahuk",
        "code_ciudad": "DA"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Diyala",
        "code_ciudad": "DI"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Dhi Qar",
        "code_ciudad": "DQ"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Karbala'",
        "code_ciudad": "KA"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Kirkuk",
        "code_ciudad": "KI"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Maysan",
        "code_ciudad": "MA"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Al Muthanna",
        "code_ciudad": "MU"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "An Najaf",
        "code_ciudad": "NA"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Ninawa",
        "code_ciudad": "NI"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Al Qadisiyah",
        "code_ciudad": "QA"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Salah ad Din",
        "code_ciudad": "SD"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "As Sulaymaniyah",
        "code_ciudad": "SU"
    },
    {
        "pais": "Irak",
        "code_pais": "IQ",
        "ciudad": "Wasit",
        "code_ciudad": "WA"
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Azarbayjan-e Sharqi",
        "code_ciudad": "01"
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Azarbayjan-e Gharbi",
        "code_ciudad": "02"
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Ardabil",
        "code_ciudad": "03"
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Esfahan",
        "code_ciudad": "04"
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Ilam",
        "code_ciudad": "05"
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Bushehr",
        "code_ciudad": "06"
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Tehran",
        "code_ciudad": "07"
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Chahar Mahal va Bakhtiari",
        "code_ciudad": "08"
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Khuzestan",
        "code_ciudad": 10
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Zanjan",
        "code_ciudad": 11
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Semnan",
        "code_ciudad": 12
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Sistan va Baluchestan",
        "code_ciudad": 13
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Fars",
        "code_ciudad": 14
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Kerman",
        "code_ciudad": 15
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Kordestan",
        "code_ciudad": 16
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Kermanshah",
        "code_ciudad": 17
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Kohgiluyeh va Bowyer Ahmad",
        "code_ciudad": 18
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Gilan",
        "code_ciudad": 19
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Lorestan",
        "code_ciudad": 20
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Mazandaran",
        "code_ciudad": 21
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Markazi",
        "code_ciudad": 22
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Hormozgan",
        "code_ciudad": 23
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Hamadan",
        "code_ciudad": 24
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Yazd",
        "code_ciudad": 25
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Qom",
        "code_ciudad": 26
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Golestan",
        "code_ciudad": 27
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Qazvin",
        "code_ciudad": 28
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Khorasan-e Jonubi",
        "code_ciudad": 29
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Khorasan-e Razavi",
        "code_ciudad": 30
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Khorasan-e Shomali",
        "code_ciudad": 31
    },
    {
        "pais": "Irán",
        "code_pais": "IR",
        "ciudad": "Alborz",
        "code_ciudad": 32
    },
    {
        "pais": "Islandia",
        "code_pais": "IS",
        "ciudad": "Hofudborgarsvaedi",
        "code_ciudad": 1
    },
    {
        "pais": "Islandia",
        "code_pais": "IS",
        "ciudad": "Sudurnes",
        "code_ciudad": 2
    },
    {
        "pais": "Islandia",
        "code_pais": "IS",
        "ciudad": "Vesturland",
        "code_ciudad": 3
    },
    {
        "pais": "Islandia",
        "code_pais": "IS",
        "ciudad": "Vestfirdir",
        "code_ciudad": 4
    },
    {
        "pais": "Islandia",
        "code_pais": "IS",
        "ciudad": "Nordurland vestra",
        "code_ciudad": 5
    },
    {
        "pais": "Islandia",
        "code_pais": "IS",
        "ciudad": "Nordurland eystra",
        "code_ciudad": 6
    },
    {
        "pais": "Islandia",
        "code_pais": "IS",
        "ciudad": "Austurland",
        "code_ciudad": 7
    },
    {
        "pais": "Islandia",
        "code_pais": "IS",
        "ciudad": "Sudurland",
        "code_ciudad": 8
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Piemonte",
        "code_ciudad": 21
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Valle d'Aosta",
        "code_ciudad": 23
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Lombardia",
        "code_ciudad": 25
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Trentino-Alto Adige",
        "code_ciudad": 32
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Veneto",
        "code_ciudad": 34
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Friuli-Venezia Giulia",
        "code_ciudad": 36
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Liguria",
        "code_ciudad": 42
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Emilia-Romagna",
        "code_ciudad": 45
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Toscana",
        "code_ciudad": 52
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Umbria",
        "code_ciudad": 55
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Marche",
        "code_ciudad": 57
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Lazio",
        "code_ciudad": 62
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Abruzzo",
        "code_ciudad": 65
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Molise",
        "code_ciudad": 67
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Campania",
        "code_ciudad": 72
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Puglia",
        "code_ciudad": 75
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Basilicata",
        "code_ciudad": 77
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Calabria",
        "code_ciudad": 78
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Sicilia",
        "code_ciudad": 82
    },
    {
        "pais": "Italia",
        "code_pais": "IT",
        "ciudad": "Sardegna",
        "code_ciudad": 88
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Kingston",
        "code_ciudad": "01"
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Saint Andrew",
        "code_ciudad": "02"
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Saint Thomas",
        "code_ciudad": "03"
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Portland",
        "code_ciudad": "04"
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Saint Mary",
        "code_ciudad": "05"
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Saint Ann",
        "code_ciudad": "06"
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Trelawny",
        "code_ciudad": "07"
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Saint James",
        "code_ciudad": "08"
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Hanover",
        "code_ciudad": "09"
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Westmoreland",
        "code_ciudad": 10
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Saint Elizabeth",
        "code_ciudad": 11
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Manchester",
        "code_ciudad": 12
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Clarendon",
        "code_ciudad": 13
    },
    {
        "pais": "Jamaica",
        "code_pais": "JM",
        "ciudad": "Saint Catherine",
        "code_ciudad": 14
    },
    {
        "pais": "Jordania",
        "code_pais": "JO",
        "ciudad": "'Ajlun",
        "code_ciudad": "AJ"
    },
    {
        "pais": "Jordania",
        "code_pais": "JO",
        "ciudad": "Al 'Asimah",
        "code_ciudad": "AM"
    },
    {
        "pais": "Jordania",
        "code_pais": "JO",
        "ciudad": "Al 'Aqabah",
        "code_ciudad": "AQ"
    },
    {
        "pais": "Jordania",
        "code_pais": "JO",
        "ciudad": "Az Zarqa'",
        "code_ciudad": "AZ"
    },
    {
        "pais": "Jordania",
        "code_pais": "JO",
        "ciudad": "Al Balqa'",
        "code_ciudad": "BA"
    },
    {
        "pais": "Jordania",
        "code_pais": "JO",
        "ciudad": "Irbid",
        "code_ciudad": "IR"
    },
    {
        "pais": "Jordania",
        "code_pais": "JO",
        "ciudad": "Jarash",
        "code_ciudad": "JA"
    },
    {
        "pais": "Jordania",
        "code_pais": "JO",
        "ciudad": "Al Karak",
        "code_ciudad": "KA"
    },
    {
        "pais": "Jordania",
        "code_pais": "JO",
        "ciudad": "Al Mafraq",
        "code_ciudad": "MA"
    },
    {
        "pais": "Jordania",
        "code_pais": "JO",
        "ciudad": "Madaba",
        "code_ciudad": "MD"
    },
    {
        "pais": "Jordania",
        "code_pais": "JO",
        "ciudad": "Ma'an",
        "code_ciudad": "MN"
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Hokkaido",
        "code_ciudad": "01"
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Aomori",
        "code_ciudad": "02"
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Iwate",
        "code_ciudad": "03"
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Miyagi",
        "code_ciudad": "04"
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Akita",
        "code_ciudad": "05"
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Yamagata",
        "code_ciudad": "06"
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Fukushima",
        "code_ciudad": "07"
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Ibaraki",
        "code_ciudad": "08"
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Tochigi",
        "code_ciudad": "09"
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Gunma",
        "code_ciudad": 10
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Saitama",
        "code_ciudad": 11
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Chiba",
        "code_ciudad": 12
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Tokyo",
        "code_ciudad": 13
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Kanagawa",
        "code_ciudad": 14
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Niigata",
        "code_ciudad": 15
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Toyama",
        "code_ciudad": 16
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Ishikawa",
        "code_ciudad": 17
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Fukui",
        "code_ciudad": 18
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Yamanashi",
        "code_ciudad": 19
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Nagano",
        "code_ciudad": 20
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Gifu",
        "code_ciudad": 21
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Shizuoka",
        "code_ciudad": 22
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Aichi",
        "code_ciudad": 23
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Mie",
        "code_ciudad": 24
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Shiga",
        "code_ciudad": 25
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Kyoto",
        "code_ciudad": 26
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Osaka",
        "code_ciudad": 27
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Hyogo",
        "code_ciudad": 28
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Nara",
        "code_ciudad": 29
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Wakayama",
        "code_ciudad": 30
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Tottori",
        "code_ciudad": 31
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Shimane",
        "code_ciudad": 32
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Okayama",
        "code_ciudad": 33
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Hiroshima",
        "code_ciudad": 34
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Yamaguchi",
        "code_ciudad": 35
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Tokushima",
        "code_ciudad": 36
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Kagawa",
        "code_ciudad": 37
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Ehime",
        "code_ciudad": 38
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Kochi",
        "code_ciudad": 39
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Fukuoka",
        "code_ciudad": 40
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Saga",
        "code_ciudad": 41
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Nagasaki",
        "code_ciudad": 42
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Kumamoto",
        "code_ciudad": 43
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Oita",
        "code_ciudad": 44
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Miyazaki",
        "code_ciudad": 45
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Kagoshima",
        "code_ciudad": 46
    },
    {
        "pais": "Japón",
        "code_pais": "JP",
        "ciudad": "Okinawa",
        "code_ciudad": 47
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Baringo",
        "code_ciudad": "01"
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Bomet",
        "code_ciudad": "02"
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Bungoma",
        "code_ciudad": "03"
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Busia",
        "code_ciudad": "04"
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Elgeyo/Marakwet",
        "code_ciudad": "05"
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Embu",
        "code_ciudad": "06"
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Garissa",
        "code_ciudad": "07"
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Homa Bay",
        "code_ciudad": "08"
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Isiolo",
        "code_ciudad": "09"
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Kajiado",
        "code_ciudad": 10
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Kakamega",
        "code_ciudad": 11
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Kericho",
        "code_ciudad": 12
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Kiambu",
        "code_ciudad": 13
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Kilifi",
        "code_ciudad": 14
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Kirinyaga",
        "code_ciudad": 15
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Kisii",
        "code_ciudad": 16
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Kisumu",
        "code_ciudad": 17
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Kitui",
        "code_ciudad": 18
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Kwale",
        "code_ciudad": 19
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Laikipia",
        "code_ciudad": 20
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Lamu",
        "code_ciudad": 21
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Machakos",
        "code_ciudad": 22
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Makueni",
        "code_ciudad": 23
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Mandera",
        "code_ciudad": 24
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Marsabit",
        "code_ciudad": 25
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Meru",
        "code_ciudad": 26
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Migori",
        "code_ciudad": 27
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Mombasa",
        "code_ciudad": 28
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Murang'a",
        "code_ciudad": 29
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Nairobi City",
        "code_ciudad": 30
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Nakuru",
        "code_ciudad": 31
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Nandi",
        "code_ciudad": 32
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Narok",
        "code_ciudad": 33
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Nyamira",
        "code_ciudad": 34
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Nyandarua",
        "code_ciudad": 35
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Nyeri",
        "code_ciudad": 36
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Siaya",
        "code_ciudad": 38
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Taita/Taveta",
        "code_ciudad": 39
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Tharaka-Nithi",
        "code_ciudad": 41
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Trans Nzoia",
        "code_ciudad": 42
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Turkana",
        "code_ciudad": 43
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Uasin Gishu",
        "code_ciudad": 44
    },
    {
        "pais": "Kenia",
        "code_pais": "KE",
        "ciudad": "Wajir",
        "code_ciudad": 46
    },
    {
        "pais": "Kirguistán",
        "code_pais": "KG",
        "ciudad": "Batken",
        "code_ciudad": "B"
    },
    {
        "pais": "Kirguistán",
        "code_pais": "KG",
        "ciudad": "Chuy",
        "code_ciudad": "C"
    },
    {
        "pais": "Kirguistán",
        "code_pais": "KG",
        "ciudad": "Bishkek",
        "code_ciudad": "GB"
    },
    {
        "pais": "Kirguistán",
        "code_pais": "KG",
        "ciudad": "Osh",
        "code_ciudad": "GO"
    },
    {
        "pais": "Kirguistán",
        "code_pais": "KG",
        "ciudad": "Jalal-Abad",
        "code_ciudad": "J"
    },
    {
        "pais": "Kirguistán",
        "code_pais": "KG",
        "ciudad": "Naryn",
        "code_ciudad": "N"
    },
    {
        "pais": "Kirguistán",
        "code_pais": "KG",
        "ciudad": "Talas",
        "code_ciudad": "T"
    },
    {
        "pais": "Kirguistán",
        "code_pais": "KG",
        "ciudad": "Ysyk-Kol",
        "code_ciudad": "Y"
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Banteay Mean Chey",
        "code_ciudad": 1
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Kracheh",
        "code_ciudad": 10
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Mondol Kiri",
        "code_ciudad": 11
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Phnom Penh",
        "code_ciudad": 12
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Prey Veaeng",
        "code_ciudad": 14
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Pousaat",
        "code_ciudad": 15
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Rotanak Kiri",
        "code_ciudad": 16
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Siem Reab",
        "code_ciudad": 17
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Krong Preah Sihanouk",
        "code_ciudad": 18
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Baat Dambang",
        "code_ciudad": 2
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Svaay Rieng",
        "code_ciudad": 20
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Taakaev",
        "code_ciudad": 21
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Otdar Mean Chey",
        "code_ciudad": 22
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Krong Kaeb",
        "code_ciudad": 23
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Krong Pailin",
        "code_ciudad": 24
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Kampong Chaam",
        "code_ciudad": 3
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Kampong Chhnang",
        "code_ciudad": 4
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Kampong Spueu",
        "code_ciudad": 5
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Kampong Thum",
        "code_ciudad": 6
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Kampot",
        "code_ciudad": 7
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Kandaal",
        "code_ciudad": 8
    },
    {
        "pais": "Camboya",
        "code_pais": "KH",
        "ciudad": "Kaoh Kong",
        "code_ciudad": 9
    },
    {
        "pais": "Kiribati",
        "code_pais": "KI",
        "ciudad": "Gilbert Islands",
        "code_ciudad": "G"
    },
    {
        "pais": "Kiribati",
        "code_pais": "KI",
        "ciudad": "Line Islands",
        "code_ciudad": "L"
    },
    {
        "pais": "Comoras",
        "code_pais": "KM",
        "ciudad": "Grande Comore",
        "code_ciudad": "G"
    },
    {
        "pais": "San Cristóbal y Nieves",
        "code_pais": "KN",
        "ciudad": "Saint Anne Sandy Point",
        "code_ciudad": "02"
    },
    {
        "pais": "San Cristóbal y Nieves",
        "code_pais": "KN",
        "ciudad": "Saint George Basseterre",
        "code_ciudad": "03"
    },
    {
        "pais": "San Cristóbal y Nieves",
        "code_pais": "KN",
        "ciudad": "Saint James Windward",
        "code_ciudad": "05"
    },
    {
        "pais": "San Cristóbal y Nieves",
        "code_pais": "KN",
        "ciudad": "Saint John Capisterre",
        "code_ciudad": "06"
    },
    {
        "pais": "San Cristóbal y Nieves",
        "code_pais": "KN",
        "ciudad": "Saint John Figtree",
        "code_ciudad": "07"
    },
    {
        "pais": "San Cristóbal y Nieves",
        "code_pais": "KN",
        "ciudad": "Saint Mary Cayon",
        "code_ciudad": "08"
    },
    {
        "pais": "San Cristóbal y Nieves",
        "code_pais": "KN",
        "ciudad": "Saint Paul Capisterre",
        "code_ciudad": "09"
    },
    {
        "pais": "San Cristóbal y Nieves",
        "code_pais": "KN",
        "ciudad": "Saint Paul Charlestown",
        "code_ciudad": 10
    },
    {
        "pais": "San Cristóbal y Nieves",
        "code_pais": "KN",
        "ciudad": "Saint Peter Basseterre",
        "code_ciudad": 11
    },
    {
        "pais": "San Cristóbal y Nieves",
        "code_pais": "KN",
        "ciudad": "Saint Thomas Lowland",
        "code_ciudad": 12
    },
    {
        "pais": "San Cristóbal y Nieves",
        "code_pais": "KN",
        "ciudad": "Saint Thomas Middle Island",
        "code_ciudad": 13
    },
    {
        "pais": "Corea del Norte",
        "code_pais": "KP",
        "ciudad": "P'yongyang",
        "code_ciudad": "01"
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Seoul-teukbyeolsi",
        "code_ciudad": 11
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Busan-gwangyeoksi",
        "code_ciudad": 26
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Daegu-gwangyeoksi",
        "code_ciudad": 27
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Incheon-gwangyeoksi",
        "code_ciudad": 28
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Gwangju-gwangyeoksi",
        "code_ciudad": 29
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Daejeon-gwangyeoksi",
        "code_ciudad": 30
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Ulsan-gwangyeoksi",
        "code_ciudad": 31
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Gyeonggi-do",
        "code_ciudad": 41
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Gangwon-do",
        "code_ciudad": 42
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Chungcheongbuk-do",
        "code_ciudad": 43
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Chungcheongnam-do",
        "code_ciudad": 44
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Jeollabuk-do",
        "code_ciudad": 45
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Jeollanam-do",
        "code_ciudad": 46
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Gyeongsangbuk-do",
        "code_ciudad": 47
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Gyeongsangnam-do",
        "code_ciudad": 48
    },
    {
        "pais": "Corea del Sur",
        "code_pais": "KR",
        "ciudad": "Jeju-teukbyeoljachido",
        "code_ciudad": 49
    },
    {
        "pais": "Kuwait",
        "code_pais": "KW",
        "ciudad": "Al Ahmadi",
        "code_ciudad": "AH"
    },
    {
        "pais": "Kuwait",
        "code_pais": "KW",
        "ciudad": "Al Farwaniyah",
        "code_ciudad": "FA"
    },
    {
        "pais": "Kuwait",
        "code_pais": "KW",
        "ciudad": "Hawalli",
        "code_ciudad": "HA"
    },
    {
        "pais": "Kuwait",
        "code_pais": "KW",
        "ciudad": "Al Jahra'",
        "code_ciudad": "JA"
    },
    {
        "pais": "Kuwait",
        "code_pais": "KW",
        "ciudad": "Al 'Asimah",
        "code_ciudad": "KU"
    },
    {
        "pais": "Kuwait",
        "code_pais": "KW",
        "ciudad": "Mubarak al Kabir",
        "code_ciudad": "MU"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Aqmola oblysy",
        "code_ciudad": "AKM"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Aqtobe oblysy",
        "code_ciudad": "AKT"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Almaty",
        "code_ciudad": "ALA"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Almaty oblysy",
        "code_ciudad": "ALM"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Astana",
        "code_ciudad": "AST"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Atyrau oblysy",
        "code_ciudad": "ATY"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Bayqongyr",
        "code_ciudad": "BAY"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Qaraghandy oblysy",
        "code_ciudad": "KAR"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Qostanay oblysy",
        "code_ciudad": "KUS"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Qyzylorda oblysy",
        "code_ciudad": "KZY"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Mangghystau oblysy",
        "code_ciudad": "MAN"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Pavlodar oblysy",
        "code_ciudad": "PAV"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Soltustik Qazaqstan oblysy",
        "code_ciudad": "SEV"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Shyghys Qazaqstan oblysy",
        "code_ciudad": "VOS"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Ongtustik Qazaqstan oblysy",
        "code_ciudad": "YUZ"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Batys Qazaqstan oblysy",
        "code_ciudad": "ZAP"
    },
    {
        "pais": "Kazajistán",
        "code_pais": "KZ",
        "ciudad": "Zhambyl oblysy",
        "code_ciudad": "ZHA"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Attapu",
        "code_ciudad": "AT"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Champasak",
        "code_ciudad": "CH"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Houaphan",
        "code_ciudad": "HO"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Khammouan",
        "code_ciudad": "KH"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Louang Namtha",
        "code_ciudad": "LM"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Louangphabang",
        "code_ciudad": "LP"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Oudomxai",
        "code_ciudad": "OU"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Salavan",
        "code_ciudad": "SL"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Savannakhet",
        "code_ciudad": "SV"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Viangchan",
        "code_ciudad": "VI"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Xaignabouli",
        "code_ciudad": "XA"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Xekong",
        "code_ciudad": "XE"
    },
    {
        "pais": "Laos",
        "code_pais": "LA",
        "ciudad": "Xiangkhouang",
        "code_ciudad": "XI"
    },
    {
        "pais": "Líbano",
        "code_pais": "LB",
        "ciudad": "Aakkar",
        "code_ciudad": "AK"
    },
    {
        "pais": "Líbano",
        "code_pais": "LB",
        "ciudad": "Liban-Nord",
        "code_ciudad": "AS"
    },
    {
        "pais": "Líbano",
        "code_pais": "LB",
        "ciudad": "Beyrouth",
        "code_ciudad": "BA"
    },
    {
        "pais": "Líbano",
        "code_pais": "LB",
        "ciudad": "Baalbek-Hermel",
        "code_ciudad": "BH"
    },
    {
        "pais": "Líbano",
        "code_pais": "LB",
        "ciudad": "Beqaa",
        "code_ciudad": "BI"
    },
    {
        "pais": "Líbano",
        "code_pais": "LB",
        "ciudad": "Liban-Sud",
        "code_ciudad": "JA"
    },
    {
        "pais": "Líbano",
        "code_pais": "LB",
        "ciudad": "Mont-Liban",
        "code_ciudad": "JL"
    },
    {
        "pais": "Líbano",
        "code_pais": "LB",
        "ciudad": "Nabatiye",
        "code_ciudad": "NA"
    },
    {
        "pais": "Santa Lucía",
        "code_pais": "LC",
        "ciudad": "Anse la Raye",
        "code_ciudad": "01"
    },
    {
        "pais": "Santa Lucía",
        "code_pais": "LC",
        "ciudad": "Castries",
        "code_ciudad": "02"
    },
    {
        "pais": "Santa Lucía",
        "code_pais": "LC",
        "ciudad": "Choiseul",
        "code_ciudad": "03"
    },
    {
        "pais": "Santa Lucía",
        "code_pais": "LC",
        "ciudad": "Dennery",
        "code_ciudad": "05"
    },
    {
        "pais": "Santa Lucía",
        "code_pais": "LC",
        "ciudad": "Gros Islet",
        "code_ciudad": "06"
    },
    {
        "pais": "Santa Lucía",
        "code_pais": "LC",
        "ciudad": "Laborie",
        "code_ciudad": "07"
    },
    {
        "pais": "Santa Lucía",
        "code_pais": "LC",
        "ciudad": "Micoud",
        "code_ciudad": "08"
    },
    {
        "pais": "Santa Lucía",
        "code_pais": "LC",
        "ciudad": "Soufriere",
        "code_ciudad": 10
    },
    {
        "pais": "Santa Lucía",
        "code_pais": "LC",
        "ciudad": "Vieux Fort",
        "code_ciudad": 11
    },
    {
        "pais": "Liechtenstein",
        "code_pais": "LI",
        "ciudad": "Balzers",
        "code_ciudad": "01"
    },
    {
        "pais": "Liechtenstein",
        "code_pais": "LI",
        "ciudad": "Eschen",
        "code_ciudad": "02"
    },
    {
        "pais": "Liechtenstein",
        "code_pais": "LI",
        "ciudad": "Gamprin",
        "code_ciudad": "03"
    },
    {
        "pais": "Liechtenstein",
        "code_pais": "LI",
        "ciudad": "Mauren",
        "code_ciudad": "04"
    },
    {
        "pais": "Liechtenstein",
        "code_pais": "LI",
        "ciudad": "Planken",
        "code_ciudad": "05"
    },
    {
        "pais": "Liechtenstein",
        "code_pais": "LI",
        "ciudad": "Ruggell",
        "code_ciudad": "06"
    },
    {
        "pais": "Liechtenstein",
        "code_pais": "LI",
        "ciudad": "Schaan",
        "code_ciudad": "07"
    },
    {
        "pais": "Liechtenstein",
        "code_pais": "LI",
        "ciudad": "Schellenberg",
        "code_ciudad": "08"
    },
    {
        "pais": "Liechtenstein",
        "code_pais": "LI",
        "ciudad": "Triesen",
        "code_ciudad": "09"
    },
    {
        "pais": "Liechtenstein",
        "code_pais": "LI",
        "ciudad": "Triesenberg",
        "code_ciudad": 10
    },
    {
        "pais": "Liechtenstein",
        "code_pais": "LI",
        "ciudad": "Vaduz",
        "code_ciudad": 11
    },
    {
        "pais": "Sri Lanka",
        "code_pais": "LK",
        "ciudad": "Western Province",
        "code_ciudad": 1
    },
    {
        "pais": "Sri Lanka",
        "code_pais": "LK",
        "ciudad": "Central Province",
        "code_ciudad": 2
    },
    {
        "pais": "Sri Lanka",
        "code_pais": "LK",
        "ciudad": "Southern Province",
        "code_ciudad": 3
    },
    {
        "pais": "Sri Lanka",
        "code_pais": "LK",
        "ciudad": "Northern Province",
        "code_ciudad": 4
    },
    {
        "pais": "Sri Lanka",
        "code_pais": "LK",
        "ciudad": "Eastern Province",
        "code_ciudad": 5
    },
    {
        "pais": "Sri Lanka",
        "code_pais": "LK",
        "ciudad": "North Western Province",
        "code_ciudad": 6
    },
    {
        "pais": "Sri Lanka",
        "code_pais": "LK",
        "ciudad": "North Central Province",
        "code_ciudad": 7
    },
    {
        "pais": "Sri Lanka",
        "code_pais": "LK",
        "ciudad": "Uva Province",
        "code_ciudad": 8
    },
    {
        "pais": "Sri Lanka",
        "code_pais": "LK",
        "ciudad": "Sabaragamuwa Province",
        "code_ciudad": 9
    },
    {
        "pais": "Liberia",
        "code_pais": "LR",
        "ciudad": "Grand Bassa",
        "code_ciudad": "GB"
    },
    {
        "pais": "Liberia",
        "code_pais": "LR",
        "ciudad": "Grand Gedeh",
        "code_ciudad": "GG"
    },
    {
        "pais": "Liberia",
        "code_pais": "LR",
        "ciudad": "Margibi",
        "code_ciudad": "MG"
    },
    {
        "pais": "Liberia",
        "code_pais": "LR",
        "ciudad": "Montserrado",
        "code_ciudad": "MO"
    },
    {
        "pais": "Liberia",
        "code_pais": "LR",
        "ciudad": "Maryland",
        "code_ciudad": "MY"
    },
    {
        "pais": "Liberia",
        "code_pais": "LR",
        "ciudad": "Nimba",
        "code_ciudad": "NI"
    },
    {
        "pais": "Liberia",
        "code_pais": "LR",
        "ciudad": "Sinoe",
        "code_ciudad": "SI"
    },
    {
        "pais": "Lesoto",
        "code_pais": "LS",
        "ciudad": "Maseru",
        "code_ciudad": "A"
    },
    {
        "pais": "Lesoto",
        "code_pais": "LS",
        "ciudad": "Leribe",
        "code_ciudad": "C"
    },
    {
        "pais": "Lesoto",
        "code_pais": "LS",
        "ciudad": "Berea",
        "code_ciudad": "D"
    },
    {
        "pais": "Lesoto",
        "code_pais": "LS",
        "ciudad": "Mafeteng",
        "code_ciudad": "E"
    },
    {
        "pais": "Lesoto",
        "code_pais": "LS",
        "ciudad": "Quthing",
        "code_ciudad": "G"
    },
    {
        "pais": "Lituania",
        "code_pais": "LT",
        "ciudad": "Alytaus apskritis",
        "code_ciudad": "AL"
    },
    {
        "pais": "Lituania",
        "code_pais": "LT",
        "ciudad": "Klaipedos apskritis",
        "code_ciudad": "KL"
    },
    {
        "pais": "Lituania",
        "code_pais": "LT",
        "ciudad": "Kauno apskritis",
        "code_ciudad": "KU"
    },
    {
        "pais": "Lituania",
        "code_pais": "LT",
        "ciudad": "Marijampoles apskritis",
        "code_ciudad": "MR"
    },
    {
        "pais": "Lituania",
        "code_pais": "LT",
        "ciudad": "Panevezio apskritis",
        "code_ciudad": "PN"
    },
    {
        "pais": "Lituania",
        "code_pais": "LT",
        "ciudad": "Siauliu apskritis",
        "code_ciudad": "SA"
    },
    {
        "pais": "Lituania",
        "code_pais": "LT",
        "ciudad": "Taurages apskritis",
        "code_ciudad": "TA"
    },
    {
        "pais": "Lituania",
        "code_pais": "LT",
        "ciudad": "Telsiu apskritis",
        "code_ciudad": "TE"
    },
    {
        "pais": "Lituania",
        "code_pais": "LT",
        "ciudad": "Utenos apskritis",
        "code_ciudad": "UT"
    },
    {
        "pais": "Lituania",
        "code_pais": "LT",
        "ciudad": "Vilniaus apskritis",
        "code_ciudad": "VL"
    },
    {
        "pais": "Luxemburgo",
        "code_pais": "LU",
        "ciudad": "Diekirch",
        "code_ciudad": "DI"
    },
    {
        "pais": "Luxemburgo",
        "code_pais": "LU",
        "ciudad": "Grevenmacher",
        "code_ciudad": "GR"
    },
    {
        "pais": "Luxemburgo",
        "code_pais": "LU",
        "ciudad": "Luxembourg",
        "code_ciudad": "LU"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Aglonas novads",
        "code_ciudad": "001"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Aizkraukles novads",
        "code_ciudad": "002"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Aizputes novads",
        "code_ciudad": "003"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Alojas novads",
        "code_ciudad": "005"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Aluksnes novads",
        "code_ciudad": "007"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Adazu novads",
        "code_ciudad": "011"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Babites novads",
        "code_ciudad": "012"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Baldones novads",
        "code_ciudad": "013"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Balvu novads",
        "code_ciudad": "015"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Bauskas novads",
        "code_ciudad": "016"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Beverinas novads",
        "code_ciudad": "017"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Brocenu novads",
        "code_ciudad": "018"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Carnikavas novads",
        "code_ciudad": "020"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Cesvaines novads",
        "code_ciudad": "021"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Cesu novads",
        "code_ciudad": "022"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Daugavpils novads",
        "code_ciudad": "025"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Dobeles novads",
        "code_ciudad": "026"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Dundagas novads",
        "code_ciudad": "027"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Erglu novads",
        "code_ciudad": "030"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Gulbenes novads",
        "code_ciudad": "033"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Iecavas novads",
        "code_ciudad": "034"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Ikskiles novads",
        "code_ciudad": "035"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Incukalna novads",
        "code_ciudad": "037"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Jaunjelgavas novads",
        "code_ciudad": "038"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Jaunpiebalgas novads",
        "code_ciudad": "039"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Jaunpils novads",
        "code_ciudad": "040"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Jelgavas novads",
        "code_ciudad": "041"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Jekabpils novads",
        "code_ciudad": "042"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Kokneses novads",
        "code_ciudad": "046"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Kraslavas novads",
        "code_ciudad": "047"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Kuldigas novads",
        "code_ciudad": "050"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Kekavas novads",
        "code_ciudad": "052"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Lielvardes novads",
        "code_ciudad": "053"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Limbazu novads",
        "code_ciudad": "054"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Livanu novads",
        "code_ciudad": "056"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Lubanas novads",
        "code_ciudad": "057"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Ludzas novads",
        "code_ciudad": "058"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Madonas novads",
        "code_ciudad": "059"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Malpils novads",
        "code_ciudad": "061"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Nauksenu novads",
        "code_ciudad": "064"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Ogres novads",
        "code_ciudad": "067"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Olaines novads",
        "code_ciudad": "068"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Ozolnieku novads",
        "code_ciudad": "069"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Preilu novads",
        "code_ciudad": "073"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Rezeknes novads",
        "code_ciudad": "077"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Riebinu novads",
        "code_ciudad": "078"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Rojas novads",
        "code_ciudad": "079"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Ropazu novads",
        "code_ciudad": "080"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Rundales novads",
        "code_ciudad": "083"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Salacgrivas novads",
        "code_ciudad": "086"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Salaspils novads",
        "code_ciudad": "087"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Saldus novads",
        "code_ciudad": "088"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Saulkrastu novads",
        "code_ciudad": "089"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Sejas novads",
        "code_ciudad": "090"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Siguldas novads",
        "code_ciudad": "091"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Skrundas novads",
        "code_ciudad": "093"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Stopinu novads",
        "code_ciudad": "095"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Strencu novads",
        "code_ciudad": "096"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Talsu novads",
        "code_ciudad": "097"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Tukuma novads",
        "code_ciudad": "099"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Valkas novads",
        "code_ciudad": 101
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Varkavas novads",
        "code_ciudad": 103
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Vecumnieku novads",
        "code_ciudad": 105
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Ventspils novads",
        "code_ciudad": 106
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Jelgava",
        "code_ciudad": "JEL"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Jurmala",
        "code_ciudad": "JUR"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Liepaja",
        "code_ciudad": "LPX"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Riga",
        "code_ciudad": "RIX"
    },
    {
        "pais": "Letonia",
        "code_pais": "LV",
        "ciudad": "Valmiera",
        "code_ciudad": "VMR"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Banghazi",
        "code_ciudad": "BA"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Al Butnan",
        "code_ciudad": "BU"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Darnah",
        "code_ciudad": "DR"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Al Jabal al Akhdar",
        "code_ciudad": "JA"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Al Jabal al Gharbi",
        "code_ciudad": "JG"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Al Jufrah",
        "code_ciudad": "JU"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Al Kufrah",
        "code_ciudad": "KF"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Al Marqab",
        "code_ciudad": "MB"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Misratah",
        "code_ciudad": "MI"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Al Marj",
        "code_ciudad": "MJ"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Murzuq",
        "code_ciudad": "MQ"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Nalut",
        "code_ciudad": "NL"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "An Nuqat al Khams",
        "code_ciudad": "NQ"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Sabha",
        "code_ciudad": "SB"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Tarabulus",
        "code_ciudad": "TB"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Al Wahat",
        "code_ciudad": "WA"
    },
    {
        "pais": "Libia",
        "code_pais": "LY",
        "ciudad": "Az Zawiyah",
        "code_ciudad": "ZA"
    },
    {
        "pais": "Marruecos",
        "code_pais": "MA",
        "ciudad": "Tanger-Tetouan-Al Hoceima",
        "code_ciudad": "01"
    },
    {
        "pais": "Marruecos",
        "code_pais": "MA",
        "ciudad": "L'Oriental",
        "code_ciudad": "02"
    },
    {
        "pais": "Marruecos",
        "code_pais": "MA",
        "ciudad": "Fes- Meknes",
        "code_ciudad": "03"
    },
    {
        "pais": "Marruecos",
        "code_pais": "MA",
        "ciudad": "Rabat-Sale-Kenitra",
        "code_ciudad": "04"
    },
    {
        "pais": "Marruecos",
        "code_pais": "MA",
        "ciudad": "Beni-Mellal-Khenifra",
        "code_ciudad": "05"
    },
    {
        "pais": "Marruecos",
        "code_pais": "MA",
        "ciudad": "Casablanca-Settat",
        "code_ciudad": "06"
    },
    {
        "pais": "Marruecos",
        "code_pais": "MA",
        "ciudad": "Marrakech-Safi",
        "code_ciudad": "07"
    },
    {
        "pais": "Marruecos",
        "code_pais": "MA",
        "ciudad": "Draa-Tafilalet",
        "code_ciudad": "08"
    },
    {
        "pais": "Marruecos",
        "code_pais": "MA",
        "ciudad": "Souss-Massa",
        "code_ciudad": "09"
    },
    {
        "pais": "Marruecos",
        "code_pais": "MA",
        "ciudad": "Guelmim-Oued Noun (EH-partial)",
        "code_ciudad": 10
    },
    {
        "pais": "Marruecos",
        "code_pais": "MA",
        "ciudad": "Laayoune-Sakia El Hamra (EH-partial)",
        "code_ciudad": 11
    },
    {
        "pais": "Mónaco",
        "code_pais": "MC",
        "ciudad": "La Condamine",
        "code_ciudad": "CO"
    },
    {
        "pais": "Mónaco",
        "code_pais": "MC",
        "ciudad": "Fontvieille",
        "code_ciudad": "FO"
    },
    {
        "pais": "Mónaco",
        "code_pais": "MC",
        "ciudad": "Monte-Carlo",
        "code_ciudad": "MC"
    },
    {
        "pais": "Mónaco",
        "code_pais": "MC",
        "ciudad": "Monaco-Ville",
        "code_ciudad": "MO"
    },
    {
        "pais": "Mónaco",
        "code_pais": "MC",
        "ciudad": "Saint-Roman",
        "code_ciudad": "SR"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Anenii Noi",
        "code_ciudad": "AN"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Balti",
        "code_ciudad": "BA"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Bender",
        "code_ciudad": "BD"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Briceni",
        "code_ciudad": "BR"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Basarabeasca",
        "code_ciudad": "BS"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Cahul",
        "code_ciudad": "CA"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Calarasi",
        "code_ciudad": "CL"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Cimislia",
        "code_ciudad": "CM"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Criuleni",
        "code_ciudad": "CR"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Causeni",
        "code_ciudad": "CS"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Cantemir",
        "code_ciudad": "CT"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Chisinau",
        "code_ciudad": "CU"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Donduseni",
        "code_ciudad": "DO"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Drochia",
        "code_ciudad": "DR"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Dubasari",
        "code_ciudad": "DU"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Edinet",
        "code_ciudad": "ED"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Falesti",
        "code_ciudad": "FA"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Floresti",
        "code_ciudad": "FL"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Gagauzia, Unitatea teritoriala autonoma",
        "code_ciudad": "GA"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Glodeni",
        "code_ciudad": "GL"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Hincesti",
        "code_ciudad": "HI"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Ialoveni",
        "code_ciudad": "IA"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Leova",
        "code_ciudad": "LE"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Nisporeni",
        "code_ciudad": "NI"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Ocnita",
        "code_ciudad": "OC"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Orhei",
        "code_ciudad": "OR"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Rezina",
        "code_ciudad": "RE"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Riscani",
        "code_ciudad": "RI"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Soldanesti",
        "code_ciudad": "SD"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Singerei",
        "code_ciudad": "SI"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Stinga Nistrului, unitatea teritoriala din",
        "code_ciudad": "SN"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Soroca",
        "code_ciudad": "SO"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Straseni",
        "code_ciudad": "ST"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Stefan Voda",
        "code_ciudad": "SV"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Taraclia",
        "code_ciudad": "TA"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Telenesti",
        "code_ciudad": "TE"
    },
    {
        "pais": "Moldavia",
        "code_pais": "MD",
        "ciudad": "Ungheni",
        "code_ciudad": "UN"
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Bar",
        "code_ciudad": "02"
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Berane",
        "code_ciudad": "03"
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Bijelo Polje",
        "code_ciudad": "04"
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Budva",
        "code_ciudad": "05"
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Cetinje",
        "code_ciudad": "06"
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Danilovgrad",
        "code_ciudad": "07"
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Herceg-Novi",
        "code_ciudad": "08"
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Kolasin",
        "code_ciudad": "09"
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Kotor",
        "code_ciudad": 10
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Niksic",
        "code_ciudad": 12
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Plav",
        "code_ciudad": 13
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Pljevlja",
        "code_ciudad": 14
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Pluzine",
        "code_ciudad": 15
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Podgorica",
        "code_ciudad": 16
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Rozaje",
        "code_ciudad": 17
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Savnik",
        "code_ciudad": 18
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Tivat",
        "code_ciudad": 19
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Ulcinj",
        "code_ciudad": 20
    },
    {
        "pais": "Montenegro",
        "code_pais": "ME",
        "ciudad": "Zabljak",
        "code_ciudad": 21
    },
    {
        "pais": "Madagascar",
        "code_pais": "MG",
        "ciudad": "Toamasina",
        "code_ciudad": "A"
    },
    {
        "pais": "Madagascar",
        "code_pais": "MG",
        "ciudad": "Antsiranana",
        "code_ciudad": "D"
    },
    {
        "pais": "Madagascar",
        "code_pais": "MG",
        "ciudad": "Fianarantsoa",
        "code_ciudad": "F"
    },
    {
        "pais": "Madagascar",
        "code_pais": "MG",
        "ciudad": "Mahajanga",
        "code_ciudad": "M"
    },
    {
        "pais": "Madagascar",
        "code_pais": "MG",
        "ciudad": "Antananarivo",
        "code_ciudad": "T"
    },
    {
        "pais": "Madagascar",
        "code_pais": "MG",
        "ciudad": "Toliara",
        "code_ciudad": "U"
    },
    {
        "pais": "Islas Marshall",
        "code_pais": "MH",
        "ciudad": "Kwajalein",
        "code_ciudad": "KWA"
    },
    {
        "pais": "Islas Marshall",
        "code_pais": "MH",
        "ciudad": "Majuro",
        "code_ciudad": "MAJ"
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Veles",
        "code_ciudad": 101
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Demir Kapija",
        "code_ciudad": 103
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Kavadarci",
        "code_ciudad": 104
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Negotino",
        "code_ciudad": 106
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Rosoman",
        "code_ciudad": 107
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Sveti Nikole",
        "code_ciudad": 108
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Berovo",
        "code_ciudad": 201
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Vinica",
        "code_ciudad": 202
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Delcevo",
        "code_ciudad": 203
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Karbinci",
        "code_ciudad": 205
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Kocani",
        "code_ciudad": 206
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Makedonska Kamenica",
        "code_ciudad": 207
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Pehcevo",
        "code_ciudad": 208
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Probistip",
        "code_ciudad": 209
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Cesinovo-Oblesevo",
        "code_ciudad": 210
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Stip",
        "code_ciudad": 211
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Vevcani",
        "code_ciudad": 301
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Debar",
        "code_ciudad": 303
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Kicevo",
        "code_ciudad": 307
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Ohrid",
        "code_ciudad": 310
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Plasnica",
        "code_ciudad": 311
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Struga",
        "code_ciudad": 312
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Centar Zupa",
        "code_ciudad": 313
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Bogdanci",
        "code_ciudad": 401
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Bosilovo",
        "code_ciudad": 402
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Valandovo",
        "code_ciudad": 403
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Vasilevo",
        "code_ciudad": 404
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Gevgelija",
        "code_ciudad": 405
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Novo Selo",
        "code_ciudad": 408
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Radovis",
        "code_ciudad": 409
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Strumica",
        "code_ciudad": 410
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Bitola",
        "code_ciudad": 501
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Dolneni",
        "code_ciudad": 503
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Krivogastani",
        "code_ciudad": 504
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Krusevo",
        "code_ciudad": 505
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Novaci",
        "code_ciudad": 507
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Prilep",
        "code_ciudad": 508
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Resen",
        "code_ciudad": 509
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Bogovinje",
        "code_ciudad": 601
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Brvenica",
        "code_ciudad": 602
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Vrapciste",
        "code_ciudad": 603
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Gostivar",
        "code_ciudad": 604
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Zelino",
        "code_ciudad": 605
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Jegunovce",
        "code_ciudad": 606
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Mavrovo i Rostusa",
        "code_ciudad": 607
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Tearce",
        "code_ciudad": 608
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Tetovo",
        "code_ciudad": 609
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Kratovo",
        "code_ciudad": 701
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Kriva Palanka",
        "code_ciudad": 702
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Kumanovo",
        "code_ciudad": 703
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Lipkovo",
        "code_ciudad": 704
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Aracinovo",
        "code_ciudad": 802
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Zelenikovo",
        "code_ciudad": 806
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Ilinden",
        "code_ciudad": 807
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Petrovec",
        "code_ciudad": 810
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Sopiste",
        "code_ciudad": 812
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Studenicani",
        "code_ciudad": 813
    },
    {
        "pais": "Macedonia",
        "code_pais": "MK",
        "ciudad": "Skopje",
        "code_ciudad": 85
    },
    {
        "pais": "Malí",
        "code_pais": "ML",
        "ciudad": "Kayes",
        "code_ciudad": 1
    },
    {
        "pais": "Malí",
        "code_pais": "ML",
        "ciudad": "Koulikoro",
        "code_ciudad": 2
    },
    {
        "pais": "Malí",
        "code_pais": "ML",
        "ciudad": "Sikasso",
        "code_ciudad": 3
    },
    {
        "pais": "Malí",
        "code_pais": "ML",
        "ciudad": "Segou",
        "code_ciudad": 4
    },
    {
        "pais": "Malí",
        "code_pais": "ML",
        "ciudad": "Mopti",
        "code_ciudad": 5
    },
    {
        "pais": "Malí",
        "code_pais": "ML",
        "ciudad": "Tombouctou",
        "code_ciudad": 6
    },
    {
        "pais": "Malí",
        "code_pais": "ML",
        "ciudad": "Gao",
        "code_ciudad": 7
    },
    {
        "pais": "Malí",
        "code_pais": "ML",
        "ciudad": "Kidal",
        "code_ciudad": 8
    },
    {
        "pais": "Malí",
        "code_pais": "ML",
        "ciudad": "Bamako",
        "code_ciudad": "BKO"
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Sagaing",
        "code_ciudad": "01"
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Bago",
        "code_ciudad": "02"
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Magway",
        "code_ciudad": "03"
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Mandalay",
        "code_ciudad": "04"
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Tanintharyi",
        "code_ciudad": "05"
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Yangon",
        "code_ciudad": "06"
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Ayeyarwady",
        "code_ciudad": "07"
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Kachin",
        "code_ciudad": 11
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Kayah",
        "code_ciudad": 12
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Kayin",
        "code_ciudad": 13
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Chin",
        "code_ciudad": 14
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Mon",
        "code_ciudad": 15
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Shan",
        "code_ciudad": 17
    },
    {
        "pais": "Myanmar nota 3​",
        "code_pais": "MM",
        "ciudad": "Nay Pyi Taw",
        "code_ciudad": 18
    },
    {
        "pais": "Mongolia",
        "code_pais": "MN",
        "ciudad": "Orhon",
        "code_ciudad": "035"
    },
    {
        "pais": "Mongolia",
        "code_pais": "MN",
        "ciudad": "Tov",
        "code_ciudad": "047"
    },
    {
        "pais": "Mongolia",
        "code_pais": "MN",
        "ciudad": "Selenge",
        "code_ciudad": "049"
    },
    {
        "pais": "Mongolia",
        "code_pais": "MN",
        "ciudad": "Omnogovi",
        "code_ciudad": "053"
    },
    {
        "pais": "Mongolia",
        "code_pais": "MN",
        "ciudad": "Ovorhangay",
        "code_ciudad": "055"
    },
    {
        "pais": "Mongolia",
        "code_pais": "MN",
        "ciudad": "Dornogovi",
        "code_ciudad": "063"
    },
    {
        "pais": "Mongolia",
        "code_pais": "MN",
        "ciudad": "Govi-Altay",
        "code_ciudad": "065"
    },
    {
        "pais": "Mongolia",
        "code_pais": "MN",
        "ciudad": "Bayan-Olgiy",
        "code_ciudad": "071"
    },
    {
        "pais": "Mongolia",
        "code_pais": "MN",
        "ciudad": "Ulaanbaatar",
        "code_ciudad": 1
    },
    {
        "pais": "Mauritania",
        "code_pais": "MR",
        "ciudad": "Trarza",
        "code_ciudad": "06"
    },
    {
        "pais": "Mauritania",
        "code_pais": "MR",
        "ciudad": "Dakhlet Nouadhibou",
        "code_ciudad": "08"
    },
    {
        "pais": "Mauritania",
        "code_pais": "MR",
        "ciudad": "Tiris Zemmour",
        "code_ciudad": 11
    },
    {
        "pais": "Mauritania",
        "code_pais": "MR",
        "ciudad": "Nouakchott Nord",
        "code_ciudad": 14
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Attard",
        "code_ciudad": "01"
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Balzan",
        "code_ciudad": "02"
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Birgu",
        "code_ciudad": "03"
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Birkirkara",
        "code_ciudad": "04"
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Birzebbuga",
        "code_ciudad": "05"
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Bormla",
        "code_ciudad": "06"
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Dingli",
        "code_ciudad": "07"
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Fgura",
        "code_ciudad": "08"
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Floriana",
        "code_ciudad": "09"
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Fontana",
        "code_ciudad": 10
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Gudja",
        "code_ciudad": 11
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Gzira",
        "code_ciudad": 12
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Gharb",
        "code_ciudad": 14
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Gharghur",
        "code_ciudad": 15
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Ghasri",
        "code_ciudad": 16
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Ghaxaq",
        "code_ciudad": 17
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Hamrun",
        "code_ciudad": 18
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Iklin",
        "code_ciudad": 19
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Isla",
        "code_ciudad": 20
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Kalkara",
        "code_ciudad": 21
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Kercem",
        "code_ciudad": 22
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Kirkop",
        "code_ciudad": 23
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Lija",
        "code_ciudad": 24
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Luqa",
        "code_ciudad": 25
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Marsa",
        "code_ciudad": 26
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Marsaskala",
        "code_ciudad": 27
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Marsaxlokk",
        "code_ciudad": 28
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Mdina",
        "code_ciudad": 29
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Mellieha",
        "code_ciudad": 30
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Mgarr",
        "code_ciudad": 31
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Mosta",
        "code_ciudad": 32
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Mqabba",
        "code_ciudad": 33
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Msida",
        "code_ciudad": 34
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Mtarfa",
        "code_ciudad": 35
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Munxar",
        "code_ciudad": 36
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Nadur",
        "code_ciudad": 37
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Naxxar",
        "code_ciudad": 38
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Paola",
        "code_ciudad": 39
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Pembroke",
        "code_ciudad": 40
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Pieta",
        "code_ciudad": 41
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Qala",
        "code_ciudad": 42
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Qormi",
        "code_ciudad": 43
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Rabat Gozo",
        "code_ciudad": 45
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Rabat Malta",
        "code_ciudad": 46
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Saint Julian's",
        "code_ciudad": 48
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Saint John",
        "code_ciudad": 49
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Saint Paul's Bay",
        "code_ciudad": 51
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Sannat",
        "code_ciudad": 52
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Saint Lucia's",
        "code_ciudad": 53
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Santa Venera",
        "code_ciudad": 54
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Siggiewi",
        "code_ciudad": 55
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Sliema",
        "code_ciudad": 56
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Swieqi",
        "code_ciudad": 57
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Ta' Xbiex",
        "code_ciudad": 58
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Tarxien",
        "code_ciudad": 59
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Valletta",
        "code_ciudad": 60
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Xaghra",
        "code_ciudad": 61
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Xewkija",
        "code_ciudad": 62
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Xghajra",
        "code_ciudad": 63
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Zabbar",
        "code_ciudad": 64
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Zebbug Gozo",
        "code_ciudad": 65
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Zejtun",
        "code_ciudad": 67
    },
    {
        "pais": "Malta",
        "code_pais": "MT",
        "ciudad": "Zurrieq",
        "code_ciudad": 68
    },
    {
        "pais": "Mauricio",
        "code_pais": "MU",
        "ciudad": "Black River",
        "code_ciudad": "BL"
    },
    {
        "pais": "Mauricio",
        "code_pais": "MU",
        "ciudad": "Flacq",
        "code_ciudad": "FL"
    },
    {
        "pais": "Mauricio",
        "code_pais": "MU",
        "ciudad": "Grand Port",
        "code_ciudad": "GP"
    },
    {
        "pais": "Mauricio",
        "code_pais": "MU",
        "ciudad": "Moka",
        "code_ciudad": "MO"
    },
    {
        "pais": "Mauricio",
        "code_pais": "MU",
        "ciudad": "Pamplemousses",
        "code_ciudad": "PA"
    },
    {
        "pais": "Mauricio",
        "code_pais": "MU",
        "ciudad": "Port Louis",
        "code_ciudad": "PL"
    },
    {
        "pais": "Mauricio",
        "code_pais": "MU",
        "ciudad": "Plaines Wilhems",
        "code_ciudad": "PW"
    },
    {
        "pais": "Mauricio",
        "code_pais": "MU",
        "ciudad": "Rodrigues Islands",
        "code_ciudad": "RO"
    },
    {
        "pais": "Mauricio",
        "code_pais": "MU",
        "ciudad": "Riviere du Rempart",
        "code_ciudad": "RR"
    },
    {
        "pais": "Mauricio",
        "code_pais": "MU",
        "ciudad": "Savanne",
        "code_ciudad": "SA"
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Seenu",
        "code_ciudad": "01"
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Alifu Alifu",
        "code_ciudad": "02"
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Vaavu",
        "code_ciudad": "04"
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Laamu",
        "code_ciudad": "05"
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Haa Alifu",
        "code_ciudad": "07"
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Meemu",
        "code_ciudad": 12
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Raa",
        "code_ciudad": 13
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Dhaalu",
        "code_ciudad": 17
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Baa",
        "code_ciudad": 20
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Haa Dhaalu",
        "code_ciudad": 23
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Noonu",
        "code_ciudad": 25
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Kaafu",
        "code_ciudad": 26
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Gaafu Dhaalu",
        "code_ciudad": 28
    },
    {
        "pais": "Maldivas",
        "code_pais": "MV",
        "ciudad": "Maale",
        "code_ciudad": "MLE"
    },
    {
        "pais": "Malaui",
        "code_pais": "MW",
        "ciudad": "Balaka",
        "code_ciudad": "BA"
    },
    {
        "pais": "Malaui",
        "code_pais": "MW",
        "ciudad": "Blantyre",
        "code_ciudad": "BL"
    },
    {
        "pais": "Malaui",
        "code_pais": "MW",
        "ciudad": "Dowa",
        "code_ciudad": "DO"
    },
    {
        "pais": "Malaui",
        "code_pais": "MW",
        "ciudad": "Lilongwe",
        "code_ciudad": "LI"
    },
    {
        "pais": "Malaui",
        "code_pais": "MW",
        "ciudad": "Mangochi",
        "code_ciudad": "MG"
    },
    {
        "pais": "Malaui",
        "code_pais": "MW",
        "ciudad": "Machinga",
        "code_ciudad": "MH"
    },
    {
        "pais": "Malaui",
        "code_pais": "MW",
        "ciudad": "Mzimba",
        "code_ciudad": "MZ"
    },
    {
        "pais": "Malaui",
        "code_pais": "MW",
        "ciudad": "Ntchisi",
        "code_ciudad": "NI"
    },
    {
        "pais": "Malaui",
        "code_pais": "MW",
        "ciudad": "Salima",
        "code_ciudad": "SA"
    },
    {
        "pais": "Malaui",
        "code_pais": "MW",
        "ciudad": "Zomba",
        "code_ciudad": "ZO"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Aguascalientes",
        "code_ciudad": "AGU"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Baja California",
        "code_ciudad": "BCN"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Baja California Sur",
        "code_ciudad": "BCS"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Campeche",
        "code_ciudad": "CAM"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Chihuahua",
        "code_ciudad": "CHH"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Chiapas",
        "code_ciudad": "CHP"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Ciudad de Mexico",
        "code_ciudad": "CMX"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Coahuila de Zaragoza",
        "code_ciudad": "COA"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Colima",
        "code_ciudad": "COL"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Durango",
        "code_ciudad": "DUR"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Guerrero",
        "code_ciudad": "GRO"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Guanajuato",
        "code_ciudad": "GUA"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Hidalgo",
        "code_ciudad": "HID"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Jalisco",
        "code_ciudad": "JAL"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Mexico",
        "code_ciudad": "MEX"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Michoacan de Ocampo",
        "code_ciudad": "MIC"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Morelos",
        "code_ciudad": "MOR"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Nayarit",
        "code_ciudad": "NAY"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Nuevo Leon",
        "code_ciudad": "NLE"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Oaxaca",
        "code_ciudad": "OAX"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Puebla",
        "code_ciudad": "PUE"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Queretaro",
        "code_ciudad": "QUE"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Quintana Roo",
        "code_ciudad": "ROO"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Sinaloa",
        "code_ciudad": "SIN"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "San Luis Potosi",
        "code_ciudad": "SLP"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Sonora",
        "code_ciudad": "SON"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Tabasco",
        "code_ciudad": "TAB"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Tamaulipas",
        "code_ciudad": "TAM"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Tlaxcala",
        "code_ciudad": "TLA"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Veracruz de Ignacio de la Llave",
        "code_ciudad": "VER"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Yucatan",
        "code_ciudad": "YUC"
    },
    {
        "pais": "México",
        "code_pais": "MX",
        "ciudad": "Zacatecas",
        "code_ciudad": "ZAC"
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Johor",
        "code_ciudad": "01"
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Kedah",
        "code_ciudad": "02"
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Kelantan",
        "code_ciudad": "03"
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Melaka",
        "code_ciudad": "04"
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Negeri Sembilan",
        "code_ciudad": "05"
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Pahang",
        "code_ciudad": "06"
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Pulau Pinang",
        "code_ciudad": "07"
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Perak",
        "code_ciudad": "08"
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Perlis",
        "code_ciudad": "09"
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Selangor",
        "code_ciudad": 10
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Terengganu",
        "code_ciudad": 11
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Sabah",
        "code_ciudad": 12
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Sarawak",
        "code_ciudad": 13
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Wilayah Persekutuan Kuala Lumpur",
        "code_ciudad": 14
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Wilayah Persekutuan Labuan",
        "code_ciudad": 15
    },
    {
        "pais": "Malasia",
        "code_pais": "MY",
        "ciudad": "Wilayah Persekutuan Putrajaya",
        "code_ciudad": 16
    },
    {
        "pais": "Mozambique",
        "code_pais": "MZ",
        "ciudad": "Niassa",
        "code_ciudad": "A"
    },
    {
        "pais": "Mozambique",
        "code_pais": "MZ",
        "ciudad": "Manica",
        "code_ciudad": "B"
    },
    {
        "pais": "Mozambique",
        "code_pais": "MZ",
        "ciudad": "Gaza",
        "code_ciudad": "G"
    },
    {
        "pais": "Mozambique",
        "code_pais": "MZ",
        "ciudad": "Inhambane",
        "code_ciudad": "I"
    },
    {
        "pais": "Mozambique",
        "code_pais": "MZ",
        "ciudad": "Maputo",
        "code_ciudad": "L"
    },
    {
        "pais": "Mozambique",
        "code_pais": "MZ",
        "ciudad": "Nampula",
        "code_ciudad": "N"
    },
    {
        "pais": "Mozambique",
        "code_pais": "MZ",
        "ciudad": "Cabo Delgado",
        "code_ciudad": "P"
    },
    {
        "pais": "Mozambique",
        "code_pais": "MZ",
        "ciudad": "Zambezia",
        "code_ciudad": "Q"
    },
    {
        "pais": "Mozambique",
        "code_pais": "MZ",
        "ciudad": "Sofala",
        "code_ciudad": "S"
    },
    {
        "pais": "Mozambique",
        "code_pais": "MZ",
        "ciudad": "Tete",
        "code_ciudad": "T"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Zambezi",
        "code_ciudad": "CA"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Erongo",
        "code_ciudad": "ER"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Hardap",
        "code_ciudad": "HA"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Karas",
        "code_ciudad": "KA"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Kavango East",
        "code_ciudad": "KE"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Khomas",
        "code_ciudad": "KH"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Kunene",
        "code_ciudad": "KU"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Otjozondjupa",
        "code_ciudad": "OD"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Omaheke",
        "code_ciudad": "OH"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Oshana",
        "code_ciudad": "ON"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Omusati",
        "code_ciudad": "OS"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Oshikoto",
        "code_ciudad": "OT"
    },
    {
        "pais": "Namibia",
        "code_pais": "NA",
        "ciudad": "Ohangwena",
        "code_ciudad": "OW"
    },
    {
        "pais": "Níger",
        "code_pais": "NE",
        "ciudad": "Agadez",
        "code_ciudad": 1
    },
    {
        "pais": "Níger",
        "code_pais": "NE",
        "ciudad": "Diffa",
        "code_ciudad": 2
    },
    {
        "pais": "Níger",
        "code_pais": "NE",
        "ciudad": "Dosso",
        "code_ciudad": 3
    },
    {
        "pais": "Níger",
        "code_pais": "NE",
        "ciudad": "Tahoua",
        "code_ciudad": 5
    },
    {
        "pais": "Níger",
        "code_pais": "NE",
        "ciudad": "Tillaberi",
        "code_ciudad": 6
    },
    {
        "pais": "Níger",
        "code_pais": "NE",
        "ciudad": "Zinder",
        "code_ciudad": 7
    },
    {
        "pais": "Níger",
        "code_pais": "NE",
        "ciudad": "Niamey",
        "code_ciudad": 8
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Abia",
        "code_ciudad": "AB"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Adamawa",
        "code_ciudad": "AD"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Akwa Ibom",
        "code_ciudad": "AK"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Anambra",
        "code_ciudad": "AN"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Bauchi",
        "code_ciudad": "BA"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Benue",
        "code_ciudad": "BE"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Borno",
        "code_ciudad": "BO"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Bayelsa",
        "code_ciudad": "BY"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Cross River",
        "code_ciudad": "CR"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Delta",
        "code_ciudad": "DE"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Ebonyi",
        "code_ciudad": "EB"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Edo",
        "code_ciudad": "ED"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Ekiti",
        "code_ciudad": "EK"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Enugu",
        "code_ciudad": "EN"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Abuja Federal Capital Territory",
        "code_ciudad": "FC"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Gombe",
        "code_ciudad": "GO"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Imo",
        "code_ciudad": "IM"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Jigawa",
        "code_ciudad": "JI"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Kaduna",
        "code_ciudad": "KD"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Kebbi",
        "code_ciudad": "KE"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Kano",
        "code_ciudad": "KN"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Kogi",
        "code_ciudad": "KO"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Katsina",
        "code_ciudad": "KT"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Kwara",
        "code_ciudad": "KW"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Lagos",
        "code_ciudad": "LA"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Nasarawa",
        "code_ciudad": "NA"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Niger",
        "code_ciudad": "NI"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Ogun",
        "code_ciudad": "OG"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Ondo",
        "code_ciudad": "ON"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Osun",
        "code_ciudad": "OS"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Oyo",
        "code_ciudad": "OY"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Plateau",
        "code_ciudad": "PL"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Rivers",
        "code_ciudad": "RI"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Sokoto",
        "code_ciudad": "SO"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Taraba",
        "code_ciudad": "TA"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Yobe",
        "code_ciudad": "YO"
    },
    {
        "pais": "Nigeria",
        "code_pais": "NG",
        "ciudad": "Zamfara",
        "code_ciudad": "ZA"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Costa Caribe Norte",
        "code_ciudad": "AN"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Costa Caribe Sur",
        "code_ciudad": "AS"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Boaco",
        "code_ciudad": "BO"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Carazo",
        "code_ciudad": "CA"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Chinandega",
        "code_ciudad": "CI"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Chontales",
        "code_ciudad": "CO"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Esteli",
        "code_ciudad": "ES"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Granada",
        "code_ciudad": "GR"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Jinotega",
        "code_ciudad": "JI"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Leon",
        "code_ciudad": "LE"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Madriz",
        "code_ciudad": "MD"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Managua",
        "code_ciudad": "MN"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Masaya",
        "code_ciudad": "MS"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Matagalpa",
        "code_ciudad": "MT"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Nueva Segovia",
        "code_ciudad": "NS"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Rivas",
        "code_ciudad": "RI"
    },
    {
        "pais": "Nicaragua",
        "code_pais": "NI",
        "ciudad": "Rio San Juan",
        "code_ciudad": "SJ"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Drenthe",
        "code_ciudad": "DR"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Flevoland",
        "code_ciudad": "FL"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Fryslan",
        "code_ciudad": "FR"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Gelderland",
        "code_ciudad": "GE"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Groningen",
        "code_ciudad": "GR"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Limburg",
        "code_ciudad": "LI"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Noord-Brabant",
        "code_ciudad": "NB"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Noord-Holland",
        "code_ciudad": "NH"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Overijssel",
        "code_ciudad": "OV"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Utrecht",
        "code_ciudad": "UT"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Zeeland",
        "code_ciudad": "ZE"
    },
    {
        "pais": "Países Bajos nota 4​",
        "code_pais": "NL",
        "ciudad": "Zuid-Holland",
        "code_ciudad": "ZH"
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Ostfold",
        "code_ciudad": "01"
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Akershus",
        "code_ciudad": "02"
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Oslo",
        "code_ciudad": "03"
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Hedmark",
        "code_ciudad": "04"
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Oppland",
        "code_ciudad": "05"
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Buskerud",
        "code_ciudad": "06"
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Vestfold",
        "code_ciudad": "07"
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Telemark",
        "code_ciudad": "08"
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Aust-Agder",
        "code_ciudad": "09"
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Vest-Agder",
        "code_ciudad": 10
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Rogaland",
        "code_ciudad": 11
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Hordaland",
        "code_ciudad": 12
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Sogn og Fjordane",
        "code_ciudad": 14
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "More og Romsdal",
        "code_ciudad": 15
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Sor-Trondelag",
        "code_ciudad": 16
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Nord-Trondelag",
        "code_ciudad": 17
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Nordland",
        "code_ciudad": 18
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Troms",
        "code_ciudad": 19
    },
    {
        "pais": "Noruega",
        "code_pais": "NO",
        "ciudad": "Finnmark",
        "code_ciudad": 20
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Bagmati",
        "code_ciudad": "BA"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Bheri",
        "code_ciudad": "BH"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Dhawalagiri",
        "code_ciudad": "DH"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Gandaki",
        "code_ciudad": "GA"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Janakpur",
        "code_ciudad": "JA"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Kosi",
        "code_ciudad": "KO"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Lumbini",
        "code_ciudad": "LU"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Mahakali",
        "code_ciudad": "MA"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Mechi",
        "code_ciudad": "ME"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Narayani",
        "code_ciudad": "NA"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Rapti",
        "code_ciudad": "RA"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Sagarmatha",
        "code_ciudad": "SA"
    },
    {
        "pais": "Nepal",
        "code_pais": "NP",
        "ciudad": "Seti",
        "code_ciudad": "SE"
    },
    {
        "pais": "Nauru",
        "code_pais": "NR",
        "ciudad": "Yaren",
        "code_ciudad": 14
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Auckland",
        "code_ciudad": "AUK"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Bay of Plenty",
        "code_ciudad": "BOP"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Canterbury",
        "code_ciudad": "CAN"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Chatham Islands Territory",
        "code_ciudad": "CIT"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Gisborne",
        "code_ciudad": "GIS"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Hawke's Bay",
        "code_ciudad": "HKB"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Marlborough",
        "code_ciudad": "MBH"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Manawatu-Wanganui",
        "code_ciudad": "MWT"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Nelson",
        "code_ciudad": "NSN"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Northland",
        "code_ciudad": "NTL"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Otago",
        "code_ciudad": "OTA"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Southland",
        "code_ciudad": "STL"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Tasman",
        "code_ciudad": "TAS"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Taranaki",
        "code_ciudad": "TKI"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Wellington",
        "code_ciudad": "WGN"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "Waikato",
        "code_ciudad": "WKO"
    },
    {
        "pais": "Nueva Zelanda",
        "code_pais": "NZ",
        "ciudad": "West Coast",
        "code_ciudad": "WTC"
    },
    {
        "pais": "Omán",
        "code_pais": "OM",
        "ciudad": "Janub al Batinah",
        "code_ciudad": "BJ"
    },
    {
        "pais": "Omán",
        "code_pais": "OM",
        "ciudad": "Shamal al Batinah",
        "code_ciudad": "BS"
    },
    {
        "pais": "Omán",
        "code_pais": "OM",
        "ciudad": "Al Buraymi",
        "code_ciudad": "BU"
    },
    {
        "pais": "Omán",
        "code_pais": "OM",
        "ciudad": "Ad Dakhiliyah",
        "code_ciudad": "DA"
    },
    {
        "pais": "Omán",
        "code_pais": "OM",
        "ciudad": "Masqat",
        "code_ciudad": "MA"
    },
    {
        "pais": "Omán",
        "code_pais": "OM",
        "ciudad": "Musandam",
        "code_ciudad": "MU"
    },
    {
        "pais": "Omán",
        "code_pais": "OM",
        "ciudad": "Janub ash Sharqiyah",
        "code_ciudad": "SJ"
    },
    {
        "pais": "Omán",
        "code_pais": "OM",
        "ciudad": "Shamal ash Sharqiyah",
        "code_ciudad": "SS"
    },
    {
        "pais": "Omán",
        "code_pais": "OM",
        "ciudad": "Al Wusta",
        "code_ciudad": "WU"
    },
    {
        "pais": "Omán",
        "code_pais": "OM",
        "ciudad": "Az Zahirah",
        "code_ciudad": "ZA"
    },
    {
        "pais": "Omán",
        "code_pais": "OM",
        "ciudad": "Zufar",
        "code_ciudad": "ZU"
    },
    {
        "pais": "Panamá",
        "code_pais": "PA",
        "ciudad": "Bocas del Toro",
        "code_ciudad": 1
    },
    {
        "pais": "Panamá",
        "code_pais": "PA",
        "ciudad": "Cocle",
        "code_ciudad": 2
    },
    {
        "pais": "Panamá",
        "code_pais": "PA",
        "ciudad": "Colon",
        "code_ciudad": 3
    },
    {
        "pais": "Panamá",
        "code_pais": "PA",
        "ciudad": "Chiriqui",
        "code_ciudad": 4
    },
    {
        "pais": "Panamá",
        "code_pais": "PA",
        "ciudad": "Herrera",
        "code_ciudad": 6
    },
    {
        "pais": "Panamá",
        "code_pais": "PA",
        "ciudad": "Los Santos",
        "code_ciudad": 7
    },
    {
        "pais": "Panamá",
        "code_pais": "PA",
        "ciudad": "Panama",
        "code_ciudad": 8
    },
    {
        "pais": "Panamá",
        "code_pais": "PA",
        "ciudad": "Veraguas",
        "code_ciudad": 9
    },
    {
        "pais": "Panamá",
        "code_pais": "PA",
        "ciudad": "Ngobe-Bugle",
        "code_ciudad": "NB"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Amazonas",
        "code_ciudad": "AMA"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Ancash",
        "code_ciudad": "ANC"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Apurimac",
        "code_ciudad": "APU"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Arequipa",
        "code_ciudad": "ARE"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Ayacucho",
        "code_ciudad": "AYA"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Cajamarca",
        "code_ciudad": "CAJ"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "El Callao",
        "code_ciudad": "CAL"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Cusco",
        "code_ciudad": "CUS"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Huanuco",
        "code_ciudad": "HUC"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Huancavelica",
        "code_ciudad": "HUV"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Ica",
        "code_ciudad": "ICA"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Junin",
        "code_ciudad": "JUN"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "La Libertad",
        "code_ciudad": "LAL"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Lambayeque",
        "code_ciudad": "LAM"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Lima",
        "code_ciudad": "LIM"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Loreto",
        "code_ciudad": "LOR"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Madre de Dios",
        "code_ciudad": "MDD"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Moquegua",
        "code_ciudad": "MOQ"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Pasco",
        "code_ciudad": "PAS"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Piura",
        "code_ciudad": "PIU"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Puno",
        "code_ciudad": "PUN"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "San Martin",
        "code_ciudad": "SAM"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Tacna",
        "code_ciudad": "TAC"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Tumbes",
        "code_ciudad": "TUM"
    },
    {
        "pais": "Perú",
        "code_pais": "PE",
        "ciudad": "Ucayali",
        "code_ciudad": "UCA"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "Chimbu",
        "code_ciudad": "CPK"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "Central",
        "code_ciudad": "CPM"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "East New Britain",
        "code_ciudad": "EBR"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "Eastern Highlands",
        "code_ciudad": "EHG"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "East Sepik",
        "code_ciudad": "ESW"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "Milne Bay",
        "code_ciudad": "MBA"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "Morobe",
        "code_ciudad": "MPL"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "Madang",
        "code_ciudad": "MPM"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "Manus",
        "code_ciudad": "MRL"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "National Capital District (Port Moresby)",
        "code_ciudad": "NCD"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "New Ireland",
        "code_ciudad": "NIK"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "Bougainville",
        "code_ciudad": "NSB"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "West Sepik",
        "code_ciudad": "SAN"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "Southern Highlands",
        "code_ciudad": "SHM"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "West New Britain",
        "code_ciudad": "WBK"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "Western Highlands",
        "code_ciudad": "WHM"
    },
    {
        "pais": "Papúa Nueva Guinea",
        "code_pais": "PG",
        "ciudad": "Western",
        "code_ciudad": "WPD"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "National Capital Region",
        "code_ciudad": "00"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Abra",
        "code_ciudad": "ABR"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Agusan del Norte",
        "code_ciudad": "AGN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Agusan del Sur",
        "code_ciudad": "AGS"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Aklan",
        "code_ciudad": "AKL"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Albay",
        "code_ciudad": "ALB"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Antique",
        "code_ciudad": "ANT"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Apayao",
        "code_ciudad": "APA"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Aurora",
        "code_ciudad": "AUR"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Bataan",
        "code_ciudad": "BAN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Basilan",
        "code_ciudad": "BAS"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Benguet",
        "code_ciudad": "BEN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Biliran",
        "code_ciudad": "BIL"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Bohol",
        "code_ciudad": "BOH"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Batangas",
        "code_ciudad": "BTG"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Batanes",
        "code_ciudad": "BTN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Bukidnon",
        "code_ciudad": "BUK"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Bulacan",
        "code_ciudad": "BUL"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Cagayan",
        "code_ciudad": "CAG"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Camiguin",
        "code_ciudad": "CAM"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Camarines Norte",
        "code_ciudad": "CAN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Capiz",
        "code_ciudad": "CAP"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Camarines Sur",
        "code_ciudad": "CAS"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Catanduanes",
        "code_ciudad": "CAT"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Cavite",
        "code_ciudad": "CAV"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Cebu",
        "code_ciudad": "CEB"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Compostela Valley",
        "code_ciudad": "COM"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Davao Oriental",
        "code_ciudad": "DAO"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Davao del Sur",
        "code_ciudad": "DAS"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Davao del Norte",
        "code_ciudad": "DAV"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Dinagat Islands",
        "code_ciudad": "DIN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Eastern Samar",
        "code_ciudad": "EAS"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Guimaras",
        "code_ciudad": "GUI"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Ifugao",
        "code_ciudad": "IFU"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Iloilo",
        "code_ciudad": "ILI"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Ilocos Norte",
        "code_ciudad": "ILN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Ilocos Sur",
        "code_ciudad": "ILS"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Isabela",
        "code_ciudad": "ISA"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Kalinga",
        "code_ciudad": "KAL"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Laguna",
        "code_ciudad": "LAG"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Lanao del Norte",
        "code_ciudad": "LAN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Lanao del Sur",
        "code_ciudad": "LAS"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Leyte",
        "code_ciudad": "LEY"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "La Union",
        "code_ciudad": "LUN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Marinduque",
        "code_ciudad": "MAD"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Maguindanao",
        "code_ciudad": "MAG"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Masbate",
        "code_ciudad": "MAS"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Mindoro Occidental",
        "code_ciudad": "MDC"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Mindoro Oriental",
        "code_ciudad": "MDR"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Mountain Province",
        "code_ciudad": "MOU"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Misamis Occidental",
        "code_ciudad": "MSC"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Misamis Oriental",
        "code_ciudad": "MSR"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Cotabato",
        "code_ciudad": "NCO"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Negros Occidental",
        "code_ciudad": "NEC"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Negros Oriental",
        "code_ciudad": "NER"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Northern Samar",
        "code_ciudad": "NSA"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Nueva Ecija",
        "code_ciudad": "NUE"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Nueva Vizcaya",
        "code_ciudad": "NUV"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Pampanga",
        "code_ciudad": "PAM"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Pangasinan",
        "code_ciudad": "PAN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Palawan",
        "code_ciudad": "PLW"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Quezon",
        "code_ciudad": "QUE"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Quirino",
        "code_ciudad": "QUI"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Rizal",
        "code_ciudad": "RIZ"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Romblon",
        "code_ciudad": "ROM"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Sarangani",
        "code_ciudad": "SAR"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "South Cotabato",
        "code_ciudad": "SCO"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Siquijor",
        "code_ciudad": "SIG"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Southern Leyte",
        "code_ciudad": "SLE"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Sulu",
        "code_ciudad": "SLU"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Sorsogon",
        "code_ciudad": "SOR"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Sultan Kudarat",
        "code_ciudad": "SUK"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Surigao del Norte",
        "code_ciudad": "SUN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Surigao del Sur",
        "code_ciudad": "SUR"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Tarlac",
        "code_ciudad": "TAR"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Tawi-Tawi",
        "code_ciudad": "TAW"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Samar",
        "code_ciudad": "WSA"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Zamboanga del Norte",
        "code_ciudad": "ZAN"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Zamboanga del Sur",
        "code_ciudad": "ZAS"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Zambales",
        "code_ciudad": "ZMB"
    },
    {
        "pais": "Filipinas",
        "code_pais": "PH",
        "ciudad": "Zamboanga Sibugay",
        "code_ciudad": "ZSI"
    },
    {
        "pais": "Pakistán",
        "code_pais": "PK",
        "ciudad": "Balochistan",
        "code_ciudad": "BA"
    },
    {
        "pais": "Pakistán",
        "code_pais": "PK",
        "ciudad": "Gilgit-Baltistan",
        "code_ciudad": "GB"
    },
    {
        "pais": "Pakistán",
        "code_pais": "PK",
        "ciudad": "Islamabad",
        "code_ciudad": "IS"
    },
    {
        "pais": "Pakistán",
        "code_pais": "PK",
        "ciudad": "Azad Jammu and Kashmir",
        "code_ciudad": "JK"
    },
    {
        "pais": "Pakistán",
        "code_pais": "PK",
        "ciudad": "Khyber Pakhtunkhwa",
        "code_ciudad": "KP"
    },
    {
        "pais": "Pakistán",
        "code_pais": "PK",
        "ciudad": "Punjab",
        "code_ciudad": "PB"
    },
    {
        "pais": "Pakistán",
        "code_pais": "PK",
        "ciudad": "Sindh",
        "code_ciudad": "SD"
    },
    {
        "pais": "Pakistán",
        "code_pais": "PK",
        "ciudad": "Federally Administered Tribal Areas",
        "code_ciudad": "TA"
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Dolnoslaskie",
        "code_ciudad": "02"
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Kujawsko-pomorskie",
        "code_ciudad": "04"
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Lubelskie",
        "code_ciudad": "06"
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Lubuskie",
        "code_ciudad": "08"
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Lodzkie",
        "code_ciudad": 10
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Malopolskie",
        "code_ciudad": 12
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Mazowieckie",
        "code_ciudad": 14
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Opolskie",
        "code_ciudad": 16
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Podkarpackie",
        "code_ciudad": 18
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Podlaskie",
        "code_ciudad": 20
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Pomorskie",
        "code_ciudad": 22
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Slaskie",
        "code_ciudad": 24
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Swietokrzyskie",
        "code_ciudad": 26
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Warminsko-mazurskie",
        "code_ciudad": 28
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Wielkopolskie",
        "code_ciudad": 30
    },
    {
        "pais": "Polonia",
        "code_pais": "PL",
        "ciudad": "Zachodniopomorskie",
        "code_ciudad": 32
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Bethlehem",
        "code_ciudad": "BTH"
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Gaza",
        "code_ciudad": "GZA"
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Hebron",
        "code_ciudad": "HBN"
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Jerusalem",
        "code_ciudad": "JEM"
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Jenin",
        "code_ciudad": "JEN"
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Jericho and Al Aghwar",
        "code_ciudad": "JRH"
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Nablus",
        "code_ciudad": "NBS"
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Qalqilya",
        "code_ciudad": "QQA"
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Ramallah",
        "code_ciudad": "RBH"
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Salfit",
        "code_ciudad": "SLT"
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Tubas",
        "code_ciudad": "TBS"
    },
    {
        "pais": "Palestina, Estado de",
        "code_pais": "PS",
        "ciudad": "Tulkarm",
        "code_ciudad": "TKM"
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Aveiro",
        "code_ciudad": "01"
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Beja",
        "code_ciudad": "02"
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Braga",
        "code_ciudad": "03"
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Braganca",
        "code_ciudad": "04"
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Castelo Branco",
        "code_ciudad": "05"
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Coimbra",
        "code_ciudad": "06"
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Evora",
        "code_ciudad": "07"
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Faro",
        "code_ciudad": "08"
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Guarda",
        "code_ciudad": "09"
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Leiria",
        "code_ciudad": 10
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Lisboa",
        "code_ciudad": 11
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Portalegre",
        "code_ciudad": 12
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Porto",
        "code_ciudad": 13
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Santarem",
        "code_ciudad": 14
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Setubal",
        "code_ciudad": 15
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Viana do Castelo",
        "code_ciudad": 16
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Vila Real",
        "code_ciudad": 17
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Viseu",
        "code_ciudad": 18
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Regiao Autonoma dos Acores",
        "code_ciudad": 20
    },
    {
        "pais": "Portugal",
        "code_pais": "PT",
        "ciudad": "Regiao Autonoma da Madeira",
        "code_ciudad": 30
    },
    {
        "pais": "Palaos",
        "code_pais": "PW",
        "ciudad": "Airai",
        "code_ciudad": "004"
    },
    {
        "pais": "Palaos",
        "code_pais": "PW",
        "ciudad": "Kayangel",
        "code_ciudad": 100
    },
    {
        "pais": "Palaos",
        "code_pais": "PW",
        "ciudad": "Koror",
        "code_ciudad": 150
    },
    {
        "pais": "Palaos",
        "code_pais": "PW",
        "ciudad": "Melekeok",
        "code_ciudad": 212
    },
    {
        "pais": "Palaos",
        "code_pais": "PW",
        "ciudad": "Ngaraard",
        "code_ciudad": 214
    },
    {
        "pais": "Palaos",
        "code_pais": "PW",
        "ciudad": "Ngardmau",
        "code_ciudad": 222
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Concepcion",
        "code_ciudad": 1
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Alto Parana",
        "code_ciudad": 10
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Central",
        "code_ciudad": 11
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Neembucu",
        "code_ciudad": 12
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Amambay",
        "code_ciudad": 13
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Canindeyu",
        "code_ciudad": 14
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Presidente Hayes",
        "code_ciudad": 15
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Alto Paraguay",
        "code_ciudad": 16
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Boqueron",
        "code_ciudad": 19
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "San Pedro",
        "code_ciudad": 2
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Cordillera",
        "code_ciudad": 3
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Guaira",
        "code_ciudad": 4
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Caaguazu",
        "code_ciudad": 5
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Caazapa",
        "code_ciudad": 6
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Itapua",
        "code_ciudad": 7
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Misiones",
        "code_ciudad": 8
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Paraguari",
        "code_ciudad": 9
    },
    {
        "pais": "Paraguay",
        "code_pais": "PY",
        "ciudad": "Asuncion",
        "code_ciudad": "ASU"
    },
    {
        "pais": "Catar",
        "code_pais": "QA",
        "ciudad": "Ad Dawhah",
        "code_ciudad": "DA"
    },
    {
        "pais": "Catar",
        "code_pais": "QA",
        "ciudad": "Al Khawr wa adh Dhakhirah",
        "code_ciudad": "KH"
    },
    {
        "pais": "Catar",
        "code_pais": "QA",
        "ciudad": "Ash Shamal",
        "code_ciudad": "MS"
    },
    {
        "pais": "Catar",
        "code_pais": "QA",
        "ciudad": "Ar Rayyan",
        "code_ciudad": "RA"
    },
    {
        "pais": "Catar",
        "code_pais": "QA",
        "ciudad": "Umm Salal",
        "code_ciudad": "US"
    },
    {
        "pais": "Catar",
        "code_pais": "QA",
        "ciudad": "Al Wakrah",
        "code_ciudad": "WA"
    },
    {
        "pais": "Catar",
        "code_pais": "QA",
        "ciudad": "Az Za'ayin",
        "code_ciudad": "ZA"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Alba",
        "code_ciudad": "AB"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Arges",
        "code_ciudad": "AG"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Arad",
        "code_ciudad": "AR"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Bucuresti",
        "code_ciudad": "B"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Bacau",
        "code_ciudad": "BC"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Bihor",
        "code_ciudad": "BH"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Bistrita-Nasaud",
        "code_ciudad": "BN"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Braila",
        "code_ciudad": "BR"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Botosani",
        "code_ciudad": "BT"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Brasov",
        "code_ciudad": "BV"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Buzau",
        "code_ciudad": "BZ"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Cluj",
        "code_ciudad": "CJ"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Calarasi",
        "code_ciudad": "CL"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Caras-Severin",
        "code_ciudad": "CS"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Constanta",
        "code_ciudad": "CT"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Covasna",
        "code_ciudad": "CV"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Dambovita",
        "code_ciudad": "DB"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Dolj",
        "code_ciudad": "DJ"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Gorj",
        "code_ciudad": "GJ"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Galati",
        "code_ciudad": "GL"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Giurgiu",
        "code_ciudad": "GR"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Hunedoara",
        "code_ciudad": "HD"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Harghita",
        "code_ciudad": "HR"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Ilfov",
        "code_ciudad": "IF"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Ialomita",
        "code_ciudad": "IL"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Iasi",
        "code_ciudad": "IS"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Mehedinti",
        "code_ciudad": "MH"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Maramures",
        "code_ciudad": "MM"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Mures",
        "code_ciudad": "MS"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Neamt",
        "code_ciudad": "NT"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Olt",
        "code_ciudad": "OT"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Prahova",
        "code_ciudad": "PH"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Sibiu",
        "code_ciudad": "SB"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Salaj",
        "code_ciudad": "SJ"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Satu Mare",
        "code_ciudad": "SM"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Suceava",
        "code_ciudad": "SV"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Tulcea",
        "code_ciudad": "TL"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Timis",
        "code_ciudad": "TM"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Teleorman",
        "code_ciudad": "TR"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Valcea",
        "code_ciudad": "VL"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Vrancea",
        "code_ciudad": "VN"
    },
    {
        "pais": "Rumania",
        "code_pais": "RO",
        "ciudad": "Vaslui",
        "code_ciudad": "VS"
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Beograd",
        "code_ciudad": "00"
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Severnobacki okrug",
        "code_ciudad": "01"
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Srednjebanatski okrug",
        "code_ciudad": "02"
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Severnobanatski okrug",
        "code_ciudad": "03"
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Juznobanatski okrug",
        "code_ciudad": "04"
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Zapadnobacki okrug",
        "code_ciudad": "05"
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Juznobacki okrug",
        "code_ciudad": "06"
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Sremski okrug",
        "code_ciudad": "07"
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Macvanski okrug",
        "code_ciudad": "08"
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Kolubarski okrug",
        "code_ciudad": "09"
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Podunavski okrug",
        "code_ciudad": 10
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Branicevski okrug",
        "code_ciudad": 11
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Sumadijski okrug",
        "code_ciudad": 12
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Pomoravski okrug",
        "code_ciudad": 13
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Borski okrug",
        "code_ciudad": 14
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Zajecarski okrug",
        "code_ciudad": 15
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Zlatiborski okrug",
        "code_ciudad": 16
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Moravicki okrug",
        "code_ciudad": 17
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Raski okrug",
        "code_ciudad": 18
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Rasinski okrug",
        "code_ciudad": 19
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Nisavski okrug",
        "code_ciudad": 20
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Toplicki okrug",
        "code_ciudad": 21
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Pirotski okrug",
        "code_ciudad": 22
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Jablanicki okrug",
        "code_ciudad": 23
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Pcinjski okrug",
        "code_ciudad": 24
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Pecki okrug",
        "code_ciudad": 26
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Prizrenski okrug",
        "code_ciudad": 27
    },
    {
        "pais": "Serbia",
        "code_pais": "RS",
        "ciudad": "Kosovsko-Mitrovacki okrug",
        "code_ciudad": 28
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Adygeya, Respublika",
        "code_ciudad": "AD"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Altay, Respublika",
        "code_ciudad": "AL"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Altayskiy kray",
        "code_ciudad": "ALT"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Amurskaya oblast'",
        "code_ciudad": "AMU"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Arkhangel'skaya oblast'",
        "code_ciudad": "ARK"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Astrakhanskaya oblast'",
        "code_ciudad": "AST"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Bashkortostan, Respublika",
        "code_ciudad": "BA"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Belgorodskaya oblast'",
        "code_ciudad": "BEL"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Bryanskaya oblast'",
        "code_ciudad": "BRY"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Buryatiya, Respublika",
        "code_ciudad": "BU"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Chechenskaya Respublika",
        "code_ciudad": "CE"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Chelyabinskaya oblast'",
        "code_ciudad": "CHE"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Chukotskiy avtonomnyy okrug",
        "code_ciudad": "CHU"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Chuvashskaya Respublika",
        "code_ciudad": "CU"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Dagestan, Respublika",
        "code_ciudad": "DA"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Ingushetiya, Respublika",
        "code_ciudad": "IN"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Irkutskaya oblast'",
        "code_ciudad": "IRK"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Ivanovskaya oblast'",
        "code_ciudad": "IVA"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Kamchatskiy kray",
        "code_ciudad": "KAM"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Kabardino-Balkarskaya Respublika",
        "code_ciudad": "KB"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Karachayevo-Cherkesskaya Respublika",
        "code_ciudad": "KC"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Krasnodarskiy kray",
        "code_ciudad": "KDA"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Kemerovskaya oblast'",
        "code_ciudad": "KEM"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Kaliningradskaya oblast'",
        "code_ciudad": "KGD"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Kurganskaya oblast'",
        "code_ciudad": "KGN"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Khabarovskiy kray",
        "code_ciudad": "KHA"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Khanty-Mansiyskiy avtonomnyy okrug",
        "code_ciudad": "KHM"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Kirovskaya oblast'",
        "code_ciudad": "KIR"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Khakasiya, Respublika",
        "code_ciudad": "KK"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Kalmykiya, Respublika",
        "code_ciudad": "KL"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Kaluzhskaya oblast'",
        "code_ciudad": "KLU"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Komi, Respublika",
        "code_ciudad": "KO"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Kostromskaya oblast'",
        "code_ciudad": "KOS"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Kareliya, Respublika",
        "code_ciudad": "KR"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Kurskaya oblast'",
        "code_ciudad": "KRS"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Krasnoyarskiy kray",
        "code_ciudad": "KYA"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Leningradskaya oblast'",
        "code_ciudad": "LEN"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Lipetskaya oblast'",
        "code_ciudad": "LIP"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Magadanskaya oblast'",
        "code_ciudad": "MAG"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Mariy El, Respublika",
        "code_ciudad": "ME"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Mordoviya, Respublika",
        "code_ciudad": "MO"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Moskovskaya oblast'",
        "code_ciudad": "MOS"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Moskva",
        "code_ciudad": "MOW"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Murmanskaya oblast'",
        "code_ciudad": "MUR"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Nenetskiy avtonomnyy okrug",
        "code_ciudad": "NEN"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Novgorodskaya oblast'",
        "code_ciudad": "NGR"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Nizhegorodskaya oblast'",
        "code_ciudad": "NIZ"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Novosibirskaya oblast'",
        "code_ciudad": "NVS"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Omskaya oblast'",
        "code_ciudad": "OMS"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Orenburgskaya oblast'",
        "code_ciudad": "ORE"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Orlovskaya oblast'",
        "code_ciudad": "ORL"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Permskiy kray",
        "code_ciudad": "PER"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Penzenskaya oblast'",
        "code_ciudad": "PNZ"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Primorskiy kray",
        "code_ciudad": "PRI"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Pskovskaya oblast'",
        "code_ciudad": "PSK"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Rostovskaya oblast'",
        "code_ciudad": "ROS"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Ryazanskaya oblast'",
        "code_ciudad": "RYA"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Saha, Respublika",
        "code_ciudad": "SA"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Sakhalinskaya oblast'",
        "code_ciudad": "SAK"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Samarskaya oblast'",
        "code_ciudad": "SAM"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Saratovskaya oblast'",
        "code_ciudad": "SAR"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Severnaya Osetiya, Respublika",
        "code_ciudad": "SE"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Smolenskaya oblast'",
        "code_ciudad": "SMO"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Sankt-Peterburg",
        "code_ciudad": "SPE"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Stavropol'skiy kray",
        "code_ciudad": "STA"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Sverdlovskaya oblast'",
        "code_ciudad": "SVE"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Tatarstan, Respublika",
        "code_ciudad": "TA"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Tambovskaya oblast'",
        "code_ciudad": "TAM"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Tomskaya oblast'",
        "code_ciudad": "TOM"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Tul'skaya oblast'",
        "code_ciudad": "TUL"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Tverskaya oblast'",
        "code_ciudad": "TVE"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Tyva, Respublika",
        "code_ciudad": "TY"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Tyumenskaya oblast'",
        "code_ciudad": "TYU"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Udmurtskaya Respublika",
        "code_ciudad": "UD"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Ul'yanovskaya oblast'",
        "code_ciudad": "ULY"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Volgogradskaya oblast'",
        "code_ciudad": "VGG"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Vladimirskaya oblast'",
        "code_ciudad": "VLA"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Vologodskaya oblast'",
        "code_ciudad": "VLG"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Voronezhskaya oblast'",
        "code_ciudad": "VOR"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Yamalo-Nenetskiy avtonomnyy okrug",
        "code_ciudad": "YAN"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Yaroslavskaya oblast'",
        "code_ciudad": "YAR"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Yevreyskaya avtonomnaya oblast'",
        "code_ciudad": "YEV"
    },
    {
        "pais": "Rusia",
        "code_pais": "RU",
        "ciudad": "Zabaykal'skiy kray",
        "code_ciudad": "ZAB"
    },
    {
        "pais": "Ruanda",
        "code_pais": "RW",
        "ciudad": "Ville de Kigali",
        "code_ciudad": "01"
    },
    {
        "pais": "Ruanda",
        "code_pais": "RW",
        "ciudad": "Est",
        "code_ciudad": "02"
    },
    {
        "pais": "Ruanda",
        "code_pais": "RW",
        "ciudad": "Nord",
        "code_ciudad": "03"
    },
    {
        "pais": "Ruanda",
        "code_pais": "RW",
        "ciudad": "Ouest",
        "code_ciudad": "04"
    },
    {
        "pais": "Ruanda",
        "code_pais": "RW",
        "ciudad": "Sud",
        "code_ciudad": "05"
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Ar Riyad",
        "code_ciudad": "01"
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Makkah al Mukarramah",
        "code_ciudad": "02"
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Al Madinah al Munawwarah",
        "code_ciudad": "03"
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Ash Sharqiyah",
        "code_ciudad": "04"
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Al Qasim",
        "code_ciudad": "05"
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Ha'il",
        "code_ciudad": "06"
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Tabuk",
        "code_ciudad": "07"
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Al Hudud ash Shamaliyah",
        "code_ciudad": "08"
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Jazan",
        "code_ciudad": "09"
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Najran",
        "code_ciudad": 10
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Al Bahah",
        "code_ciudad": 11
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "Al Jawf",
        "code_ciudad": 12
    },
    {
        "pais": "Arabia Saudita",
        "code_pais": "SA",
        "ciudad": "'Asir",
        "code_ciudad": 14
    },
    {
        "pais": "Islas Salomón",
        "code_pais": "SB",
        "ciudad": "Choiseul",
        "code_ciudad": "CH"
    },
    {
        "pais": "Islas Salomón",
        "code_pais": "SB",
        "ciudad": "Guadalcanal",
        "code_ciudad": "GU"
    },
    {
        "pais": "Seychelles",
        "code_pais": "SC",
        "ciudad": "Anse aux Pins",
        "code_ciudad": "01"
    },
    {
        "pais": "Seychelles",
        "code_pais": "SC",
        "ciudad": "Anse Boileau",
        "code_ciudad": "02"
    },
    {
        "pais": "Seychelles",
        "code_pais": "SC",
        "ciudad": "Baie Lazare",
        "code_ciudad": "06"
    },
    {
        "pais": "Seychelles",
        "code_pais": "SC",
        "ciudad": "Beau Vallon",
        "code_ciudad": "08"
    },
    {
        "pais": "Seychelles",
        "code_pais": "SC",
        "ciudad": "Bel Ombre",
        "code_ciudad": 10
    },
    {
        "pais": "Seychelles",
        "code_pais": "SC",
        "ciudad": "Cascade",
        "code_ciudad": 11
    },
    {
        "pais": "Seychelles",
        "code_pais": "SC",
        "ciudad": "Grand Anse Mahe",
        "code_ciudad": 13
    },
    {
        "pais": "Seychelles",
        "code_pais": "SC",
        "ciudad": "La Digue",
        "code_ciudad": 15
    },
    {
        "pais": "Seychelles",
        "code_pais": "SC",
        "ciudad": "English River",
        "code_ciudad": 16
    },
    {
        "pais": "Seychelles",
        "code_pais": "SC",
        "ciudad": "Pointe Larue",
        "code_ciudad": 20
    },
    {
        "pais": "Seychelles",
        "code_pais": "SC",
        "ciudad": "Takamaka",
        "code_ciudad": 23
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "North Darfur",
        "code_ciudad": "DN"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "South Darfur",
        "code_ciudad": "DS"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "West Darfur",
        "code_ciudad": "DW"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "Gedaref",
        "code_ciudad": "GD"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "Gezira",
        "code_ciudad": "GZ"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "Kassala",
        "code_ciudad": "KA"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "Khartoum",
        "code_ciudad": "KH"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "North Kordofan",
        "code_ciudad": "KN"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "South Kordofan",
        "code_ciudad": "KS"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "Blue Nile",
        "code_ciudad": "NB"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "Northern",
        "code_ciudad": "NO"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "River Nile",
        "code_ciudad": "NR"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "White Nile",
        "code_ciudad": "NW"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "Red Sea",
        "code_ciudad": "RS"
    },
    {
        "pais": "Sudán",
        "code_pais": "SD",
        "ciudad": "Sennar",
        "code_ciudad": "SI"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Stockholms lan",
        "code_ciudad": "AB"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Vasterbottens lan",
        "code_ciudad": "AC"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Norrbottens lan",
        "code_ciudad": "BD"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Uppsala lan",
        "code_ciudad": "C"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Sodermanlands lan",
        "code_ciudad": "D"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Ostergotlands lan",
        "code_ciudad": "E"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Jonkopings lan",
        "code_ciudad": "F"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Kronobergs lan",
        "code_ciudad": "G"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Kalmar lan",
        "code_ciudad": "H"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Gotlands lan",
        "code_ciudad": "I"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Blekinge lan",
        "code_ciudad": "K"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Skane lan",
        "code_ciudad": "M"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Hallands lan",
        "code_ciudad": "N"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Vastra Gotalands lan",
        "code_ciudad": "O"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Varmlands lan",
        "code_ciudad": "S"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Orebro lan",
        "code_ciudad": "T"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Vastmanlands lan",
        "code_ciudad": "U"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Dalarnas lan",
        "code_ciudad": "W"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Gavleborgs lan",
        "code_ciudad": "X"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Vasternorrlands lan",
        "code_ciudad": "Y"
    },
    {
        "pais": "Suecia",
        "code_pais": "SE",
        "ciudad": "Jamtlands lan",
        "code_ciudad": "Z"
    },
    {
        "pais": "Santa Elena, Ascensión y Tristán de Acuña",
        "code_pais": "SH",
        "ciudad": "Saint Helena",
        "code_ciudad": "HL"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ajdovscina",
        "code_ciudad": "001"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Beltinci",
        "code_ciudad": "002"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Bled",
        "code_ciudad": "003"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Bohinj",
        "code_ciudad": "004"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Borovnica",
        "code_ciudad": "005"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Bovec",
        "code_ciudad": "006"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Brda",
        "code_ciudad": "007"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Brezovica",
        "code_ciudad": "008"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Brezice",
        "code_ciudad": "009"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Tisina",
        "code_ciudad": "010"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Celje",
        "code_ciudad": "011"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Cerklje na Gorenjskem",
        "code_ciudad": "012"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Cerknica",
        "code_ciudad": "013"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Cerkno",
        "code_ciudad": "014"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Crensovci",
        "code_ciudad": "015"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Crnomelj",
        "code_ciudad": "017"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Destrnik",
        "code_ciudad": "018"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Divaca",
        "code_ciudad": "019"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Dobrepolje",
        "code_ciudad": "020"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Dobrova-Polhov Gradec",
        "code_ciudad": "021"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Domzale",
        "code_ciudad": "023"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Dornava",
        "code_ciudad": "024"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Dravograd",
        "code_ciudad": "025"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Duplek",
        "code_ciudad": "026"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Gornja Radgona",
        "code_ciudad": "029"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Gornji Petrovci",
        "code_ciudad": "031"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Grosuplje",
        "code_ciudad": "032"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Salovci",
        "code_ciudad": "033"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Hrastnik",
        "code_ciudad": "034"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Hrpelje-Kozina",
        "code_ciudad": "035"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Idrija",
        "code_ciudad": "036"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ig",
        "code_ciudad": "037"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ilirska Bistrica",
        "code_ciudad": "038"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ivancna Gorica",
        "code_ciudad": "039"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Izola",
        "code_ciudad": "040"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Jesenice",
        "code_ciudad": "041"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Jursinci",
        "code_ciudad": "042"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kamnik",
        "code_ciudad": "043"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kanal",
        "code_ciudad": "044"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kidricevo",
        "code_ciudad": "045"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kobarid",
        "code_ciudad": "046"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kobilje",
        "code_ciudad": "047"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kocevje",
        "code_ciudad": "048"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Komen",
        "code_ciudad": "049"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Koper",
        "code_ciudad": "050"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kranj",
        "code_ciudad": "052"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kranjska Gora",
        "code_ciudad": "053"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Krsko",
        "code_ciudad": "054"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kungota",
        "code_ciudad": "055"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kuzma",
        "code_ciudad": "056"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Lasko",
        "code_ciudad": "057"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Lenart",
        "code_ciudad": "058"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Lendava",
        "code_ciudad": "059"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Litija",
        "code_ciudad": "060"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ljubljana",
        "code_ciudad": "061"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ljutomer",
        "code_ciudad": "063"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Logatec",
        "code_ciudad": "064"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Loska Dolina",
        "code_ciudad": "065"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Luce",
        "code_ciudad": "067"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Lukovica",
        "code_ciudad": "068"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Majsperk",
        "code_ciudad": "069"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Maribor",
        "code_ciudad": "070"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Medvode",
        "code_ciudad": "071"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Menges",
        "code_ciudad": "072"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Metlika",
        "code_ciudad": "073"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Mezica",
        "code_ciudad": "074"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Miren-Kostanjevica",
        "code_ciudad": "075"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Mislinja",
        "code_ciudad": "076"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Moravce",
        "code_ciudad": "077"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Mozirje",
        "code_ciudad": "079"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Murska Sobota",
        "code_ciudad": "080"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Muta",
        "code_ciudad": "081"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Naklo",
        "code_ciudad": "082"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Nazarje",
        "code_ciudad": "083"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Nova Gorica",
        "code_ciudad": "084"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Novo Mesto",
        "code_ciudad": "085"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Odranci",
        "code_ciudad": "086"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ormoz",
        "code_ciudad": "087"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Piran",
        "code_ciudad": "090"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Pivka",
        "code_ciudad": "091"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Podcetrtek",
        "code_ciudad": "092"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Postojna",
        "code_ciudad": "094"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Preddvor",
        "code_ciudad": "095"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ptuj",
        "code_ciudad": "096"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Puconci",
        "code_ciudad": "097"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Race-Fram",
        "code_ciudad": "098"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Radece",
        "code_ciudad": "099"
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Radenci",
        "code_ciudad": 100
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Radlje ob Dravi",
        "code_ciudad": 101
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Radovljica",
        "code_ciudad": 102
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ravne na Koroskem",
        "code_ciudad": 103
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ribnica",
        "code_ciudad": 104
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Rogasovci",
        "code_ciudad": 105
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Rogaska Slatina",
        "code_ciudad": 106
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ruse",
        "code_ciudad": 108
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Semic",
        "code_ciudad": 109
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sevnica",
        "code_ciudad": 110
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sezana",
        "code_ciudad": 111
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Slovenj Gradec",
        "code_ciudad": 112
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Slovenska Bistrica",
        "code_ciudad": 113
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Slovenske Konjice",
        "code_ciudad": 114
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Starse",
        "code_ciudad": 115
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sveti Jurij",
        "code_ciudad": 116
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sencur",
        "code_ciudad": 117
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sentilj",
        "code_ciudad": 118
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sentjernej",
        "code_ciudad": 119
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sentjur",
        "code_ciudad": 120
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Skocjan",
        "code_ciudad": 121
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Skofja Loka",
        "code_ciudad": 122
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Skofljica",
        "code_ciudad": 123
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Smarje pri Jelsah",
        "code_ciudad": 124
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Smartno ob Paki",
        "code_ciudad": 125
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sostanj",
        "code_ciudad": 126
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Store",
        "code_ciudad": 127
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Tolmin",
        "code_ciudad": 128
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Trbovlje",
        "code_ciudad": 129
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Trebnje",
        "code_ciudad": 130
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Trzic",
        "code_ciudad": 131
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Turnisce",
        "code_ciudad": 132
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Velenje",
        "code_ciudad": 133
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Velike Lasce",
        "code_ciudad": 134
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Videm",
        "code_ciudad": 135
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Vipava",
        "code_ciudad": 136
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Vitanje",
        "code_ciudad": 137
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Vodice",
        "code_ciudad": 138
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Vojnik",
        "code_ciudad": 139
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Vrhnika",
        "code_ciudad": 140
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Vuzenica",
        "code_ciudad": 141
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Zagorje ob Savi",
        "code_ciudad": 142
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Zavrc",
        "code_ciudad": 143
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Zrece",
        "code_ciudad": 144
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Zelezniki",
        "code_ciudad": 146
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Ziri",
        "code_ciudad": 147
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Benedikt",
        "code_ciudad": 148
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Bistrica ob Sotli",
        "code_ciudad": 149
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Bloke",
        "code_ciudad": 150
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Braslovce",
        "code_ciudad": 151
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Cankova",
        "code_ciudad": 152
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Dobje",
        "code_ciudad": 154
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Dobrna",
        "code_ciudad": 155
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Dobrovnik",
        "code_ciudad": 156
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Grad",
        "code_ciudad": 158
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Hajdina",
        "code_ciudad": 159
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Hoce-Slivnica",
        "code_ciudad": 160
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Hodos",
        "code_ciudad": 161
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Horjul",
        "code_ciudad": 162
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Komenda",
        "code_ciudad": 164
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kostel",
        "code_ciudad": 165
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Krizevci",
        "code_ciudad": 166
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Lovrenc na Pohorju",
        "code_ciudad": 167
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Markovci",
        "code_ciudad": 168
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Miklavz na Dravskem Polju",
        "code_ciudad": 169
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Mirna Pec",
        "code_ciudad": 170
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Oplotnica",
        "code_ciudad": 171
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Podlehnik",
        "code_ciudad": 172
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Polzela",
        "code_ciudad": 173
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Prebold",
        "code_ciudad": 174
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Prevalje",
        "code_ciudad": 175
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Razkrizje",
        "code_ciudad": 176
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sodrazica",
        "code_ciudad": 179
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Solcava",
        "code_ciudad": 180
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sveti Andraz v Slovenskih Goricah",
        "code_ciudad": 182
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sempeter-Vrtojba",
        "code_ciudad": 183
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Tabor",
        "code_ciudad": 184
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Trnovska Vas",
        "code_ciudad": 185
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Trzin",
        "code_ciudad": 186
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Velika Polana",
        "code_ciudad": 187
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Verzej",
        "code_ciudad": 188
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Vransko",
        "code_ciudad": 189
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Zalec",
        "code_ciudad": 190
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Zetale",
        "code_ciudad": 191
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Zuzemberk",
        "code_ciudad": 193
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Smartno pri Litiji",
        "code_ciudad": 194
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Apace",
        "code_ciudad": 195
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Cirkulane",
        "code_ciudad": 196
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Kosanjevica na Krki",
        "code_ciudad": 197
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Makole",
        "code_ciudad": 198
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Mokronog-Trebelno",
        "code_ciudad": 199
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Poljcane",
        "code_ciudad": 200
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Rence-Vogrsko",
        "code_ciudad": 201
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Straza",
        "code_ciudad": 203
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sveta Trojica v Slovenskih Goricah",
        "code_ciudad": 204
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sveti Tomaz",
        "code_ciudad": 205
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Smarjeske Toplice",
        "code_ciudad": 206
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Gorje",
        "code_ciudad": 207
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Log-Dragomer",
        "code_ciudad": 208
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Recica ob Savinji",
        "code_ciudad": 209
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sveti Jurij v Slovenskih Goricah",
        "code_ciudad": 210
    },
    {
        "pais": "Eslovenia",
        "code_pais": "SI",
        "ciudad": "Sentrupert",
        "code_ciudad": 211
    },
    {
        "pais": "Eslovaquia",
        "code_pais": "SK",
        "ciudad": "Banskobystricky kraj",
        "code_ciudad": "BC"
    },
    {
        "pais": "Eslovaquia",
        "code_pais": "SK",
        "ciudad": "Bratislavsky kraj",
        "code_ciudad": "BL"
    },
    {
        "pais": "Eslovaquia",
        "code_pais": "SK",
        "ciudad": "Kosicky kraj",
        "code_ciudad": "KI"
    },
    {
        "pais": "Eslovaquia",
        "code_pais": "SK",
        "ciudad": "Nitriansky kraj",
        "code_ciudad": "NI"
    },
    {
        "pais": "Eslovaquia",
        "code_pais": "SK",
        "ciudad": "Presovsky kraj",
        "code_ciudad": "PV"
    },
    {
        "pais": "Eslovaquia",
        "code_pais": "SK",
        "ciudad": "Trnavsky kraj",
        "code_ciudad": "TA"
    },
    {
        "pais": "Eslovaquia",
        "code_pais": "SK",
        "ciudad": "Trenciansky kraj",
        "code_ciudad": "TC"
    },
    {
        "pais": "Eslovaquia",
        "code_pais": "SK",
        "ciudad": "Zilinsky kraj",
        "code_ciudad": "ZI"
    },
    {
        "pais": "Sierra Leona",
        "code_pais": "SL",
        "ciudad": "Eastern",
        "code_ciudad": "E"
    },
    {
        "pais": "Sierra Leona",
        "code_pais": "SL",
        "ciudad": "Northern",
        "code_ciudad": "N"
    },
    {
        "pais": "Sierra Leona",
        "code_pais": "SL",
        "ciudad": "Western Area",
        "code_ciudad": "W"
    },
    {
        "pais": "San Marino",
        "code_pais": "SM",
        "ciudad": "Domagnano",
        "code_ciudad": "03"
    },
    {
        "pais": "San Marino",
        "code_pais": "SM",
        "ciudad": "San Marino",
        "code_ciudad": "07"
    },
    {
        "pais": "San Marino",
        "code_pais": "SM",
        "ciudad": "Serravalle",
        "code_ciudad": "09"
    },
    {
        "pais": "Senegal",
        "code_pais": "SN",
        "ciudad": "Diourbel",
        "code_ciudad": "DB"
    },
    {
        "pais": "Senegal",
        "code_pais": "SN",
        "ciudad": "Dakar",
        "code_ciudad": "DK"
    },
    {
        "pais": "Senegal",
        "code_pais": "SN",
        "ciudad": "Fatick",
        "code_ciudad": "FK"
    },
    {
        "pais": "Senegal",
        "code_pais": "SN",
        "ciudad": "Kaffrine",
        "code_ciudad": "KA"
    },
    {
        "pais": "Senegal",
        "code_pais": "SN",
        "ciudad": "Kaolack",
        "code_ciudad": "KL"
    },
    {
        "pais": "Senegal",
        "code_pais": "SN",
        "ciudad": "Louga",
        "code_ciudad": "LG"
    },
    {
        "pais": "Senegal",
        "code_pais": "SN",
        "ciudad": "Saint-Louis",
        "code_ciudad": "SL"
    },
    {
        "pais": "Senegal",
        "code_pais": "SN",
        "ciudad": "Tambacounda",
        "code_ciudad": "TC"
    },
    {
        "pais": "Senegal",
        "code_pais": "SN",
        "ciudad": "Thies",
        "code_ciudad": "TH"
    },
    {
        "pais": "Senegal",
        "code_pais": "SN",
        "ciudad": "Ziguinchor",
        "code_ciudad": "ZG"
    },
    {
        "pais": "Somalia",
        "code_pais": "SO",
        "ciudad": "Awdal",
        "code_ciudad": "AW"
    },
    {
        "pais": "Somalia",
        "code_pais": "SO",
        "ciudad": "Banaadir",
        "code_ciudad": "BN"
    },
    {
        "pais": "Somalia",
        "code_pais": "SO",
        "ciudad": "Bay",
        "code_ciudad": "BY"
    },
    {
        "pais": "Somalia",
        "code_pais": "SO",
        "ciudad": "Nugaal",
        "code_ciudad": "NU"
    },
    {
        "pais": "Somalia",
        "code_pais": "SO",
        "ciudad": "Shabeellaha Hoose",
        "code_ciudad": "SH"
    },
    {
        "pais": "Somalia",
        "code_pais": "SO",
        "ciudad": "Togdheer",
        "code_ciudad": "TO"
    },
    {
        "pais": "Somalia",
        "code_pais": "SO",
        "ciudad": "Woqooyi Galbeed",
        "code_ciudad": "WO"
    },
    {
        "pais": "Surinam",
        "code_pais": "SR",
        "ciudad": "Brokopondo",
        "code_ciudad": "BR"
    },
    {
        "pais": "Surinam",
        "code_pais": "SR",
        "ciudad": "Commewijne",
        "code_ciudad": "CM"
    },
    {
        "pais": "Surinam",
        "code_pais": "SR",
        "ciudad": "Nickerie",
        "code_ciudad": "NI"
    },
    {
        "pais": "Surinam",
        "code_pais": "SR",
        "ciudad": "Paramaribo",
        "code_ciudad": "PM"
    },
    {
        "pais": "Surinam",
        "code_pais": "SR",
        "ciudad": "Para",
        "code_ciudad": "PR"
    },
    {
        "pais": "Surinam",
        "code_pais": "SR",
        "ciudad": "Saramacca",
        "code_ciudad": "SA"
    },
    {
        "pais": "Surinam",
        "code_pais": "SR",
        "ciudad": "Sipaliwini",
        "code_ciudad": "SI"
    },
    {
        "pais": "Surinam",
        "code_pais": "SR",
        "ciudad": "Wanica",
        "code_ciudad": "WA"
    },
    {
        "pais": "Sudán del Sur",
        "code_pais": "SS",
        "ciudad": "Northern Bahr el Ghazal",
        "code_ciudad": "BN"
    },
    {
        "pais": "Sudán del Sur",
        "code_pais": "SS",
        "ciudad": "Central Equatoria",
        "code_ciudad": "EC"
    },
    {
        "pais": "Sudán del Sur",
        "code_pais": "SS",
        "ciudad": "Eastern Equatoria",
        "code_ciudad": "EE"
    },
    {
        "pais": "Sudán del Sur",
        "code_pais": "SS",
        "ciudad": "Western Equatoria",
        "code_ciudad": "EW"
    },
    {
        "pais": "Sudán del Sur",
        "code_pais": "SS",
        "ciudad": "Lakes",
        "code_ciudad": "LK"
    },
    {
        "pais": "Sudán del Sur",
        "code_pais": "SS",
        "ciudad": "Upper Nile",
        "code_ciudad": "NU"
    },
    {
        "pais": "Sudán del Sur",
        "code_pais": "SS",
        "ciudad": "Unity",
        "code_ciudad": "UY"
    },
    {
        "pais": "Santo Tomé y Príncipe",
        "code_pais": "ST",
        "ciudad": "Principe",
        "code_ciudad": "P"
    },
    {
        "pais": "Santo Tomé y Príncipe",
        "code_pais": "ST",
        "ciudad": "Sao Tome",
        "code_ciudad": "S"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "Ahuachapan",
        "code_ciudad": "AH"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "Cabanas",
        "code_ciudad": "CA"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "Chalatenango",
        "code_ciudad": "CH"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "Cuscatlan",
        "code_ciudad": "CU"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "La Libertad",
        "code_ciudad": "LI"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "Morazan",
        "code_ciudad": "MO"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "La Paz",
        "code_ciudad": "PA"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "Santa Ana",
        "code_ciudad": "SA"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "San Miguel",
        "code_ciudad": "SM"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "Sonsonate",
        "code_ciudad": "SO"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "San Salvador",
        "code_ciudad": "SS"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "San Vicente",
        "code_ciudad": "SV"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "La Union",
        "code_ciudad": "UN"
    },
    {
        "pais": "El Salvador",
        "code_pais": "SV",
        "ciudad": "Usulutan",
        "code_ciudad": "US"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Dimashq",
        "code_ciudad": "DI"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Dar'a",
        "code_ciudad": "DR"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Dayr az Zawr",
        "code_ciudad": "DY"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Al Hasakah",
        "code_ciudad": "HA"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Hims",
        "code_ciudad": "HI"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Halab",
        "code_ciudad": "HL"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Hamah",
        "code_ciudad": "HM"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Idlib",
        "code_ciudad": "ID"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Al Ladhiqiyah",
        "code_ciudad": "LA"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Al Qunaytirah",
        "code_ciudad": "QU"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Ar Raqqah",
        "code_ciudad": "RA"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Rif Dimashq",
        "code_ciudad": "RD"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "As Suwayda'",
        "code_ciudad": "SU"
    },
    {
        "pais": "Siria",
        "code_pais": "SY",
        "ciudad": "Tartus",
        "code_ciudad": "TA"
    },
    {
        "pais": "Suazilandia",
        "code_pais": "SZ",
        "ciudad": "Hhohho",
        "code_ciudad": "HH"
    },
    {
        "pais": "Suazilandia",
        "code_pais": "SZ",
        "ciudad": "Lubombo",
        "code_ciudad": "LU"
    },
    {
        "pais": "Suazilandia",
        "code_pais": "SZ",
        "ciudad": "Manzini",
        "code_ciudad": "MA"
    },
    {
        "pais": "Chad",
        "code_pais": "TD",
        "ciudad": "Guera",
        "code_ciudad": "GR"
    },
    {
        "pais": "Chad",
        "code_pais": "TD",
        "ciudad": "Hadjer Lamis",
        "code_ciudad": "HL"
    },
    {
        "pais": "Chad",
        "code_pais": "TD",
        "ciudad": "Logone-Occidental",
        "code_ciudad": "LO"
    },
    {
        "pais": "Chad",
        "code_pais": "TD",
        "ciudad": "Ville de Ndjamena",
        "code_ciudad": "ND"
    },
    {
        "pais": "Chad",
        "code_pais": "TD",
        "ciudad": "Ouaddai",
        "code_ciudad": "OD"
    },
    {
        "pais": "Chad",
        "code_pais": "TD",
        "ciudad": "Tibesti",
        "code_ciudad": "TI"
    },
    {
        "pais": "Togo",
        "code_pais": "TG",
        "ciudad": "Centrale",
        "code_ciudad": "C"
    },
    {
        "pais": "Togo",
        "code_pais": "TG",
        "ciudad": "Kara",
        "code_ciudad": "K"
    },
    {
        "pais": "Togo",
        "code_pais": "TG",
        "ciudad": "Maritime",
        "code_ciudad": "M"
    },
    {
        "pais": "Togo",
        "code_pais": "TG",
        "ciudad": "Plateaux",
        "code_ciudad": "P"
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Krung Thep Maha Nakhon",
        "code_ciudad": 10
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Samut Prakan",
        "code_ciudad": 11
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Nonthaburi",
        "code_ciudad": 12
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Pathum Thani",
        "code_ciudad": 13
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Phra Nakhon Si Ayutthaya",
        "code_ciudad": 14
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Ang Thong",
        "code_ciudad": 15
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Lop Buri",
        "code_ciudad": 16
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Sing Buri",
        "code_ciudad": 17
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Chai Nat",
        "code_ciudad": 18
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Saraburi",
        "code_ciudad": 19
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Chon Buri",
        "code_ciudad": 20
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Rayong",
        "code_ciudad": 21
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Chanthaburi",
        "code_ciudad": 22
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Trat",
        "code_ciudad": 23
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Chachoengsao",
        "code_ciudad": 24
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Prachin Buri",
        "code_ciudad": 25
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Nakhon Nayok",
        "code_ciudad": 26
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Sa Kaeo",
        "code_ciudad": 27
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Nakhon Ratchasima",
        "code_ciudad": 30
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Buri Ram",
        "code_ciudad": 31
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Surin",
        "code_ciudad": 32
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Si Sa Ket",
        "code_ciudad": 33
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Ubon Ratchathani",
        "code_ciudad": 34
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Yasothon",
        "code_ciudad": 35
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Chaiyaphum",
        "code_ciudad": 36
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Amnat Charoen",
        "code_ciudad": 37
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Bueng Kan",
        "code_ciudad": 38
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Nong Bua Lam Phu",
        "code_ciudad": 39
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Khon Kaen",
        "code_ciudad": 40
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Udon Thani",
        "code_ciudad": 41
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Loei",
        "code_ciudad": 42
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Nong Khai",
        "code_ciudad": 43
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Maha Sarakham",
        "code_ciudad": 44
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Roi Et",
        "code_ciudad": 45
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Kalasin",
        "code_ciudad": 46
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Sakon Nakhon",
        "code_ciudad": 47
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Nakhon Phanom",
        "code_ciudad": 48
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Mukdahan",
        "code_ciudad": 49
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Chiang Mai",
        "code_ciudad": 50
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Lamphun",
        "code_ciudad": 51
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Lampang",
        "code_ciudad": 52
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Uttaradit",
        "code_ciudad": 53
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Phrae",
        "code_ciudad": 54
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Nan",
        "code_ciudad": 55
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Phayao",
        "code_ciudad": 56
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Chiang Rai",
        "code_ciudad": 57
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Mae Hong Son",
        "code_ciudad": 58
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Nakhon Sawan",
        "code_ciudad": 60
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Uthai Thani",
        "code_ciudad": 61
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Kamphaeng Phet",
        "code_ciudad": 62
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Tak",
        "code_ciudad": 63
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Sukhothai",
        "code_ciudad": 64
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Phitsanulok",
        "code_ciudad": 65
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Phichit",
        "code_ciudad": 66
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Phetchabun",
        "code_ciudad": 67
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Ratchaburi",
        "code_ciudad": 70
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Kanchanaburi",
        "code_ciudad": 71
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Suphan Buri",
        "code_ciudad": 72
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Nakhon Pathom",
        "code_ciudad": 73
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Samut Sakhon",
        "code_ciudad": 74
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Samut Songkhram",
        "code_ciudad": 75
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Phetchaburi",
        "code_ciudad": 76
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Prachuap Khiri Khan",
        "code_ciudad": 77
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Nakhon Si Thammarat",
        "code_ciudad": 80
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Krabi",
        "code_ciudad": 81
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Phangnga",
        "code_ciudad": 82
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Phuket",
        "code_ciudad": 83
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Surat Thani",
        "code_ciudad": 84
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Ranong",
        "code_ciudad": 85
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Chumphon",
        "code_ciudad": 86
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Songkhla",
        "code_ciudad": 90
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Satun",
        "code_ciudad": 91
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Trang",
        "code_ciudad": 92
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Phatthalung",
        "code_ciudad": 93
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Pattani",
        "code_ciudad": 94
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Yala",
        "code_ciudad": 95
    },
    {
        "pais": "Tailandia",
        "code_pais": "TH",
        "ciudad": "Narathiwat",
        "code_ciudad": 96
    },
    {
        "pais": "Tayikistán",
        "code_pais": "TJ",
        "ciudad": "Dushanbe",
        "code_ciudad": "DU"
    },
    {
        "pais": "Tayikistán",
        "code_pais": "TJ",
        "ciudad": "Kuhistoni Badakhshon",
        "code_ciudad": "GB"
    },
    {
        "pais": "Tayikistán",
        "code_pais": "TJ",
        "ciudad": "Khatlon",
        "code_ciudad": "KT"
    },
    {
        "pais": "Tayikistán",
        "code_pais": "TJ",
        "ciudad": "Nohiyahoi Tobei Jumhuri",
        "code_ciudad": "RA"
    },
    {
        "pais": "Tayikistán",
        "code_pais": "TJ",
        "ciudad": "Sughd",
        "code_ciudad": "SU"
    },
    {
        "pais": "Timor Oriental",
        "code_pais": "TL",
        "ciudad": "Ainaro",
        "code_ciudad": "AN"
    },
    {
        "pais": "Timor Oriental",
        "code_pais": "TL",
        "ciudad": "Cova Lima",
        "code_ciudad": "CO"
    },
    {
        "pais": "Timor Oriental",
        "code_pais": "TL",
        "ciudad": "Dili",
        "code_ciudad": "DI"
    },
    {
        "pais": "Turkmenistán",
        "code_pais": "TM",
        "ciudad": "Ahal",
        "code_ciudad": "A"
    },
    {
        "pais": "Turkmenistán",
        "code_pais": "TM",
        "ciudad": "Balkan",
        "code_ciudad": "B"
    },
    {
        "pais": "Turkmenistán",
        "code_pais": "TM",
        "ciudad": "Dasoguz",
        "code_ciudad": "D"
    },
    {
        "pais": "Turkmenistán",
        "code_pais": "TM",
        "ciudad": "Lebap",
        "code_ciudad": "L"
    },
    {
        "pais": "Turkmenistán",
        "code_pais": "TM",
        "ciudad": "Mary",
        "code_ciudad": "M"
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Tunis",
        "code_ciudad": 11
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "L'Ariana",
        "code_ciudad": 12
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Ben Arous",
        "code_ciudad": 13
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "La Manouba",
        "code_ciudad": 14
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Nabeul",
        "code_ciudad": 21
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Zaghouan",
        "code_ciudad": 22
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Bizerte",
        "code_ciudad": 23
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Beja",
        "code_ciudad": 31
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Jendouba",
        "code_ciudad": 32
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Le Kef",
        "code_ciudad": 33
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Siliana",
        "code_ciudad": 34
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Kairouan",
        "code_ciudad": 41
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Kasserine",
        "code_ciudad": 42
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Sidi Bouzid",
        "code_ciudad": 43
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Sousse",
        "code_ciudad": 51
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Monastir",
        "code_ciudad": 52
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Mahdia",
        "code_ciudad": 53
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Sfax",
        "code_ciudad": 61
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Gafsa",
        "code_ciudad": 71
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Tozeur",
        "code_ciudad": 72
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Kebili",
        "code_ciudad": 73
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Gabes",
        "code_ciudad": 81
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Medenine",
        "code_ciudad": 82
    },
    {
        "pais": "Túnez",
        "code_pais": "TN",
        "ciudad": "Tataouine",
        "code_ciudad": 83
    },
    {
        "pais": "Tonga",
        "code_pais": "TO",
        "ciudad": "Niuas",
        "code_ciudad": "03"
    },
    {
        "pais": "Tonga",
        "code_pais": "TO",
        "ciudad": "Tongatapu",
        "code_ciudad": "04"
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Adana",
        "code_ciudad": "01"
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Adiyaman",
        "code_ciudad": "02"
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Afyonkarahisar",
        "code_ciudad": "03"
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Agri",
        "code_ciudad": "04"
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Amasya",
        "code_ciudad": "05"
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Ankara",
        "code_ciudad": "06"
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Antalya",
        "code_ciudad": "07"
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Artvin",
        "code_ciudad": "08"
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Aydin",
        "code_ciudad": "09"
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Balikesir",
        "code_ciudad": 10
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Bilecik",
        "code_ciudad": 11
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Bingol",
        "code_ciudad": 12
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Bitlis",
        "code_ciudad": 13
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Bolu",
        "code_ciudad": 14
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Burdur",
        "code_ciudad": 15
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Bursa",
        "code_ciudad": 16
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Canakkale",
        "code_ciudad": 17
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Cankiri",
        "code_ciudad": 18
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Corum",
        "code_ciudad": 19
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Denizli",
        "code_ciudad": 20
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Diyarbakir",
        "code_ciudad": 21
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Edirne",
        "code_ciudad": 22
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Elazig",
        "code_ciudad": 23
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Erzincan",
        "code_ciudad": 24
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Erzurum",
        "code_ciudad": 25
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Eskisehir",
        "code_ciudad": 26
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Gaziantep",
        "code_ciudad": 27
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Giresun",
        "code_ciudad": 28
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Gumushane",
        "code_ciudad": 29
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Hakkari",
        "code_ciudad": 30
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Hatay",
        "code_ciudad": 31
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Isparta",
        "code_ciudad": 32
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Mersin",
        "code_ciudad": 33
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Istanbul",
        "code_ciudad": 34
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Izmir",
        "code_ciudad": 35
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Kars",
        "code_ciudad": 36
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Kastamonu",
        "code_ciudad": 37
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Kayseri",
        "code_ciudad": 38
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Kirklareli",
        "code_ciudad": 39
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Kirsehir",
        "code_ciudad": 40
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Kocaeli",
        "code_ciudad": 41
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Konya",
        "code_ciudad": 42
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Kutahya",
        "code_ciudad": 43
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Malatya",
        "code_ciudad": 44
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Manisa",
        "code_ciudad": 45
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Kahramanmaras",
        "code_ciudad": 46
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Mardin",
        "code_ciudad": 47
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Mugla",
        "code_ciudad": 48
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Mus",
        "code_ciudad": 49
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Nevsehir",
        "code_ciudad": 50
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Nigde",
        "code_ciudad": 51
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Ordu",
        "code_ciudad": 52
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Rize",
        "code_ciudad": 53
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Sakarya",
        "code_ciudad": 54
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Samsun",
        "code_ciudad": 55
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Siirt",
        "code_ciudad": 56
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Sinop",
        "code_ciudad": 57
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Sivas",
        "code_ciudad": 58
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Tekirdag",
        "code_ciudad": 59
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Tokat",
        "code_ciudad": 60
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Trabzon",
        "code_ciudad": 61
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Tunceli",
        "code_ciudad": 62
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Sanliurfa",
        "code_ciudad": 63
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Usak",
        "code_ciudad": 64
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Van",
        "code_ciudad": 65
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Yozgat",
        "code_ciudad": 66
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Zonguldak",
        "code_ciudad": 67
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Aksaray",
        "code_ciudad": 68
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Bayburt",
        "code_ciudad": 69
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Karaman",
        "code_ciudad": 70
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Kirikkale",
        "code_ciudad": 71
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Batman",
        "code_ciudad": 72
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Sirnak",
        "code_ciudad": 73
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Bartin",
        "code_ciudad": 74
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Ardahan",
        "code_ciudad": 75
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Igdir",
        "code_ciudad": 76
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Yalova",
        "code_ciudad": 77
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Karabuk",
        "code_ciudad": 78
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Kilis",
        "code_ciudad": 79
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Osmaniye",
        "code_ciudad": 80
    },
    {
        "pais": "Turquía",
        "code_pais": "TR",
        "ciudad": "Duzce",
        "code_ciudad": 81
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Arima",
        "code_ciudad": "ARI"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Chaguanas",
        "code_ciudad": "CHA"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Couva-Tabaquite-Talparo",
        "code_ciudad": "CTT"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Diego Martin",
        "code_ciudad": "DMN"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Mayaro-Rio Claro",
        "code_ciudad": "MRC"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Penal-Debe",
        "code_ciudad": "PED"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Port of Spain",
        "code_ciudad": "POS"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Princes Town",
        "code_ciudad": "PRT"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Point Fortin",
        "code_ciudad": "PTF"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "San Fernando",
        "code_ciudad": "SFO"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Sangre Grande",
        "code_ciudad": "SGE"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Siparia",
        "code_ciudad": "SIP"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "San Juan-Laventille",
        "code_ciudad": "SJL"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Tobago",
        "code_ciudad": "TOB"
    },
    {
        "pais": "Trinidad y Tobago",
        "code_pais": "TT",
        "ciudad": "Tunapuna-Piarco",
        "code_ciudad": "TUP"
    },
    {
        "pais": "Tuvalu",
        "code_pais": "TV",
        "ciudad": "Funafuti",
        "code_ciudad": "FUN"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Changhua",
        "code_ciudad": "CHA"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Chiayi",
        "code_ciudad": "CYQ"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Hsinchu",
        "code_ciudad": "HSQ"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Hualien",
        "code_ciudad": "HUA"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Yilan",
        "code_ciudad": "ILA"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Keelung",
        "code_ciudad": "KEE"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Kaohsiung",
        "code_ciudad": "KHH"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Kinmen",
        "code_ciudad": "KIN"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Lienchiang",
        "code_ciudad": "LIE"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Miaoli",
        "code_ciudad": "MIA"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Nantou",
        "code_ciudad": "NAN"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "New Taipei",
        "code_ciudad": "NWT"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Penghu",
        "code_ciudad": "PEN"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Pingtung",
        "code_ciudad": "PIF"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Taoyuan",
        "code_ciudad": "TAO"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Tainan",
        "code_ciudad": "TNN"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Taipei",
        "code_ciudad": "TPE"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Taitung",
        "code_ciudad": "TTT"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Taichung",
        "code_ciudad": "TXG"
    },
    {
        "pais": "Taiwán",
        "code_pais": "TW",
        "ciudad": "Yunlin",
        "code_ciudad": "YUN"
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Arusha",
        "code_ciudad": "01"
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Dar es Salaam",
        "code_ciudad": "02"
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Dodoma",
        "code_ciudad": "03"
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Iringa",
        "code_ciudad": "04"
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Kagera",
        "code_ciudad": "05"
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Kaskazini Unguja",
        "code_ciudad": "07"
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Kigoma",
        "code_ciudad": "08"
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Kilimanjaro",
        "code_ciudad": "09"
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Kusini Pemba",
        "code_ciudad": 10
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Kusini Unguja",
        "code_ciudad": 11
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Lindi",
        "code_ciudad": 12
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Mara",
        "code_ciudad": 13
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Mbeya",
        "code_ciudad": 14
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Mjini Magharibi",
        "code_ciudad": 15
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Morogoro",
        "code_ciudad": 16
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Mtwara",
        "code_ciudad": 17
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Mwanza",
        "code_ciudad": 18
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Pwani",
        "code_ciudad": 19
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Rukwa",
        "code_ciudad": 20
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Ruvuma",
        "code_ciudad": 21
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Shinyanga",
        "code_ciudad": 22
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Singida",
        "code_ciudad": 23
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Tabora",
        "code_ciudad": 24
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Tanga",
        "code_ciudad": 25
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Manyara",
        "code_ciudad": 26
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Geita",
        "code_ciudad": 27
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Katavi",
        "code_ciudad": 28
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Njombe",
        "code_ciudad": 29
    },
    {
        "pais": "Tanzania",
        "code_pais": "TZ",
        "ciudad": "Simiyu",
        "code_ciudad": 30
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Vinnytska oblast",
        "code_ciudad": "05"
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Volynska oblast",
        "code_ciudad": "07"
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Luhanska oblast",
        "code_ciudad": "09"
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Dnipropetrovska oblast",
        "code_ciudad": 12
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Donetska oblast",
        "code_ciudad": 14
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Zhytomyrska oblast",
        "code_ciudad": 18
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Zakarpatska oblast",
        "code_ciudad": 21
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Zaporizka oblast",
        "code_ciudad": 23
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Ivano-Frankivska oblast",
        "code_ciudad": 26
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Kyiv",
        "code_ciudad": 30
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Kyivska oblast",
        "code_ciudad": 32
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Kirovohradska oblast",
        "code_ciudad": 35
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Sevastopol",
        "code_ciudad": 40
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Avtonomna Respublika Krym",
        "code_ciudad": 43
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Lvivska oblast",
        "code_ciudad": 46
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Mykolaivska oblast",
        "code_ciudad": 48
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Odeska oblast",
        "code_ciudad": 51
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Poltavska oblast",
        "code_ciudad": 53
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Rivnenska oblast",
        "code_ciudad": 56
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Sumska oblast",
        "code_ciudad": 59
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Ternopilska oblast",
        "code_ciudad": 61
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Kharkivska oblast",
        "code_ciudad": 63
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Khersonska oblast",
        "code_ciudad": 65
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Khmelnytska oblast",
        "code_ciudad": 68
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Cherkaska oblast",
        "code_ciudad": 71
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Chernihivska oblast",
        "code_ciudad": 74
    },
    {
        "pais": "Ucrania",
        "code_pais": "UA",
        "ciudad": "Chernivetska oblast",
        "code_ciudad": 77
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kalangala",
        "code_ciudad": 101
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kampala",
        "code_ciudad": 102
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kiboga",
        "code_ciudad": 103
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Luwero",
        "code_ciudad": 104
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Masaka",
        "code_ciudad": 105
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Mpigi",
        "code_ciudad": 106
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Mukono",
        "code_ciudad": 108
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kayunga",
        "code_ciudad": 112
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Wakiso",
        "code_ciudad": 113
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Mityana",
        "code_ciudad": 115
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Nakaseke",
        "code_ciudad": 116
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Buikwe",
        "code_ciudad": 117
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Buvuma",
        "code_ciudad": 120
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kalungu",
        "code_ciudad": 122
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Bugiri",
        "code_ciudad": 201
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Iganga",
        "code_ciudad": 203
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Jinja",
        "code_ciudad": 204
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kamuli",
        "code_ciudad": 205
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kapchorwa",
        "code_ciudad": 206
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kumi",
        "code_ciudad": 208
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Mbale",
        "code_ciudad": 209
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Pallisa",
        "code_ciudad": 210
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Soroti",
        "code_ciudad": 211
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Mayuge",
        "code_ciudad": 214
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Sironko",
        "code_ciudad": 215
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Bukedea",
        "code_ciudad": 219
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kaliro",
        "code_ciudad": 222
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Namayingo",
        "code_ciudad": 230
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Arua",
        "code_ciudad": 303
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Gulu",
        "code_ciudad": 304
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kitgum",
        "code_ciudad": 305
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Lira",
        "code_ciudad": 307
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Amuru",
        "code_ciudad": 316
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Oyam",
        "code_ciudad": 321
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Nwoya",
        "code_ciudad": 328
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Hoima",
        "code_ciudad": 403
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kabale",
        "code_ciudad": 404
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kabarole",
        "code_ciudad": 405
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kasese",
        "code_ciudad": 406
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kibaale",
        "code_ciudad": 407
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Masindi",
        "code_ciudad": 409
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Mbarara",
        "code_ciudad": 410
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Rukungiri",
        "code_ciudad": 412
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kyenjojo",
        "code_ciudad": 415
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Buliisa",
        "code_ciudad": 416
    },
    {
        "pais": "Uganda",
        "code_pais": "UG",
        "ciudad": "Kiruhura",
        "code_ciudad": 419
    },
    {
        "pais": "Islas Ultramarinas Menores de Estados Unidos",
        "code_pais": "UM",
        "ciudad": "Palmyra Atoll",
        "code_ciudad": 95
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Alaska",
        "code_ciudad": "AK"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Alabama",
        "code_ciudad": "AL"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Arkansas",
        "code_ciudad": "AR"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Arizona",
        "code_ciudad": "AZ"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "California",
        "code_ciudad": "CA"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Colorado",
        "code_ciudad": "CO"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Connecticut",
        "code_ciudad": "CT"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "District of Columbia",
        "code_ciudad": "DC"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Delaware",
        "code_ciudad": "DE"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Florida",
        "code_ciudad": "FL"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Georgia",
        "code_ciudad": "GA"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Hawaii",
        "code_ciudad": "HI"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Iowa",
        "code_ciudad": "IA"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Idaho",
        "code_ciudad": "ID"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Illinois",
        "code_ciudad": "IL"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Indiana",
        "code_ciudad": "IN"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Kansas",
        "code_ciudad": "KS"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Kentucky",
        "code_ciudad": "KY"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Louisiana",
        "code_ciudad": "LA"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Massachusetts",
        "code_ciudad": "MA"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Maryland",
        "code_ciudad": "MD"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Maine",
        "code_ciudad": "ME"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Michigan",
        "code_ciudad": "MI"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Minnesota",
        "code_ciudad": "MN"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Missouri",
        "code_ciudad": "MO"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Mississippi",
        "code_ciudad": "MS"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Montana",
        "code_ciudad": "MT"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "North Carolina",
        "code_ciudad": "NC"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "North Dakota",
        "code_ciudad": "ND"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Nebraska",
        "code_ciudad": "NE"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "New Hampshire",
        "code_ciudad": "NH"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "New Jersey",
        "code_ciudad": "NJ"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "New Mexico",
        "code_ciudad": "NM"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Nevada",
        "code_ciudad": "NV"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "New York",
        "code_ciudad": "NY"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Ohio",
        "code_ciudad": "OH"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Oklahoma",
        "code_ciudad": "OK"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Oregon",
        "code_ciudad": "OR"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Pennsylvania",
        "code_ciudad": "PA"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Rhode Island",
        "code_ciudad": "RI"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "South Carolina",
        "code_ciudad": "SC"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "South Dakota",
        "code_ciudad": "SD"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Tennessee",
        "code_ciudad": "TN"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Texas",
        "code_ciudad": "TX"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Utah",
        "code_ciudad": "UT"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Virginia",
        "code_ciudad": "VA"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Vermont",
        "code_ciudad": "VT"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Washington",
        "code_ciudad": "WA"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Wisconsin",
        "code_ciudad": "WI"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "West Virginia",
        "code_ciudad": "WV"
    },
    {
        "pais": "Estados Unidos",
        "code_pais": "US",
        "ciudad": "Wyoming",
        "code_ciudad": "WY"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Artigas",
        "code_ciudad": "AR"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Canelones",
        "code_ciudad": "CA"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Cerro Largo",
        "code_ciudad": "CL"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Colonia",
        "code_ciudad": "CO"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Durazno",
        "code_ciudad": "DU"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Florida",
        "code_ciudad": "FD"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Flores",
        "code_ciudad": "FS"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Lavalleja",
        "code_ciudad": "LA"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Maldonado",
        "code_ciudad": "MA"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Montevideo",
        "code_ciudad": "MO"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Paysandu",
        "code_ciudad": "PA"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Rio Negro",
        "code_ciudad": "RN"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Rocha",
        "code_ciudad": "RO"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Rivera",
        "code_ciudad": "RV"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Salto",
        "code_ciudad": "SA"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "San Jose",
        "code_ciudad": "SJ"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Soriano",
        "code_ciudad": "SO"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Tacuarembo",
        "code_ciudad": "TA"
    },
    {
        "pais": "Uruguay",
        "code_pais": "UY",
        "ciudad": "Treinta y Tres",
        "code_ciudad": "TT"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Andijon",
        "code_ciudad": "AN"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Buxoro",
        "code_ciudad": "BU"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Farg'ona",
        "code_ciudad": "FA"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Jizzax",
        "code_ciudad": "JI"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Namangan",
        "code_ciudad": "NG"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Navoiy",
        "code_ciudad": "NW"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Qashqadaryo",
        "code_ciudad": "QA"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Qoraqalpog'iston Respublikasi",
        "code_ciudad": "QR"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Samarqand",
        "code_ciudad": "SA"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Sirdaryo",
        "code_ciudad": "SI"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Surxondaryo",
        "code_ciudad": "SU"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Toshkent",
        "code_ciudad": "TK"
    },
    {
        "pais": "Uzbekistán",
        "code_pais": "UZ",
        "ciudad": "Xorazm",
        "code_ciudad": "XO"
    },
    {
        "pais": "San Vicente y las Granadinas",
        "code_pais": "VC",
        "ciudad": "Charlotte",
        "code_ciudad": "01"
    },
    {
        "pais": "San Vicente y las Granadinas",
        "code_pais": "VC",
        "ciudad": "Saint David",
        "code_ciudad": "03"
    },
    {
        "pais": "San Vicente y las Granadinas",
        "code_pais": "VC",
        "ciudad": "Saint George",
        "code_ciudad": "04"
    },
    {
        "pais": "San Vicente y las Granadinas",
        "code_pais": "VC",
        "ciudad": "Saint Patrick",
        "code_ciudad": "05"
    },
    {
        "pais": "San Vicente y las Granadinas",
        "code_pais": "VC",
        "ciudad": "Grenadines",
        "code_ciudad": "06"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Distrito Capital",
        "code_ciudad": "A"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Anzoategui",
        "code_ciudad": "B"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Apure",
        "code_ciudad": "C"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Aragua",
        "code_ciudad": "D"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Barinas",
        "code_ciudad": "E"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Bolivar",
        "code_ciudad": "F"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Carabobo",
        "code_ciudad": "G"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Cojedes",
        "code_ciudad": "H"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Falcon",
        "code_ciudad": "I"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Guarico",
        "code_ciudad": "J"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Lara",
        "code_ciudad": "K"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Merida",
        "code_ciudad": "L"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Miranda",
        "code_ciudad": "M"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Monagas",
        "code_ciudad": "N"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Nueva Esparta",
        "code_ciudad": "O"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Portuguesa",
        "code_ciudad": "P"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Sucre",
        "code_ciudad": "R"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Tachira",
        "code_ciudad": "S"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Trujillo",
        "code_ciudad": "T"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Yaracuy",
        "code_ciudad": "U"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Zulia",
        "code_ciudad": "V"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Vargas",
        "code_ciudad": "X"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Delta Amacuro",
        "code_ciudad": "Y"
    },
    {
        "pais": "Venezuela",
        "code_pais": "VE",
        "ciudad": "Amazonas",
        "code_ciudad": "Z"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Lai Chau",
        "code_ciudad": "01"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Lao Cai",
        "code_ciudad": "02"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Ha Giang",
        "code_ciudad": "03"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Cao Bang",
        "code_ciudad": "04"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Son La",
        "code_ciudad": "05"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Yen Bai",
        "code_ciudad": "06"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Tuyen Quang",
        "code_ciudad": "07"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Lang Son",
        "code_ciudad": "09"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Quang Ninh",
        "code_ciudad": 13
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Hoa Binh",
        "code_ciudad": 14
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Ninh Binh",
        "code_ciudad": 18
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Thai Binh",
        "code_ciudad": 20
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Thanh Hoa",
        "code_ciudad": 21
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Nghe An",
        "code_ciudad": 22
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Ha Tinh",
        "code_ciudad": 23
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Quang Binh",
        "code_ciudad": 24
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Quang Tri",
        "code_ciudad": 25
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Thua Thien-Hue",
        "code_ciudad": 26
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Quang Nam",
        "code_ciudad": 27
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Kon Tum",
        "code_ciudad": 28
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Quang Ngai",
        "code_ciudad": 29
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Gia Lai",
        "code_ciudad": 30
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Binh Dinh",
        "code_ciudad": 31
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Phu Yen",
        "code_ciudad": 32
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Dak Lak",
        "code_ciudad": 33
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Khanh Hoa",
        "code_ciudad": 34
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Lam Dong",
        "code_ciudad": 35
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Ninh Thuan",
        "code_ciudad": 36
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Tay Ninh",
        "code_ciudad": 37
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Dong Nai",
        "code_ciudad": 39
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Binh Thuan",
        "code_ciudad": 40
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Long An",
        "code_ciudad": 41
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Ba Ria - Vung Tau",
        "code_ciudad": 43
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "An Giang",
        "code_ciudad": 44
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Dong Thap",
        "code_ciudad": 45
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Tien Giang",
        "code_ciudad": 46
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Kien Giang",
        "code_ciudad": 47
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Vinh Long",
        "code_ciudad": 49
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Ben Tre",
        "code_ciudad": 50
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Tra Vinh",
        "code_ciudad": 51
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Soc Trang",
        "code_ciudad": 52
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Bac Kan",
        "code_ciudad": 53
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Bac Giang",
        "code_ciudad": 54
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Bac Lieu",
        "code_ciudad": 55
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Bac Ninh",
        "code_ciudad": 56
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Binh Duong",
        "code_ciudad": 57
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Binh Phuoc",
        "code_ciudad": 58
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Ca Mau",
        "code_ciudad": 59
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Hai Duong",
        "code_ciudad": 61
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Ha Nam",
        "code_ciudad": 63
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Hung Yen",
        "code_ciudad": 66
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Nam Dinh",
        "code_ciudad": 67
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Phu Tho",
        "code_ciudad": 68
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Thai Nguyen",
        "code_ciudad": 69
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Vinh Phuc",
        "code_ciudad": 70
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Dien Bien",
        "code_ciudad": 71
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Dak Nong",
        "code_ciudad": 72
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Can Tho",
        "code_ciudad": "CT"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Da Nang",
        "code_ciudad": "DN"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Ha Noi",
        "code_ciudad": "HN"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Hai Phong",
        "code_ciudad": "HP"
    },
    {
        "pais": "Vietnam",
        "code_pais": "VN",
        "ciudad": "Ho Chi Minh",
        "code_ciudad": "SG"
    },
    {
        "pais": "Vanuatu",
        "code_pais": "VU",
        "ciudad": "Shefa",
        "code_ciudad": "SEE"
    },
    {
        "pais": "Vanuatu",
        "code_pais": "VU",
        "ciudad": "Tafea",
        "code_ciudad": "TAE"
    },
    {
        "pais": "Vanuatu",
        "code_pais": "VU",
        "ciudad": "Torba",
        "code_ciudad": "TOB"
    },
    {
        "pais": "Wallis y Futuna",
        "code_pais": "WF",
        "ciudad": "Sigave",
        "code_ciudad": "SG"
    },
    {
        "pais": "Wallis y Futuna",
        "code_pais": "WF",
        "ciudad": "Uvea",
        "code_ciudad": "UV"
    },
    {
        "pais": "Samoa",
        "code_pais": "WS",
        "ciudad": "Atua",
        "code_ciudad": "AT"
    },
    {
        "pais": "Samoa",
        "code_pais": "WS",
        "ciudad": "Fa'asaleleaga",
        "code_ciudad": "FA"
    },
    {
        "pais": "Samoa",
        "code_pais": "WS",
        "ciudad": "Tuamasaga",
        "code_ciudad": "TU"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Abyan",
        "code_ciudad": "AB"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "'Adan",
        "code_ciudad": "AD"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "'Amran",
        "code_ciudad": "AM"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Al Bayda'",
        "code_ciudad": "BA"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Ad Dali'",
        "code_ciudad": "DA"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Dhamar",
        "code_ciudad": "DH"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Hadramawt",
        "code_ciudad": "HD"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Al Hudaydah",
        "code_ciudad": "HU"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Ibb",
        "code_ciudad": "IB"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Al Jawf",
        "code_ciudad": "JA"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Lahij",
        "code_ciudad": "LA"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Ma'rib",
        "code_ciudad": "MA"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Al Mahwit",
        "code_ciudad": "MW"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Amanat al 'Asimah",
        "code_ciudad": "SA"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Sa'dah",
        "code_ciudad": "SD"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Shabwah",
        "code_ciudad": "SH"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "San'a'",
        "code_ciudad": "SN"
    },
    {
        "pais": "Yemen",
        "code_pais": "YE",
        "ciudad": "Ta'izz",
        "code_ciudad": "TA"
    },
    {
        "pais": "Sudáfrica",
        "code_pais": "ZA",
        "ciudad": "Eastern Cape",
        "code_ciudad": "EC"
    },
    {
        "pais": "Sudáfrica",
        "code_pais": "ZA",
        "ciudad": "Free State",
        "code_ciudad": "FS"
    },
    {
        "pais": "Sudáfrica",
        "code_pais": "ZA",
        "ciudad": "Gauteng",
        "code_ciudad": "GP"
    },
    {
        "pais": "Sudáfrica",
        "code_pais": "ZA",
        "ciudad": "Kwazulu-Natal",
        "code_ciudad": "KZN"
    },
    {
        "pais": "Sudáfrica",
        "code_pais": "ZA",
        "ciudad": "Limpopo",
        "code_ciudad": "LP"
    },
    {
        "pais": "Sudáfrica",
        "code_pais": "ZA",
        "ciudad": "Mpumalanga",
        "code_ciudad": "MP"
    },
    {
        "pais": "Sudáfrica",
        "code_pais": "ZA",
        "ciudad": "Northern Cape",
        "code_ciudad": "NC"
    },
    {
        "pais": "Sudáfrica",
        "code_pais": "ZA",
        "ciudad": "North-West",
        "code_ciudad": "NW"
    },
    {
        "pais": "Sudáfrica",
        "code_pais": "ZA",
        "ciudad": "Western Cape",
        "code_ciudad": "WC"
    },
    {
        "pais": "Zambia",
        "code_pais": "ZM",
        "ciudad": "Western",
        "code_ciudad": "01"
    },
    {
        "pais": "Zambia",
        "code_pais": "ZM",
        "ciudad": "Central",
        "code_ciudad": "02"
    },
    {
        "pais": "Zambia",
        "code_pais": "ZM",
        "ciudad": "Eastern",
        "code_ciudad": "03"
    },
    {
        "pais": "Zambia",
        "code_pais": "ZM",
        "ciudad": "Luapula",
        "code_ciudad": "04"
    },
    {
        "pais": "Zambia",
        "code_pais": "ZM",
        "ciudad": "Northern",
        "code_ciudad": "05"
    },
    {
        "pais": "Zambia",
        "code_pais": "ZM",
        "ciudad": "North-Western",
        "code_ciudad": "06"
    },
    {
        "pais": "Zambia",
        "code_pais": "ZM",
        "ciudad": "Southern",
        "code_ciudad": "07"
    },
    {
        "pais": "Zambia",
        "code_pais": "ZM",
        "ciudad": "Copperbelt",
        "code_ciudad": "08"
    },
    {
        "pais": "Zambia",
        "code_pais": "ZM",
        "ciudad": "Lusaka",
        "code_ciudad": "09"
    },
    {
        "pais": "Zimbabue",
        "code_pais": "ZW",
        "ciudad": "Bulawayo",
        "code_ciudad": "BU"
    },
    {
        "pais": "Zimbabue",
        "code_pais": "ZW",
        "ciudad": "Harare",
        "code_ciudad": "HA"
    },
    {
        "pais": "Zimbabue",
        "code_pais": "ZW",
        "ciudad": "Manicaland",
        "code_ciudad": "MA"
    },
    {
        "pais": "Zimbabue",
        "code_pais": "ZW",
        "ciudad": "Mashonaland Central",
        "code_ciudad": "MC"
    },
    {
        "pais": "Zimbabue",
        "code_pais": "ZW",
        "ciudad": "Mashonaland East",
        "code_ciudad": "ME"
    },
    {
        "pais": "Zimbabue",
        "code_pais": "ZW",
        "ciudad": "Midlands",
        "code_ciudad": "MI"
    },
    {
        "pais": "Zimbabue",
        "code_pais": "ZW",
        "ciudad": "Matabeleland North",
        "code_ciudad": "MN"
    },
    {
        "pais": "Zimbabue",
        "code_pais": "ZW",
        "ciudad": "Matabeleland South",
        "code_ciudad": "MS"
    },
    {
        "pais": "Zimbabue",
        "code_pais": "ZW",
        "ciudad": "Masvingo",
        "code_ciudad": "MV"
    },
    {
        "pais": "Zimbabue",
        "code_pais": "ZW",
        "ciudad": "Mashonaland West",
        "code_ciudad": "MW"
    }
]