import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/pages/Plan.scss";
import Loader from "../components/Loader";
import { Course, MagazzinePlan, Product, ResponseGeneral } from "../common/types";
import { MagazzinePlanCard } from "./Magazzine";
import { timeShoppingCartOpen } from "../common/variables";
import { addElementToShoppingCart } from "../common/user";
import { madePost } from "../common/post";
import toast from "../common/toast";

import "../styles/pages/Magazzine.scss";

const Plan = () => {
    const [openCart, setOpenCart] = useState(false);
    const [plan, setPlan] = useState<{ isLoaded: boolean, plan: (MagazzinePlan) | null }>({ isLoaded: false, plan: null });

    useEffect(() => {
        const id = (new URLSearchParams(window.location.search)).get('id');
        madePost(
            {
                key: 'get-plan',
                id: id
            },
            'Magazzine.php'
        ).then((r) => {
            let res = r as ResponseGeneral;
            if (res.RESULT) {
                let p = res.VALUE;

                try {
                    p.habs = JSON.parse(p.habs);
                } catch (err) { }
                try {
                    p.winnings = JSON.parse(p.winnings);
                } catch (err) { }

                setPlan({ isLoaded: true, plan: p });
            } else {
                toast.error("El plan no se ha encontrado")
            }
        }
        ).catch(err => {
            console.log(err)
        })
    }, [])

    return <div className="plan-page contrast">
        <Header openShoppingCart={openCart} />

        {
            plan.isLoaded && plan.plan ?
                <section className="plan-container " style={{ marginTop: plan.plan.img ? "10vh" : '20vh' }}>
                    <div className="magazzine-plans" style={{ justifyContent: 'center' }}>
                        <div className="plan-unique">

                            {
                                plan.plan.img != null ?
                                    <>
                                        <div></div>
                                        <img src={plan.plan.img} loading="lazy" />
                                    </>
                                    :
                                    <></>
                            }
                            <MagazzinePlanCard style={{ marginTop: 0 }} noborder={plan.plan.img ? true : false} plan={plan.plan} index={0} onSubscribe={(plan: MagazzinePlan, index: number) => {
                                setOpenCart(true);

                                setTimeout(() => {
                                    if (openCart) {
                                        setOpenCart(false);
                                    }
                                }, timeShoppingCartOpen);

                                addElementToShoppingCart("magazzine", plan as MagazzinePlan & Course & Product);
                            }} />
                        </div>
                    </div>
                </section>

                :
                <Loader text={"Cargando plan"} />
        }
        <Footer />
    </div>
}

export default Plan;