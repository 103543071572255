import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { cloneObject } from "../common/functions";
import { madePost } from "../common/post";
import { Product as ProductType, Location, MagazzinePlan, ResponseGeneral, Winning, Course } from "../common/types";
import { addElementToShoppingCart } from "../common/user";
import { onCatch, onNotFound, timeShoppingCartOpen } from "../common/variables";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/swiper-bundle.css';

import "../styles/pages/Courses.scss";
import "../styles/pages/Product.scss";
import { Categories } from "./CercoVerde";

const Product = () => {
    let location: {
        pathname: string,
        state: {
            product: ProductType
        }
    } = useLocation() as Location;

    const params = useParams<{ product: string }>();
    console.debug(params)

    const [product, setProduct] = useState({ value: {} as ProductType, isLoaded: false });
    const [isOpenCart, setIsOpenCart] = useState(false);

    const [isShowProvider, setIsShowProvider] = useState(true);

    useEffect(() => {
        if (location.state === null) {
            //Obtain by id

            if (!product.isLoaded) {

                let id = params.product!.split("-")[params.product!.split("-").length - 1];

                madePost(
                    {
                        key: "get-product-by-id",
                        id: id
                    },
                    "Product.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        setProduct({ value: restoreProduct(res.VALUE), isLoaded: true });
                    } else {
                        console.error(res.EXCEPTION_MESSAGE);
                    }
                }).catch(err => {
                    console.error(onCatch);
                })
            }
        } else {
            setProduct({ value: restoreProduct(location.state.product), isLoaded: true });
        }

    }, [location])

    const restoreProduct = (product: ProductType): ProductType => {
        //clean "[""]"

        let productClean: ProductType = cloneObject(product)

        try {
            productClean.winnings = JSON.parse(productClean.winnings as unknown as string);
            productClean.provider = JSON.parse(productClean.provider as unknown as string);
            productClean.images = JSON.parse(productClean.images as unknown as string);
        } catch (e) {
            console.debug(e)
        }
        return productClean;
    }

    return <div>
        <Header openShoppingCart={isOpenCart} />

        {
            product.isLoaded ?
                <>
                    <div className="page-course-container page-product">
                        <div className="course-page">

                            <img src={product.value.images[0]} alt={"Curso " + product.value.name} className="bg" />

                            <div className="btns-aux">
                                <div onClick={() => {
                                    setIsOpenCart(true);

                                    setTimeout(() => {
                                        if (isOpenCart) {
                                            setIsOpenCart(false);
                                        }
                                    }, timeShoppingCartOpen);

                                    addElementToShoppingCart("product", product.value as MagazzinePlan & Course & ProductType);
                                }}>
                                    <i className="bi bi-cart"></i>
                                    <small>Agregar al carrito</small>
                                </div>
                            </div>

                            <div className="asociated-images">
                                <Swiper
                                    style={{ maxWidth: "86vw", width: "100%" }}
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    breakpoints={{
                                        400: {
                                            slidesPerView: 2
                                        },
                                        800: {
                                            slidesPerView: 3
                                        },
                                    }}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, Pagination]}

                                    className="asociated-images-swiper"
                                >
                                    {
                                        product.value.images.map((img: string) => {
                                            return <SwiperSlide
                                                className={"slide-image-asoc"}
                                                onClick={() => {

                                                }} >
                                                <img src={img} alt="" onError={(ev: any) => {
                                                    ev.target.src = "/assets/img/404-img.png";
                                                }} />
                                            </SwiperSlide>
                                        })
                                    }
                                </Swiper>
                            </div>

                            <div className="course-content " >
                                <div className="provider">
                                    <div>
                                        <span>Proveedor</span>

                                        <div className={isShowProvider ? "show" : ""}>
                                            <small><b>Empresa: </b>{product.value.provider.enterprise}</small>
                                        </div>
                                        <div className={isShowProvider ? "show" : ""}>
                                            <small><b>Ubicación: </b>{product.value.provider.location}</small>
                                            <small>{product.value.provider.locationInfo}</small>
                                        </div>
                                        {
                                            product.value.provider.others.length > 0 ?
                                                <div className={isShowProvider ? "show" : ""}>
                                                    <small><b>Más:</b></small>
                                                    <small>{product.value.provider.others}</small>
                                                </div>
                                                :
                                                <></>
                                        }

                                        <i className={isShowProvider ? "bi bi-chevron-up" : "bi bi-chevron-down"} onClick={() => {
                                            setIsShowProvider(!isShowProvider);
                                        }}></i>
                                    </div>

                                </div>
                                <div className="course-info" style={{ paddingTop: "2rem" }}>
                                    <span className="badge">
                                        {
                                            Categories.find((e: { title: string, code: string }) => {
                                                return e.code == product.value.category;
                                            })?.title
                                        }
                                    </span>
                                    <div className="name-with-sc">
                                        <h2>
                                            {product.value.name}
                                        </h2>
                                        {
                                            product.value.provider.sanitaryCode != "N/N" ?
                                                <span className="badge">Reg. {product.value.provider.sanitaryCode}</span>
                                                :
                                                <></>
                                        }

                                    </div>
                                    <p>
                                        {product.value.description}
                                    </p>
                                </div>

                                <div className="course-winning">
                                    <span>Detalles</span>
                                    <ul className="winning-course-list">
                                        {
                                            product.value.winnings.map((win: Winning, index: number) => {
                                                return <li className="winning-course" key={"winning-" + index}>
                                                    <i className={"bi bi-" + win.icon}></i>
                                                    <p>{win.text}</p>
                                                </li>
                                            })
                                        }
                                    </ul>

                                    <Button className="btn-green" solid onClick={() => {
                                        setIsOpenCart(true);

                                        setTimeout(() => {
                                            if (isOpenCart) {
                                                setIsOpenCart(false);
                                            }
                                        }, timeShoppingCartOpen);

                                        addElementToShoppingCart("product", product.value as MagazzinePlan & Course & ProductType);
                                    }}>
                                        Agregar al carrito
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <Loader text="Cargando curso" />
        }

        <Footer />
    </div>
}

export default Product;