import { Routes } from "./types";

const routes: Routes = {
    home: "/inicio",
    root: "/",

    pays: "/pagos",
    courses: "/cursos",
    course: "/curso",
    magazzine: "/revista",
    plan: "/plan",
    cercoverde: "/cercoverde",
    producto: "/producto",

    profile: "/perfil",
    shoppingCart: "/tu-carrito",
    login: "/ingresar",
    recovery: "/recupera-tu-cuenta",

    policy: "/politica-de-uso-privacidad-y-cookies",

    confirmationAccount: "/confirmation-account",
    successPaymentCard: "/successPaymentCard",

    supportMail: "mailto:info@nuevaeconomia.com.bo"
}
export default routes;