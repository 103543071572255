import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { madePost } from "../common/post";
import routes from "../common/routes";
import toast from "../common/toast";
import { Course, ResponseGeneral } from "../common/types";
import Card, { CardsManager } from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Title from "../components/Title";

import "../styles/pages/Home.scss";

const Home = () => {

    const [visibleSection, setVisibleSection] = useState({ section: "magazzine" as "magazzine" | "virtual" | "face-to-face" | "train" | "access", isOpen: false });
    const [courses, setCourses] = useState({ values: [] as Course[], isLoaded: false });
    const [visibleCourses, setVisibleCourses] = useState([] as Course[]);

    const allSectionsRef = useRef(new Object as HTMLDivElement);
    const contentRef = useRef(new Object as HTMLDivElement);

    const navigate = useNavigate();

    const obtainAllCourses = () => {
        madePost(
            {
                key: "get-visible-courses"
            },
            "Course.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                setCourses({ values: res.VALUE, isLoaded: true });
            } else {
                toast.error("Ha ocurrido un error, porfavor intenta de nuevo más tarde");
            }
        }).catch(err => {
            console.error("> Error obtaining courses: ", err)
        })
    }

    useEffect(obtainAllCourses, [])

    useEffect(() => {
        if (courses.isLoaded && visibleSection.isOpen) {
            let actualCourses: Course[] = [];

            courses.values.find((course: Course, index: number) => {
                if (course.type === visibleSection.section) {
                    actualCourses.push(course)
                }
            });

            setVisibleCourses(actualCourses);
        }

        try {
            window.scrollTo({ top: contentRef.current.getBoundingClientRect().top, behavior: "smooth" })
        } catch (err) {

        }

    }, [visibleSection]);

    const AllSectionAccess = () => {
        return <div className="home-all-sections-access sides-section-x sides-max-y" ref={allSectionsRef}>
            <Title>Otras categorías</Title>
            <CardsManager
                className="cardsmanager-home-allsections grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4"
                cards={
                    [
                        <Card
                            title="Revista"
                            description=""
                            image="/assets/img/magazzine-card.jpg"
                            className="card-all-sections"
                            onClick={() => navigate(routes.magazzine)}
                            noPrice
                        />,
                        <Card
                            title="Cursos Virtuales"
                            description=""
                            image="/assets/img/virtual-card.jpg"
                            className="card-all-sections"
                            onClick={() => setVisibleSection({ section: "virtual", isOpen: visibleSection.isOpen })}
                            noPrice
                        />,
                        <Card
                            title="Cursos Presenciales"
                            description=""
                            image="/assets/img/presencial-card.jpg"
                            className="card-all-sections"
                            onClick={() => setVisibleSection({ section: "face-to-face", isOpen: visibleSection.isOpen })}
                            noPrice
                        />,
                        <Card
                            title="Talleres"
                            description=""
                            image="/assets/img/train-card.jpg"
                            className="card-all-sections"
                            onClick={() => setVisibleSection({ section: "train", isOpen: visibleSection.isOpen })}
                            noPrice
                        />
                    ]
                }
            />
        </div>
    }

    const SupSection = () => {

        return <div className="home-supsection">
            <div className="access-message">
                <section className=" section">
                    <h1 className="text-3xl w-full md:w-1/2 text-center md:text-left">Accede a nuestra <b>revista</b> y <b>capacitaciones</b> desde tu hogar o negocio</h1>
                </section>
            </div>

            <div className="services-menu py-4">
                <section className="section grid grid-cols-1 sm:grid-col-2 md:grid-cols-4 gap-y-10 gap-x-10 md:gap-x-20">
                    <div className="home-submenu-access" onClick={() => {

                        navigate(routes.magazzine);
                    }}>
                        <span><strong>Revista</strong></span>
                        <img src="/assets/icons/magazzine.png" alt="" />
                        <p className="">
                            Recibe una copia impresa de nuestra revista
                        </p>
                    </div>

                    <div className="home-submenu-access" onClick={() => {
                        setVisibleSection({ section: "virtual", isOpen: true });
                    }}>
                        <span><strong>Cursos Virtuales</strong></span>
                        <img src="/assets/icons/virtual.png" alt="" />
                        <p className="">
                            Aprende desde tu hogar con nuestros cursos virtuales
                        </p>
                    </div>

                    <div className="home-submenu-access" onClick={() => {
                        setVisibleSection({ section: "face-to-face", isOpen: true });
                    }}>

                        <span><strong>Cursos Presenciales</strong></span>
                        <img src="/assets/icons/courses.png" alt="" />
                        <p className="">
                            Aprende nuevas habilidades con nuestros cursos presenciales
                        </p>
                    </div>

                    <div className="home-submenu-access" onClick={() => {
                        setVisibleSection({ section: "train", isOpen: true });
                    }}>
                        <span><strong>Talleres</strong></span>
                        <img src="/assets/icons/train.png" alt="" />
                        <p className="">
                            Capacítate adecuadamente con nuestros talleres
                        </p>
                    </div>
                </section>
            </div>

            <img src="/assets/img/mockup-phone.jpg" alt="" className="img-phone-home" />

        </div>
    }

    return <div className="home-page">
        <Header />

        <SupSection />
        {
            visibleSection.isOpen ?
                <>
                    <div className="magazzine-blue-bg sides-section-x sides-max-y pt-10 mb-5">
                        <h1 className="text-3xl">
                            {
                                visibleSection.section === "face-to-face" ?
                                    <><b className="font-bold">Cursos</b> Presenciales</>
                                    :
                                    (
                                        visibleSection.section === "virtual" ?
                                            <><b className="font-bold">Cursos</b> Virtuales</>
                                            :
                                            (
                                                visibleSection.section === "train" ?
                                                    "Talleres"
                                                    :
                                                    <></>
                                            )
                                    )
                            }
                        </h1>
                        <h5>No dejes de aprender desde la comodidad de tu hogar</h5>
                    </div>
                    <div className="home-presencial-courses-section sides-section-x sides-max-y contrast -mt-28 bg-transparent" ref={contentRef}>

                        {
                            courses.isLoaded ?
                                <CardsManager cards={
                                    visibleCourses.map((course: Course, index: number) => {
                                        return <Card
                                            title={course.title}
                                            description={course.description}
                                            image={course.cover_image}
                                            price={course.price.toString()}
                                            key={"card-" + index}
                                            onClick={() => {
                                                navigate(routes.course + "/" + course.title + "-" + course.course_code, { state: { course: course } });
                                            }}
                                        />
                                    })

                                } />
                                :
                                <Loader text="Cargando cursos" style={{ height: "auto" }} />
                        }
                    </div>
                </>
                :
                <></>
        }

        {
            visibleSection.section == "access" || visibleSection.isOpen ?
                <>
                    {/*  <AllSectionAccess /> */}
                </>
                :
                <></>
        }

        <Footer />
    </div>
}

export default Home;