import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../common/routes";
import { User } from "../common/types";
import { getUser } from "../common/user";
import { setItem } from "../common/useStorage";
import { userStorageName } from "../common/variables";
import Footer from "../components/Footer";
import Header from "../components/Header";

import "../styles/pages/ConfirmationAccount.scss";

const ConfirmationAccount = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const caseParam = params.get("res");

    const [counter, setCounter] = useState(5);

    const navigate = useNavigate();

    useEffect(() => {
        if (counter > 0) {
            setTimeout(() => {
                setCounter(counter - 1)
            }, 1000)
        } else {
            if (caseParam != "0" && caseParam != "1") {
                navigate(routes.home);
            } else {
                //Correct 0
                let us: User | null = getUser();
                if (us) {
                    //Logged
                    us.active = 1;
                    setItem(userStorageName, JSON.stringify(us));
                    navigate(routes.profile);
                } else {
                    navigate(routes.login);
                }


            }
        }
    }, [counter])

    return <div>
        <Header />

        <div className="confirmation-page">
            {
                caseParam == "0" ?
                    <>
                        {/* CORRECT */}
                        <div className="form">
                            <div className="form-content">
                                <div className="form-header">
                                    <h4>Confirmaste correctamente tu cuenta</h4>
                                    <span>Gracias por confirmar tu cuenta, ahora podrás reestablecer tu contraseña con tu correo electrónico</span></div>
                                <div className="form-body">
                                    <span>Volviendo a tu perfil en {counter}</span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    caseParam == "1" ?
                        <>
                            {/* ACTUALLY ACTIVED ACCOUNT */}
                            <div className="form">
                                <div className="form-content">
                                    <div className="form-header">
                                        <h4>Cuenta ya confirmada</h4>
                                        <span>Ya confirmaste tu cuenta, ahora podrás reestablecer tu contraseña con tu correo electrónico</span></div>
                                    <div className="form-body">
                                        <span>Volviendo a tu perfil en {counter}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {/* ERROR URL */}
                            <div className="form">
                                <div className="form-content">
                                    <div className="form-header">
                                        <h4>Revisa la URL</h4>
                                        <span>Ha ocurrido un error, porfavor revisa la URL e intenta nuevamente</span></div>
                                    <div className="form-body">
                                        <span>Volviendo a tu inicio en {counter}</span>
                                    </div>
                                </div>
                            </div>
                        </>
            }
        </div>

        <Footer />
    </div>
}

export default ConfirmationAccount; 