import "../styles/components/Footer.scss";

const Footer = () => {
    return <footer className="footer-component" id="footer-component">
        <h4 style={{fontSize: "1.5rem"}}>Grupo Nueva Economía S.R.L</h4>
        <div className="second-row">
            <div>
                <p style={{fontSize: ".9rem"}}>Grupo Nueva Economía desarrolla productos y servicios creando espacios y soluciones innovadoras, que promueven valor entre los diferentes rubros de la economía.</p>


                <div className="social-container-footer a1">
                    <div className="social-networks-icons">
                        <a href="https://www.facebook.com/nuevaeconomia/" target="_blank">
                            <img src="https://nuevaeconomia.com.bo/img/icons/fb.png" alt="Página en Facebook de Grupo Nueva Economía" />
                        </a>
                        <a href="https://twitter.com/Nueva_Economia" target="_blank">
                            <img src="https://nuevaeconomia.com.bo/img/icons/twitter.png" alt="Página en Twitter de Grupo Nueva Economía" />
                        </a>
                        <a href="https://www.linkedin.com/company/gruponuevaeconomia/" target="_blank">
                            <img src="https://nuevaeconomia.com.bo/img/icons/linkedin.png" alt="Página en LinkedIn de Grupo Nueva Economía" />
                        </a>
                        <a href="https://www.instagram.com/gruponueva.economia/" target="_blank">
                            <img src="https://nuevaeconomia.com.bo/img/icons/instagram.png" alt="Página en Instagram de Grupo Nueva Economía" />
                        </a>
                        <a href="https://www.tiktok.com/@nueva_economia" target="_blank">
                            <img src="https://nuevaeconomia.com.bo/img/icons/tiktok.png" alt="Página en Tiktok de Grupo Nueva Economía" />
                        </a>
                        <a href="https://www.youtube.com/channel/UCMBkGOdR5vMuWGrdV9CoaPg" target="_blank">
                            <img src="https://nuevaeconomia.com.bo/img/icons/youtube.png" alt="Página en Youtube de Grupo Nueva Economía" />
                        </a>
                    </div>

                    <span style={{marginTop: ".5rem"}}>Todos los Derechos Reservados <b>Grupo Nueva Economía 2022</b></span>
                </div>

            </div>

            <div style={{gap: ".5rem"}}>
                <strong style={{fontSize: "1rem"}}>DIRECCIÓN</strong>
                <span style={{ fontSize: ".9rem" }}><b>La Paz:</b> Zona San Miguel, Calle Claudio Aliaga Nro. 1239.</span>
                <span style={{ fontSize: ".9rem" }}><b>Teléfono:</b> +591 78889753</span>

                <span style={{ fontSize: ".9rem" }}><b>Correo:</b> <a href="mailto:arubin@nuevaeconomia.com.bo">arubin@nuevaeconomia.com.bo</a></span>
            </div>

        </div>

        <img src="https://nuevaeconomia.com.bo/img/logos/logoGNEWhite.png" alt="" style={{ height: "3rem", marginTop: ".9rem", display: "none" }} />
    </footer >
}
export default Footer;