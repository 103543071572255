import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { madePost } from "../common/post";
import routes from "../common/routes";
import { Course, ResponseGeneral } from "../common/types";
import Card, { CardsManager } from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Title from "../components/Title";
import "../styles/pages/Courses.scss";

import { saveAs } from 'file-saver';
import Button from "../components/Button";
import Loader from "../components/Loader";
import toast from "../common/toast";

export const DollarCurrency = [27];


const Courses = () => {
    const [courses, setCourses] = useState({ values: [] as Course[], isLoaded: false, order: [] as string[] }); //order: ["face-to-face", "virtual", "train"]

    const navigate = useNavigate();

    const obtainAllCourses = () => {
        madePost(
            {
                key: "get-visible-courses"
            },
            "Course.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                let order = [
                    ["face-to-face", res.VALUE.filter((c: Course) => { return c.type === "face-to-face" }).length],
                    ["virtual", res.VALUE.filter((c: Course) => { return c.type === "virtual" }).length],
                    ["train", res.VALUE.filter((c: Course) => { return c.type === "train" }).length]
                ]
                let sortable = [];

                for (var key in order) {
                    sortable.push([key, order[key]]);
                }

                sortable.sort(function (a: any, b: any) {
                    return a[1][1] - b[1][1];
                });

                setCourses({
                    values: res.VALUE, isLoaded: true, order: [sortable[2][1][0], sortable[1][1][0], sortable[0][1][0]]
                });
            } else {
                toast.error("Ha ocurrido un error, porfavor intenta de nuevo más tarde")
            }
        }).catch(err => {
            console.error("> Error obtaining courses: ", err)
        })
    }

    const goToCourse = (course: Course) => {
        navigate(routes.course + "/" + course.title + "-" + course.course_code, {
            state: {
                course: course
            }
        })
    }

    useEffect(() => {

    }, [courses])

    useEffect(() => {
        obtainAllCourses()
    }, [])

    return <div className="courses-page">
        <Header />


        {
            courses.isLoaded ?
                <div className="">

                    {
                        courses.values.length > 0 ?
                            courses.order.map((key: string, index: number) => {
                                let coursesFrom: Course[] = courses.values.filter((course: Course) => { return key === course.type });
                                if (coursesFrom.length > 0) {
                                    return <>
                                        <div className="magazzine-blue-bg sides-section-x sides-max-y">
                                            <h1 className="text-3xl">
                                                {
                                                    key === "face-to-face" ?
                                                        <><b className="font-bold">Cursos</b> Presenciales</>
                                                        :
                                                        (
                                                            key === "virtual" ?
                                                                <><b className="font-bold">Cursos</b> Virtuales</>
                                                                :
                                                                (
                                                                    key === "train" ?
                                                                        "Talleres"
                                                                        :
                                                                        <></>
                                                                )
                                                        )
                                                }
                                            </h1>
                                            <h5>No dejes de aprender desde la comodidad de tu hogar</h5>
                                        </div>
                                        <section className="home-presencial-courses-section sides-section-x sides-max-y contrast -mt-28 bg-transparent" key={"section-courses-" + index}>
                                            <CardsManager cards={
                                                coursesFrom.map((course: Course, index: number) => {
                                                    return <Card
                                                        title={course.title}
                                                        description={course.description}
                                                        image={(index == 0? "/assets/probes/1.jpg" : (index == 1? "/assets/probes/2.jpg" : "/assets/probes/3.jpg" ) )}
                                                        price={course.price.toString()}
                                                        currency={DollarCurrency.includes(course.course_code) ? "$us" : "Bs"}
                                                        key={"card-" + index}
                                                        onClick={() => goToCourse(course)}
                                                    />
                                                })

                                            } />
                                        </section>
                                    </>
                                } else {
                                    return <></>
                                }
                            })
                            :
                            <div className="items-not-found-message">
                                <label>No se tienen elementos disponibles en este momento</label>
                            </div>
                    }

                </div>
                :
                <Loader text="Cargando cursos" />
        }

        <Footer />
    </div>
}

export default Courses;