import { createRef, forwardRef, useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { cloneObject } from "../common/functions";
import { madePost } from "../common/post";
import routes from "../common/routes";
import toast from "../common/toast";
import { ResponseGeneral, User } from "../common/types";
import { getUser } from "../common/user";
import { clear, setItem } from "../common/useStorage";
import { Catch, onCatch, userStorageName } from "../common/variables";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Input, { InputPhone } from "../components/Input";
import InputLocation from "../components/InputLocation";

import "../styles/pages/Profile.scss";

const Profile = () => {

    const [user, setUser]: [null | User, any] = useState(getUser());

    const [isValidform, setIsVaidForm] = useState({ info: false, pass: false })
    const [cell, setCell] = useState(user?.cellphone);
    const [isSendedEmailConfirm, setIsSendingEmailConfirm] = useState(false);
    const [location, setLocation] = useState("");

    const navigate = useNavigate();

    const nameRef = useRef({} as HTMLInputElement);
    const nit_ciRef = useRef({} as HTMLInputElement);
    const emailRef = useRef({} as HTMLInputElement);

    const prevPassRef = useRef({} as HTMLInputElement);
    const newPassRef = useRef({} as HTMLInputElement);


    useEffect(() => {
        analizeInfoForm()
        analizePasswordForm()

        if (getUser() === null) {
            navigate(routes.login)
        }
    }, [])

    useEffect(() => {
        analizeInfoForm()
    }, [cell])

    const analizeInfoForm = () => {
        try {
            if (
                nameRef.current.value.length > 0 &&
                nit_ciRef.current.value.length > 0 &&
                location.length > 0 &&
                cell!.length > 0
            ) {
                setIsVaidForm({ info: true, pass: isValidform.pass })
            } else {
                setIsVaidForm({ info: false, pass: isValidform.pass })
            }
        } catch (er) {
            setIsVaidForm({ info: false, pass: isValidform.pass })
        }
    }
    const analizePasswordForm = () => {
        if (
            prevPassRef.current.value.length > 5 &&
            newPassRef.current.value.length > 5
        ) {
            setIsVaidForm({ pass: true, info: isValidform.info })
        } else {
            setIsVaidForm({ pass: false, info: isValidform.info })
        }
    }

    const onInfoUpdate = () => {
        setIsVaidForm({ pass: isValidform.pass, info: false })
        madePost(
            {
                key: "update-info",
                user_code: user?.user_code,
                password: user?.password,

                name: nameRef.current.value,
                ci_nit: nit_ciRef.current.value,
                location: location,
                cellphone: cell
            },
            "User.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast.success("Tu información se ha actualizado correctamente");

                try {
                    user!.full_name = nameRef.current.value;
                    user!.ci_nit = parseInt(nit_ciRef.current.value);
                    user!.location = location;

                    setItem(userStorageName, JSON.stringify(user));
                } catch (err) {
                    clear();
                    navigate(routes.home);
                }
            } else {
                toast.error(res.EXCEPTION_MESSAGE)
            }
        }).catch(Catch)
    }
    const onPasswordUpdate = () => {
        setIsVaidForm({ info: isValidform.info, pass: false })
        madePost(
            {
                key: "update-pass",
                user_code: user?.user_code,

                password: prevPassRef.current.value,
                new_password: newPassRef.current.value
            },
            "User.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast.success("Tu contraseña se ha actualizado correctamente");

                try {
                    user!.password = res.VALUE;

                    setItem(userStorageName, JSON.stringify(user));
                } catch (err) {
                    clear();
                    navigate(routes.home);
                }
            } else {
                toast.error(res.EXCEPTION_MESSAGE)
            }
        }).catch(Catch)
    }

    return <div className="profile-page">
        <Header />

        <div className="form">
            <div className="form-content expanded">
                <div className="form-header">
                    <h4>Tu información</h4>
                    <span>Gestiona tu información personal utilizada para tu perfil</span>
                </div>

                <div className="form-body q-2">
                    <Input
                        title="Nombre completo"
                        inputProperties={{
                            defaultValue: user?.full_name,
                            ref: nameRef,
                            onInput: analizeInfoForm
                        }}
                    />

                    <Input
                        title="Número de NIT o CI (Para facturación)"
                        inputProperties={{
                            type: "text",
                            defaultValue: user?.ci_nit,
                            ref: nit_ciRef,
                            onInput: analizeInfoForm
                        }}
                    />

                    <InputPhone
                        defaultValue={cell}
                        onChange={(ev) => {
                            setCell(ev);
                        }}
                    />

                    <Input
                        style={{ alignSelf: "start" }}
                        title="Correo electrónico"
                        inputProperties={{
                            disabled: user?.active == 1 || isSendedEmailConfirm,
                            defaultValue: user?.email,
                            ref: emailRef
                        }}
                        isWithContent
                        isWithContentElement={
                            user?.active == 1 ?
                                <i className="bi bi-check" color="blue"></i>
                                :
                                <Button
                                    style={{ padding: ".25rem .7rem", marginLeft: ".5rem" }}
                                    disabled={isSendedEmailConfirm}
                                    onClick={() => {
                                        if (!isSendedEmailConfirm) {
                                            toast.info("Se está enviando un enlace a tu correo para confirmar tu cuenta");
                                            setIsSendingEmailConfirm(true);

                                            madePost(
                                                {
                                                    key: "confirm-email",
                                                    user_code: user?.user_code,
                                                    email: emailRef.current.value
                                                },
                                                "User.php"
                                            ).then(r => {
                                                let res = r as ResponseGeneral;

                                                if (!res.RESULT) {
                                                    toast.error("El correo no se ha enviado, "+res.EXCEPTION_MESSAGE);

                                                    setIsSendingEmailConfirm(false);
                                                } else {
                                                    toast.success("El correo se ha enviado");
                                                }
                                            }).catch((err) => {
                                                Catch(err);
                                                setIsSendingEmailConfirm(false);
                                            })
                                        }
                                    }}>{!isSendedEmailConfirm ? "Confirmar" : "Confirmando..."}</Button>
                        }
                    />

                    <InputLocation
                        defaultValue={user?.location}
                        onInput={analizeInfoForm}
                        onChange={(loc)=>{
                            setLocation(loc);
                        }}
                    />

                    <div>
                    </div>
                    <Button className="col-2" solid disabled={!isValidform.info} onClick={onInfoUpdate}>Guardar</Button>
                </div>

                <div className="form-header " style={{ marginTop: "2rem" }}>
                    <h4>Seguridad</h4>
                    <span>Cambia tu contraseña regularmente para mantener segura tu cuenta</span>
                </div>

                <div className="form-body q-2">
                    <Input
                        title="Contraseña anterior"
                        required
                        inputProperties={{
                            type: "password",
                            ref: prevPassRef,
                            onInput: analizePasswordForm
                        }}
                    />

                    <Input
                        title="Contraseña nueva"
                        required
                        inputProperties={{
                            type: "password",
                            ref: newPassRef,
                            onInput: analizePasswordForm
                        }}
                    />

                    <Button className="col-2" solid disabled={!isValidform.pass} onClick={onPasswordUpdate}>Actualizar contraseña</Button>
                </div>

                <div className="form-footer centered column border-top">
                    <Button solid onClick={() => {
                        clear();

                        navigate(routes.home);
                    }}>Cerrar sesión</Button>
                    <Button
                        onClick={() => {
                            window.location.href = routes.supportMail
                        }}
                    >Contactar con soporte</Button>
                </div>
            </div>
        </div>

        <Footer />
    </div>
}

export default Profile;