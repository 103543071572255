import { useState } from "react";

import "../styles/components/Card.scss";

import { ReactComponent as Side } from '../styles/svg/side-br.svg';

export const CardsManager = (props: {
    cards: JSX.Element[],
    className?: string
}) => {
    return props.cards.length > 0 ?
        <div className={"cards-manager-component " + props.className}>

            {
                props.cards.map((card) => {
                    return card;
                })
            }
        </div>
        :
        <>
            <label htmlFor="" className="info-void w-full block text-center" style={{ filter: "invert(100%)", paddingBottom: "0", marginBottom: "2rem" }}>No se tienen elementos disponibles en este momento</label>
        </>

}

const Card = (props: {
    title: string,
    description: string,
    image: File | string,
    price?: string,
    className?: string,
    noPrice?: boolean,
    currency?: string,
    onClick?: () => any
}) => {

    const letterLimit = 80;

    const [image, setImage] = useState(props.image as any);

    return <div className={"card-component " + props.className} onClick={props.onClick}>
        <picture className="img">
            <img src={image} alt={props.title} />
            <div className="hidde">
                <Side className="hidde-sup-top-corner" />
                <Side className="hidde-left-bott-corner" />
            </div>
        </picture>

        <div>
            <span className="card-title">{props.title}</span>
            <p className="card-description">{props.description.slice(0, letterLimit) + (props.description.length > letterLimit ? "..." : "")}</p>

            {
                props.noPrice ?
                    <>
                    </>
                    :
                    <p className="card-price"><b>{props.currency && props.price ? (props.currency == "Bs" ? props.price : Math.floor((parseInt(props.price as string)) / 7)) : props.price}</b><small>{props.currency ? props.currency : "Bs"}</small></p>
            }
        </div>
    </div>
}

export default Card;