import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { madePost } from "../common/post"
import routes from "../common/routes"
import toast from "../common/toast"
import { Location, ResponseGeneral } from "../common/types"
import { onCatch } from "../common/variables"
import Button from "../components/Button"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Input from "../components/Input"

const Recovery = () => {
    const location: Location = useLocation();

    const code: null | string = new URLSearchParams(location.search).get("code");
    let email: null | string = new URLSearchParams(location.search).get("email");

    const [isValidForm, setIsValidForm] = useState(false);
    const [action, setAction] = useState(code ? "code" : "no-code");
    const [writtedEmail, setWrittedEmail] = useState("");

    const emailRef = useRef(new Object as HTMLInputElement);
    const codeRef = useRef(new Object as HTMLInputElement);
    const passRef = useRef(new Object as HTMLInputElement);

    const navigate = useNavigate();

    useEffect(() => {
        setIsValidForm(false);
    }, [action])

    const onRecovery = () => {
        setWrittedEmail(emailRef.current.value);
        madePost(
            {
                key: "recovery-user",
                email: email ? email : emailRef.current.value,
                //code: code? code : codeRef.current.value
            }, "User.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                setAction("code");
            } else {
                toast.error(res.EXCEPTION_MESSAGE);
            }
        }).catch(err => {
            toast.error(onCatch);
        })
    }

    const onCode = () => {
        madePost(
            {
                key: "recovery-user-by-code",
                email: email ? email : writtedEmail,
                code: code ? code : codeRef.current.value,
                password: passRef.current.value
            }, "User.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast.success("La contraseña se ha cambiado satisfactoriamente, utilizala para iniciar sesión");

                navigate(routes.login);
            } else {
                toast.error(res.EXCEPTION_MESSAGE);
            }
        }).catch(err => {
            toast.error(onCatch);
        })
    }

    return <div className="recovery-page">
        <Header />
        <div className="form">
            <div className="form-content">
                {
                    action === "no-code" ?

                        <>
                            <div className="form-header">
                                <h4>Recupera tu cuenta</h4>
                                <span>Se enviará un código a tu correo con el que podrás restaurar tu contraseña, revisa también tu bandeja de SPAM</span>
                            </div>

                            <Input required title="Correo electrónico" inputProperties={{
                                type: "email",
                                ref: emailRef,
                                onInput: (ev: any, is: { isEmail: boolean }) => {
                                    if (ev.target.value.length > 0 && is.isEmail) {
                                        setIsValidForm(true);
                                    } else {
                                        setIsValidForm(false);
                                    }
                                }
                            }} />

                            <div className="form-footer">
                                <Button
                                    onClick={() => { navigate(routes.login) }}>Iniciar sesión</Button>
                                <Button
                                    solid
                                    disabled={!isValidForm}
                                    onClick={onRecovery}>
                                    Enviar código
                                </Button>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-header">
                                <h4>Ingresa el código</h4>
                                <span>Ingresa el código que se ha enviado a tu correo electrónico y una nueva contraseña</span>
                            </div>

                            <Input required title="Código" inputProperties={{
                                type: "text",
                                ref: codeRef,
                                onInput: (ev: any) => {
                                    if (ev.target.value.length > 0) {
                                        setIsValidForm(true);
                                    } else {
                                        setIsValidForm(false);
                                    }
                                },
                                defaultValue: code ? code : ""
                            }} />

                            <Input required title="Nueva contraseña (Mínimo 5 caracteres)" inputProperties={{
                                type: "password",
                                ref: passRef,
                                onInput: (ev: any) => {
                                    if (ev.target.value.length > 4) {
                                        setIsValidForm(true);
                                    } else {
                                        setIsValidForm(false);
                                    }
                                }
                            }} />

                            <div className="form-footer">
                                {
                                    code ?
                                        <></>
                                        :
                                        <Button
                                            onClick={() => { setAction("no-code") }}>No lo recibí aún</Button>
                                }
                                <Button
                                    solid
                                    disabled={!isValidForm}
                                    onClick={onCode}>
                                    Recuperar
                                </Button>
                            </div>
                        </>

                }
            </div>
        </div>
        <Footer />
    </div>
}

export default Recovery;