export let isset = (element: any): boolean => {
    if (typeof element === undefined || element === null || element === undefined) {
        return false;
    }
    return true;
}

export let cloneObject = (element: any) => {
    return JSON.parse(JSON.stringify(element))
}
