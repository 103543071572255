import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import routes from "./common/routes";
import Home from "./pages/Home";

import "./styles/globals.scss";
import "bootstrap-icons/font/bootstrap-icons.scss";
import Profile from "./pages/Profile";
import Magazzine from "./pages/Magazzine";
import Courses from "./pages/Courses";
import Login from "./pages/Login";
import Course from "./pages/Course";

import Recovery from "./pages/Recovery";
import Shopping from "./pages/Shopping";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./common/toast";
import Policy from "./pages/Policy";
import ConfirmationAccount from "./pages/ConfirmationAccount";
import SuccessPaymentCard from "./pages/SuccessPaymentCard";
import CercoVerde from "./pages/CercoVerde";
import Product from "./pages/Product";

import "tailwindcss/tailwind.css"
import Plan from "./pages/Plan";

function App() {
  return (
    <SnackbarProvider
    anchorOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    preventDuplicate
    >
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path={routes.root} element={<Home />} />
            <Route path={routes.home} element={<Home />} />
            <Route path={routes.profile} element={<Profile />} />
            <Route path={routes.magazzine} element={<Magazzine />} />
            <Route path={routes.cercoverde} element={<CercoVerde />} />

            <Route path={routes.shoppingCart} element={<Shopping />} />

            <Route path={routes.courses} element={<Courses />} />
            <Route path={routes.course + "/:course"} element={<Course />} />
            <Route path={routes.producto + "/:product"} element={<Product />} />
            <Route path={routes.plan} element={<Plan />} />

            <Route path={routes.login} element={<Login />} />
            <Route path={routes.recovery} element={<Recovery />}/>

            <Route path={routes.policy} element={<Policy />}/>

            <Route path={routes.confirmationAccount} element={<ConfirmationAccount />}/>
            <Route path={routes.successPaymentCard} element={<SuccessPaymentCard />} />

          </Routes>
        </BrowserRouter>
      </div>
      <SnackbarUtilsConfigurator />
    </SnackbarProvider>
  );
}

export default App;
