import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import { HTMLInputTypeAttribute, useRef, useState } from "react";
import { deprecate } from 'util';

import { availCountry, ListType } from '../common/locations/list';

/*
const InputLocation = (props: {
    hasBorder?: boolean,
    id?: string,
    name?: string,
    className?: string,
    styleClass?: string,
    ref?: any,
    innerRef?: any,
    required?: boolean,
    defaultValue?: string,
    disabled?: boolean,
    onFocus?: (ev: any) => any,
    onInput?: (ev: any) => any,
    onChange?: (ev: any) => any,
    onBlur?: (ev: any) => any,
    onClick?: (ev: any) => any
}) => {

    const [value, setValue] = useState(props.defaultValue ? props.defaultValue : "");
    const [withError, setWithError] = useState(false);

    try {
        return <div className="form-input">
            <label>{"Ubicación "} {props.required ? <small color="red">*</small> : <></>}</label>

            <PlacesAutocomplete value={value} onChange={(val: string) => {
                setValue(val);
            }}
                onError={(err: any) => {
                    console.log(err)
                }}
            >

                {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
                    <div className="autocomplete-root form-input-element">
                        <input id={props.id}
                            className={(props.className ? props.className : "").concat(props.hasBorder ? (props.hasBorder ? " border-input" : "") : "")}
                            onFocus={props.onFocus}
                            onClick={props.onClick}
                            ref={props.innerRef}
                            style={!withError ? { color: "black", border: "1px solid rgba(0, 0, 0, 0.3)" } : { color: "red", border: "1px solid red" }}
                            onInput={(ev: any) => {
                                if (ev.target.value.length > 0) {
                                    setWithError(false);
                                } else {
                                    setWithError(true);
                                }
                                if (props.onInput) {
                                    props.onInput(ev);
                                }
                            }}

                            {...getInputProps()}

                            onBlur={props.onBlur ? props.onBlur : () => { }}

                            disabled={props.disabled ? props.disabled : false}
                        />
                        {
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Cargando...</div>}
                                {suggestions.map((suggestion) => (
                                    <div {...getSuggestionItemProps(suggestion)}>
                                        <span>{suggestion.description}</span>
                                    </div>
                                ))}
                            </div>

                        }
                    </div>
                )}

            </PlacesAutocomplete>
        </div>
    } catch (err) {
        return <label htmlFor="">Ha ocurrido un error, porfavor revisa tu conexión</label>
    }
}
*/

const InputLocation = (props: {
    hasBorder?: boolean,
    id?: string,
    name?: string,
    className?: string,
    styleClass?: string,
    innerRef?: any,
    required?: boolean,
    defaultValue?: string,
    disabled?: boolean,
    onFocus?: (ev: any) => any,
    onInput?: (ev: any) => any,
    onChange?: (value: any) => any,
    onBlur?: (ev: any) => any,
    onClick?: (ev: any) => any
}) => {

    const [value, setValue] = useState(props.defaultValue ? props.defaultValue : "");//on Format CC, PP|C_C*C_P
    const [withError, setWithError] = useState(false);
    const [visible, setVisible] = useState({ open: false, list: [] as ListType[] });

    const inputRef = useRef({} as HTMLInputElement);

    try {
        return <div className="form-input">
            <label>{"Ubicación "} {props.required ? <small color="red">*</small> : <></>}</label>

            <div className="autocomplete-root form-input-element">
                <input id={props.id}
                    className={(props.className ? props.className : "").concat(props.hasBorder ? (props.hasBorder ? " border-input" : "") : "")}
                    onFocus={props.onFocus}
                    onClick={props.onClick}
                    ref={props.innerRef ? props.innerRef : inputRef}
                    style={!withError ? { color: "black", border: "1px solid rgba(0, 0, 0, 0.3)" } : { color: "red", border: "1px solid red" }}
                    onInput={(ev: any) => {
                        if (ev.target.value.length > 0) {
                            let finded: ListType[] = availCountry.filter((e: ListType) => {
                                return e.ciudad.toString().toLowerCase().includes(ev.target.value.toLowerCase()) ||
                                    e.pais.toString().toLowerCase().includes(ev.target.value.toLowerCase());
                            })

                            setVisible({ open: true, list: finded });
                        } else {
                            setVisible({ open: false, list: [] });
                        }
                        if (props.onInput) {
                            props.onInput(ev);
                        }
                    }}

                    onBlur={props.onBlur ? props.onBlur : () => { }}
                    defaultValue={value}
                    disabled={props.disabled ? props.disabled : false}
                />
                {
                    <div className="autocomplete-dropdown-container">
                        {visible.list.slice(0, 7).map((suggestion: ListType) => (
                            <div onClick={() => {
                                /**
                                 * 
                                 * LOCATION IS SENDED LIKE Listado paises FROM Pagos Net
                                 * 
                                 * ciudad, pais|code_ciudad*code_pais
                                 * 
                                 */
                                let location = suggestion.ciudad + ", " + suggestion.pais;
                                let val = location + "|" + suggestion.code_ciudad + "*" + suggestion.code_pais;

                                setValue(val);
                                setVisible({ open: false, list: [] });

                                if (props.innerRef) {
                                    props.innerRef.current.value = location;
                                } else {
                                    inputRef.current.value = location;
                                }

                                if (props.onChange) {
                                    props.onChange(val)
                                }
                            }}>
                                <span>{suggestion.ciudad}, {suggestion.pais}</span>
                            </div>
                        ))}
                    </div>

                }
            </div>

        </div>
    } catch (err) {
        return <label htmlFor="">Ha ocurrido un error, porfavor revisa tu conexión</label>
    }
}

export default InputLocation;