/* eslint-disable jsx-a11y/iframe-has-title */
import html2canvas from "html2canvas";
import { useEffect, useLayoutEffect, useRef } from "react";
import { Subscription } from "../common/types";

const Invoice = (props: {
    to: "nuevaeco" | "appcredit",
    products: string, //name;;cant;;price;;....
    subscription: Subscription,
    totalPrice: number,
    expirationDate: string,
    canvasRef: any
}) => {
    const iframeRef = useRef({} as HTMLIFrameElement);
    const invoiceContainerRef = useRef({} as HTMLDivElement);

    return <div style={{ width: "100%", height: "100%", maxHeight: "inherit", borderRadius: "0", padding: "0", overflow: "auto", alignItems: "flex-start", justifyContent: "flex-start" }} ref={invoiceContainerRef}>
        <iframe onLoad={() => {
            let body = (iframeRef.current.contentWindow?.document.getElementById("body-invoice"))!;

            html2canvas(body, {
                scale: 2,
            }).then((canvas) => {
                canvas.style.width = "100%";
                canvas.style.height = "100%";
                canvas.style.overflowY = "auto";
                props.canvasRef.current = canvas;
                invoiceContainerRef.current.appendChild(canvas)
            })
        }} src={`/invoiceNuevaEconomiaPagos.html?r=${props.subscription.subscription_code}&mth=${props.subscription.type_payment}&st=${props.subscription.state_payment}&amt=${props.totalPrice}&to=${props.to}&dt=${props.expirationDate}&prs=${props.products}`} frameBorder={0} style={{ width: "0%", height: "100%" }} ref={iframeRef}></iframe>
    </div>
}
export default Invoice;
//ATBBnpR5CgFMXeDU6ZdEV52dbAZKCDEC7EC8